import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';

function PrivacyPolicyPage() {
   return (
    <>
      <HeaderPage />
 <section className="filters-cs cs-sc-padding mt-5 pt-4 country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Privacy Policy
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
<section className="privacy bg-light py-5">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="heading text-center mb-4">
          <h2>Privacy Policy</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <p>
        Lemon Tree Hotels Limited (LTHL) herein referred to as “LTH”
        (collectively referred to as the <strong>"Company"</strong> or
        <strong> "LTH"</strong> or "Us" and also indicated by "us", "we" or
        "our") and our affiliated companies, are committed to respecting your
        online privacy and recognize your need for appropriate protection and
        management of any personally identifiable information ("Personal
        Information") you share with us based on applicable data protection laws
        and regulations.
      </p>
      <p>
        This Privacy Policy<strong> ("Policy") </strong>applies to all visitors,
        users and others (hereinafter referred to as <strong>"You"</strong> or
        the <strong>"User"</strong>) who access or use the (i) Website- Lemon
        Tree Hotels - Privacy and Cookies
        Policy(www.lemontreehotels.com/privacy-policy.aspx) (ii) the social
        media accounts and/or pages that LTH control (Sites/Apps).
      </p>
      <p>
        Except as otherwise provided in this Policy, in general, you can
        visit/use our Sites/Apps without identifying who you are. LTH will
        provide you with the option to deal with LTH without identifying
        yourself where it is lawful and practical. However, particular sections
        on our Sites/Apps may require you to provide personal information in
        order that we may provide the products/services or information you have
        requested while visiting/using our Sites/Apps.
      </p>
      <p>
        This Privacy Policy is intended to inform you know what personal
        information we collect, why we collect (purpose) and what we do with it
        (use), processing/sharing and transfer of personal information and your
        rights relating to such personal information shared with us.
      </p>
      <p>
        <strong>Personal Information</strong> means any information that
        identifies you as an individual (‘data subject’ here in referred as
        you/your’) and any other information we associate with it. In particular
        using a common identifier this includes but is not limited to: first and
        last name, email address, mailing and residential address, telephone
        number, title, birth date, gender, occupation, your profession and other
        information needed to provide a service you have requested.
      </p>
      <p>
        <strong>Sensitive personal</strong> data or information means personal
        information revealing racial or ethnic origin, political opinions,
        religious or philosophical beliefs, or trade union membership, and the
        processing of genetic data, biometric data for the purpose of uniquely
        identifying a natural person,
      </p>
    </div>
    <div className="row">
      <h4>WHAT PERSONAL INFORMATION WE COLLECT?</h4>
    </div>
    <div className="row">
      <p>
        <strong>A. Personal Data Information you give us</strong>
      </p>
      <p>
        We may collect Personal Information shared by you on our Sites/Apps.
        Depending upon the site(s) and context, this information may include the
        following:
      </p>
      <ul>
        <li>
          <strong>Contact Information:</strong>Name, phone number, address,
          city, state, postal/zip code, email address
        </li>
        <li>
          <strong>Demographic information:</strong>Date of birth, gender,
          occupation, country of origin, educational details, and employer
          details.
        </li>
        <li>
          <strong>Account Information:</strong>If you create user account on our
          website, then we may collect username/name, password, email, mobile
          number, and answers to your security question.
        </li>
      </ul>
      If you provide us with Personal Information concerning your relatives,
      friends or other third parties, by doing so you confirm and guarantee that
      you have received an appropriate consent from such third parties for the
      disclosure of such Personal Information to us for use for the purposes set
      out in this Privacy Policy.
      <p />
      <p>
        <strong>B. Information collected automatically</strong>
      </p>
      <p>
        <strong>Log information</strong>
      </p>
      <p>
        Depending upon the Sites/Apps or context, we automatically collect and
        store certain information that your browser sends whenever you visit our
        Sites/Apps in server logs ("Log Data"). This includes:
      </p>
      <ul>
        <li>details of how you used our Sites/Apps;</li>
        <li>Internet Protocol address;</li>
        <li>
          device event information such as caches, system activity, hardware
          settings, browser type, browser language, the date and time of your
          visit, time spent on those pages and other statistics and referral
          URL; and
        </li>
        <li>
          cookies, web beacons and other similar technologies that may uniquely
          identify your browser or your Account. You can instruct your browser
          to refuse all cookies or to indicate when a cookie is being sent.
          However, if you do not accept cookies, you may not be able to use some
          portions of our Sites/Apps.
        </li>
      </ul>
      <p />
    </div>
    <div className="row">
      <p>
        <strong>
          <u>Information collected through cookies</u>
        </strong>
      </p>
      <p>
        Cookies are small text files that a website downloads onto your computer
        or other internetenabled devices (such as mobile phones and tablets)
        when you visit a website. The cookie will help the website recognise
        your device the next time you visit.
      </p>
      <p>
        We may use both session cookies (disappear after you close your browser)
        and persistent cookies (remain after you close your browser and may be
        accessed every time you use or connect to our Sites/Apps). We may
        collect information like user’s IP address, geographical location,
        browser/device type and version, operating system, referral source,
        device, length of visit, page views and website navigation paths, as
        well as information about the timing, frequency and pattern of user
        website use through "cookies".
        <br />
        You can block cookies by activating the setting on your browser that
        allow you to refuse the setting of all or some cookies. However, if you
        use your browser settings to block all cookies (including essential
        cookies), you may not be able to access all or parts of our site, or
        they may not function properly.
        <br />
        For more information about our use of cookies please refer to our Cookie
        Policy.
      </p>
      <p>
        <strong>
          <u>Social Media Widgets</u>
        </strong>
      </p>
      <p>
        Our sites and applications may include social media features connected
        with social media platform such as Facebook, Twitter, Instagram,
        Snapchat, Google Plus, Pinterest, YouTube and others. These features may
        collect information about your IP address and which page you are
        visiting or features you are using, and may set a cookie or employ other
        tracking technologies. Social media features and widgets are either
        hosted by a third party or hosted directly on our Sites/Apps. Your
        interactions with those features are governed by the privacy policies of
        the companies that provide them
      </p>
      <p>
        Tracking code in website analytics, a tracking code is a snippet of
        script that tracks the activity of a website user by collecting data and
        sending it to the analytics module. The code is generated automatically,
        is different for each website, and can be installed on each page that
        needs tracking.
      </p>
      <p>
        <strong>
          <u>Careers</u>
        </strong>
      </p>
      <p>
        Our site may include a link to our Career Section. Any Personal
        Information submitted through that portion of the sites, by upload or
        via e-mail, will be governed as per this privacy policy
      </p>
    </div>
    <div className="row">
      <h4>HOW WE COLLECT THE INFORMATION?</h4>
      <p>
        LTH will generally collect your personal information directly from you.
        This may be achieved through your interactions with LTH including
        interactions via our Sites/Apps, other social media applications or
        internet sites, telephone, email or writing to us.
      </p>
      <p>
        LTH may also collect your personal information from a third-party
        source, such as our vendors, business partners, government authorities
        and judicial authorities.
      </p>
    </div>
    <div className="row">
      <h4>WHY WE COLLECT THESE INFORMATION?</h4>
      <p>
        Unless otherwise provided in the Policy, we may use personal information
        collected from the Sites/Apps in various ways including:
      </p>
      <ul>
        <li>
          To facilitate or fulfil the information, products or services you have
          requested.
        </li>
        <li>
          To contact the User for confirming your registration on our website.
        </li>
        <li>
          To communicate with the User to respond to his/her queries or seeking
          feedback or resolving disputes on the product and services.
        </li>
        <li>
          For business, marketing and promotional purposes such as sending
          information about special promotions, programs, schemes, offers, new
          features, plans on products/services and marketing communications that
          we believe may be of interest to you.
        </li>
        <li>
          For the legitimate business interest, such as prevention of money
          laundering, fraud detection and prevention, and enhancing safety. To
          consider an application for career with LTH.
        </li>
        <li>
          To consider an application from prospective Vendors for partnering
          with LTH.
        </li>
        <li>
          To educate you about us, our products and services or to understand
          your interest to improvise the content and performance of our
          Sites/Apps.
        </li>
        <li>
          For maintenance of high quality and standards of products and
          services.
        </li>
        <li>
          To send you important information regarding the Sites/Apps, changes in
          terms and conditions, user agreements, and policies and/or other
          administrative information
        </li>
        <li>
          <strong>For internal business operations including:</strong>
          <ol>
            <li>
              Reviews and data analysis for the website (e.g., to determine the
              number, category of visitors to specific pages within the
              website).
            </li>
            <li>
              To manage, operate, maintain and secure our Sites/Apps, network
              system, and other assets and to customize/personalise your
              experience with us, which may include displaying content based
              upon your preferences.
            </li>
            <li>
              For the purposes of analysing the use of the Sites/Apps, enabling
              and monitoring your use of our Sites/Apps, operating our
              Sites/Apps, ensuring the security of our Sites/Apps, for
              maintaining back-ups of our databases.
            </li>
            <li>
              To help diagnose problems with our server, and to administer our
              Sites/Apps.
            </li>
          </ol>
        </li>
        <li>
          To comply with our legal obligations or as otherwise permitted by law.
        </li>
        <li>
          To investigate potential breaches, or to protect the rights, property
          or safety of LTH, the Users of our Sites/Apps or others.
        </li>
        <li>For any other purpose necessary or incidental to our business.</li>
        <li>For any other purpose for which you give your consent.</li>
      </ul>
      In the event we use your Personal Information for other purposes, not
      specified above, we will inform you about the specific purposes for
      processing of your personal information and, when required, our basis for
      doing so at the time we collect the Personal Information from you to the
      extent required by law.
      <br />
      For the purposes of clarity, we may also use your personal information in
      combination with information we obtain from third parties about you for
      the same purposes described above.
      <br />
      In the event that you provide any personal information on any of the
      Sites/Apps, you consent to the transfer of such information across country
      borders, and to the use, processing, and disclosure of such information in
      global locations, including those that may have different levels of
      privacy protection than in your own country
      <p />
    </div>
    <div className="row">
      <h4>THIRD PARTY LINKS:</h4>
      <p>
        Our Sites/Apps may have links to the websites/Apps of other third
        parties and these thirdparty websites/Apps may collect personal
        information about Users for their own purpose, in such cases, our
        Privacy Policy does not extend to these external websites/Apps of third
        parties. Please be aware that if you access these links, you will be
        leaving our Site(s). We encourage users to read the privacy policies of
        those websites/apps, as we are not responsible for their content, links,
        or privacy procedures
      </p>
    </div>
    <div className="row">
      <h4>RETENTION, PROCESSING AND STORAGE OF INFORMATION:</h4>
      <p>
        Your personal data processed by LTH are kept in a form which permits
        your identification for no longer than is necessary for the purposes for
        which the personal data are processed in line with legal, regulatory,
        contractual or statutory obligations as applicable.
        <br />
        At the expiry of such periods, your personal data will be deleted or
        archived to comply with legal/contractual retention obligations or in
        accordance with applicable statutory limitation periods.
      </p>
    </div>
    <div className="row">
      <h4>TO WHOM WE SHARE YOUR INFORMATION WITH?</h4>
      <p>
        We may share the Personal Information collected from the Sites/Apps with
        third parties as outlined in this section
      </p>
      <ol>
        <li>
          <strong>Affiliates</strong>
          <br />
          Our parent company, subsidiaries, joint ventures, group and associate
          companies. These entities may use this information for the purposes
          outlined above.
        </li>
        <li>
          <strong>Business Partners</strong>
          <ol type="I">
            <li>
              <strong>Service Providers</strong> who perform services on behalf
              of LTH and which may need information about you in order to
              perform their functions including but not limited to authorised
              service partners, call centre operators, marketing contractors,
              social media website providers, data entry service providers, IT
              agencies operating handling or maintaining Sites/Apps,
              storing/processing information, overseas service providers who
              work for us etc
            </li>
            <li>
              <strong>Vendors </strong>who are business associates of LTH like
              suppliers, research and development vendors, professional
              advisors, agents, representatives etc.
            </li>
          </ol>
        </li>
        <li>
          <strong>Other Partners</strong>
          <br />
          Any third party with whom LTH co-brand or partner with or collaborate
          with or with any other third parties as may be necessary for LTH’s
          business purposes or to investigate potential data incidents or to
          protect the rights, property and safety of LTH and the Users of our
          Sites/Apps or others
        </li>
        <li>
          <strong>Legal Authorities</strong>
          <br />
          We may share your Personal Information in response to any notice,
          order, investigation or demand or request or any other communication
          from a law enforcement agency which necessitate or mandate the sharing
          of such personal information, or to otherwise comply with applicable
          laws.
        </li>
        <li>
          <strong>Changes in Corporate Structure or Bankruptcy</strong>
          <br />
          In the event that LTH is involved in a merger, acquisition,
          reorganization or sale of assets, bankruptcy, your information may be
          transferred as part of that transaction. We also may retain a copy of
          such information.
        </li>
      </ol>
      We DO NOT use or disclose Information for purposes other than as mentioned
      in this Policy, except with the consent of User providing such Information
      or as required by law.
      <p />
    </div>
    <div className="row">
      <h4>
        INTERNATIONAL SHARING AND / OR TRANSFERS OF YOUR PERSONAL INFORMATION
      </h4>
      <p>
        LTH is a global company and therefore uses global dealers, distributors,
        vendors, subcontractors and other business partners, as well as global
        IT systems and applications. We may require the transfer of PI/SPI to
        other third parties that are located outside of the LTH’s country of
        business. We shall develop a standardized approach for protection of
        data moving across borders. We will adopt appropriate technical and
        administrative controls that apply well to cross border data flows to
        act as an accountability framework for information management as a whole
        and including natural checkpoints for each step of international
        transfer.
      </p>
    </div>
    <div className="row">
      <h4>ACCESS AND ACCURACY</h4>
      <p>
        LTH wants to maintain only accurate information about the users. You can
        request access to your personal information by contacting us at{" "}
        <a href="mailto:dpo@lemontreehotels.com">dpo@lemontreehotels.com.</a>{" "}
        Upon receipt of appropriate identification information, and where
        required by applicable Data Protection Laws, LTH will provide you with
        access to the information that it maintains about you.
      </p>
    </div>
    <div className="row">
      <h4>YOUR RIGHTS</h4>
      <p>
        At LTH, we take your privacy very seriously, and we never monetize your
        information; we use it to enhance your experience. When it comes to your
        privacy rights, we want to make sure you have everything you need to
        make informed decisions. You have the right to:
      </p>
      <ul>
        <li>
          Right of<strong> Access</strong> - You have the right to be informed
          why and how we collect your personal data, how we will use this
          information, who we share it with, what are the security measures we
          take to protect this information and what your individual rights are;
          We will publish this information in this Privacy Notice. LTH shall
          provide a copy of the personal information undergoing processing. For
          any further copies requested, please note that LTH may charge a
          reasonable fee based on administrative costs
        </li>
        <li>
          Right to<strong> Rectification </strong>- You have the right for
          inaccurate or incomplete data we hold to be updated.
        </li>
        <li>
          Right to <strong>Erasure</strong> - Except in very specific cases
          where provided by law, you have the right to request that we delete
          any Personal Information which we are holding about you.
        </li>
        <li>
          Right to <strong>Restriction of Processing </strong>- Except in very
          specific cases where provided by law, you have the right to restrict
          processing of all or some of your personal information (e.g., if we
          have no legal right to process it) or to limit our use of it (e.g., if
          your personal data is inaccurate or unlawfully held).
        </li>
        <li>
          Right to Data Portability - You have the right to receive your
          personal information or to transmit that information to another
          controller in a structured, commonly used and machine-readable format.
        </li>
        <li>
          Right to <strong>Object to the Processing </strong>- You have the
          right to object, on grounds relating to your particular situation, at
          any time to processing of personal data concerning you. Such right can
          be exercised at any time where your personal data is processed for
          direct marketing purposes
        </li>
        <li>
          Right to object being subjected to a decision based solely on
          automated processing, including profiling.
        </li>
        <li>
          You may opt-out of receiving non-essential (promotional,
          marketing-related) communications from us or our Affiliates/Business
          Partner/Other Partners/Third Parties. If you want to opt-out from any
          such communication as mentioned above from LTH then you may send an
          email to DPO email ID (as mentioned under “Contact Us” section of this
          policy).
        </li>
      </ul>
      stage, you can withdraw your consent to the retention/storage/processing
      of your Personal Information and LTH shall erase the Personal Information
      withdrawn by you unless there is a contractual obligation or legal
      requirement/obligation to retain/hold it or otherwise permitted by law to
      retain it. However, upon such withdrawal, the Company will not able to
      communicate with you and provide you with certain information on product
      and/or services which are required for fulfilment of any obligation.
      <br />
      LTH may be allowed by law, in particular in case of excessive or
      manifestly unfounded request, to charge a fee for fulfilling your request,
      subject to applicable conditions. The relevant contact details of Data
      Protection Officer are provided in this Policy.
      <br />
      How to Submit a RequestYou may submit a request to exercise your rights by
      following below steps:
      <p />
      <ul>
        <li>Download and fill the DSR form attached below</li>
        <a
          href="https://www.lemontreehotels.com/assets/images/Form.doc"
          download="form"
          target="_blank"
        >
          <img
            src="https://www.lemontreehotels.com/assets/front/images/policy.png"
            alt=""
            width={50}
            height={50}
          />
        </a>
        <li>
          Submit the DSR form on the following email{" "}
          <a href="mailto:dpo@lemontreehotels.com">dpo@lemontreehotels.com.</a>
        </li>
      </ul>
    </div>
    <div className="row">
      <h4>HOW WE PROTECT YOUR INFORMATION?</h4>
      <p>
        Our Sites/Apps have stringent security practice and measures in place to
        protect the personal information provided to us. Our database stores
        Personal Information in secure environment that are designed to
        safeguard it from loss, misuse, wrongful disclosure, destruction and
        alteration. The security measures are reviewed periodically by LTH to
        keep pace with business, technology and regulatory changes.
      </p>
      <p></p>
      <ul>
        <li>
          The security and confidentiality of your Personal Data is important to
          us and LTH has invested significant resources to protect the
          safekeeping and confidentiality of your personal data. When using
          external service providers acting as processors, we require that they
          adhere to the same standards as LTH. Regardless of where your personal
          information is transferred or stored, we take all steps reasonably
          necessary to ensure that personal data is kept secure
        </li>
        <li>
          We have physical, electronic, and procedural safeguards that comply
          with the applicable laws to protect personal information about you. We
          seek to ensure compliance with the regulatory requirements to ensure
          the protection and preservation of your privacy.
        </li>
        <li>
          We take appropriate security measures to protect against unauthorized
          access to or unauthorized alteration, disclosure or destruction of
          data.
        </li>
      </ul>
    </div>
    <div className="row">
      <h4>CONTACT US</h4>
      <p>
        {" "}
        We welcome your questions, comments, and concerns about privacy. If you
        have any questions about the Privacy Policy or our data practices,
        please contact us as follows:
      </p>
      <p>
        <strong>Data Protection Officer</strong>
        <br />
        Lemon Tree Hotels Ltd.,
        <br />
        Asset No. 6, Aerocity Hospitality District,
        <br />
        New Delhi - 110037
        <br />
        Phone: +91 4505 0101
        <br />
        Email:
        <a href="mailto:dpo@lemontreehotels.com"> dpo@lemontreehotels.com</a>
      </p>
      <p>
        The Data Protection officer shall expeditiously redress the grievances,
        but within reasonable time as may be provided in applicable laws. Please
        note that email communications are not always secure, so please do not
        include credit card information or other sensitive information in any
        email to us.
      </p>
    </div>
    <div className="row">
      <h4>UPDATES TO THIS PRIVACY POLICY</h4>
      <p>
        We may periodically update this Privacy Policy. Please refer the
        “Updated on” legend at the top of this page to see when this Privacy
        Policy was last revised. Any changes to this Privacy Policy will become
        effective when we post the revised Privacy Policy on our sites. Your use
        of the Sites following these changes means that you accept the revised
        Privacy Policy.
      </p>
    </div>
    <div className="row">
      <h4>MISCELLANEOUS</h4>
      <p>
        This Privacy Policy shall be governed by the laws of India and the
        courts in New Delhi shall have the exclusive jurisdiction to try any
        disputes arising thereof. This Privacy Policy shall be governed by any
        applicable data protection laws.
      </p>
    </div>
  </div>
</section>


      <FooterPage />


    </>
  )

}

export default PrivacyPolicyPage;

