import HeaderPage2 from "../Header2";
import FooterPage from '../Footer';
import $ from 'jquery';
import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {Helmet} from "react-helmet";

function GalleryPage() {

  const{brand_slug} = useParams(); 
  const{city_alias} = useParams();
  const{hotel_slug} = useParams();

  const[htgallery, sethtgallery] = useState({
    brand_slug:brand_slug,
    city_slug:city_alias,
    hotel_slug:hotel_slug
})

const[htfacade, sethtfacade] = useState([]);
const[htgroom, sethtgroom] = useState([]);
const[htgrest, sethtgrest] = useState([]);
const[htgevent, sethtgevent] = useState([]);
const[meta1, setmeta1] = useState([]);

const showhotelgallery = () =>{
  axios.post('https://www.sdsfoundation.com/lth/demo/hotel-gallery', htgallery).then(res => {
    sethtfacade(res.data.gallery_facade);
    sethtgroom(res.data.gallery_room);
    sethtgrest(res.data.gallery_restaurant);
    sethtgevent(res.data.gallery_event);
    setmeta1(res.data.hotel_meta);
    
}).catch(err => {
console.log(err)
})
}


  // load more
  $(document).ready(function(){
    $(".gallery_product").slice(0,3).show();
    $("#load-more").click(function(e){
      e.preventDefault();
      $(".gallery_product:hidden").slice(0,3).fadeIn("slow");
      // or make ajax call and append 
      
      if($(".gallery_product:hidden").length == 0){
         $("#load-more").fadeOut("slow");
        }
    });
  })

  // load more end
  
  $(document).ready(function(){

    $(".filter-button").click(function(){
        var value = $(this).attr('data-filter');
        
        if(value == "all")
        {
            
            $('.filter').show('3000');
        }
        else
        {
          
            $(".filter").not('.'+value).hide('6000');
            $('.filter').filter('.'+value).show('6000');
            
        }
    });
    
    if ($(".filter-button").removeClass("active")) {
$(this).removeClass("active");
}
$(this).addClass("active");

});

// on load function call for loading 
useEffect(() => {
  showhotelgallery()
},[])

  return (
    <>

<Helmet>
        <title>{meta1 && meta1.length>0 &&  meta1[0].title}</title>
        <meta name="description" content={meta1 && meta1.length>0 &&  meta1[0].description} />
    </Helmet>

 <HeaderPage2 hoteldt1={[]} /> 

 <section className="filters-cs cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="https://www.lemontreehotels.com">Home</a>
            </li>
            <li className="breadcrumb-item">
 
              <a href="https://www.lemontreehotels.com/agra-hotels">
               
                Destination
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="https://www.lemontreehotels.com/hotels"> Hotels </a>
            </li>
            <li className="breadcrumb-item">
              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/agra/hotel-agra">
               
                {brand_slug}, {city_alias}
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Gallery
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>







<section className="gallery_cs-sc cs-sc-padding">
<div class="row px-4">
                  <div class="col-md-8">
                     <div class="sc-heading">
                        <h2> Gallery</h2>
                     </div>
                  </div>
               </div>
<div className="container mt-4">
  <div className="row">

    <div align="center">
      <button className="btn btn-default filter-button" data-filter="all">
        All
      </button>
      <button className="btn btn-default filter-button" data-filter="Facade">
      Facade
      </button>
      <button className="btn btn-default filter-button" data-filter="rooms">
        Rooms
      </button>
      <button className="btn btn-default filter-button" data-filter="Restaurant">
        Restaurant
      </button>
      <button
        className="btn btn-default filter-button"
        data-filter="Events"
      >
        Events
      </button>
    </div>
    <br />

    <div className="row">
    <div className="col-md-12 gallery">
    {
      htfacade.map((val) => 
    <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter Facade content">
      <a href={val.gallery_image} data-lightbox="gallery1">
        <img
          src={val.gallery_image}
          className="img-fluid"
        />
      </a>
      <p className="proGallCaptio"> {val.title}</p>
    </div>
      )
    }

{
      htgroom.map((val) => 
    <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter rooms content">
      <a href={val.gallery_image} data-lightbox="gallery1">
        <img
          src={val.gallery_image}
          className="img-fluid"
        />
      </a>
      <p className="proGallCaptio">{val.title}</p>
    </div>

      )
    }
   

   {
      htgrest.map((val) => 
    <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter Restaurant content">
              <a
                href={val.gallery_image}
                data-lightbox="gallery1"
              >
                <img
                  className="img-fluid"
                  src={val.gallery_image}
                  alt=""
                />
              </a>
              <p className="proGallCaptio">{val.title}</p>
            </div>

)
}


{
      htgevent.map((val) => 
    
    <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter Events content">
              <a
                href={val.gallery_image}
                data-title="Tangerine Grand "
                data-lightbox="gallery1"
              >
                <img
                  className="img-fluid"
                  src={val.gallery_image}
                  alt=""
                />
              </a>
              <p className="proGallCaptio">{val.title} </p>
            </div>
      )
    }
    
   
<div class="button-wrap">
  <button id="load-more">Load More</button>
</div>
    </div>
    </div>
  </div>
</div>

</section>



<FooterPage />

    </>
  )

}

export default GalleryPage;

