import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';

function TravekGPage() {
   return (
    <>
      <HeaderPage />
 <section className="filters-cs cs-sc-padding mt-5 pt-4 country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Travel Guidelines
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>


<section className="TravelGuideMain pt-5">
  <div className="container">
    <div className="row ">
      <div className="col-md-2" />
      <div className="col-md-8 text-center ">
        <center>
          <h1>Travel Guidelines</h1>
          <p>
            Prior to your travel, please ensure you check the guidelines of the
            destination you are traveling to and comply with the same to ensure
            a hassle-free journey.
          </p>
        </center>
      </div>
      <div className="col-md-2" />
    </div>
    <div className="row pt-5">
      <div className="col-md-12">
        <h4>International Destinations </h4>
        <div className="accordion InternationalDestinations" id="International">
          <div className="card">
            <div
              className="card-header"
              data-toggle="collapse"
              data-target="#InternationalcollapseOne"
              aria-expanded="true"
            >
              <span className="title">Bhutan</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon fa-minus" />
              </span>
            </div>
            <div
              id="InternationalcollapseOne"
              className="collapse show"
              data-parent="#International"
            >
              <div className="card-body">
                <ul>
                  <li>
                    As per the government law set by the Tourism Council of
                    Bhutan all foreign nationals, including children and
                    infants, except nationals from India, require a valid
                    passport and visa and must pay a tourist tariff to enter
                    Bhutan. For guests from India, a travel permit is required
                    to enter Bhutan. Our hotels in Bhutan can assist you with
                    your Bhutan visa/travel permit and your payment of tourist
                    tariff via our travel partner - Wangchuk Tours and Treks
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Internationalcollapsettt"
              aria-expanded="false"
              aria-controls="Internationalcollapsettt"
            >
              <span className="title">Nepal</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div
              id="Internationalcollapsettt"
              className="collapse"
              data-parent="#International"
            >
              <div className="card-body">
                <ul>
                  <li>
                    As per the law set by the Government of Nepal, all foreign
                    nationals, including children and infants, except nationals
                    from India, require a valid passport and visa to enter
                    Kathmandu.
                  </li>
                  <li>
                    For guests travelling from India, a valid passport or voter
                    ID card is required.
                  </li>
                  <li>
                    As per the guidelines of Nepal Rashtra Bank, only a
                    denomination of INR 100 is accepted on transactions in
                    hotels and other institutions. Indian currency of larger
                    denominations is not accepted in Nepal.
                  </li>
                  <li>
                    For the use of credit cards, please confirm validity from
                    your bank before arrival.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#InternationalcollapseTwo"
              aria-expanded="false"
              aria-controls="InternationalcollapseTwo"
            >
              <span className="title">Dubai</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div
              id="InternationalcollapseTwo"
              className="collapse"
              data-parent="#International"
            >
              <div className="card-body">
                <ul>
                  <li>
                    In keeping with Government regulations, all foreign
                    nationals (including Indian nationals) require a valid
                    passport and visa to enter Dubai. The passport and visa for
                    each guest, including children and infants, also need to be
                    provided to the property at the time of check-in{" "}
                  </li>
                  <li>
                    The Government mandated Tourism Tax is applicable per room,
                    per day, and must be paid at the property directly
                  </li>
                  <li>
                    Lemon Tree Hotel, Dubai is a non-alcoholic, no smoking hotel
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 pt-5">
        <h4>Domestic Destinations </h4>
        <div className="accordion InternationalDestinations" id="Domestic">
          <div className="card">
            <div
              className="card-header"
              data-toggle="collapse"
              data-target="#DomesticcollapseOne"
              aria-expanded="true"
            >
              <span className="title">Ahmedabad</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div
              id="DomesticcollapseOne"
              className="collapse"
              data-parent="#Domestic"
            >
              <div className="card-body">
                <ul>
                  <li>
                    None of our hotels in Gujarat serve alcoholic beverages, as
                    it is a dry state
                  </li>
                  <li>
                    The food and beverage (F&amp;B) operations at Keys Select by
                    Lemon Tree Hotels, Gandhi Ashram, Ahmedabad, are outsourced.
                    Any F&amp;B consumption at the outlet will need to be billed
                    and settled directly at the time of dining, using any of the
                    payment modes available. The property will not be able to
                    post any F&amp;B charges in the room bill{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*  <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#DomesticcollapseTwo" aria-expanded="false" aria-controls="DomesticcollapseTwo">     
                <span class="title">Aligarh</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="DomesticcollapseTwo" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                </div>
            </div>
        </div>*/}
          {/* <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#DomesticcollapseThree" aria-expanded="false">
                <span class="title">Allepey</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="DomesticcollapseThree" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                </div>
            </div>
        </div>*/}
          {/*  <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Alwar" aria-expanded="false">
                <span class="title">Alwar</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Alwar" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                </div>
            </div>
        </div>*/}
          {/*  <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Amritsar" aria-expanded="false">
                <span class="title">Amritsar</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Amritsar" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                </div>
            </div>
        </div>*/}
          {/* <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Aurangabad" aria-expanded="false">
                <span class="title">Aurangabad</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Aurangabad" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Baddi" aria-expanded="false">
                <span class="title">Baddi</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Baddi" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Bandhavgarh"
              aria-expanded="false"
            >
              <span className="title">Bandhavgarh</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Bandhavgarh" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    Guests can self-book a Jungle Safari online at{" "}
                    <a href="https://forest.mponline.gov.in." target="_blank">
                      https://forest.mponline.gov.in.
                    </a>{" "}
                    The permit received online needs to be submitted by the
                    guests at the Safari gate one day prior to the Safari date,
                    where they will receive the driver and vehicle details. As
                    per the government mandate, hotels/resorts cannot make these
                    bookings on behalf of guests.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*<div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Bengaluru" aria-expanded="false">
                <span class="title">Bengaluru</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Bengaluru" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                   
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Bhiwadi" aria-expanded="false">
                <span class="title">Bhiwadi</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Bhiwadi" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                   
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Bhubaneshwar" aria-expanded="false">
                <span class="title">Bhubaneshwar</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Bhubaneshwar" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                   
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Calicut" aria-expanded="false">
                <span class="title">Calicut</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Calicut" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                   
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Chandigarh" aria-expanded="false">
                <span class="title">Chandigarh</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Chandigarh" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                   
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Chennai" aria-expanded="false">
                <span class="title">Chennai</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Chennai" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                   
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Coimbatore" aria-expanded="false">
                <span class="title">Coimbatore</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Coimbatore" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                   
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Coorg"
              aria-expanded="false"
            >
              <span className="title">Coorg</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Coorg" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    Aurika, Coorg is a pet-friendly resort, and dogs are welcome
                    at the property at an additional charge
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Dehradun" aria-expanded="false">
                <span class="title">Dehradun</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Dehradun" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                  
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Delhi" aria-expanded="false">
                <span class="title">Delhi</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Delhi" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                  
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Dwarka"
              aria-expanded="false"
            >
              <span className="title">Dwarka</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Dwarka" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    None of our hotels in Gujarat serve alcoholic beverages, as
                    it is a dry state
                  </li>
                  <li>
                    Lemon Tree Premier, Dwarka, is a non-alcoholic, vegetarian
                    hotel
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*<div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Gangtok" aria-expanded="false">
                <span class="title">Gangtok</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Gangtok" class="collapse" data-parent="#Domestic">
                <div class="card-body"> 
                   
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Goa"
              aria-expanded="false"
            >
              <span className="title">Goa</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Goa" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    As per the local mandate, smoking is not allowed inside any
                    hotel room. Hence, none of our hotels in Goa have smoking
                    rooms/suites
                  </li>
                  <li>
                    Lazy Lagoon, Baga – A Lemon Tree Resort is a pet-friendly
                    resort, and dogs are welcome at the property at an
                    additional charge
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*   <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Gurugram" aria-expanded="false">
                <span class="title">Gurugram</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Gurugram" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Hyderabad" aria-expanded="false">
                <span class="title">Hyderabad</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Hyderabad" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Indore" aria-expanded="false">
                <span class="title">Indore</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Indore" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Jaipur" aria-expanded="false">
                <span class="title">Jaipur</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Jaipur" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Jammu"
              aria-expanded="false"
            >
              <span className="title">Jammu</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Jammu" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    The check-in and check-out times are mentioned on the
                    reservation confirmation. Early check-in can only be
                    provided with adequate advance notice, subject to
                    availability of rooms at the property, and on payment of
                    early check-in charges
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*<div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Jhansi" aria-expanded="false">
                <span class="title">Jhansi</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Jhansi" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Jim Corbett"
              aria-expanded="false"
            >
              <span className="title">Jim Corbett</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Jim Corbett" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    Guests can self-book a Jungle Safari online at
                    <a
                      href="https://www.corbettonline.uk.gov.in."
                      target="_blank"
                    >
                      {" "}
                      https://www.corbettonline.uk.gov.in.
                    </a>{" "}
                    As per the government mandate, hotels/resorts cannot make
                    these bookings on behalf of guests.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Katra"
              aria-expanded="false"
            >
              <span className="title">Katra</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Katra" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    Lemon Tree Hotel, Katra, is a non-alcoholic, no smoking,
                    vegetarian hotel
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*  <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Kaushambi" aria-expanded="false">
                <span class="title">Kaushambi</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Kaushambi" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Kochi" aria-expanded="false">
                <span class="title">Kochi</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Kochi" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Kolkata" aria-expanded="false">
                <span class="title">Kolkata</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Kolkata" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Lucknow" aria-expanded="false">
                <span class="title">Lucknow</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Lucknow" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Ludhiana" aria-expanded="false">
                <span class="title">Ludhiana</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Ludhiana" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Manesar"
              aria-expanded="false"
            >
              <span className="title">Manesar</span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Manesar" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    Lemon Tree Hotel, Tarudhan Valley is a pet-friendly resort,
                    and dogs are welcome at the property at an additional charge
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*     <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Mukteshwar" aria-expanded="false">
                <span class="title">Mukteshwar</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Mukteshwar" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                   
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Mumbai" aria-expanded="false">
                <span class="title">Mumbai</span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Mumbai" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                   
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Neelkanth "
              aria-expanded="false"
            >
              <span className="title">Neelkanth </span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Neelkanth " className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    The food and beverage (F&amp;B) operations at Red Fox Hotel,
                    Neelkanth, are outsourced.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*  <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Noida " aria-expanded="false">
                <span class="title">Noida </span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Noida " class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Patna "
              aria-expanded="false"
            >
              <span className="title">Patna </span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Patna " className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    None of our hotels in Bihar serve alcoholic beverages, as it
                    is a dry state
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#PortBlair"
              aria-expanded="false"
            >
              <span className="title">Port Blair </span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="PortBlair" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>Lemon Tree Hotel, Port Blair is a no smoking hotel</li>
                </ul>
              </div>
            </div>
          </div>
          {/*  <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Pune" aria-expanded="false">
                <span class="title">Pune </span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Pune" class="collapse" data-parent="#Domestic">
                <div class="card-body">  
                   
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Rishikesh"
              aria-expanded="false"
            >
              <span className="title">Rishikesh </span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Rishikesh" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    Lemon Tree Premier, Rishikesh, is a vegetarian resort.
                    Alcohol is not available for sale at the property
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Srinagar"
              aria-expanded="false"
            >
              <span className="title">Srinagar </span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Srinagar" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>Lemon Tree Hotel, Srinagar is a no smoking hotel</li>
                </ul>
              </div>
            </div>
          </div>
          {/*      <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Thiruvananthapuram" aria-expanded="false">
                <span class="title">Thiruvananthapuram </span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Thiruvananthapuram" class="collapse" data-parent="#Domestic">
                <div class="card-body">
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Tiruchirappalli" aria-expanded="false">
                <span class="title">Tiruchirappalli (Trichy)  </span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Tiruchirappalli" class="collapse" data-parent="#Domestic">
                <div class="card-body">
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header collapsed" data-toggle="collapse" data-target="#Udaipur" aria-expanded="false">
                <span class="title">Udaipur </span>
                <span class="accicon"><i class="fa fa-angle-down rotate-icon"></i></span>
            </div>
            <div id="Udaipur" class="collapse" data-parent="#Domestic">
                <div class="card-body">
                </div>
            </div>
        </div>*/}
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Vadodara"
              aria-expanded="false"
            >
              <span className="title">Vadodara </span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Vadodara" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    None of our hotels in Gujarat serve alcoholic beverages, as
                    it is a dry state
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header collapsed"
              data-toggle="collapse"
              data-target="#Vijayawada"
              aria-expanded="false"
            >
              <span className="title">Vijayawada </span>
              <span className="accicon">
                <i className="fa fa-angle-down rotate-icon" />
              </span>
            </div>
            <div id="Vijayawada" className="collapse" data-parent="#Domestic">
              <div className="card-body">
                <ul>
                  <li>
                    The food and beverage (F&amp;B) operations at Red Fox Hotel,
                    Vijayawada, are outsourced.
                  </li>
                </ul>
              </div>
            </div>
          </div>
       
        </div>
      </div>
    </div>
  </div>
</section>





      <FooterPage />


    </>
  )

}

export default TravekGPage;

