import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
function SiteMapPage() {
   return (
    <>
      <HeaderPage />

  <div className="banner-beta-one banner h350">
  <img className="w-100"
    src="https://www.lemontreehotels.com/assets/front/images/rewards_banner_1.jpg" /> 
</div>

<section className="filters-cs cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="https://www.lemontreehotels.com">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Site Map
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>


<section className="sitemap-cs cs-sc-padding location-cs-sc">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="heading text-center mb-4 col-md-12">
          <h2>Site Map</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div id="accordion">
          <div className="card">
            <div className="card-header" id="headingOne">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapse"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Corporate
                </button>
              </h5>
            </div>
            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
              style={{}}
            >
              <div className="card-body">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/awards">Awards</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                  <li>
                    <a href="/day-use-rooms">Day Use</a>
                  </li>
                  <li>
                    <a href="/rewards">Loyalty Program</a>
                  </li>
                  <li>
                    <a href="/travel-guide">Travel Guidelines</a>
                  </li>
                  <li>
                    <a href="/non-affiliation">
                      Non Affiliation Terms &amp; Conditions
                    </a>
                  </li>
                  <li>
                    <a href="/media">Media</a>
                  </li>
                  <li>
                    {" "}
                    <a href="/term-condition">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="/sustainability">Sustainability</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/upcominghotels">Upcoming Hotels</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingThree">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Others
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                  <li>
                    <a href="http://blog.lemontreehotels.com/">Blog</a>
                  </li>
                  <li>
                    <a href="https://lemontreehotelcareers.com/">Career</a>
                  </li>
                  <li>
                    <a href="https://www.lemontreehotels.com/c_assets/upload/cookie_policy.pdf">
                      Cookies Policy
                    </a>
                  </li>
                  <li>
                    <a href="https://investors.lemontreehotels.com/">
                      Investor Relations
                    </a>
                  </li>
                  <li>
                    <a href="https://nidhi.nic.in/HotelDivision/Default.aspx">
                      Nidhi
                    </a>
                  </li>
                  <li>
                    <a href="https://saathi.qcin.org/">Saathi</a>
                  </li>
                  <li>
                    <a href="/sitemap">Site Map</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingFour">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Aurika Hotels &amp; Resorts
                </button>
              </h5>
            </div>
            <div
              id="collapseFour"
              className="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                  <li>
                    Coorg
                    <ul>
                      <li>
                        <a href="https://www.aurikahotels.com/coorg-hotels">
                          Aurika, Coorg - Luxury By Lemon Tree Hotels
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Mumbai
                    <ul>
                      <li>
                        <a href="https://www.aurikahotels.com/mumbai-hotels/">
                          Aurika, Mumbai Skycity - Luxury By Lemon Tree Hotels
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Udaipur
                    <ul>
                      <li>
                        <a href="https://www.aurikahotels.com/udaipur-hotels/">
                          Aurika, Udaipur – Luxury By Lemon Tree Hotels
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingFive">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Lemon Tree Premier
                </button>
              </h5>
            </div>
            <div
              id="collapseFive"
              className="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                  <li>
                    Ahmedabad
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/ahmedabad/the-atrium-ahmedabad">
                          Lemon Tree Premier, The Atrium, Ahmedabad
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Bandhavgarh
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/bandhavgarh/hotel-bandhavgarh">
                          Lemon Tree Wildlife Resort Bandhavgarh
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Bengaluru
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/bengaluru/city-center-bengaluru">
                          Lemon Tree Premier, Ulsoor Lake, Bengaluru
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Bhubaneswar
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/bhubaneswar/hotel-bhubaneswar">
                          Lemon Tree Premier, Bhubaneswar
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Corbett
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/corbett/hotel-corbett">
                          Lemon Tree Premier, Corbett
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Delhi
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/delhi/delhi-international-airport">
                          Lemon Tree Premier, Delhi Airport
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Dwarka
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/dwarka/hotel-dwarka">
                          Lemon Tree Premier, Dwarka
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Gurugram
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/gurugram/city-center-gurgaon">
                          Lemon Tree Premier, City Center, Gurugram
                        </a>
                      </li>
                      <li>
                        <a href="/lemon-tree-premier/gurugram/city-center-north-gurgaon">
                          Lemon Tree Premier-1, Leisure Valley, Gurugram
                        </a>
                      </li>
                      <li>
                        <a href="/lemon-tree-premier/gurugram/leisure-valley-2-gurgaon">
                          Lemon Tree Premier-2, Leisure Valley, Gurugram
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Hyderabad
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/hyderabad/hotel-hyderabad">
                          Lemon Tree Premier, HITEC City, Hyderabad
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Jaipur
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/jaipur/bani-park-jaipur">
                          Lemon Tree Premier, Jaipur
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Kolkata
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/kolkata/new-town-kolkata">
                          Lemon Tree Premier, New Town, Kolkata
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Mumbai
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/mumbai/mumbai-international-airport">
                          Lemon Tree Premier, Mumbai International Airport
                        </a>
                      </li>
                      <li>
                        <a href="/lemon-tree-premier/mumbai/hotel-malad-mumbai">
                          Lemon Tree Premier, Malad, Mumbai
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Patna
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/patna/hotel-patna">
                          Lemon Tree Premier, Patna
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Pune
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/pune/city-center-pune">
                          Lemon Tree Premier, City Center, Pune
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Rishikesh
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/rishikesh/hotel-rishikesh">
                          Lemon Tree Premier, Rishikesh
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Vijayawada
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/vijayawada/hotel-vijayawada">
                          Lemon Tree Premier, Vijayawada
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingSix">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Lemon Tree Hotels
                </button>
              </h5>
            </div>
            <div
              id="collapseSix"
              className="collapse"
              aria-labelledby="headingSix"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                  <li>
                    Agra
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/agra/hotel-agra">
                          Lemon Tree Hotel, Agra
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Ahmedabad
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/ahmedabad/hotel-ahmedabad">
                          Lemon Tree Hotel, Ahmedabad
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Aligarh
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/aligarh/hotel-aligarh">
                          Lemon Tree Hotel, Aligarh
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Alwar
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/alwar/hotel-alwar">
                          Lemon Tree Hotel, Alwar
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Aurangabad
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/aurangabad/hotel-aurangabad">
                          Lemon Tree Hotel, Aurangabad
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Baddi
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/baddi/hotel-baddi">
                          Lemon Tree Hotel, Baddi
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Bengaluru
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/bengaluru/electronics-city-bengaluru">
                          Lemon Tree Hotel, Electronics City, Bengaluru
                        </a>
                      </li>
                      <li>
                        <a href="/lemon-tree-hotel/bengaluru/whitefield">
                          Lemon Tree Hotel, Whitefield, Bengaluru
                        </a>
                      </li>
                      <li>
                        <a href="/lemon-tree-hotel/bengaluru/peninsula-suites-hotels">
                          Peninsula Suites - operated By Lemon Tree Hotels,
                          Bengaluru
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Bhopal
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/bhopal/hotel-bhopal">
                          Lemon Tree Hotel, Bhopal
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Bhutan
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/thimphu/hotel-thimphu">
                          Lemon Tree Hotel, Thimphu, Bhutan
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Chandigarh
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/chandigarh/hotel-chandigarh">
                          Lemon Tree Hotel, Chandigarh
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Chennai
                    <ul>
                      <li>
                        <a href="/lemon-tree-hotel/chennai/hotel-chennai">
                          Lemon Tree Hotel, Chennai
                        </a>
                      </li>
                      <li>
                        <a href="/lemon-tree-hotel/chennai/shimona-hotel-chennai">
                          Lemon Tree Hotel, Shimona, Chennai
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <ul>
                    <li>
                      Coimbatore
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/coimbatore/hotel-coimbatore">
                            Lemon Tree Hotel, Coimbatore
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Dapoli
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/dapoli/hotel-dapoli">
                            Lemon Tree Hotel, Dapoli
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Dehradun
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/dehradun/hotel-dehradun">
                            Lemon Tree Hotel, Dehradun
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Delhi
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/delhi/east-delhi-kaushambi">
                            Lemon Tree Hotel, East Delhi Mall, Kaushambi
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Dubai
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/dubai/hotel-dubai">
                            Lemon Tree Hotel, Jumeirah, Dubai
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Gangtok
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/gangtok/hotel-gangtok">
                            Lemon Tree Hotel, Gangtok
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Goa
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/goa/amarante-goa">
                            Lemon Tree Amarante Beach Resort, Goa
                          </a>
                        </li>
                        <li>
                          <a href="/lemon-tree-hotel/goa/candolim-goa">
                            Lemon Tree Hotel, Candolim, Goa
                          </a>
                        </li>
                        <li>
                          <a href="/lemon-tree-hotel/goa/baga-goa">
                            Lazy Lagoon, Baga – A Lemon Tree Resort, Goa
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Gurugram
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/gurgaon/sector-60-gurugram">
                            Lemon Tree Hotel, Sector 60, Gurugram
                          </a>
                        </li>
                        <li>
                          <a href="/lemon-tree-hotel/gurugram/sohna-road-gurgaon">
                            Lemon Tree Hotel, Sector 68, Sohna Road, Gurugram
                          </a>
                        </li>
                        <li>
                          <a href="/lemon-tree-hotel/gurugram/udyog-vihar-gurgaon">
                            Lemon Tree Hotel, Udyog Vihar, Gurugram
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Haridwar
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/haridwar/haridwar-hotels">
                            Lemon Tree Hotel, Haridwar
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Hisar
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/hisar/hotel-hisar">
                            Lemon Tree Hotel, Hisar
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Hubli
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/hubli/hotel-hubli">
                            Lemon Tree Hotel, Hubli
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Hyderabad
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/hyderabad/banjara-hills-hyderabad">
                            Lemon Tree Hotel, Banjara Hills, Hyderabad
                          </a>
                        </li>
                        <li>
                          <a href="/lemon-tree-hotel/hyderabad/gachibowli-hyderabad">
                            Lemon Tree Hotel, Gachibowli, Hyderabad
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Indore
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/indore/hotel-indore">
                            Lemon Tree Hotel, Indore
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Jammu
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/jammu/hotel-jammu">
                            Lemon Tree Hotel, Jammu
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Jhansi
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/jhansi/hotel-jhansi">
                            Lemon Tree Hotel, Jhansi
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Katra
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/katra/hotel-katra">
                            Lemon Tree Hotel, Katra
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Kerala
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/kerala/alleppey-kerala">
                            Lemon Tree Vembanad Lake Resort, Kerala
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Lucknow
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/lucknow/hotel-lucknow">
                            Lemon Tree Hotel, Lucknow
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Manali
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/manali/hotel-manali">
                            Lemon Tree Hotel, Manali
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Manesar
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/manesar/hotel-tarudhan">
                            Lemon Tree Hotel, Tarudhan Valley
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      McLeodganj
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/mcleodganj/hotel-mcleodganj">
                            Lemon Tree Hotel, McLeodganj
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Mumbai
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/mumbai/hotel-kalina-mumbai">
                            Lemon Tree Hotel, Kalina, Mumbai
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Mukteshwar
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/mukteshwar/hotel-mukteshwar">
                            Lemon Tree Hotel, Mukteshwar
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Noida
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/noida/sandal-suites">
                            Sandal Suites, Operated By Lemon Tree Hotels, Noida
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Port Blair
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/port-blair/hotel-port-blair">
                            Lemon Tree Hotel, Port Blair
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Pune
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/pune/hinjawadi-pune">
                            Lemon Tree Hotel, Hinjawada, Pune
                          </a>
                        </li>
                        <li>
                          <a href="/lemon-tree-hotel/pune/viman-nagar-pune">
                            Lemon Tree Hotel, Viman Nagar, Pune
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Rajkot
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/rajkot/hotel-rajkot">
                            Lemon Tree Hotel, Rajkot
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Siliguri
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/siliguri/hotel-siliguri">
                            Lemon Tree Hotel, Siliguri
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Sonmarg
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/sonmarg/hotel-sonmarg">
                            Lemon Tree Hotel, Sonmarg
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Srinagar
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/srinagar/hotel-srinagar">
                            Lemon Tree Hotel, Srinagar
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Vadodara
                      <ul>
                        <li>
                          <a href="/lemon-tree-hotel/vadodara/hotel-vadodara">
                            Lemon Tree Hotel, Vadodara
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingSeven">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Red Fox By Lemon Tree Hotels
                </button>
              </h5>
            </div>
            <div
              id="collapseSeven"
              className="collapse"
              aria-labelledby="headingSeven"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                  <li>
                    Alwar
                    <ul>
                      <li>
                        <a href="/red-fox-hotel/alwar/hotels-alwar">
                          Red Fox Hotel, Alwar
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Bhiwadi
                    <ul>
                      <li>
                        <a href="/red-fox-hotel/bhiwadi/hotel-bhiwadi">
                          Red Fox Hotel, Bhiwadi
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Dehradun
                    <ul>
                      <li>
                        <a href="/red-fox-hotel/dehradun/hotels-dehradun">
                          Red Fox Hotel, Dehradun
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Delhi
                    <ul>
                      <li>
                        <a href="/red-fox-hotel/delhi/hotels-delhi">
                          Red Fox Hotel, Delhi Airport
                        </a>
                      </li>
                      <li>
                        <a href="/red-fox-hotel/delhi/hotels-east-delhi">
                          Red Fox Hotel, East Delhi , New Delhi
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Gurugram
                    <ul>
                      <li>
                        <a href="/red-fox-hotel/gurgaon/sector-60-gurugram">
                          Red Fox Hotel, Sector 60, Gurugram
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Hyderabad
                    <ul>
                      <li>
                        <a href="/red-fox-hotel/hyderabad/hotels-hyderabad">
                          Red Fox Hotel, Hyderabad
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Jaipur
                    <ul>
                      <li>
                        <a href="/red-fox-hotel/jaipur/hotels-jaipur">
                          Red Fox Hotel, Jaipur
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Neelkanth
                    <ul>
                      <li>
                        <a href="/red-fox-hotel/neelkanth/hotel-neelkanth">
                          Red Fox Hotel, Neelkanth
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Tiruchirappalli
                    <ul>
                      <li>
                        <a href="/red-fox-hotel/tiruchirappalli/hotels-tiruchirappalli">
                          Red Fox Hotel, Tiruchirappalli
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Vijayawada
                    <ul>
                      <li>
                        <a href="/red-fox-hotel/vijayawada/hotels-vijayawada">
                          Red Fox Hotel, Vijayawada
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingEight">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Keys Prima By Lemon Tree Hotels
                </button>
              </h5>
            </div>
            <div
              id="collapseEight"
              className="collapse"
              aria-labelledby="headingEight"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                  <li>
                    Thekkady
                    <ul>
                      <li>
                        <a href="/keys-prima-hotel/thekkady/hotel-thekkady">
                          Keys Prima by Lemon Tree Hotels, Thekkady
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingNine">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Keys Select By Lemon Tree Hotels
                </button>
              </h5>
            </div>
            <div
              id="collapseNine"
              className="collapse"
              aria-labelledby="headingNine"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                  <li>
                    Ahmedabad
                    <ul>
                      <li>
                        <a href="/lemon-tree-premier/ahmedabad/the-atrium-ahmedabad">
                          Keys Select By Lemon Tree Hotels, Gandhi Ashram,
                          Ahmedabad
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Bengaluru
                    <ul>
                      <li>
                        <a href="/keys-select-hotel/bengaluru/hotel-hosur-road-bengaluru">
                          Keys Select by Lemon Tree Hotels, Hosur Road,
                          Bengaluru
                        </a>
                      </li>
                      <li>
                        <a href="/keys-select-hotel/bengaluru/hotel-whitefield-bengaluru">
                          Keys Select By Lemon Tree Hotels, Whitefield,
                          Bengaluru
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Chennai
                    <ul>
                      <li>
                        <a href="/keys-select-hotel/chennai/hotel-katti-ma-chennai">
                          Keys Select By Lemon Tree Hotels, Katti-Ma, Chennai
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Kochi
                    <ul>
                      <li>
                        <a href="/keys-select-hotel/kochi/hotel-kochi">
                          Keys Select By Lemon Tree Hotels, Kochi
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Ludhiana
                    <ul>
                      <li>
                        <a href="/keys-select-hotel/ludhiana/hotel-ludhiana">
                          Keys Select by Lemon Tree Hotels, Ludhiana
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Kozhikode
                    <ul>
                      <li>
                        <a href="/keys-select-hotel/kozhikode/hotel-malabar-gate-kozhikode">
                          Keys Select By Lemon Tree Hotels, Malabar Gate,
                          Kozhikode
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Pune
                    <ul>
                      <li>
                        <a href="/keys-select-hotel/pune/hotel-pimpri-pune">
                          Keys Select by Lemon Tree Hotels, Pimpri Pune
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Thiruvananthapuram
                    <ul>
                      <li>
                        <a href="/keys-select-hotel/thiruvananthapuram/hotel-thiruvananthapuram">
                          Keys Select By Lemon Tree Hotels, Thiruvananthapuram
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Visakhapatnam
                    <ul>
                      <li>
                        <a href="/keys-select-hotel/visakhapatnam/hotel-visakhapatnam">
                          Keys Select By Lemon Tree Hotels, Visakhapatnam
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingTen">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  Keys Lite By Lemon Tree Hotels
                </button>
              </h5>
            </div>
            <div
              id="collapseTen"
              className="collapse"
              aria-labelledby="headingTen"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                  <li>
                    Dapoli
                    <ul>
                      <li>
                        <a href="/keys-lite-hotel/dapoli/hotel-sylvan">
                          Keys Lite By Lemon Tree Hotels Sylvan, Dapoli
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Gurugram
                    <ul>
                      <li>
                        <a href="/keys-lite-hotel/gurugram/hotel-mayfield-gurugram">
                          Keys Lite By Lemon Tree Hotels, Mayfield, Gurugram
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Rishikesh
                    <ul>
                      <li>
                        <a href="/keys-lite-hotel/rishikesh/hotels-rishikesh">
                          Keys Lite By Lemon Tree Hotels, Tapovan, Rishikesh
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Visakhapatnam
                    <ul>
                      <li>
                        <a href="/keys-lite-hotel/visakhapatnam/hotel-sreekanya-visakhapatnam">
                          Keys Lite By Lemon Tree Hotels, Sreekanya,
                          Visakhapatnam
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   </div>
</section>

<section
  className="cs_our_brands pt-5 pb-5 updated_our_brand_rewards"
  id="updated_our_brand "
>
  <div className="container">
    <div className="row">
      <div className="heading text-center pb-4 text-center w-100">
        <h4 className="text-uppercase">Our brands</h4>
      </div>
    </div>
    <div className="row pb-5">
      <div className="col-md-3">
        <a href="aurika-hotels-resorts" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/aurika_color_logo.jpg"
            />
          </figure>
        </a>
      </div>
      <div className="col-md-3">
        <a href="lemon-tree-premier" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/LTP_newupdated.jpg"
            />
          </figure>
        </a>
      </div>
      <div className="col-md-3">
        <a href="lemon-tree-hotel" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/LTH-newupdated.jpg"
            />
          </figure>
        </a>
      </div>
      <div className="col-md-3">
        <a href="red-fox-hotel" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/red_newupdated.jpg"
            />
          </figure>
        </a>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4">
        <a href="keys-prima-hotel" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/keys-prima-LTH.jpg"
            />
          </figure>
        </a>
      </div>
      <div className="col-md-4">
        <a href="keys-select-hotel" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/KeysSelectLogo-colour-MAIN.png"
            />
          </figure>
        </a>
      </div>
      <div className="col-md-4">
        <a href="keys-lite-hotel" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/KeysLiteLogo-colour-MAIN.png"
            />
          </figure>
        </a>
      </div>
    </div>
  </div>
</section>


      <FooterPage />


    </>
  )

}

export default SiteMapPage;

