import OwlCarousel from 'react-owl-carousel';  
import $ from 'jquery'; 
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import '../index.css';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPlane, faTrain } from '@fortawesome/free-solid-svg-icons'

    export default function ReviewHotelCity({htcity, adult,children, countroom, rangeStart, rangeEnd}){    

        const options = {           
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 3,
        
                }
            },
        };
     
      
          return (  
            <div>  
     
  
     {htcity?.length>0 && (   
        <OwlCarousel className="owl-carousel owl-theme owl-loaded owl-drag"
            id="hotels-in-overview" items={4} margin={8} autoplay ={true} loop={true}  nav={true} autoplayTimeout={8000} autoplaySpeed={4000} {...options} >  

<div className="owl-stage-outer">
              <div
                className="owl-stage"
              >
               
{
   htcity.map((val) => 
                <div className="owl-item">
 
                  <div className="item">
                    <div className="otherHotels-item">
                      <a href="https://www.lemontreehotels.com/lemon-tree-hotel/jhansi/hotel-jhansi">
                        <img
                          src={val.hotel_image}
                          alt="Lemon Tree Hotel, Jhansi"
                        />
                      </a>
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>{val.hotel_name}</h4>
                        <div className="otherHotels-item-icon">
                          <ul>
                            <li>
                            <FontAwesomeIcon className='pl-2' icon={faPlane} transform={{ rotate: -45 }} fontSize={12}/> -
                              {val.distance_from_airport} KM |
                            </li>
                            <li>
                            <FontAwesomeIcon className='pl-2' icon={faTrain} fontSize={12}/> -
                              {val.distance_from_railway_station} KM
                            </li>
                          </ul>
                        </div>
                        <button className="hotel-new-booknow-btn" onClick={() => window.open(`https://be.synxis.com/?adult=${adult}&arrive=${rangeStart}&chain=7710&child=${children}&currency=INR&depart=${rangeEnd}&hotel=${$("#getBeCityHotel").val()}&level=hotel&locale=en-US&rooms=${countroom}&shell=SBE&start=availresults&template=SBE&theme=initialTheme2`)}>
    Book Now
    </button>
                      </div>
                    </div>
                  </div>

                </div>
                  )}
              </div>
            </div>          
      </OwlCarousel>  
        
     )
    }
      </div>  
          )  
          
      }  
        
  
