import HeaderPage2 from "../Header2";
import * as React from "react";
import FooterPage from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import axiosBaseURL from "../member/httpCommon";
import {Helmet} from "react-helmet";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import ThankYouPage from "../otherPages/thankyou";

  function EventPage() {
  const navigate = useNavigate()
  const{brand_slug, city_alias, hotel_slug}  = useParams(); 

  const [date, setDate] = useState(new Date());

  const [rangeStart, setRangeStart] = React.useState(new Date)

  const selectStartDate = d => {
    setRangeStart(d)
    eventenq.event_date=d;
  }

  const[htlreview, sethtlreview] = useState({
    brand_slug:brand_slug,
    city_slug:city_alias,
    hotel_slug:hotel_slug
  })

const[htls, sethtls] = useState([]);
const[banner, setbanner] = useState([]);

const[htamen, sethtamen] = useState([]);

const[hevent, sethevent] = useState([]);
const[meta1, setmeta1] = useState([]);

const showhotelevent = () =>{
  axiosBaseURL.post('/hotel-events', htlreview).then(res => {
    setbanner(res.data.hotel_banner_slider);
    sethevent(res.data.hotel_event);
    sethtls(res.data.hotels);
    sethtamen(res.data.freebies);
    setmeta1(res.data.hotel_meta);
}).catch(err => {
console.log(err)
})
}

const[eventenq,seteventenq]=useState({
  name:"",
  email:"",
  phone:"",
  event_date:"",
  event_title:"Meetings & Events",
  event_type:"",
  room_required:"",
  noofguest:"",
  hotel_name:hotel_slug,
  hotel_id:"1"
})

//inputHandler
const inputHandler=(e)=>{
  seteventenq({...eventenq,[e.target.name]:e.target.value})
}

const eventquerydtl=(e)=>{
e.preventDefault();
axiosBaseURL.post(`/miceenquirysend`,eventenq)
.then(()=>{
    alert("Submitted Successfully")
    navigate("/thankyou")
    console.log("test1", eventenq)
    seteventenq(null);
    e.target.reset();
}).catch(err=>{console.log(err)});
}



// on load function call for loading 
useEffect(() => {
  showhotelevent()
  
},[])

  return (
    <>
     <Helmet>
        <title>{meta1 && meta1.length>0 &&  meta1[0].title}</title>
        <meta name="description" content={meta1 && meta1.length>0 &&  meta1[0].description} />
    </Helmet>
<HeaderPage2/>

<section className="filters-cs cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href={hotel_slug}>
               
                Destination
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/hotels"> Hotels </a>
            </li>
            <li className="breadcrumb-item">
            <Link to={`../${brand_slug}/${city_alias}/${hotel_slug}`}> {brand_slug}, {city_alias}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Events
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>


<section className="hero pt-3 pb-5 overviewheading">
  <div className="container">
    <div className="row">
    <div className="col-md-12">
      {
          banner.map((val) => 
        <div className="hero-right">
          <div className="hero-left">
            
            <img
              src={val.banner_image_1}
              alt={val.banner_image_title_1}
            />
          </div>
          <div className="hero-right-inner-main">
            <div className="hero-right-inner">
              <img
                src={val.banner_image_2}
                alt={val.banner_image_title_2}
              />
              <img
                src={val.banner_image_3}
                alt={val.banner_image_title_3}
              />
            </div>
            <div className="hero-right-inner hero-right-inner-last">
              <img
                src={val.banner_image_4}
                alt={val.banner_image_title_4}
              />
              <img
                src={val.banner_image_5}
                alt={val.banner_image_title_5}
              />
            </div>
          </div>
          <a
             onClick={() => navigate("/GalleryPage/")}
            id="GalleryURL">
           <FontAwesomeIcon icon={faArrowsAlt} /> Show all
            photos
          </a>
        </div>
          )
        }
      </div>
    </div>
  </div>
</section>

<section className="meeting-events-cs-sc cs-sc-padding bg-light heading-bg-sc-cs">
  <div className="container">
    <div className="row">
      <div className="col-md-8 pb-5">
        <div className="restaurant-details__description">
          <div className="restaurant-details__description--heading meeitng-n-evnt-hd-rj">
            <center className="text-left sc-heading restaurant-details__description--heading-title ">
              <h1>
                Meetings &amp; Events
                {/* Red Fox Hotel, Delhi Airport */}
              </h1>
            </center>
          </div>
        
          <div className="restaurant-details__description--text meeitng-n-evnt-rj">
          {
          htls.map((val) => 
           <div dangerouslySetInnerHTML={{__html: val.meeting_overview_content}}></div>
        )
      }
            {/* <input type="hidden" id="get_hotel_id" defaultValue={4} /> */}
            <div className="below_slider-cs-sc-list">
              <ul>
              {
          htamen.map((val) => 
                <li>
                  <img
                    src={val.image}
                    alt={val.title}
                  />
                 {val.title}
                </li>
          )
        }
              </ul>
            </div>
          </div>
       
        </div>
      </div>
      <div className="col-md-4 pb-5">
      <form onSubmit={eventquerydtl}>
        <div className="cs-booking-form-belowSlider new-booking-form-rj">
          <div className="form-row">
            <div className="col">
            <input required type="text" className="form-control" placeholder="Name" name="name" value={eventenq?.name} onChange={inputHandler} />
                          </div>
            <div className="col">
            <input required type="email" className="form-control" placeholder="Email" name="email" value={eventenq?.email} onChange={inputHandler} />  
              
            </div>
          </div>
          <div className="form-row">
            <div className="col">
            <input required type="tel" className="form-control" maxLength={10} placeholder="Phone" name="phone" value={eventenq?.phone} onChange={inputHandler} />  
            </div>
            <div className="col">
            <DatePicker name="event_date" dateFormat="dd-MMM-yyyy" selected={rangeStart} 
                 onChange={selectStartDate} className="form-control datepicker calendar" />
            </div>

            <div className="col">
            <input required type="hidden" className="form-control" name="event_title" value={eventenq?.event_title} onChange={inputHandler} />  
            </div> 
            <div className="col">
            <input required type="hidden" className="form-control" name="hotel_name" value={eventenq?.hotel_name} onChange={inputHandler} />  
            </div> 
            <div className="col">
            <input required type="hidden" className="form-control" name="hotel_id" value={eventenq?.hotel_id} onChange={inputHandler} />  
            </div> 

          </div>
          <div className="form-row">
            <div className="col">
            <input required type="tel" className="form-control" maxLength={10} placeholder="No. of Guests" name="noofguest" value={eventenq?.noofguest} onChange={inputHandler} /> 
              {/* <input
                id="txtMNumberOfGuest"
                className="form-control"
                placeholder="No. of Guests"
              /> */}
            </div>
            <div className="col">
              <select onChange={inputHandler} value={eventenq?.event_type} name="event_type"  className="form-control">
              <option value="0">Select Event</option>
                           <option value="Wedding">Wedding </option> 
                           <option value="Birthday">Birthday </option> 
                           <option value="Anniversary">Anniversary </option>
                           <option value="Social Event">Social Event </option>
                           <option value="Conference">Conference </option>
                           <option value="Other">Other</option>             
              </select>
            </div>

            <div className="col">
              <select onChange={inputHandler} value={eventenq?.room_required} name="room_required" className="form-control">
                <option>Select Room Required</option> 
                <option value="Yes">Yes </option>
                <option value="No">No </option>           
              </select>
            </div>
          </div>

      
          <button className="cs-cta reserve-cs-cta btn">
                   Submit
                  </button>
          {/* booking form start */}
          {/* booking form end */}
        </div>
        </form>
      </div>
    </div>
  </div>
</section>

<section
  className="compare_benefit_main bg-light  cs-sc-padding meeting-events-table"
  id="meeting-events-find-a-venue"
>
  <div className="container">
    <div className="row">
      <div className="col-md-8 mb-4">
        <div className="sc-heading">
          <h2>Find a Venue</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="tableComponent">
          <div className="container">
       
            <div className="table-component">
              <div className="table-component-row table-component_head_title new-compo-title-rj">
                <div className="table-col">
                  <h3 className="t-title-s">Meeting &amp; Events</h3>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-silver-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">
                    Reception/Lobby
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-gold-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">Theater</div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-platinum-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">Class Room</div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-platinum-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">Round Table</div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-platinum-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">U Shape</div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-platinum-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">Cluster</div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-platinum-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">Wedding</div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-platinum-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">Board Room</div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-platinum-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">Floating</div>
                </div>
              </div>
              {
          hevent.map((val) => 
              <div className="table-component-row new-compo-row-rj">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>{val.title}</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-silver icon-decorative text-success"
                    aria-hidden="true"
                  >
                 {val.reception_lobby}
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-gold icon-decorative text-success"
                    aria-hidden="true"
                  >
                    {val.theater}
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-platinum icon-decorative text-success"
                    aria-hidden="true"
                  >
                    {val.class_room}
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-platinum icon-decorative text-success"
                    aria-hidden="true"
                  >
                    {val.round_table}
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-platinum icon-decorative text-success"
                    aria-hidden="true"
                  >
                    {val.u_shape}
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-platinum icon-decorative text-success"
                    aria-hidden="true"
                  >
                    {val.cluster}
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-platinum icon-decorative text-success"
                    aria-hidden="true"
                  >
                    {val.wedding}
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-platinum icon-decorative text-success"
                    aria-hidden="true"
                  >
                    {val.board_room}
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-platinum icon-decorative text-success"
                    aria-hidden="true"
                  >
                    {val.floating}
                  </span>
                </div>
              </div>
                )
              }
            </div>
       
          </div>
        </div>
        <p className="events-mbl-table-info">
          <i>Please scroll for more details</i>
          <i className="fa fa-hand-o-up" aria-hidden="true" />
        </p>
      </div>
    </div>
  </div>
</section>




<FooterPage />

    </>
  )

}

export default EventPage;

