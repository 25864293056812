import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

function RewardPage() {
   return (
    <>
      <HeaderPage />
 <div className="RewardsBanner reward_main_page_banner">
  <div className="container">
    <h2>Seamless. Limitless. Never-ending</h2>
    <p>
      
      <span>Welcome to</span> Infinity Rewards
    </p>
    <div className="rewardsBtns">
      <a
        href="https://www.lemontreehotels.com/member/signup"
        className="reward_join_btn"
      >
        Join
      </a>
      <a
        href="https://www.lemontreehotels.com/member/signin"
        className="reward_sign_btn"
      >
        Sign in
      </a>
    </div>
  </div>
</div>

<section className="subMenurewards_sc2">
  <center className="w-75 mx-auto">
    <h2>Stay More and Earn More</h2>
    <p>
      Infinity Rewards by Lemon Tree allows you to earn and burn reward coins
      easier than ever before. Infinity levels include Silver, Gold and Platinum
      tiers, and each tier offers members exclusive rewards and privileges with
      endless possibilities, from free room nights and upgrades to special
      offers and previews.
    </p>
  </center>
  <div className="container">
    <div className="row">
      <ul>
        <li>
          <img
            className=""
            src="https://www.lemontreehotels.com/assets/front/images/InfinityTierCardsSilver.jpg"
          />
          <h5>As soon as you join </h5>
          <ul>
            <li>
            <FontAwesomeIcon icon={faCheckCircle} />
             Member's rate
            </li>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> Special offer
            </li>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> Free Wifi
            </li>
          </ul>
        </li>
        <li>
          <img
            className=""
            src="https://www.lemontreehotels.com/assets/front/images/InfinityTierGold.jpg"
          />
          <h5>From 1500 Infinity coins</h5>
          <ul>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> All the benefits of Silver
              Tier, And
            </li>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> Extra Bonus on Infinity Coins
              Earned
            </li>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> Complimentary Room Upgrade
            </li>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> Flexible Cancellation
            </li>
          </ul>
        </li>
        <li>
          <img
            className=""
            src="https://www.lemontreehotels.com/assets/front/images/InfinityTierPlatinum.jpg"
          />
          <h5>From 3000 Infinity Coins</h5>
          <ul>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> All the benefits of Gold
              tier, And
            </li>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> 2nd Guest Stays Free
            </li>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> 2 piece of laundry per stay
            </li>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> Early checkin - late check
              out
            </li>
            <li>
               <FontAwesomeIcon icon={faCheckCircle} /> 48 hrs Room Guarantee
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</section>

 <section className="compare_benefit_main bg-light py-5">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="tableComponent">
          <div className="container">
            <div className="table-component">
              <div className="table-component-row table-component_head_title">
                <div className="table-col">
                  <h3 className="t-title-s">Compare Tier Benefits</h3>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-silver-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">Silver</div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-gold-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">Gold</div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-status-platinum-elite  icon-decorative"
                    aria-hidden="true"
                  />
                  <div className="t-label-s table-dark-text">Platinum</div>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>Member Exclusive Offers</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-silver icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-gold icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-platinum icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>Pay at Hotel</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-silver icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-gold icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-platinum icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>Redeem &amp; Gift</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>Redeem Free Room Nights</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>Free Breakfast on paid room nights</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>Free High Speed Wifi 24*7</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>Earn coins on every stay</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-platinum icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-titanium icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>Discount on food and beverage</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                    10%
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                    15%
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span className="t-label-s table-dark-text text-success">
                    20%
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>** Discount on spa services</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                    10%
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                    15%
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                    20%
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>* Complimentary Room Upgrade</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative"
                    aria-hidden="true"
                  >
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>** All Day Happy Hours</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative"
                    aria-hidden="true"
                  >
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>* Early Check in &amp; Late Check Out</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative"
                    aria-hidden="true"
                  >
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative"
                    aria-hidden="true"
                  >
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>2 pieces of laundry per stay</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative"
                    aria-hidden="true"
                  >
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative"
                    aria-hidden="true"
                  >
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>* 48 hrs. room guarantee</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative"
                    aria-hidden="true"
                  >
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative"
                    aria-hidden="true"
                  >
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
              <div className="table-component-row">
                <div className="table-col">
                  <div className="t-label-s table-dark-text">
                    <p>
                      <b>2nd guest stays free</b>
                    </p>
                  </div>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative"
                    aria-hidden="true"
                  >
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                    <sup />
                  </span>
                </div>
                <div className="table-col">
                  <span
                    className="icon-success-fill icon-color-ambassador icon-decorative text-success"
                    aria-hidden="true"
                  >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    <sup />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="tAndC py-3">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <p>
            <span>* Subject To Availability</span>
            <span>** In Applicable Hotels</span>
            <a
              title=""
              href="https://www.lemontreehotels.com/assets/front/upload/term-condition/Infinity_Rewards_Program_and_TandC.pdf"
              target="_blank"
            >
              Terms and Conditions
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
 

<section className="subMenurewards Not_member">
  <div className="RewardsBanner">
    <div className="container">
      <h2>Not a member yet? Join today.</h2>
      <p className="">
        Exclusive rates, tier benefits, free room nights, and much more...{" "}
        <span>Sign up today for free.</span>
      </p>
      <div className="rewardsBtns">
        <a
          href="https://www.lemontreehotels.com/member/signup"
          className="reward_join_btn"
        >
          Join
        </a>
        <a
          href="https://www.lemontreehotels.com/member/signin"
          className="reward_sign_btn"
        >
          Sign in
        </a>
      </div>
    </div>
  </div>
</section>



{/* our brand section */}

<section
  className="cs_our_brands pt-5 pb-5 updated_our_brand_rewards"
  id="updated_our_brand "
>
  <div className="container">
    <div className="row">
      <div className="heading text-center pb-4 text-center w-100">
        <h4 className="text-uppercase">Our brands</h4>
      </div>
    </div>
    <div className="row pb-5">
      <div className="col-md-3">
        <a href="aurika-hotels-resorts" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/aurika_color_logo.jpg"
            />
          </figure>
        </a>
      </div>
      <div className="col-md-3">
        <a href="lemon-tree-premier" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/LTP_newupdated.jpg"
            />
          </figure>
        </a>
      </div>
      <div className="col-md-3">
        <a href="lemon-tree-hotel" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/LTH-newupdated.jpg"
            />
          </figure>
        </a>
      </div>
      <div className="col-md-3">
        <a href="red-fox-hotel" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/red_newupdated.jpg"
            />
          </figure>
        </a>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4">
        <a href="keys-prima-hotel" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/keys-prima-LTH.jpg"
            />
          </figure>
        </a>
      </div>
      <div className="col-md-4">
        <a href="keys-select-hotel" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/KeysSelectLogo-colour-MAIN.png"
            />
          </figure>
        </a>
      </div>
      <div className="col-md-4">
        <a href="keys-lite-hotel" target="_blank">
          <figure className="mb-0">
            <img
              className=""
              src="https://www.lemontreehotels.com/assets/front/images/KeysLiteLogo-colour-MAIN.png"
            />
          </figure>
        </a>
      </div>
    </div>
  </div>
</section>


      <FooterPage />


    </>
  )

}

export default RewardPage;

