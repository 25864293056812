import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';

function AboutUsPage() {
   return (
    <>
      <HeaderPage />
<div className="banner-beta-one banner h350">
  <img
    src="https://www.lemontreehotels.com/Ka_assest/images/rewards_banner_1.jpg"
    className="w-100"
  />
</div>

<div className="cs-sc-about-us ">
  <div className="container py-5 Whats_New_cs">
    <div className="row row justify-content-center">
      <div className="col-12">
        <div className="why_us">
          <div className="heading text-center mb-4">
            <h4>About Us</h4>
       
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="about-tabs new-award-tabs-rj">
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12">
        <div className="about-tabs-inner">
          <nav>
            <div
              className="nav nav-tabs about-tabs-nav"
              id="nav-tab-about-us"
              role="tablist"
            >
              <a
                className="nav-item nav-link active"
                id="nav-our-story-tab"
                data-toggle="tab"
                href="#nav-our-story"
                role="tab"
                aria-controls="nav-our-story"
                aria-selected="true"
              >
                Our Story
              </a>
              <a
                className="nav-item nav-link"
                id="nav-ourHotels-tab"
                data-toggle="tab"
                href="#nav-ourHotels"
                role="tab"
                aria-controls="nav-ourHotels"
                aria-selected="false"
              >
                Our Brands
              </a>
              <a
                className="nav-item nav-link"
                id="nav-awards-tab"
                data-toggle="tab"
                href="#nav-awards"
                role="tab"
                aria-controls="nav-awards"
                aria-selected="false"
              >
                Awards
              </a>
              <a
                className="nav-item nav-link"
                id="nav-mission-vision-tab"
                data-toggle="tab"
                href="#nav-mission-vision"
                role="tab"
                aria-controls="nav-mission-vision"
                aria-selected="false"
              >
                Vision, Mission, Values
              </a>
              <a
                className="nav-item nav-link"
                id="nav-csr-tab"
                data-toggle="tab"
                href="#nav-csr"
                role="tab"
                aria-controls="nav-csr"
                aria-selected="false"
              >
                CSR
              </a>
              <a
                className="nav-item nav-link"
                id="nav-EFP-tab"
                data-toggle="tab"
                href="#nav-EFP"
                role="tab"
                aria-controls="nav-EFP"
                aria-selected="false"
              >
                Eco-Friendly Practices
              </a>
              <a
                className="nav-item nav-link"
                id="nav-safetySecurity-tab"
                data-toggle="tab"
                href="#nav-safetySecurity"
                role="tab"
                aria-controls="nav-safetySecurity"
                aria-selected="false"
              >
                {" "}
                Safety And Security
              </a>
              <a
                className="nav-item nav-link"
                id="nav-Management-tab"
                data-toggle="tab"
                href="#nav-Management"
                role="tab"
                aria-controls="nav-Management"
                aria-selected="false"
              >
                Management
              </a>
              <a
                className="nav-item nav-link"
                id="nav-poochPolicy-tab"
                data-toggle="tab"
                href="#nav-poochPolicy"
                role="tab"
                aria-controls="nav-poochPolicy"
                aria-selected="false"
              >
                Pooch Policy
              </a>
            </div>
          </nav>
          <div
            className="tab-content about-tabs-content"
            id="nav-tabContent-about-us"
          >
            <div
              className="tab-pane fade show active"
              id="nav-our-story"
              role="tabpanel"
              aria-labelledby="nav-our-story-tab"
            >
              <p>
                In the early 2000s, 80% of the branded hotel supply in India was
                in the upscale/luxury segment. There was clearly enormous latent
                demand in the midscale and economy segments, which were then
                unmet needs. Lemon Tree Hotels was founded as the category
                creator for the branded mid-market hotel space in India.
                <br />
                <br />
                Today, Lemon Tree Hotels Limited (LTHL) is one of the largest
                hotel chains in India, and owns/leases/operates/franchises
                hotels across the upscale, upper-midscale, midscale and economy
                segments. LTHL delivers differentiated yet superior service
                offerings, with a compelling value proposition. The group offers
                seven brands to meet guests’ needs across all levels, viz.
                Aurika Hotels &amp; Resorts, Lemon Tree Premier, Lemon Tree
                Hotels, Red Fox Hotels by Lemon Tree Hotels, Keys Prima by Lemon
                Tree Hotels, Keys Select by Lemon Tree Hotels and Keys Lite by
                Lemon Tree Hotels.
                <br />
                <br />
                LTHL opened its first hotel with 49 rooms in May 2004. Today,
                the company has a portfolio of 160+ hotels, which includes over
                100+ operational hotels and more than 60 hotels set to open in
                India and internationally. Lemon Tree Hotels are located across
                metro regions, including the NCR, Mumbai, Kolkata, Bengaluru,
                Hyderabad and Chennai, as well as numerous tier I, II and III
                cities such as Pune, Ahmedabad, Chandigarh, Jaipur, Indore,
                Aurangabad, Udaipur, Vishakhapatnam, Kochi, Ludhiana,
                Thiruvananthapuram, Vijayawada etc. The company expanded
                internationally with hotels opening in Dubai in December 2019
                and in Bhutan in February 2020. New hotels are also set to open
                internationally in Bhutan and Nepal.
                <br />
                <br />
              </p>
              <p>
                The group offers seven brands to meet guests’ needs across all
                levels:
              </p>
              <ol>
                <li>
                  <strong>Aurika Hotels and Resorts</strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upscale
                </li>
                <li>
                  <strong>Lemon Tree Premier</strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upper
                  Midscale
                </li>
                <li>
                  <strong>Lemon Tree Hotels </strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Midscale
                </li>
                <li>
                  <strong>Red Fox by Lemon Tree Hotels</strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Economy
                </li>
                <li>
                  <strong>Keys Prima by Lemon Tree Hotels </strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upper Midscale
                </li>
                <li>
                  <strong>Keys Selectby Lemon Tree Hotels</strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Midscale
                </li>
                <li>
                  <strong>Keys Lite by Lemon Tree Hotels </strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Economy
                </li>
              </ol>
              {/*<p>The group offers seven brands to meet guests’ needs across all levels. At our hotels across all brands, our guests bond with our open hearted, inclusive culture and our environmentally sensitive ethos</p> <br>
                           <h5>1. Aurika Hotels & Resorts</h5>
                           <p>Aurika Hotels & Resorts are upscale properties that offer exclusive experiences in an extraordinary ambience. With an emphasis on sustainable design inspired by local culture and history, these properties combine beauty and elegance with comfortable informality. <br>
								 Our efficient service goes beyond the unusual and is anticipatory yet unobtrusive. Inspiring spaces, unexpected dining experiences, innovative technological solutions and seamless delivery ensure that you can work, play and relax effortlessly.</p>
								 <p>The brand offers:<p>
								 <ul>
								 <li>New-age dining options:</li>
								 <li>Mirasa, the all-day dining restaurant</li>
								 <li>Ariva, the bar</li>
								 <li>Specialty restaurant*</li>
								 <li>Invigorating fitness and wellness facilities, including Araya, the rejuvenating spa, a fitness center, a swimming pool and a recreation room*</li>
								 <li>Extensive banquet and event spaces </li>
								 <li> Luxury amenities</li>
								 </ul>
                           <h5>2. Lemon Tree Premier</h5>
                           <p>Lemon Tree Premier is a chain of sustainably designed upper midscale hotels and resorts that welcome you with their stylish and contemporary interiors. These refreshingly different properties offer warm, friendly and attentive service in a fresh and inviting ambience.
                              <br><br>
                              Lemon Tree Premier pampers the style conscious traveller with personalized experiences, award winning restaurants and rejuvenating recreational options in a relaxed atmosphere.
                           </p>
								 <p>The brand offers:<p>
								 <ul>
								 <li>An exciting range of dining options:</li>
								 <li>Citrus Café, the bright and inviting coffee shop offers an eclectic Indian and international menu</li>
								 <li>Slounge, the recreation bar, offers a fun memorable way to chill and have a spirited night out</li>
								 <li>Republic of Noodles*, the award winning pan Asian restaurant </li>
								 <li>Kebab Theater* brings you traditional mouth-watering kebab delicacies from across India</li>
								 <li>A fitness center, a refreshing swimming pool and a rejuvenating spa* </li>
								 <li>Banquet halls*, meeting rooms* and business facilities*</li>
								 <li>Premium amenities</li>
								 </ul>

                           <h5>3. Lemon Tree Hotels</h5>
                           <p>Lemon Tree Hotels are sustainably designed midscale hotels and resorts that uplift your spirits at the end of a long day. These refreshingly different properties bring the outdoors in and delight guests with their fresh, vibrant interiors, fun, quirky elements and spirited environment. 
                              <br><br>
                              Warm greetings, friendly smiles and a whiff of our signature lemon fragrance welcome you here. These hotels make you feel close to home with their bright, comfortable rooms and service that goes the extra mile.</p>
									<p>The brand offers:<p>
								 <ul>
								 <li> An exciting range of dining options:</li>
								 <li>Citrus Café, the bright and inviting coffee shop offers an eclectic Indian and international menu</li>
								 <li>Slounge*, the recreation bar, offers a fun memorable way to chill and have a spirited night out</li>
								 <li>A fitness center and a refreshing swimming pool* </li>
								 <li>Banquet halls*, meeting rooms* and business facilities*</li>
								 <li>Refreshing amenities</li>
								 </ul>
									
                           
                           <h5>4. Red Fox by Lemon Tree Hotels</h5>
                           <p>Red Fox Hotels are sustainably designed economy hotels. These unbeatably cool properties welcome you with their fun, bold interiors, and crisp and clean rooms. 
                              <br><br>
                              Red Fox Hotels are young at heart and let you be yourself. Here, friendly smiles, professional service, and reliable safety standards go hand in hand with an unmatched value proposition.
                           </p>
								 <p>The brand offers:<p>
								 <ul>
								 <li>A cool range of dining options:</li>
								 <li>Clever Fox Café, the casual restaurant</li>
								 <li>Tipsy Fox*, the trendy bar</li>
								 <li>A fitness center </li>
								 <li>Banquet halls*, meeting rooms* and a cyber kiosk*</li>
								 <li>Fun amenities</li>
								 </ul>
                           <h5>5. Keys Prima by Lemon Tree Hotels</h5>
                           <p>Keys Prima Hotels are sustainably designed upper-midscale hotels and resorts. These properties delight guests with their bright and trendy design and welcoming ambiance.
								 <br><br>
								 At Keys Prima Hotels, the tasteful interiors and thoughtful, anticipatory service coupled with the brand’s signature warm welcome and fond farewell make for a truly memorable experience.</p>
								 <p>The brand offers:<p>
								 <ul>
								 <li>An smart range of dining options:</li>
								 <li>Keys Café, the attractive multi-cuisine coffee shop</li>
								 <li>Unlock Bar*, the relaxed restobar</li>
								 <li>Specialty restaurant*</li>
								 <li>A fitness center, a swimming pool* and a spa*</li>
								 <li>Banquet halls*, meeting rooms* and business facilities*</li>
								 <li>Thoughtful amenities</li>
								 </ul>
                           <h5>6. Keys Select By Lemon Tree Hotels</h5>
                           <p>Keys Select Hotels are sustainably designed midscale hotels. These properties delight guests with their chic interiors, comfortable rooms, convenient business facilities and pleasing, dependable service.
								 <br><br>
								 At Keys Select Hotels, the lively ambiance and cheerful smiles go hand in hand with the brand’s signature warm welcome and fond farewell. All this, at a sensible price.</p>
								 <p>The brand offers:<p>
								 <ul>
								 <li>A smart range of dining options:</li>
								 <li>Keys Café, the attractive multi-cuisine restaurant</li>
								 <li>Unlock Bar*, the relaxing restobar</li>
								 <li>A fitness center and a swimming pool*</li>
								 <li>Banquet halls*, meeting rooms* and business facilities*</li>
								 <li>Select amenities</li>
								 </ul>
                           <h5>7. Keys Lite by Lemon Tree Hotels</h5>
                           <p>Keys Lite Hotels are sustainably designed economy hotels. These properties delight guests with their impeccably clean interiors, efficient, agreeable service and easy going atmosphere.
								 <br><br>
								 The enhanced safety, security and hygiene measures, combined with the brand’s signature warm welcome and fond farewell, make for a stress free stay at an affordable price</p>
								 <p>The brand offers:<p>
								 <ul>
								 <li>A smart range of dining options:</li>
								 <li>Keys Café, the attractive multi-cuisine restaurant</li>
								 <li>A fitness center</li>
								 <li>Meeting rooms* and business facilities*</li>
								 <li>Essential amenities</li>
								 </ul>
								 <p>*Available at certain properties only</p> */}
            </div>
            <div
              className="tab-pane fade"
              id="nav-ourHotels"
              role="tabpanel"
              aria-labelledby="nav-ourHotels-tab"
            >
              <p>
                The group offers seven brands to meet guests’ needs across all
                levels. At our hotels across all brands, our guests bond with
                our open hearted, inclusive culture and our environmentally
                sensitive ethos
              </p>{" "}
              <br />
              <h5>1. Aurika Hotels &amp; Resorts</h5>
              <p>
                Aurika Hotels &amp; Resorts are upscale properties that offer
                exclusive experiences in an extraordinary ambience. With an
                emphasis on sustainable design inspired by local culture and
                history, these properties combine beauty and elegance with
                comfortable informality. <br />
                Our efficient service goes beyond the unusual and is
                anticipatory yet unobtrusive. Inspiring spaces, unexpected
                dining experiences, innovative technological solutions and
                seamless delivery ensure that you can work, play and relax
                effortlessly.
              </p>
              <p>The brand offers:</p>
              <p></p>
              <ul>
                <li>New-age dining options:</li>
                <li>Mirasa, the all-day dining restaurant</li>
                <li>Ariva, the bar</li>
                <li>Specialty restaurant*</li>
                <li>
                  Invigorating fitness and wellness facilities, including Araya,
                  the rejuvenating spa, a fitness center, a swimming pool and a
                  recreation room*
                </li>
                <li>Extensive banquet and event spaces </li>
                <li> Luxury amenities</li>
              </ul>
              <h5>2. Lemon Tree Premier</h5>
              <p>
                Lemon Tree Premier is a chain of sustainably designed upper
                midscale hotels and resorts that welcome you with their stylish
                and contemporary interiors. These refreshingly different
                properties offer warm, friendly and attentive service in a fresh
                and inviting ambience.
                <br />
                <br />
                Lemon Tree Premier pampers the style conscious traveller with
                personalized experiences, award winning restaurants and
                rejuvenating recreational options in a relaxed atmosphere.
              </p>
              <p>The brand offers:</p>
              <p></p>
              <ul>
                <li>An exciting range of dining options:</li>
                <li>
                  Citrus Café, the bright and inviting coffee shop offers an
                  eclectic Indian and international menu
                </li>
                <li>
                  Slounge, the recreation bar, offers a fun memorable way to
                  chill and have a spirited night out
                </li>
                <li>
                  Republic of Noodles*, the award winning pan Asian restaurant{" "}
                </li>
                <li>
                  Kebab Theater* brings you traditional mouth-watering kebab
                  delicacies from across India
                </li>
                <li>
                  A fitness center, a refreshing swimming pool and a
                  rejuvenating spa*{" "}
                </li>
                <li>Banquet halls*, meeting rooms* and business facilities*</li>
                <li>Premium amenities</li>
              </ul>
              <h5>3. Lemon Tree Hotels</h5>
              <p>
                Lemon Tree Hotels are sustainably designed midscale hotels and
                resorts that uplift your spirits at the end of a long day. These
                refreshingly different properties bring the outdoors in and
                delight guests with their fresh, vibrant interiors, fun, quirky
                elements and spirited environment.
                <br />
                <br />
                Warm greetings, friendly smiles and a whiff of our signature
                lemon fragrance welcome you here. These hotels make you feel
                close to home with their bright, comfortable rooms and service
                that goes the extra mile.
              </p>
              <p>The brand offers:</p>
              <p></p>
              <ul>
                <li> An exciting range of dining options:</li>
                <li>
                  Citrus Café, the bright and inviting coffee shop offers an
                  eclectic Indian and international menu
                </li>
                <li>
                  Slounge*, the recreation bar, offers a fun memorable way to
                  chill and have a spirited night out
                </li>
                <li>A fitness center and a refreshing swimming pool* </li>
                <li>Banquet halls*, meeting rooms* and business facilities*</li>
                <li>Refreshing amenities</li>
              </ul>
              <h5>4. Red Fox by Lemon Tree Hotels</h5>
              <p>
                Red Fox Hotels are sustainably designed economy hotels. These
                unbeatably cool properties welcome you with their fun, bold
                interiors, and crisp and clean rooms.
                <br />
                <br />
                Red Fox Hotels are young at heart and let you be yourself. Here,
                friendly smiles, professional service, and reliable safety
                standards go hand in hand with an unmatched value proposition.
              </p>
              <p>The brand offers:</p>
              <p></p>
              <ul>
                <li>A cool range of dining options:</li>
                <li>Clever Fox Café, the casual restaurant</li>
                <li>Tipsy Fox*, the trendy bar</li>
                <li>A fitness center </li>
                <li>Banquet halls*, meeting rooms* and a cyber kiosk*</li>
                <li>Fun amenities</li>
              </ul>
              <h5>5. Keys Prima by Lemon Tree Hotels</h5>
              <p>
                Keys Prima Hotels are sustainably designed upper-midscale hotels
                and resorts. These properties delight guests with their bright
                and trendy design and welcoming ambiance.
                <br />
                <br />
                At Keys Prima Hotels, the tasteful interiors and thoughtful,
                anticipatory service coupled with the brand’s signature warm
                welcome and fond farewell make for a truly memorable experience.
              </p>
              <p>The brand offers:</p>
              <p></p>
              <ul>
                <li>An smart range of dining options:</li>
                <li>Keys Café, the attractive multi-cuisine coffee shop</li>
                <li>Unlock Bar*, the relaxed restobar</li>
                <li>Specialty restaurant*</li>
                <li>A fitness center, a swimming pool* and a spa*</li>
                <li>Banquet halls*, meeting rooms* and business facilities*</li>
                <li>Thoughtful amenities</li>
              </ul>
              <h5>6. Keys Select By Lemon Tree Hotels</h5>
              <p>
                Keys Select Hotels are sustainably designed midscale hotels.
                These properties delight guests with their chic interiors,
                comfortable rooms, convenient business facilities and pleasing,
                dependable service.
                <br />
                <br />
                At Keys Select Hotels, the lively ambiance and cheerful smiles
                go hand in hand with the brand’s signature warm welcome and fond
                farewell. All this, at a sensible price.
              </p>
              <p>The brand offers:</p>
              <p></p>
              <ul>
                <li>A smart range of dining options:</li>
                <li>Keys Café, the attractive multi-cuisine restaurant</li>
                <li>Unlock Bar*, the relaxing restobar</li>
                <li>A fitness center and a swimming pool*</li>
                <li>Banquet halls*, meeting rooms* and business facilities*</li>
                <li>Select amenities</li>
              </ul>
              <h5>7. Keys Lite by Lemon Tree Hotels</h5>
              <p>
                Keys Lite Hotels are sustainably designed economy hotels. These
                properties delight guests with their impeccably clean interiors,
                efficient, agreeable service and easy going atmosphere.
                <br />
                <br />
                The enhanced safety, security and hygiene measures, combined
                with the brand’s signature warm welcome and fond farewell, make
                for a stress free stay at an affordable price
              </p>
              <p>The brand offers:</p>
              <p></p>
              <ul>
                <li>A smart range of dining options:</li>
                <li>Keys Café, the attractive multi-cuisine restaurant</li>
                <li>A fitness center</li>
                <li>Meeting rooms* and business facilities*</li>
                <li>Essential amenities</li>
              </ul>
              <p>*Available at certain properties only</p>
              {/*<h3>Upcoming Hotels:</h3>
                           <ul class="upcoming-hotels">
                              <!--<li>Manali</li>
                              <li>Rajkot</li>
                              <li>Biratnagar</li>
                              <li>Lumbini</li>
                              <!--<li>Rishikesh </li>
                              <li>Thiruvananthapuram</li>
                              <li>Anjuna</li>                                    
                              <li>Bengaluru</li>
                              <li>Budhanilkantha, Kathmandu</li>
                              <li>Dehradun </li>
                              <li>Jaipur</li>
                              <li>Thimphu</li>
                              <li>Chirang</li>
                              <!--<li>Kumbhalgarh</li>
                              <li>Haridwar</li>
                              <li>McLeodganj, Dharamshala</li>
                              <li>Mussoorie</li>                                   
                              <li>Jamshedpur</li>
                              <li>Erode</li>
                              <li>Gurugram</li>
                              <li>Kanha</li>
                              <li>Darjeeling </li>
                              <li>Jabalpur</li>
                              <li>Banswara</li>
                              <li>Chandausi</li>
                              <li>Rajahmundry</li>
                              <li>Kasauli</li>
                              <li>Rishikesh</li>
                              <li>Tezpur</li>
                              <li>Bharuch</li>
                              <li>Kharar</li>
                              <li>Lucknow</li>
                              <li>Sri Ganganagar</li>
                              <li>Lucknow</li>
                              <li>Gulmarg</li>
                              <li>Bokaro</li>
                              <li>Nagarkot</li>
                              <li>Kathmandu</li>
                              <li>Ludhiana</li>
                           </ul>*/}
            </div>
            <div
              className="tab-pane fade"
              id="nav-awards"
              role="tabpanel"
              aria-labelledby="nav-awards-tab"
            >
              <h3>2023</h3>
              <ul>
                <li>BW Hotelier Awards 2023</li>
                <h3>Winners</h3>
                <p>
                  ❖ BW Hotelier awarded Patanjali Govind Keswani, Chairman &amp;
                  MD – Lemon Tree Hotels Ltd. with The Hall of Fame Award
                </p>
                <br />
                <p style={{ marginLeft: 60 }}>
                  ➢ General Manager Upscale (East) - Abhishek Bairagi - Lemon
                  Tree Premier, Bhubaneswar
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Head of HR (National) - Monalisa Samantaray, Lemon Tree
                  Premier, Patna
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Head of Housekeeping (North) - Manish Kumar, Lemon Tree
                  Premier, Leisure Valley 2, Gurugram
                </p>
                <li>
                  Recognized by Great Place To Work® for Top 50 India's Best
                  Companies To Work For™ 2023
                </li>
                <li>
                  Recognized by Great Place To Work® for Best Workplaces™ in
                  Industry 2023
                </li>
                <li>
                  Recognized by Great Place To Work® for India's Best
                  Workplaces™ for Millennials 2023
                </li>
                <li>
                  Recognized by Great Place To Work® for Top 100 (large) Best
                  India’s Workplaces for Women 2023
                </li>
                <li>
                  Recognized by Great Place To Work® for India’s Best Workplaces
                  in Diversity, Equity, Inclusion And Belonging (large) 2023
                </li>
                <li>
                  Recognized by Great Place To Work® for Accreditation for
                  Inclusive Practices™ 2023
                </li>
                <li>
                  Received the Award for Excellence by H&amp;RA – Lemon Tree
                  Hotels awarded as Best Hotel Chain CSR
                </li>{" "}
                <li>TripAdvisor Travellers Choice Awards 2023</li>
                <p>
                  72 out of 84 eligible hotels awarded Traveller’s Choice Award
                  by TripAdvisor
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Aurika Hotels &amp; Resorts – 2
                </p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Premier –14</p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Hotels – 36</p>
                <p style={{ marginLeft: 60 }}>➢ Red Fox Hotels – 10</p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Keys Prima by Lemon Tree Hotels - 1
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Keys Select by Lemon Tree Hotels –8
                </p>
                <li>
                  Received the Gold Certification by the Indian Green Building
                  Council (I.G.B.C.)
                </li>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Premier, Ulsoor Lake, Bengaluru
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Hotel, Electronics City, Bengaluru
                </p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Hotel, Gachibowli</p>
                <br />
                <h3>2022</h3>
                <li>Hotelier India Awards 2022</li>
                <h3>Winners</h3>
                <p style={{ marginLeft: 60 }}>
                  ➢ Unsung Hero Award - Santosh Jha –Lemon Tree Premier,
                  Rishikesh
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Housekeeper of the Year - Prasun Kumar – Red Fox Hotel,
                  Delhi Airport
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ F&amp;B person the Year - Vijay Kumar - Red Fox Hotel, Delhi
                  Airport
                </p>
                <br />
                <li>BW Hotelier Awards 2022</li>
                <h3>Winners</h3>
                <p style={{ marginLeft: 60 }}>
                  ➢ BW Hotelier Anil Bhandari Chef of the Year in the East Zone
                  - Mr. Amit Bose – Lemon Tree Premier, Bhubaneswar (currently
                  at Lemon Tree Hotel, Sohna Road, Gurugram)
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ DOSM of the Year Award in the East Zone - Mr. Agnijeet Ghosh
                  –Lemon Tree Premier, New Town, Kolkata
                </p>
                <br />
                <li>
                  Awarded Chief Minister’s Excellence Award for Labour Welfare -
                  Keys Select By Lemon Tree Hotels, Thiruvananthapuram
                </li>
                <li>
                  Received the Gold Certification by the Indian Green Building
                  Council (I.G.B.C.)
                </li>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Premier, Mumbai International Airport
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Aurika, Udaipur – Luxury by Lemon Tree Hotels
                </p>
                <li>
                  Lemon Tree Premier, Dwarka, Gujarat – Awarded by Asia’s
                  Biggest Tourism Awards, organized by the Ministry of Tourism
                  Gujarat:
                </li>
                <p style={{ marginLeft: 60 }}>➢ Best 4 Star Hotel in Dwarka</p>
                <p style={{ marginLeft: 60 }}>➢ Best Restaurant in Dwarka</p>
                <p style={{ marginLeft: 60 }}>➢ Best MICE Venue in Dwarka</p>
                <br />
                <li>TripAdvisor Travellers Choice Awards 2022</li>
                <p>
                  61 out of 80 eligible hotels awarded Traveller’s Choice Award
                  by TripAdvisor
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Aurika Hotels &amp; Resorts – 1
                </p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Premier –14</p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Hotels – 31</p>
                <p style={{ marginLeft: 60 }}>➢ Red Fox Hotels – 8</p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Keys Select by Lemon Tree Hotels –7
                </p>
                <br />
                <li>
                  Awarded HRANI Award of Excellence during the 7th HRANI
                  Conclave
                </li>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Hotel, East Delhi Mall, Kaushambi awarded as
                  “Best MICE Hotel – Ghaziabad”
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ General Manager of the Hotel awarded as the “Best General
                  Manager – Midscale”
                </p>
                <h3>2021</h3>
                <li>Hotelier India Awards 2021</li>
                <p>
                  8 LTH team members made it to the finalist positions in
                  different categories/regions, with 4 winners and 4 runners up
                </p>
                <h3>Winners</h3>
                <p style={{ marginLeft: 60 }}>
                  ➢ Abhiram Menon - Lemon Tree Premier, Mumbai International
                  Airport
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Umesh Sachdev - Red Fox Hotel, Sector 60, Gurugram
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Wasim Sheikh - Lemon Tree Premier, Mumbai International
                  Airport
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Gurmeet Bajaj - Lemon Tree Hotel, Banjara Hills, Hyderabad
                </p>
                <br />
                <li>TripAdvisor Travellers Choice Awards 2021</li>
                <p>
                  68 out of 79 hotels awarded Traveller’s Choice Award by
                  TripAdvisor
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Aurika Hotels &amp; Resorts – 1
                </p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Premier –12</p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Hotels – 36</p>
                <p style={{ marginLeft: 60 }}>➢ Red Fox Hotels – 12</p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Keys Select by Lemon Tree Hotels –7
                </p>
                <br />
                <h3>2020</h3>
                <li>TripAdvisor Travellers Choice Awards 2020</li>
                <p>
                  62 out of 80 hotels awarded Traveller’s Choice Award by
                  TripAdvisor
                </p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Premier – 10</p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Hotels – 31</p>
                <p style={{ marginLeft: 60 }}>➢ Red Fox Hotels – 9</p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Keys Prima by Lemon Tree Hotels – 1
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Keys Select by Lemon Tree Hotels – 10
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Keys Lite by Lemon Tree Hotels – 1
                </p>
                <li>BW Hotelier Indian Hospitality Awards 2020</li>
                <p>
                  26 LTH team members made it to the finalist position in
                  different categories/regions 5 won in their categories and
                  there were 2 runners up
                </p>
                <h3>Winners</h3>
                <p style={{ marginLeft: 60 }}>
                  ➢ Midscale General Manager of the Year: Bipendra Gupta - Lemon
                  Tree Premier, Ulsoor Lake, Bengaluru
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Budget &amp; Economy General Manager of the Year – National:
                  Kashif Aziz - Red Fox Hotel, Delhi Airport
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Hotel Manager of the Year – East Zone: Koushik Pandit -
                  Lemon Tree Hotel, Gangtok
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Hotel Manager of the Year – North Zone: Rohit Bhandari -
                  Lemon Tree Premier, Delhi Airport
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Executive Housekeeper of the Year – South Zone: Sanjiv Kumar
                  Thakur - Lemon Tree Premier, HITEC City, Hyderabad
                </p>
                <br />
                <h3>2019</h3>
                <h3>Key Awards</h3>
                <li>
                  Investing in People Award during Tourism for Tomorrow Awards
                  2019 organized by World Travel &amp; Tourism Council in
                  Seville, Spain
                </li>
                <li>
                  Best Differently Abled Friendly Hotel at FICCI Travel &amp;
                  Tourism Excellence Awards 2019
                </li>
                <li>
                  Best 3-star Hotel at FICCI Travel &amp; Tourism Excellence
                  Awards 2019
                </li>
                <li>Awarded BW Pure: Purpose-Led Brand Award 2019</li>
                <li>Won IT Excellence Award by The Indian Express group</li>
                <li>
                  Felicitated by Schneider Electric with the 'Best Responsive
                  Award'
                </li>
                <h3>Other Awards</h3>
                <li>
                  Amity Corporate Excellence Awards for Best CSR Practices, 2019
                  by Amity School of Science and Technology
                </li>
                <li>
                  47 hotels out of 55 operating hotels (over 1 year) awarded
                  TripAdvisor’s Certificate of Excellence2019
                </li>
                <h3>2018</h3>
                <h3>Key Awards</h3>
                <li>
                  Green Champion Award 2018 under the category ‘Organization
                  Leading the Green Building Movement in India (Commercial)’
                  during 5th Green Champion Award 2018 organized by IBGC (a part
                  of CII formed in2001)
                </li>
                <li>
                  Best 3 Star Hotel Award Category during National Tourism
                  Award2016-17
                </li>
                <li>
                  Ranked 12th amongst the Best Large Workplaces in Asia by the
                  Great Place To Work Survey,2018
                </li>
                <li>
                  Financial Times Arcelor Mittal Boldness in Business Award for
                  Corporate Responsibility/ Environment
                </li>
                <h3>Other Awards</h3>
                <li>
                  Amity Leadership Awards for Business Excellence by Leveraging
                  IT, 2018 by Amity School of Science and Technology
                </li>
                <li>
                  Customer Loyalty Awards for the category of "Best Loyalty
                  Program in Services Sector” 2018
                </li>
                <li>
                  26 hotels out of 41 operating hotels (over 1 year) awarded
                  TripAdvisor’s Certificate of Excellence 2018
                </li>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Premier Ahmedabad</p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Premier 1, Leisure Valley, Gurugram
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Premier 2, Leisure Valley, Gurugram
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Premier, City Center, Gurugram
                </p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Premier, Jaipur</p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Hotel, Baddi</p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Hotel, Electronics City, Bengaluru
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Wildlife Resort, Bandhavgarh
                </p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Hotel, Chandigarh</p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Hotel, Shimona, Chennai
                </p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Hotel, Dehradun</p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Hotel, East Delhi Mall, Kaushambi
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Hotel, Candolim, Goa
                </p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Lemon Tree Hotel, Gachibowli, Hyderabad
                </p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Hotel, Indore</p>
                <p style={{ marginLeft: 60 }}>➢ Lemon Tree Hotel, Katra</p>
                <p style={{ marginLeft: 60 }}>➢ Red Fox Hotel, Bhiwadi</p>
                <p style={{ marginLeft: 60 }}>➢ Red Fox Hotel, Chandigarh</p>
                <p style={{ marginLeft: 60 }}>
                  ➢ Red Fox Hotel, East Delhi Mall, Kaushambi
                </p>
                <p style={{ marginLeft: 60 }}>➢ Red Fox Hotel, Jaipur</p>
                <p style={{ marginLeft: 60 }}>➢ Red Fox Hotel, Trichy</p>
                {/*<h3>2017</h3>
								 <h3>Key Awards</h3>
								 <li>Ranked #4 Best Company in India by Great Places to Work For Institute2017</li>
								 <li>Ranked #19 Best Company in Asia by Great Places to Work For Institute2017</li>
								 <li>Recognition for being among the best in the industry – Hotels & Resorts,2017</li>
								 <li>Recognition for being among the best in the Special Category – Employer Branding, 2017; Utilizing Analytics to Drive Great Place to Work Initiative,2017</li>
								 <li>NCPEDP Hellen Keler Award 2017 for Role Model Companies</li>
								 <li>Dr. Anji Reddy Memorial Award for Model Employer for Persons With Disabilities2017</li>
								 <h3>Other Awards</h3>
								 <li>23 hotels out of 33 operating hotels (over 1 year) awarded TripAdvisor’s Certificate of Excellence2017</li>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier Ahmedabad</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, Ulsoor Lake, Bengaluru</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, Leisure Valley 1, Gurugram</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, Leisure Valley 2, Gurugram</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, HITEC City, Hyderabad</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, Delhi Airport</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, Jaipur</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Aurangabad</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Electronics City, Bengaluru</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Whitefield</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Chandigarh</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Dehradun</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, East Delhi Mall, Kaushambi</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Candolim, Goa</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Gachibowli, Hyderabad</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Indore</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Katra</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Hinjawadi, Pune</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Vembanad Lake Resort, Kerala</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Tarudhan Valley, Manesar</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Vadodara</p>
								 <p style="margin-left: 60px;">➢ Red Fox Hotel, Bhiwadi</p>
								 <p style="margin-left: 60px;">➢ Red Fox Hotel, Jaipur</p>
								 <li>24 hotels won the Clear Trip Letter of Appreciation 2016-17</li>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, Delhi Airport</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, Jaipur</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, HITEC City, Hyderabad</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, Leisure Valley 1, Gurugram</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, Leisure Valley 2, Gurugram</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, The Atrium, Ahmedabad</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Premier, Ulsoor Lake, Bengaluru</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Chennai</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Indore</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Ahmedabad</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Shimona, Chennai</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Pune</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Chandigarh</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Whitefield, Bengaluru</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Candolim, Goa</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Whitefield, Bengaluru</p>
								 <p style="margin-left: 60px;">➢ Lemon Tree Hotel, Udyog Vihar, Gurugram</p>
								 <p style="margin-left: 60px;">➢ Red Fox Hotel, Hyderabad</p>
								 <p style="margin-left: 60px;">➢ Red Fox Hotel, Jaipur</p>
								 <p style="margin-left: 60px;">➢ Red Fox Hotel, Delhi Airport</p>
								 <li>Holiday IQ- Better Holiday Awards 2017 for being voted India’s Favourite Hotel Chain</li>
								 <li>BML Munjal Awards 2017: Runner-Up: Business Excellence through Learning and Development</li>
								 <li>People Matters Talent Acquisition League 2017:’ Best in Diversity’ Award</li>
								 <li>People Matters Total Rewards 2017: 'Best in Building Career' Award</li>*/}
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="nav-mission-vision"
              role="tabpanel"
              aria-labelledby="nav-mission-vision-tab"
            >
              <h4>Vision</h4>
              <p>
                Lemon Tree Hotels shall be the largest and most preferred Indian
                chain of upscale, upper-midscale, midscale and economy hotels
                and resorts.
              </p>
              <h4>Mission</h4>
              <p>We shall be committed to:</p>
              <ul>
                <li>
                  Ensuring the well-being and self-worth of our colleagues, who
                  are of the utmost importance to us. Enhancing this by also
                  building a culture of diversity, inclusion and equal
                  opportunity.
                </li>
                <li>
                  Contributing to the community we live in and to India in
                  general.
                </li>
                <li>
                  Delighting our guests, whose comfort, safety, security and
                  well-being is our main reason for being.
                </li>
                <li>
                  Rewarding our stakeholders, whose trust motivates us to excel
                  further.
                </li>
                <li>Protecting the environment</li>
                <li>
                  Maximizing the efficiency of our processes, to enable us to be
                  the most cost-effective company offering the greatest value,
                  which our customers have every right to expect
                </li>
              </ul>
              <h4>Core Values (Has To Rise):</h4>
              <p>
                <span>
                  <b>H</b>ealth <b>A</b>nd <b>S</b>afety:
                </span>{" "}
                We will always focus on ensuring the health, safety, security
                and well-being of all our stake holders including the
                communities within which we operate our business.
              </p>
              <ul>
                <li>
                  <span>
                    <b>T</b>eamwork
                  </span>
                  – We recognize that superlative performance is always the
                  result of teamwork.
                </li>
                <li>
                  <span>
                    <b>O</b>wnership
                  </span>
                  – We always take responsibility for our actions.
                </li>
                <li>
                  <span>
                    <b>R</b>espect and Empathy
                  </span>
                  – We always exhibit respect and concern for colleagues, guests
                  and partners.
                </li>
                <li>
                  <span>
                    <b>I</b>ntegrity
                  </span>
                  – We always maintain the highest standards of fairness and
                  transparency in all our dealings.
                </li>
                <li>
                  <span>
                    <b>S</b>pirited Fun
                  </span>
                  – We create an exciting and spirited work environment
                  encouraging our colleagues to think freely.
                </li>
                <li>
                  <span>
                    <b>E</b>xcellence
                  </span>
                  – We always drive excellence in what we do.
                </li>
              </ul>
              <h4>Learning And Development</h4>
              <p>
                The company has implemented a detailed plan for learning and
                development in order to deliver on its brand promise and has
                developed customized content and training processes for LTH that
                are world class as well as benchmarked across industries.
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="nav-csr"
              role="tabpanel"
              aria-labelledby="nav-csr-tab"
            >
              <h4>Corporate Social Responsibility</h4>
              <p>
                Lemon Tree believes that the brand should stand for more than
                ‘just profit’. We have focused our efforts on creating a
                socially inclusive work environment which seeks to bring in
                people of different backgrounds, abilities and ethnicities and
                offer them work as a unified team with a common goal.
              </p>
              <br />
              <p>
                We believe that persons with disabilities (which can be
                physical, social or economic disabilities leading to an
                opportunity deprivation) must be provided the same opportunities
                as others to realize their full potential and live with dignity.
                By creating a supportive environment in the organization that
                allows them to deliver their best, we are able to play a part,
                however small, in social inclusiveness, opportunity/livelihood
                creation and therefore nation building.
              </p>
              <p>
                Lemon Tree has defined the goal as mainstreaming ‘Opportunity
                Deprived Indians’ i.e. ODIs into its workforce. ODIs include:
              </p>
              <h3>A. Employees With Disability (EwD):</h3>
              <em>Physical Disability</em>
              <ul>
                <li>Speech and Hearing Impaired (SHI)</li>
                <li>Orthopedically Handicapped (OH)</li>
                <li>Acid Survivors (AS)</li>
                <li>
                  Going forward, also Visually Impaired (VI), Low Vision (LV)
                </li>
              </ul>
              <em>Intellectual Disability</em>
              <ul>
                <li>Down Syndrome</li>
                <li>Autism</li>
              </ul>
              <p>
                People who belong to marginalized sections of society or are
                from the Economically/ Socially (EcoSoc) weak segment:
              </p>
              <ul>
                <li>Below Poverty Line (BPL) individuals</li>
                <li> Widowed or abandoned/battered/destitute/divorced women</li>
                <li> Orphans/abandoned girls</li>
                <li> Individuals from economically weak families</li>
                <li>
                  Communities who do not get education and employment
                  opportunities easily i.e. North Eastern States, Bihar,
                  Jharkand, Chhattisgarh, Orissa, tribal/interior areas of any
                  state, etc.
                </li>
              </ul>
              <p>
                For any of the above segments (except #2), we consider those who
                have studied upto Class 9 and not beyond that.
              </p>
              <h3>Evolution of the EWD Initiative | Disability Types</h3>
              <p>
                In the early stages, we inducted EwD only in back-end roles like
                Kitchen Stewarding and Housekeeping, where direct guest
                interaction was minimal. Also the focus was only on SHI
                employees. This gave us an opportunity to develop standard
                operating procedures and training modules in an iterative
                manner. Subsequently we extended this initiative to guest
                contact areas such as our restaurants. Here, interaction with
                guests is an integral part of the job role. We then
                re-engineered the relevant service process to enable EwD to
                interact with guests. We also engaged with people with
                orthopaedic impairment where their disability did not come in
                the way of their job performance. We have now done six trials
                with Down Syndrome in Delhi/NCR at the coffee shop. As we are
                building sufficient learning and experience from this exercise,
                we will start hiring such candidates across other hotels in the
                group and slowly ramp up to a national scale. Autism trials
                (two) and Acid Survivor trials have started too and the
                learnings are being assimilated.
                <br />
                <br />
                This initiative was started in 2007. As on May, 2018,
                approximately 21% of our own employees are Indians who are
                opportunity deprived in some way.
              </p>
              <h4>Summary of Awards</h4>
              <h3>2018</h3>
              <ul>
                <li>
                  FT ArcelorMittal Boldness in Business Award for Corporate
                  Responsibility/ Environment 2018
                </li>
                <li>
                  Business Excellence by Leveraging IT, 2018 by Amity School of
                  Science and Technology
                </li>
              </ul>
              <h3>2017</h3>
              <ul>
                <li>
                  Dr. Anji Reddy Memorial Award for Model Employer for Persons
                  with Disabilities 2017 NCPEDP Helen Keller Awards for Role
                  Model Companies 2017
                </li>
                <li>
                  Ranked #4 Best Company in India by Great Places to Work For
                  Institute 2017
                </li>
                <li>
                  Ranked #19 Best Company in Asia by Great Places to Work For
                  Institute 2017
                </li>
                <li>
                  Recognition for being among the best in the Special Category –
                  Employer Branding, 2017; Utilizing Analytics to Drive Great
                  Place to Work Initiative, 2017
                </li>
                <li>
                  People Matters Talent Acquisition League 2017:’ Best in
                  Diversity’ Award
                </li>
                <li>
                  People Matters Total Rewards 2017: 'Best in Building Career'
                  Award
                </li>
              </ul>
              <h3>2016</h3>
              <ul>
                <li>
                  Ministry of Social Justice and Empowerment, Government of
                  India: Best Employer – National Award, 2016. Award received
                  from the President of India
                </li>
                <li>
                  Ranked #9 Best Company in India by Great Places to Work For
                  Institute 2016
                </li>
                <li>
                  Ranked the Best Company in India for Workplace Culture
                  Transformation 2016
                </li>
                <li>
                  Overall Winners World Responsible Tourism Award 2016 at WTM®,
                  London
                </li>
                <li>
                  Gold Winner-'Best Accommodation for Responsible Employment'
                  category’ 2016,WTM®, London
                </li>
                <li>
                  HR Innovation Award for Excellence in Diversity and
                  Inclusion,2016
                </li>
              </ul>
              <h3>2015</h3>
              <ul>
                <li>
                  HICAP Sustainable Hotel Award 2015 for Sustainable Communities
                </li>
                <li>
                  Asian Human Capital Award 2015 for Innovative and Impactful
                  People Practices by Ministry of Manpower, Singapore and the
                  Human Capital Leadership Institute
                </li>
                <li>
                  Hotel Investment Conference Asia Pacific (HICAP) 2015
                  Sustainable Hotel Awards: for Sustainable Communities
                </li>
                <li>
                  NASSCOM Social Innovation Award 2015 for Corporate
                  (Responsible Business). This award is for our training AV in
                  Indian Sign Language (ISL) for our Speech &amp; Hearing
                  Impaired (SHI) employees
                </li>
                <li>Ranked #30 in the Top 50 Great Places to Work For 2015</li>
                <li>
                  Ranked #2 Best Company in India with Unique Initiative/
                  Programme 2015
                </li>
                <li>
                  BW Hotelier HR Excellence Award 2015 for being an equal
                  opportunity employer
                </li>
              </ul>
              <h3>2014</h3>
              <ul>
                <li>
                  Cornell University Exemplary Practice Award 2014 for Lemon
                  Tree Hotels’ ccompanywide inclusion program and initiative of
                  hiring and supporting People with Disabilities.
                </li>
                <li>
                  National Tourism Award 2012-13: Best Classified hotel under 2
                  Star Category - Red Fox Hotel, East Delhi
                </li>
                <li>Nipman Foundation ‘Equal opportunity Award’ 2014</li>
                <li>
                  Ranked #86 in the Top 100 Great Places to Work For in India
                  2013 by the Great Place to Work Institute &amp; the Economic
                  Times
                </li>
              </ul>
              <h3>2013</h3>
              <ul>
                <li>
                  Aaj Tak Care Awards 2013 for CSR (Corporate Social
                  Responsibility) in the Empowerment category. This award is for
                  our Employees with Disabilities (EwD) initiative.
                </li>
                <li>
                  Ranked #67 in the Top 100 Great Places to Work For in India
                  2013 by the Great Place to Work Institute &amp; the Economic
                  Times.
                </li>
              </ul>
              <h3>2012</h3>
              <ul>
                <li>
                  Ministry of Social Justice and Empowerment, Government of
                  India: National Award: Outstanding Work in the Creation of a
                  Barrier-free Environment for Persons with Disabilities, 2012.
                  Award received from the President of India.
                </li>
                <li>
                  Ranked in the Top 50 large organizations (&gt;1000 employees)
                  in the 2012 study of Best Companies to Work For in India by
                  the Great Place to Work Institute &amp; the Economic Times.
                </li>
                <li>
                  NCPEDP (National Center for Promotion of Employment for
                  Disabled People)-Shell Helen Keller Award 2012: Category C:
                  Role Model Organizations, for the work towards promoting
                  employment opportunities for people with disabilities.
                </li>
                <li>
                  NCPEDP-Shell Helen Keller Award 2012: Category B: Role Model
                  Non-Disabled Individuals, for your work towards promoting
                  employment opportunities for people with disabilities.
                </li>
                <li>Svayam Accessibility and Universal Design Award, 2012</li>
              </ul>
              <h3>2011</h3>
              <ul>
                <li>
                  Ministry of Social Justice and Empowerment, Government of
                  India: Best Employer – National Award, 2011. Award received
                  from the President of India.
                </li>
                <li>
                  Ranked in the Top 100 in the 2011 study of Best Companies to
                  Work For in India by the Great Place to Work Institute &amp;
                  the Economic Times
                </li>
                <li>
                  MphasiS Universal Design Award, 2011: from NCPEDP (National
                  Center for Promotion of Employment for Disabled People)
                </li>
              </ul>
              <h3>2010</h3>
              <ul>
                <li>
                  NCPEDP (National Center for Promotion of Employment for
                  Disabled People, a nonprofit organization working as an
                  interface between government, industry, international agencies
                  and the voluntary sector) – Shell Helen Keller Award, 2010 for
                  exemplary beliefs and practices in employing Differently
                  Enabled People.
                </li>
              </ul>
              <h4>B.TRIBAL ART</h4>
              <p>
                Lemon Tree Hotels is the largest buyer nationally of tribal art
                from Bastar, Madhya Pradesh. This enables the group to support
                poor tribal craftsmen in this region and allows the chain to
                showcase their art extensively across its hotels.
              </p>
              <h4>C. GIVING BACK TO SOCIETY</h4>
              <p>LTH supports and partners these NGOs and societies:</p>
              <ul>
                <li>
                  Goonj: which provides clothes and utensils to the
                  impoverished.
                </li>
                <li>
                  Suniye: that runs a school for Speech and Hearing Impaired
                  children from economically weaker sections of society. It
                  provides extensive life skills support to these children.
                </li>
                <li>
                  Akshaya Patra: whose focus is to eliminate hunger in the city.
                  It regularly provides a free meal to approximately 2000
                  destitute people in Delhi, across the city.
                </li>
                <li>
                  Muskaan: which provides comprehensive education, vocational
                  training and work opportunities to young people who are
                  intellectually challenged.
                </li>
                <li>
                  Ramanujan Society: where LTH has donated gifts to students for
                  successfully clearing the IIT entrance exam.
                </li>
              </ul>
              <h4>D. Art Objects Through People For Animals</h4>
              <p>
                LTH is a large supporter of art objects promoted by People for
                Animals. This money helps support the initiatives undertaken by
                PFA for the welfare and care of animals across India.
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="nav-EFP"
              role="tabpanel"
              aria-labelledby="nav-EFP-tab"
            >
              <h4>Lemon Tree Hotels - Eco-Friendly Practices</h4>
              <p>
                Committed to a healthy and happy earth, Lemon Tree Hotels has
                implemented many eco-friendly processes for energy saving and
                water preservation, responsible waste management as well as
                measures to control water, noise and environmental pollution.
              </p>
              <h4>Caring for the Environment</h4>
              <p>
                Our existing and upcoming hotels are designed and constructed to
                qualify for the L.E.E.D Gold Standard. Leadership in Energy and
                Environment Design (L.E.E.D) is the internationally recognized
                eco-friendly building certification standard awarded by the
                United States Green Building Council (USGBC) and the Indian
                Green Building Council (IGBC) to buildings designed for energy
                savings, efficient use of water, reduction of CO2 emission and
                overall improvement in environmental quality.
              </p>
              <br />
              <p>
                Our Lemon Tree Premier, Delhi Airport, Red Fox Hotel, Delhi
                Airport and Lemon Tree Hotels, City Centre Gurugram hotels, have
                been built in accordance with the U.S. Green Building Council’s
                Gold LEED standards and the Indian Green Building Council’s
                standards.
              </p>
              <h4>Energy Conservation</h4>
              <ul>
                <li>
                  Heat Recovery Ventilators (HRV) with thermal enthalpy wheels:
                  for heat recovery from washroom exhausts
                </li>
                <li>
                  Chilled water reset through building automation: to reduce
                  power consumption required for cooling building
                </li>
                <li>
                  Heat pumps: for heat recovery, for heating domestic water.
                </li>
                <li>
                  LED lighting and CFL Lighting: both consume far less energy
                  than traditional lighting
                </li>
                <li>
                  Key Tag Energy Saver System: conserves energy in unoccupied
                  rooms
                </li>
                <li>
                  Natural/day lighting: reduces power consumption dramatically
                </li>
                <li>
                  Double Glazed Vacuum Sealed Windows: conserves energy and
                  reduces noise
                </li>
                <li>
                  Auto Time Management (for lighting, air-conditioning and
                  ventilation fans) through timers and motion sensors: helps
                  conserve energy
                </li>
                <li>
                  Energy-Efficient Hydro-Pneumatic System with
                  Variable-frequency Drive (VFD) motors for water supply:
                  ensures constant pressure and reduces load on pumps
                </li>
                <li>
                  LT Voltage Stabilizer: is energy saving and prevents damage to
                  equipment due to sudden power fluctuations
                </li>
                <li>
                  Thermal Insulation: increases room comfort and conserves
                  energy
                </li>
                <li>
                  Use of BEE certified equipment e.g. air-conditioner,
                  refrigerator, fans, etc.: reduces energy consumption
                </li>
                <li>
                  Solar Panel for hot water: alternative, renewable energy
                </li>
              </ul>
              <h4>Water Conservation</h4>
              <ul>
                <li>
                  Sewage Treatment Plant (STP): recycles water used across the
                  hotel and this recycled water is used in the garden and flush
                  systems
                </li>
                <li>
                  Aerators/Flow Restrictors including Duel Flush System:
                  maintains water force and yet reduces outflow, hence saving
                  water
                </li>
                <li>
                  Rain Water Harvesting: protects and replenishes the ground
                  water table
                </li>
                <li>Auto Flush For Public Urinals: minimizes water wastage</li>
                <li>
                  Guest engagement program – water saving poster placed in all
                  rooms that quantifies the saving of water each guest can do by
                  not getting their linen changed daily – encourages them to do
                  their small bit to save precious water when they are
                  travelling.
                </li>
              </ul>
              <h4>Green Fuels and Green Materials</h4>
              <ul>
                <li>
                  Use of CNG instead of LPG: leads to reduction of pollution
                </li>
                <li>
                  Use of Green Building Material for example recycled wood,
                  medium density fiberboard, rubber wood, particle board,
                  sawmill shavings or saw dust, AAC blocks.
                </li>
              </ul>
              <h4>Waste Management</h4>
              <ul>
                <li>Sewage Treatment: prevents pollution</li>
              </ul>
              <h4>Noise Pollution Management</h4>
              <ul>
                <li>
                  Double Glazed Vacuum Sealed Windows: reduces external noise
                  level below 50 decibels
                </li>
                <li>
                  Environmental Seals: prevents entry of noise and smoke (in
                  case of fire) into the room
                </li>
                <li>
                  Noiseless Generators: acoustically insulated, the sound level
                  is dampened to a minimal level
                </li>
              </ul>
              <h4>Operational Practices</h4>
              <ul>
                <li>
                  Laundry Paper/Cloth bags instead of plastic: environmentally
                  friendly
                </li>
                <li>
                  Recycled Garbage Bio-degradable Bags: environmentally friendly
                </li>
                <li>
                  Water Glasses inverted and placed on a cork surface: thereby
                  doing away with plastic covers
                </li>
                <li>Pencils not plastic pens</li>
              </ul>
              <h4>MoEF Report</h4>
              <ul>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_June_2016.pdf"
                    target="_blank"
                  >
                    MoEF Report June-2016
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_Dec_2016.pdf"
                    target="_blank"
                  >
                    MoEF Report Dec-2016
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_Dec_2017.pdf"
                    target="_blank"
                  >
                    MoEF Report Dec-2017
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_June_2017_Part_1.pdf"
                    target="_blank"
                  >
                    MoEF Report June-2017 Part-1
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_June_2017_Part_2.pdf"
                    target="_blank"
                  >
                    MoEF Report June-2017 Part-2
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_June_2017_Part_3.pdf"
                    target="_blank"
                  >
                    MoEF Report June-2017 Part-3
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_June_2018.pdf"
                    target="_blank"
                  >
                    MoEF Report June-2018
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_December_2018.pdf"
                    target="_blank"
                  >
                    MoEF Report December-2018
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_December_2019.pdf"
                    target="_blank"
                  >
                    MoEF Report December-2019
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/JUNE_2020_COMPLIANCE_SINGLE_FILE.pdf"
                    target="_blank"
                  >
                    MoEF Report June-2020
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/EC_CTO_COMPLIANCE_DEC_2020.pdf"
                    target="_blank"
                  >
                    HALF YEARLY EC &amp; CTO COMPLIANCE-DEC 2020
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/Final_Compliance_Report_new_June_2021.pdf"
                    target="_blank"
                  >
                    MoEF Report June-2021
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_December_2021_Part_1.pdf"
                    target="_blank"
                  >
                    MoEF Report December-2021 Part-1
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_December_2021_Part_2.pdf"
                    target="_blank"
                  >
                    MoEF Report December-2021 Part-2
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/MoEF_Report_December_2021_Part_3.pdf"
                    target="_blank"
                  >
                    MoEF Report December-2021 Part-3
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/E_waste_2022.pdf"
                    target="_blank"
                  >
                    MoEF report month of June. 2022-E waste
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/Form_4_2022.pdf"
                    target="_blank"
                  >
                    MoEF report month of June. 2022-Form 4
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/lemon_tree_compliance_2022.pdf"
                    target="_blank"
                  >
                    MoEF report month of June. 2022-Lemon Tree Compliance
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.lemontreehotels.com/factsheet/Used_Batteries_2022.pdf"
                    target="_blank"
                  >
                    MoEF report month of June. 2022-Used Batteries
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="nav-safetySecurity"
              role="tabpanel"
              aria-labelledby="nav-safetySecurity-tab"
            >
              <h4>Safety and Security:</h4>
              <p>
                At Lemon Tree Hotels, safety and security of our guests,
                employees and assets is paramount to us. Our security department
                has invested in updated technology and equipment for all our
                recent hotels and evaluates ways to upgrade security measures in
                our older hotels.
                <br />
                <br />
                For example, our hotel, Lemon Tree Premier, Delhi Airport has
                adopted face recognition software together with cameras, a video
                wall in the security control room, a 3D walkthrough and
                bio-metric access control. Some key security arrangements in our
                recent hotels include crash bollards and boom barriers at each
                entrance, under-vehicle surveillance systems, baggage x-ray
                scanners, CCTV coverage of all public areas, intruder alarm
                system and panic buttons at key locations such as the main gate,
                reception and swimming pool areas.
              </p>
              <h4>Some of the measures in practice are:</h4>
              <ul>
                <li>
                  Baggage scanners to ensure that all guest baggage is screened
                  on arrival
                </li>
                <li>
                  CCTVs located in all public areas especially at strategic
                  points
                </li>
                <li>
                  Individual fire indicators outside each room to expedite
                  emergency response in case of a fire emergency
                </li>
                <li>
                  Wide angle (110 degrees) peephole on room entrance doors
                </li>
                <li>
                  Safety chain and double lock facility in the main room door
                  interactions
                </li>
                <li>
                  Emergency procedures notice displayed behind the entrance door
                </li>
                <li>Fire resistant room door (up to a limited extent)</li>
                <li>
                  All entrance doors are lined with an environmental seal to
                  minimize noise and protect the room from external smoke, in
                  case of fire
                </li>
                <li>Smoke detector in each room</li>
                <li>Water sprinklers in each room for enhanced fire safety</li>
                <li>
                  Entrance door and bathroom doors can be double locked from the
                  inside
                </li>
                <li>Low level signage on all our buildings</li>
                <li>
                  A public address system (in most hotels) to help evacuate
                  guests and employees during an emergency
                </li>
                <li>24x7 power backup</li>
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="nav-Management"
              role="tabpanel"
              aria-labelledby="nav-Management-tab"
            >
              <p style={{ textAlign: "center" }}>
                LTH is run by a group of experienced hotel professionals who
                have worked with leading luxury hotel brands and across
                industries including Consulting, FMCG, Banking and Insurance,
                Telecom, Automobiles and offshore business processing.
              </p>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Patanjali-G-Keswani,-Chairman-_-Managing-Director.jpg"
                />
                <div className="about-wp-content">
                  <h4>Patanjali (Patu) G. Keswani</h4>
                  Chairman &amp; Managing Director
                  <br />
                  <br />
                  <p>
                    {" "}
                    Patanjali (Patu) is a B. Tech. in Electrical Engineering
                    from IIT Delhi and holds a Post Graduate Diploma Degree in
                    Management from IIM Kolkata. He was presented the
                    Distinguished Alumni Award by IIT Delhi in 2011 and by IIM
                    Kolkata in 2012. Prior to promoting Lemon Tree Hotels, he
                    has worked with Tata Administrative Services, Taj Hotels and
                    A.T. Kearney Limited. Patu is considered not just a pioneer
                    in the midscale hospitality space in India, but also a
                    leader in fostering diversity and inclusion in the industry,
                    by spearheading Lemon Tree’s robust D&amp;I initiatives,
                    including hiring of Persons With Disability. He has been the
                    founding Chairman of the Skill Council for Persons with
                    Disability and a founding member of the Sector Skill Council
                    for the Hospitality, Travel and Tourism industry.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Kapil-Sharma.jpg"
                />
                <div className="about-wp-content">
                  <h4>Kapil Sharma</h4>
                  Executive Vice President Chief Finance Officer
                  <br />
                  <br />
                  <p>
                    {" "}
                    Kapil is a qualified Chartered Accountant with B.Com (H)
                    degree from Hans Raj College, University of Delhi. His
                    diverse experience spans across businesses from Investment
                    Banking, Financial services, Logistics services,
                    Manufacturing to Hospitality Industry. He joined Lemon Tree
                    from Leroy Somer &amp; Controls India Private Limited where
                    he worked for several years as Head-Finance and Accounts.
                    Kapil has more than 25 years of experience in the finance
                    and commercial functions covering mergers &amp;
                    acquisitions, private equity, debt instruments, project
                    financing, joint venture and capital market listing etc. He
                    has previously worked with Onida Finance Limited and AFL
                    Limited.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Davander-Singh.jpg"
                />
                <div className="about-wp-content">
                  <h4>Davander Singh Tomar</h4>
                  Executive Vice President – Corporate Affairs
                  <br />
                  <br />
                  <p>
                    Davander is a B. Com, M.A. and an L.L.B. from University of
                    Delhi. He has 18 years of experience with Taj Hotels where
                    he worked as the Area Security Manager of Taj Palace Hotel,
                    New Delhi. He has over 35 years of experience in the
                    hospitality industry.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Jagdish-Kumar-Chawla.jpg"
                />
                <div className="about-wp-content">
                  <h4>Jagdish Kumar Chawla</h4>
                  Executive Vice President – Projects &amp; Engineering Services
                  <br />
                  <br />
                  <p>
                    Jagdish holds a Diploma in Electrical Engineering from the
                    Pusa Polytechnic, Delhi and has more than 45 years of
                    experience in engineering, construction and operations.
                    Prior to joining Lemon Tree Hotels in 2002, he has worked at
                    Taj Hotels and The National Thermal Power Corporation,
                    Bharti Electric Steel Company Limited and Mother Dairy. His
                    efforts have ensured that Lemon Tree Hotels Ltd. received
                    the “Green Champion Award” from the Indian Green Building
                    Council in 2018 for the construction of several
                    gold-certified hotel buildings.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Vilas-Pawar,-CEO.jpg"
                />
                <div className="about-wp-content">
                  <h4>Mr. Vilas Pawar</h4>
                  CEO – Managed &amp; Franchise Business
                  <br />
                  <br />
                  <p>
                    Vilas is a hotel management graduate of IHM, Mumbai, and has
                    worked for over 35 years with marquee brands including Taj
                    Hotels, Hyatt Hotels and Resorts, Radisson Hotels, Edenpark
                    Hotels and TGI Fridays. In his last assignment, as Director
                    and Chief Executive Officer, he was responsible for
                    establishing the operations and franchise business for
                    Choice Hotels International in India, Nepal, Sri Lanka and
                    the Maldives.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Sumant-Jaidka.jpg"
                />
                <div className="about-wp-content">
                  <h4>Sumant Jaidka</h4>
                  Senior Vice President – Operations
                  <br />
                  <br />
                  <p>
                    Sumant is a graduate of the Salzburg School of Hotel
                    Management, Austria. He has over 30 years of experience in
                    the hospitality industry. He started his career with the
                    Hyatt Regency New Delhi in the F&amp;B department, before
                    moving to the Intercontinental Hotel and later Taj Palace,
                    New Delhi. After a short tenure with Crowne Plaza, he joined
                    the Lemon Tree Hotels. He has been with the company for over
                    15 years and has played an instrumental role in its growth.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Harleen-Mehta-Senior-Vice-President-Sales.jpg"
                />
                <div className="about-wp-content">
                  <h4>Harleen Mehta</h4>
                  Senior Vice President – Sales
                  <br />
                  <br />
                  <p>
                    Harleen is an MBA from Symbiosis Institute of Management
                    Studies, Pune and holds a degree in Hotel Management from
                    IHM, Gwalior. She has over 20 years of experience in the
                    hospitality industry and has played a wide range of
                    leadership roles in Sales and Marketing at unit, regional
                    and corporate levels across multiple brands within India.
                    Prior to joining Lemon Tree Hotels, Harleen has been
                    associated with Oberoi Hotels and Resorts, Taj Hotels and
                    Hyatt Hotels and Resorts, where her last role was as Vice
                    President – Sales Operations, India.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Aradhana-Lal.jpg"
                />
                <div className="about-wp-content">
                  <h4>Aradhana Lal</h4>
                  Senior Vice President – Sustainability &amp; ESG
                  <br />
                  <br />
                  <p>
                    Aradhana is a TedX speaker and an MBA from IIM Ahmedabad
                    with 29 years of experience in Sales, Marketing, Corporate
                    Communications and ESG/Sustainability at Hindustan Unilever,
                    Taj Group and Lemon Tree Hotels. Today, Aradhana leads
                    ESG/Sustainability which includes Planet/Environment
                    (renewal energy, water recycling and reuse, etc.) and
                    People/Diversity, Equity and Inclusion (hiring Employees
                    With Disabilities – deaf, low vision, Acid Survivor, Down
                    Syndrome, Autism and Economically &amp; Socially
                    Marginalized – transgender, school dropouts, orphans,
                    widows/divorcees). Aradhana was awarded the Role Model
                    Supporter of Increased Employment Opportunities for Persons
                    With Disabilities by NCPEDP in 2018.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Ritu-Ranjan.jpg"
                />
                <div className="about-wp-content">
                  <h4>Ritu Ranjan</h4>
                  Senior Vice President &amp; Chief Design Officer
                  <br />
                  <br />
                  <p>
                    Ritu, a graduate from Lady Shri Ram College, New Delhi, has
                    been working with Lemon Tree Hotels for over 10 years. She
                    works closely with the Interior designers, Architects and
                    the Chairman’s office to develop and execute the design
                    vision of the group across its brands. She leads the design
                    conceptualization and implementation for new hotels and also
                    works on the upgradation of the older hotels in the group.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Sareena-Kochar.jpg"
                />
                <div className="about-wp-content">
                  <h4>Sareena Kochar</h4>
                  Senior Vice President – Housekeeping
                  <br />
                  <br />
                  <p>
                    A Post Graduate in Hotel Management from All India Institute
                    of Management Studies, Chennai, Sareena has three decades of
                    experience in the industry. An expert in hotel openings, she
                    has launched several brands for Lemon Tree Hotels and hotels
                    for the Lalit Group.. She has previously worked with VLCC,
                    IHG Hotels &amp; Resorts and Hilton Hotels &amp; Resorts.
                    She has launched eco-friendly practises in the company. A
                    certified mentor from XLRI Jamshedpur, she has nurtured
                    talent in the organisation. A key member of the ‘Swachya
                    Bharat Swasthya Bharat’ campaign, she also trains Speech and
                    Hearing Impaired (SHIs) team members in Housekeeping.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Rajesh-Kumar,-S.jpg"
                />
                <div className="about-wp-content">
                  <h4>Rajesh Kumar</h4>
                  Senior Vice President – Human Resources
                  <br />
                  <br />
                  <p>
                    Rajesh is a Post Graduate of Personnel Management and
                    Industrial Relations as well as Public Administration from
                    Himachal Pradesh University. In a career spanning over 20
                    years, Rajesh has been associated with Oberoi Hotels and
                    Resorts, Select Holiday Resorts and Mahindra Holidays &amp;
                    Resorts, where he experimented with and implemented new HR
                    initiatives. He joined Lemon Tree Hotels over 15 years ago,
                    and has been awarded the Margdarshak Award from FOSTIIMA
                    Business School for his initiatives at the company.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Chef-Rajeev-Janveja.jpg"
                />
                <div className="about-wp-content">
                  <h4>Chef Rajeev Janveja</h4>
                  Senior Vice President &amp; Corporate Chef
                  <br />
                  <br />
                  <p>
                    Chef Rajeev started his culinary journey with Taj Hotels
                    after completing his studies from IHM Lucknow. Over the
                    years, he has honed his skills at various Taj Hotels across
                    the country as well as with some other leading retail food
                    businesses. As the Senior Vice President &amp; Corporate
                    Chef of Lemon Tree Hotels, Chef Rajeev is directly
                    responsible for F&amp;B (Production) and F&amp;B (Service)
                    across all brands and hotels in the company. He also
                    researches and plans menus for new, upcoming hotels and
                    develops new and innovative food concepts for the company’s
                    F&amp;B brands.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Rajiv-Tyagi,-Vice-President.jpg"
                />
                <div className="about-wp-content">
                  <h4>Rajiv Tyagi</h4>
                  Vice President – Finance
                  <br />
                  <br />
                  <p>
                    Rajiv is a commerce graduate from the University of Delhi.
                    During a career spanning over 25 years, he has worked with
                    ITC Hotels and Taj Hotels, prior to joining Lemon Tree
                    Hotels. He was previously the Assistant Vice President -
                    Finance, of Lemon Tree Hotels.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Geeta-Jetley,-Vice-President.jpg"
                />
                <div className="about-wp-content">
                  <h4>Geeta Jetley</h4>
                  Vice President – Operations
                  <br />
                  <br />
                  <p>
                    Geeta Jetley is an alumna of the Oberoi Centre for Learning
                    and Development and has spent over 25 years working in the
                    hotel business in India and overseas. Before joining Lemon
                    Tree Hotels in a leadership role at Carnation Hotels (the
                    management subsidiary of Lemon Tree Hotels), Geeta headed
                    operations for Wyndham Hotels in India. She has been
                    associated with Oberoi Hotels and Resorts as well as
                    Starwood Hotels and Resorts, and has extensive experience in
                    hotel openings and re-brands. As a part of Carnation Hotels,
                    Geeta has driven the asset light growth strategy of the
                    company.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Naresh-Chander-Malhotra.jpg"
                />
                <div className="about-wp-content">
                  <h4>Naresh Chander Malhotra</h4>
                  Vice President – Projects &amp; Engineering Services
                  <br />
                  <br />
                  <p>
                    Naresh is a mechanical engineer, a graduate from University
                    of Delhi and holds a part ‘A' AMIE certification. His last
                    assignment was as Chief Engineer, Batra Hospital. His vast
                    industry experience also encompasses Taj Hotels, Clarks
                    Hotels, Santa Cruz International Airport and the
                    Indraprastha Power Station.
                  </p>
                </div>
              </div>
              {/*<div class="about-wp">
                              <img alt=""  class="" src="https://www.lemontreehotels.com/assets/front/kimages/Samarth-Agarwal.jpg">
                              <div class="about-wp-content">
                                 <h4>Samarth Agarwal</h4>
                                 Vice President - Business Development<br>
                                 <br>
                                 <p>Samarth is a post graduate in management studies in finance and marketing from Amity Business School, Noida. With over 15 years of experience in the Realty business, he has been associated with Damac Properties (Dubai), Wal-Mart India, InterGlobe Hotels, Cushman & Wakefield and Evalueserve at various stages of his career. Prior to joining Lemon Tree Hotels, he was working as Chief Development Officer for Hamstede Living.</p>
                              </div>
                           </div>*/}
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Shivali-Verma.jpg"
                />
                <div className="about-wp-content">
                  <h4>Shivali Verma</h4>
                  Vice President - Commercial &amp; Franchise Services
                  <br />
                  <br />
                  <p>
                    Shivali is an alumnus of IHM Pusa Delhi and FORE School of
                    ManagementWith an experience of over 25 years in the field
                    of hospitality, she has worked with brands including
                    Radisson Hotels, Hyatt Hotels and Resorts and Oberoi Hotels
                    and Resorts. She was previously heading sales, marketing and
                    brand management for Choice Hotels India as Vice President
                    of Sales &amp; Marketing, and was responsible for franchise
                    services and business development for the organisation
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Rajinder-Kapur.jpg"
                />
                <div className="about-wp-content">
                  <h4>Rajinder Kapur</h4>
                  Vice President – Finance &amp; Legal (Franchise Business)
                  <br />
                  <br />
                  <p>
                    Rajinder is Master of Commerce, MBA in Finance and Bachelor
                    of Law. He comes with a diverse experience of nearly 35
                    years and has worked with Hyatt Hotels and Resorts, Edenpark
                    Hotels and Choice Hotels, prior to joining Lemon Tree
                    Hotels. In his previous role as Vice President – Finance
                    &amp; Legal, Choice Hotels India, he was responsible for the
                    company’s finance and legal departments, along with business
                    development.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Ajai-Kumar,-Chief-Information-Officer.jpg"
                />
                <div className="about-wp-content">
                  <h4>Ajai Kumar</h4>
                  Chief Information Officer
                  <br />
                  <br />
                  <p>
                    An MCA from Madan Mohan Malviya Engineering College,
                    Gorakhpur &amp; MBA (Information Systems) from Sikkim
                    Manipal University, Ajai has over 30 years of experience in
                    IT Strategy, Digital Enablement, Technology Management and
                    Operations. He has worked in Senior IT Management roles with
                    BAX Global India Pvt Ltd, Gati Ltd, Central Cottage
                    Industries Corp of India Ltd, Anand Automotive Systems Ltd
                    and Blue Dart Express Ltd. At Lemon Tree Hotels, he leads
                    the evaluation, priority setting, deployment and management
                    of current and future technology products, services and
                    systems for the company.
                  </p>
                </div>
              </div>
              {/*    <div class="about-wp">
                              <img alt="" class="" src="https://www.lemontreehotels.com/assets/front/kimages/Nikhil-Sethi,-Assistant-Vice-President.jpg">
                              <div class="about-wp-content">
                                 <h4>Nikhil Sethi</h4>
                                 Assistant Vice President Legal & Group Company Secretary<br>
                                 <br>
                                 <p>Nikhil is a qualified Company Secretary and a Law Graduate with an experience of over 17 years in handling secretarial and legal matters for hospitality and other companies. He has been instrumental in handling key assignments such as listing of equities and debt instruments; corporate restructuring that involves mergers, acquisitions and demergers, joint ventures, PE investments etc. He also has an extensive experience in execution of various commercial and legal agreements. Prior to joining Lemon Tree Hotels, he has worked with Punj Lloyd Ltd. and Asian Hotels (West) Ltd.</p>
                              </div>
                           </div>*/}
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Mayank-Sharma.jpg"
                />
                <div className="about-wp-content">
                  <h4>Mayank Sharma</h4>
                  Assistant Vice President - Finance
                  <br />
                  <br />
                  <p>
                    Mayank is a Member of the Institute of Chartered Accountants
                    of India. He also holds a Bachelor's degree in Commerce from
                    Delhi University. He has been associated with Lemon Tree
                    Hotels since 2012 and is a key member of finance team with
                    excellent analytical skills and results driven attitude.
                    Prior to joining Lemon Tree Hotels, he was working with the
                    assurance arm of Ernst &amp; Young.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Devinder-Kumar-Assistant-Vice.jpg"
                />
                <div className="about-wp-content">
                  <h4>Devinder Kumar</h4>
                  Vice President – Revenue Management &amp; Distribution
                  <br />
                  <br />
                  <p>
                    Devinder has a B.Sc. and a Post Graduate Diploma in Hotel
                    Management from IHM, Shimla. He has studied Revenue
                    Management and Dynamic Pricing at IIM, Ahmedabad. With more
                    than 20 years of experience, Devinder came on board Lemon
                    Tree Hotels in 2010 and has contributed to the Revenue
                    Management and Distribution Team ever since. He has also
                    handled diverse responsibilities in Operations &amp; Sales
                    across hotel brands like ITC Hotels, Starwood Hotels and
                    Resorts, Marriott and The Grand, New Delhi.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Naresh-Kumar.jpg"
                />
                <div className="about-wp-content">
                  <h4>Naresh Kumar</h4>
                  Assistant Vice President – Materials
                  <br />
                  <br />
                  <p>
                    Naresh is a postgraduate in Commerce from the University of
                    Shimla, Himachal Pradesh. During a career spanning more than
                    25 years, he has worked with Grand Hyatt, Park Royal,
                    Imperial Hotel and Shangri-la Hotel. He has worked with
                    Lemon Tree Hotels since 2007 in the Purchase Department. His
                    last assignment was Assistance Purchase Manager at
                    Shangri-la Hotel.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-poochPolicy"
              role="tabpanel"
              aria-labelledby="nav-poochPolicy-tab"
            >
              <h4>Pooch Policy - Canines (K9)</h4>
              <p>
                We love dogs, which is why we have adopted a street dog across
                almost all of our 100+ hotels. We name the dog, inoculate it and
                feed it well. Watch the video for a typical day in the life of a
                K9 at Lemon Tree Hotels. Enjoy!
              </p>
              <a
                href="https://www.youtube.com/watch?v=_1zRNnGDeDo"
                target="_blank"
              >
                <b style={{ color: "blue", textDecoration: "underline" }}>
                  Youtube Video
                </b>
              </a>
              <div className="about-wp mt-5">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/arti.jpg"
                />
                <div className="about-wp-content">
                  <h4>Artificer Polytechnus Keswani aka Street Arthur</h4>
                  Assistant Manager, Chairman’s Office
                  <br />
                  <br />
                  <p>
                    {" "}
                    Lemon Tree Hotels <br />
                    Artificer, who was earlier serving as Executive Assistant to
                    the Vice Chairdog, has been elevated to the position of
                    Assistant Manager, Chairman’s Office with immediate effect,
                    on account of the sad demise of Sparky Keswani (who was also
                    our Company Mascot) on 1st August, 2017. Artificer, will be
                    personally inducted into his new role by the Chairman
                    himself, as he steps into the illustrious paw-th of his
                    predecessor.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Knotty(2)-Copy.jpg"
                />
                <div className="about-wp-content">
                  <h4>Knotty aka Not-Yet-A-Tree</h4>
                  Associate, K9 squad
                  <br />
                  <br />
                  <p>
                    Knotty, a honey blonde Indian dog, thrives at the Lemon Tree
                    Hotel, Ahmedabad. The alcohol-free, mostly vegetarian
                    environment of the city appears to suit his spiritual
                    nature, demonstrated by his deep desire to meditate for
                    prolonged periods, in absolute silence. Curious guests may
                    however spot him vociferously loitering just outside the
                    coffee shop kitchen at lunch time only (1 pm to 2 pm,
                    daily).
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/K-Squad.jpg"
                />
                <div className="about-wp-content">
                  <h4>Potty aka Pot Tree</h4>
                  Training Associate
                  <br />
                  <br />
                  <p>
                    Pot Tree, a male dog, resides at the Lemon Tree Hotel,
                    Aurangabad. His aimless (to the ignorant) scampering fueled
                    by his incredibly high metabolism has led to a prophetic
                    nickname. Due to his swift intake, speedy throughput and
                    copious output, Potty represents our core value of
                    "Excellence".
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/spaw.jpg"
                />
                <div className="about-wp-content">
                  <h4>S'Paw</h4>
                  Spouse of Potty
                  <br />
                  <br />
                  <p>
                    S'Paw, a female dog, cohabits with Potty in the sylvan
                    surrounds of the Lemon Tree Hotel, Aurangabad. A confident
                    and affectionate dog, s'Paw can normally be found gamboling
                    in the front lawns of the hotel or hanging about near the
                    greenhouse in the backyard.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Ouch.jpg"
                />
                <div className="about-wp-content">
                  <h4>Ouch</h4>
                  Associate, K9 Squad
                  <br />
                  <br />
                  <p>
                    Ouch, a white Indian dog of male persuasion, lives in the
                    Lemon Tree Hotel, Chandigarh. With his charming yet playful
                    nature and propensity to jump in and out of nearby bushes,
                    he has already acquired a female fan following in the
                    neighborhood.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Sleepee.jpg"
                />
                <div className="about-wp-content">
                  <h4>S'leepee</h4>
                  Canine Resource Associate, K9 squad
                  <br />
                  <br />
                  <p>
                    S'leepee, a multi-hued Indian dog, resides at the south east
                    corner of the Lemon Tree Hotel, Chennai. An ascetic by
                    inclination, S'leepee prefers a simple meal of curd rice or
                    on Sundays, idli and coconut chutney.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Byte.jpg"
                />
                <div className="about-wp-content">
                  <h4>Byte</h4>
                  Associate, K9 Squad
                  <br />
                  <br />
                  <p>
                    Byte, a silky tan and white female dog lives in the basement
                    of the Lemon Tree Hotel, Electronics City, Bengaluru. Her
                    focus on 3 principles: eat, love and play symbolizes Lemon
                    Tree Hotels yearning for simplicity.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/K9- nipnrun.jpg"
                />
                <div className="about-wp-content">
                  <h4>Nip'n'run aka Nipper</h4>
                  Accounts Associate, K9 squad
                  <br />
                  <br />
                  <p>
                    Nipper a brainy blonde male dog, resides at the south west
                    corner of Lemon Tree Hotel, Indore, adjoining the staff
                    entrance gate. Nipper would embody our core value of
                    "Inquisitiveness", had we such a value.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Lottery.jpg"
                />
                <div className="about-wp-content">
                  <h4>Lottery aka Lot Tree</h4>
                  Associate, K9 squad
                  <br />
                  <br />
                  <p>
                    Lot Tree, a black and white female dog of indeterminate
                    origin, frisks around the Lemon Tree Hotel, UdyogVihar,
                    Gurgaon. As far as we can tell, her skill set indicates a
                    marked preference for games-of-chance and somnolence.
                    Lottery embodies our core value of 'Fun'.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Snippy1.jpg"
                />
                <div className="about-wp-content">
                  <h4>Snippy aka Snippet-Of-A-Tree</h4>
                  Associate, K9 squad
                  <br />
                  <br />
                  <p>
                    Snippy, a richly bewhiskered chocolate brown dog presently
                    frolics at the Lemon Tree Vembanad Lake Resort, Alleppey,
                    Kerala. Snippy may symbolize our core value of 'Integrity'
                    as she never shirks any of her self-assigned duties, no
                    matter how inscrutable these may appear to be.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/tommy12.jpg"
                />
                <div className="about-wp-content">
                  <h4>Tommy aka Tom Tree</h4>
                  Top Dog, Guest Protection Services, Pune Region
                  <br />
                  <br />
                  <p>
                    Tom Tree, a male tan and white Indian dog, is located at the
                    Lemon Tree Hotel, Hinjawadi, Pune. Tom Tree embodies our
                    core value of "Respect and Concern for others" and is
                    responsible for guarding. Update: Subsequent to his
                    confirmation and assurance of a steady job at the hotel,
                    Tommy has started a family. Lemon Tree is delighted to
                    introduce these three new additions to the group: Tic, Tac
                    and Toe.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Lemo.jpg"
                />
                <div className="about-wp-content">
                  <h4>Lemo</h4>
                  Deputy Associate
                  <br />
                  <br />
                  <p>
                    In keeping with our mantra to reduce bureaucracy, Lemo has
                    been hired as Deputy Assistant Associate to the Assistant
                    Deputy Manager reporting to the Executive Assistant to the
                    Chairman. His purview is to make management lean.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Blotch.jpg"
                />
                <div className="about-wp-content">
                  <h4>Blotch</h4>
                  Horticulture Associate
                  <br />
                  <br />
                  <p>
                    Blotch, a white, tan and brown Indian dog lives in the Rock
                    Garden of the Lemon Tree Premier, HITEC City, Hyderabad. In
                    spite of the natural camouflage offered by his coat, he may
                    occasionally be glimpsed from guest rooms facing the South
                    West.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Foxy.jpg"
                />
                <div className="about-wp-content">
                  <h4>Foxy</h4>
                  <br />
                  <br />
                  <p>
                    Foxy, a black and beige pup resides in Red Fox Hotel, East
                    Delhi and can often be spotted at the perimeter of the hotel
                    slightly recumbent. Having acquired a gourmet’s taste for
                    bread, she now enjoys a position among the ranks (and
                    hearts) of the neighborhood bakers.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Pixy.jpg"
                />
                <div className="about-wp-content">
                  <h4>Pixy</h4>
                  <br />
                  <br />
                  <p>
                    Pixy is an angelic white dog with gentle eyes who lives in
                    Red Fox Hotel, Jaipur. Playful by nature, she is frequently
                    seen bouncing outdoors with sudden bursts of inexplicable
                    intensity.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Roxy.jpg"
                />
                <div className="about-wp-content">
                  <h4>Roxy</h4>
                  Training Associate
                  <br />
                  <br />
                  <p>
                    Roxy is the cutest kid on the block. This cuddly beige ball
                    of joy is often seen basking in the sun and rolling around
                    in the lush green grass of Red Fox Hotel, Hyderabad’s
                    Heritage Garden. With the classiest bowtie around, this
                    bonny hound is the apple of everyone’s eye.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Snuggles1.jpg"
                />
                <div className="about-wp-content">
                  <h4>Snuggles aka Eat Tree</h4>
                  K9 Squad
                  <br />
                  <br />
                  <p>
                    Snuggles, a black and white Indian dog resides in the Lemon
                    Tree Premier at St. Johns road, Bengaluru. Snuggles seems to
                    like snuggling and cuddling.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Pointy.jpg"
                />
                <div className="about-wp-content">
                  <h4>Pointy</h4>
                  Assistant Administrative Officer
                  <br />
                  <br />
                  <p>
                    Pointy, a beige Indian dog of female gender resides all over
                    the Lemon Tree Hotel, Shimona, Chennai. Her name is derived
                    from her ability to always face North, no matter what the
                    temptation to turn elsewhere.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Geek.jpg"
                />
                <div className="about-wp-content">
                  <h4>Geek</h4>
                  Customer Analytics Specialist
                  <br />
                  <br />
                  <p>
                    Geek, a yellow Labrador Retriever typically lounges within a
                    14.35 meter radius of the coffee shop kitchen. His ability
                    to collect, analyse and review customer behaviour helps the
                    hotel to anticipate the changing needs of customers and
                    deliver guest delight.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Battery.jpg"
                />
                <div className="about-wp-content">
                  <h4>Battery a.k.a Bat Tree</h4>
                  K9 security squad
                  <br />
                  <br />
                  <p>
                    Battery a.k.a Bat Tree is an Indian male dog who generally
                    resides in the North West quadrant of the Lemon Tree Hotel,
                    Gachibowli, Hyderabad. His nocturnal habit of stealthy
                    patrolling is greatly aided by his pitch black color.
                  </p>
                </div>
              </div>
              <div className="about-wp">
                <img
                  alt=""
                  className=""
                  src="https://www.lemontreehotels.com/assets/front/kimages/Queen.jpg"
                />
                <div className="about-wp-content">
                  <h4>Queen Mala aka Ma’ lady</h4>
                  Wellness Associate
                  <br />
                  <br />
                  <p>
                    All dressed in charcoal grey and white, Queen Mala is as
                    exquisite as her name. Perfectly sculptured and with deep
                    pristine eyes, this charmer with a slightly husky bark, can
                    be found engaging with the hotel staff as if to check on
                    every ones well-being and health.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


      <FooterPage />


    </>
  )

}

export default AboutUsPage;

