import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';


function ThankYouPage() {
   return (
    <>
      <HeaderPage />

      <section id="redfox-bodypart" style={{ marginTop: 0 }}>
  <div id="redfox-midwrapper">
    <div className="right-pannel">
      <section className="updated-error-page">
        <div className="container">
          <div className="row">
            <div className="updated-error-page-inner text-center">
              <div className="updated-error-page-inner-heading">
                <svg width={100}
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                >
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#fff"
                    strokeWidth={6}
                    strokeMiterlimit={10}
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#fff"
                    strokeWidth={6}
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
                <h2 className="mt-4">
                  Thank you for selecting Lemon Tree Hotels.
                </h2>
                <p>
                  We look forward to hosting you and are committed to making
                  every aspect of your stay comfortable and enjoyable. Should
                  you require any assistance, please feel free to contact us. We
                  hope to welcome you back for another stay at Lemon Tree Hotels
                  soon.
                </p>
              </div>
              <div className="updated-error-page-inner-btn">
                <a href="/">Home Page</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <p />
      <div style={{ clear: "both" }} />
      <div></div>
    </div>
  </div>
</section>

      <FooterPage />


    </>
  )

}

export default ThankYouPage;

