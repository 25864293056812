import HeaderPage2 from "../Header2";
import FooterPage from '../Footer';
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";


import OfferSlider from "../Sliders/Offerslider";
function HofferPage() {
  const{brand_slug} = useParams(); 
  const{city_alias} = useParams();
  const{hotel_slug} = useParams();

  const[htlcontact, sethtlcontact] = useState({   
      brand_slug:brand_slug,
      city_slug:city_alias,
      hotel_slug:hotel_slug
})
  return (
    <>
<HeaderPage2 />
<section className="filters-cs cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href={hotel_slug}>
               
                Destination
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/hotels"> Hotels </a>
            </li>
            <li className="breadcrumb-item">
            <Link to={`../${brand_slug}/${city_alias}/${hotel_slug}`}> {brand_slug}, {city_alias}</Link>
            
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Offers
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>



<section className="ourOffers-cs-sc cs-sc-padding ">
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <div className="sc-heading new-reso-heading-rj">
          <h1> Hotel Offers</h1>
        </div>
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-md-12">
        <div className="below_slider-cs-sc-main">
       <OfferSlider /> 

        </div>
      </div>
    </div>
  </div>
</section>


<FooterPage />

    </>
  )

}

export default HofferPage;

