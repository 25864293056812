import HeaderPage2 from "../Header2";
import FooterPage from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faVolumeControlPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {useParams} from "react-router-dom";
import {useState, useEffect} from "react";
import axios from "axios";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

function ContactUsPage() {  
  const{brand_slug} = useParams(); 
  const{city_alias} = useParams();
  const{hotel_slug} = useParams();

  const[htlcontact, sethtlcontact] = useState({   
      brand_slug:brand_slug,
      city_slug:city_alias,
      hotel_slug:hotel_slug
})

// {[key:'Heading', value: [{}], [key:'Heading', value: [{}]}

// [{key: 'Accordion Title', values: []}]  nested  object structure

const[hcontact, sethcontact] = useState([]);
const[todoculture, settodoculture] = useState([]);
const[hltcare, sethltcare] = useState([]);
const[lndmrk, setlndmrk] = useState([]);
const[shpg, setshpg] = useState([]);
const[meta1, setmeta1] = useState([]);

const showhotelcontact = () =>{
  axios.post('https://www.sdsfoundation.com/lth/demo/hotel-contact-us', htlcontact).then(res => {
    sethcontact(res.data.hotels);
    settodoculture(res.data.things_to_dos_culture_category);
    sethltcare(res.data.things_to_dos_healthcare_category);
    setlndmrk(res.data.things_to_dos_landmark_category);
    setshpg(res.data.things_to_dos_shopping_category);
    setmeta1(res.data.hotel_meta);

}).catch(err => {
console.log(err)
})
}

// on load function call for loading 
useEffect(() => {
  showhotelcontact();
},[])

  return (
    <>
    <Helmet>
        <title>{meta1 && meta1.length>0 &&  meta1[0].title}</title>
        <meta name="description" content={meta1 && meta1.length>0 &&  meta1[0].description} />
    </Helmet>

 <HeaderPage2/> 

<section className="filters-cs cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
        {
      hcontact.map((val) => 
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href={hotel_slug}>
               
                Destination
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#"> Hotels </a>
            </li>
            <li className="breadcrumb-item">
            <Link to={`../${brand_slug}/${city_alias}/${hotel_slug}`}> {brand_slug}, {city_alias}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Contact Us
            </li>
          </ol>
      )
    }
        </nav>
      </div>
    </div>
  </div>
</section>


<section className="contact-main-cs-sc cs-sc-padding bg-light">
  <div className="container">
  <div className="row">
  <div className="col-md-8">
    <div className="sc-heading">
      <h1> Contact Us</h1>
    </div>
  </div>
</div>


    <div className="row">
      <div className="col-md-12">
        <div className="contact-us-inner-main contact-us-lt-rj">
        {
      hcontact.map((val) => 
        
         
          <ul>
          
            <li>
              <h4>
              <FontAwesomeIcon icon={faMapMarkerAlt} color="#c3cfd6" />
                     Address   
              </h4>
              {val.hotel_address}
              {/* <span>
                <a
                  href="https://www.google.com/maps?ll=27.21121,77.997202&z=13&t=m&hl=en&gl=IN&mapclient=embed&cid=1767151043225105889"
                  target="_blank"
                >
                  NH 2, near St. Conrad’s Inter College, Bypass Road Khandari,
                  Agra, Uttar Pradesh - 282002, India
                </a>
              </span> */}
            </li>
 
            <li>
              <h4>
              <FontAwesomeIcon icon={faVolumeControlPhone} color="#c3cfd6" spin />
              
                Phone
              </h4>
              <span>
                <a href={val.contactnumber1}>{val.contactnumber1}</a>
              </span>
            </li>
            <li>
              <h4>
              <FontAwesomeIcon icon={faEnvelope} color="#c3cfd6" /> Email
              </h4>
              <span>
                <a href={val.contact_email}>
                 {val.contact_email}
                </a>
                <br />
                <br />
                <a
                  href={val.factsheet}
                  className="cta-cs"
                  target="_blank"
                >
                  Download Factsheet
                </a>
              </span>
            </li>
          </ul>
            )
          }
   
        </div>
      </div>
    </div>
  </div>
</section>

<section className="location-cs-sc cs-sc-padding cont1">
  <div className="container">
    <div className="row">
      <div className="col-md-8 mb-4 new-reso-heading-rj">
        <center className="sc-heading">
          <h2>Things to do in {city_alias}</h2>
        </center>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6">
        <div id="accordion">
          <div className="card">
            <div className="card-header" id="heading0">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapse0"
                  aria-expanded="false"
                  aria-controls="collapse0"
                >
                  
                  {todoculture && todoculture.length>0 && todoculture[0].thingstodo_category_id}
                </button>
              </h5>
            </div>
            <div
              id="collapse0"
              className="collapse show"
              aria-labelledby="heading0"
              data-parent="#accordion"
            >
              <div className="card-body">
            { 
            todoculture.map((val) => 
                <ul>
                  <li>
                    {val.title} <span>{val.distance_in} km</span>
                  </li>
                
                </ul>
            )
          }
              </div>
            </div>
          </div>

        

          <div className="card">
            <div className="card-header" id="heading1">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapse1"
                  aria-expanded="false"
                  aria-controls="collapse1"
                >
           {hltcare && hltcare.length>0 && hltcare[0].thingstodo_category_id}
                </button>
              </h5>
            </div>
            <div
              id="collapse1"
              className="collapse"
              aria-labelledby="heading1"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                { 
            hltcare.map((val) => 
                  <li>
                       {val.title} <span>{val.distance_in} km</span>
                  </li>
          
)}
                
                </ul>
              </div>
            </div>
          </div>


        
          <div className="card">
            <div className="card-header" id="heading2">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapse2"
                  aria-expanded="false"
                  aria-controls="collapse2"
                >
                 {lndmrk && lndmrk.length>0 && lndmrk[0].thingstodo_category_id}
                </button>
              </h5>
            </div>
            <div
              id="collapse2"
              className="collapse"
              aria-labelledby="heading2"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                { 
            lndmrk.map((val) => 
                  <li>
                  {val.title} <span>{val.distance_in} km</span>
                  </li>
           )
          }
                </ul>
              </div>
            </div>
          </div>
 

           
          <div className="card">
            <div className="card-header" id="heading3">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapse3"
                  aria-expanded="false"
                  aria-controls="collapse3"
                >
             {shpg && shpg.length>0 && shpg[0].thingstodo_category_id}
                </button>
              </h5>
            </div>
            <div
              id="collapse3"
              className="collapse"
              aria-labelledby="heading3"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                { 
            shpg.map((val) =>
                  <li>
                  {val.title} <span>{val.distance_in} km</span>
                  </li>
                      )
                    }
            </ul>
              </div>
            </div>
          </div>
        
              
        </div>
      </div>
      <div className="col-md-6">
        <div className="location-map-cs">
        {
      hcontact.map((val) => 
          <a href={val.embed_map} target="_blank">
            <img src={val.google_map_image} />
          </a>
      )
    }
        </div>
      </div>
    </div>
  </div>
</section>




<FooterPage />

    </>
  )

}

export default ContactUsPage;

