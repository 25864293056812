import HeaderPage2 from "../Header2";
import FooterPage from '../Footer';
import { useState, useEffect } from "react";
import axiosBaseURL from "../member/httpCommon";
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import $ from 'jquery';
import useCounter from "../useCounter";
import useCounterChild from "../useCounterChild";
import useCountroom from "../useCountroom.js";
import useCalender from "../useCalender.js";
import { Link } from "react-router-dom";


function RoomsPage() {

  const[adult]=useCounter();
  const[ children]=useCounterChild();
  const[ countroom]=useCountroom();
  const[ rangeStart,rangeEnd,]=useCalender();

  const{brand_slug} = useParams();
  const{city_alias} = useParams();
  const{hotel_slug} = useParams();

  const[htlreview, sethtlcontact] = useState({   
      brand_slug:brand_slug,
      city_slug:city_alias,
      hotel_slug:hotel_slug
})

const[stay, setsetstay] = useState([]);
const[meta1, setmeta1] = useState([]);

const showhotelroom = () =>{
  axiosBaseURL.post('/hotel-rooms', htlreview).then(res => {
    setsetstay(res.data.hotel_stay);
    setmeta1(res.data.hotel_meta);
    
}).catch(err => {
console.log(err)
})
}

// on load function call for loading 
useEffect(() => {
  showhotelroom()
},[])


return (

    <>
    
<Helmet>
        <title>{meta1 && meta1.length>0 &&  meta1[0].title}</title>
        <meta name="description" content={meta1 && meta1.length>0 &&  meta1[0].description} />
    </Helmet>

<HeaderPage2 />
<section className="filters-cs cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href={hotel_slug}>
               
                Destination
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/hotels"> Hotels </a>
            </li>
            <li className="breadcrumb-item">
            <Link to={`../${brand_slug}/${city_alias}/${hotel_slug}`}> {brand_slug}, {city_alias}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Rooms & Suites
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>


<section className="rooms-cs cs-sc-padding bg-light heading-bg-sc-cs">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="sc-heading">
          <h1> Rooms &amp; Suites</h1>
        </div>
    
        <div className="row mt-5">
          <div className="col-md-12">
            <div className="cs-room-box">
              <div className="below_slider-cs-sc-main">
                <div
                  id="sync2"
                  className="navigation-thumbs owl-carousel owl-loaded owl-drag"
                >
                  
                  <div className="owl-stage-outer">
                    <div
                      className="owl-stage"
                      style={{
                        transform: "translate3d(-277px, 0px, 0px)",
                        transition: "all 0.1s ease 0s",
                        width: 1388
                      }}
                    >
                            {
          stay.map((val) => 
                      <div className="owl-item">
                        <div className="item">
                          <img
                            src={val.image}
                            alt="Executive King Room"
                          />
                          <h3>{val.title}</h3>
                        </div>
                      </div>
          )
        }
                    
                    </div>
                  </div>
                 
                </div>
              </div>
              <div
                id="sync1"
                className="room-thumb-slider owl-carousel mt-5 owl-loaded owl-drag"
              >
                <div className="owl-stage-outer">
                  <div
                    className="owl-stage"
                    style={{
                      transform: "translate3d(-7770px, 0px, 0px)",
                      transition: "all 0.25s ease 0s",
                      width: 12210
                    }}
                  >
                                          {
          stay.map((val) => 
                    <div className="owl-item cloned" style={{ width: 1110 }}>
                      <div className="item">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="rooms-cs-sc-item-left">
                              <img
                                className="w-100"
                                src={val.image}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="rooms-cs-sc-item-right">
                              <h3>{val.title}</h3>
                              <p>
                               {val.short_description}
                              </p>
                              {/* <ul>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                  Tea Coffee Maker
                                </li>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                  LCD TV
                                </li>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                  Electronic Safe
                                </li>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                  Wi-Fi
                                </li>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                  Phone
                                </li>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                  Working Desk
                                </li>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                  DTH Services
                                </li>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  />
                                  Mini Bar
                                </li>
                              </ul> */}
                              <div className="bookBtn-rooms-item">
                                <div className="room-price-item" />

    <button className="hotel-new-booknow-btn" onClick={() => window.open(`https://be.synxis.com/?adult=${adult}&arrive=${rangeStart}&chain=7710&child=${children}&currency=INR&depart=${rangeEnd}&hotel=${$("#getBeCityHotel").val()}&level=hotel&locale=en-US&rooms=${countroom}&shell=SBE&start=availresults&template=SBE&theme=initialTheme2`)}>
    Book Now
    </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
)
}
                  </div>
                </div>
                <div className="owl-nav disabled">
                  <button
                    type="button"
                    role="presentation"
                    className="owl-prev"
                  >
                    
                    <span className="prev-txt">Prev</span>
                    <div className="owl-btn-line">
                      <i className="fa fa-angle-left" aria-hidden="true" />
                    </div>
                  </button>
                  <button
                    type="button"
                    role="presentation"
                    className="owl-next"
                  >
                    <div className="owl-btn-line">
                      <i className="fa fa fa-angle-right" aria-hidden="true" />
                    </div>
                    <span className="next-txt">Next</span>
                  </button>
                </div>
                <div className="owl-dots">
                  <button role="button" className="owl-dot">
                    <span />
                  </button>
                  <button role="button" className="owl-dot">
                    <span />
                  </button>
                  <button role="button" className="owl-dot">
                    <span />
                  </button>
                  <button role="button" className="owl-dot">
                    <span />
                  </button>
                  <button role="button" className="owl-dot active">
                    <span />
                  </button>
                </div>
              </div>
              {/* <div class="slider-counter-roomPage"></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<FooterPage />

    </>
  )

}

export default RoomsPage;

