import HeaderPage2 from "../Header2";
import FooterPage from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import {useState, useEffect} from "react";
import axiosBaseURL from "../member/httpCommon";
import DatePicker from "react-datepicker";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React from "react";
import {Helmet} from "react-helmet";
import AccordionItem from '../AccordionComponent/Accordion.js';


function RestaurantsPage() {

  const [activeIndex, setActiveIndex] = useState(null);
  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  const [date, setDate] = useState(new Date());

  const [rangeStart, setRangeStart] = React.useState(new Date)

  const selectStartDate = d => {
    setRangeStart(d)
    reserve.checkin_date=d;
  }

  const [activeIndex1, setActiveIndex1] = useState(0);

  const navigate =  useNavigate()
  const{brand_slug} = useParams(); 
  const{city_alias} = useParams();
  const{hotel_slug} = useParams();


  const[reserve,setreserve]=useState({
    name:"",
    email:"",
    phone:"",
    checkin_date:"",
    dine_title:"",
    noofguest:"",
    hotel_name:hotel_slug,
    hotel_id:"1"
})


//inputHandler
const inputHandler=(e)=>{
  setreserve({...reserve,[e.target.name]:e.target.value})
}

const reservetable=(e)=>{
  e.preventDefault();
  axiosBaseURL.post(`/dineenquirysend`,reserve)
  .then(()=>{
      alert("Submitted Successfully1")
      setreserve(null);
      console.log("this is", e.target[3].value)
      e.target.reset();
  }).catch(err=>{console.log(err)});
}

const[htlreview, sethtlcontact] = useState({   
      brand_slug:brand_slug,
      city_slug:city_alias,
      hotel_slug:hotel_slug
})

const[banner, setbanner] = useState([]);
const[htfaqs, sethtfaqs] = useState([]);
const[htrests, sethtrests] = useState([]);
const[meta1, setmeta1] = useState([]);
const[galry, setgalry] = useState([]);

const showhotelevent = () =>{
  axiosBaseURL.post('/hotel-restaurants', htlreview).then(res => {
    setbanner(res.data.hotel_banner_slider);
    sethtfaqs(res.data.hotel_faqs);
    sethtrests(res.data.hotel_restaurants);
    setmeta1(res.data.hotel_meta);
    setgalry(res.data.restaurants_menu_gallery);
  
}).catch(err => {
console.log(err)
})
}

// on load function call for loading 
useEffect(() => {
  showhotelevent()
},[])


  return (
    <>
    <Helmet>
        <title>{meta1 && meta1.length>0 &&  meta1[0].title}</title>
        <meta name="description" content={meta1 && meta1.length>0 &&  meta1[0].description} />
    </Helmet>

<HeaderPage2 />

<section className="filters-cs cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href={hotel_slug}>
               
                Destination
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/hotels"> Hotels </a>
            </li>
            <li className="breadcrumb-item">
            <Link to={`../${brand_slug}/${city_alias}/${hotel_slug}`}> {brand_slug}, {city_alias}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Restaurants
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
<section className="hero pt-3 pb-5 overviewheading">
  <div className="container">
    <div className="row">
    <div className="col-md-12">
      {
          banner.map((val) => 
        <div className="hero-right">
          <div className="hero-left">
            
            <img
              src={val.banner_image_1}
              alt={val.banner_image_title_1}
            />
          </div>
          <div className="hero-right-inner-main">
            <div className="hero-right-inner">
              <img
                src={val.banner_image_2}
                alt={val.banner_image_title_2}
              />
              <img
                src={val.banner_image_3}
                alt={val.banner_image_title_3}
              />
            </div>
            <div className="hero-right-inner hero-right-inner-last">
              <img
                src={val.banner_image_4}
                alt={val.banner_image_title_4}
              />
              <img
                src={val.banner_image_5}
                alt={val.banner_image_title_5}
              />
            </div>
          </div>

          <Link to={`/GalleryPage/${brand_slug}/${city_alias}/${hotel_slug}`}> <FontAwesomeIcon icon={faArrowsAlt} /> Show all
          photos</Link>  
        
        </div>
          )
        }
      </div>
    </div>
  </div>
</section>

<section className="restaurant-page-tabs cs-sc-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
       <Tabs selectedIndex={activeIndex1} onSelect={index => setActiveIndex1(index)}>
    <TabList>
    {
          htrests.map((val) => 
      <Tab>{val.title}</Tab>
        )
      }
 
    </TabList>
    {
          htrests.map((val) => 
    <TabPanel>
     
      
                <div className="container">
                  <div className="row pb-4 restaurant-details__description-cs-sc border-0">
                    <div className="col-md-8">
         
                      <div className="restaurant-details__heading d-lg-block new-reso-heading-rj">
                        <div className="restaurant-details__heading--label d-flex" />
                        <h3 className="restaurant-details__heading--title">
                          {val.title}
                        </h3>
                        <ul className="restaurant-details__heading--list mt-3 mb-0">
                          <li className="restaurant-details__heading-price">
                            <span className="">{val.cuisine}</span>
                          </li>
                          <li className="restaurant-details__heading-price">
                            <span className="">
                              <strong>Open | </strong> {val.opening_hour}
                            </span>
                          </li>
                          <li className="restaurant-details__heading-price">
                            <span className="">
                              <i
                                className="fa fa-credit-card"
                                aria-hidden="true"
                              />{" "}
                              {val.payment_type}
                            </span>
                          </li>
                          <li>
                            
                            <ul className="restaurant-tags">
                              
                            {
          val.food_type.map((item) => 
          <li>
                             <a> {item.food_name}</a>
                              </li>
          )
          
        }
                            </ul>
                          </li>
                        </ul>
                      </div>
     
                    </div>
                 
                  </div>
                  <div className="row pt-4 ">
                    <div className="col-md-12">
                      <div className="restaurant-details__description">
                        <div className="restaurant-details__description--heading new-reso-heading-rj">
                          <h4 className="restaurant-details__description--heading-title">
                           {val.title}
                          </h4>
                        </div>
                        <div className="restaurant-details__description--text ">
                          <p className="mb-0 text-justify">
                         {val.short_description}
                          </p>
                          <div className="below_slider-cs-sc-list mt-5">
                            <ul>
                              <a
                                href="#"
                                className="cs-cta reserve-cs-cta restagrid mr-3"
                                data-title="Citrus Cafe"
                                data-id="Lemon Tree Hotel, Agra"
                                data-toggle="modal"
                                data-target="#res-form-book"
                              >
                                Reserve your table
                              </a>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              
                <section id="gallery94" className="cs-sc-padding">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="sc-heading">
                        <h2>Menu</h2>
                      </div>
                    </div>
                  </div>
 <div className="row">

  <div className="col-md-4 mb-3 restaurant-page">
    
  <a
      href={galry && galry.length>0 && galry[0].dine_menu_image}
      data-lightbox="gallery35"
    >
      <img
        src={galry && galry.length>0 && galry[0].dine_menu_image}
        alt=""
        className="w-100"
      />
    </a>
  </div>
  {
          galry.map((val) => 
  <a
    href={val.dine_menu_image}
    data-lightbox="gallery35"
  />
)
}
 
</div>

                </div>
              </section>
    </TabPanel>
          )
        }
   
  </Tabs>
<div className="restaurant-page-tabs-inner">
   
  <div className="tab-content" id="pills-tabContent">            

 <section className="chef-sc-cs cs-sc-padding bg-light">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <img
          className="w-100 h-100 "
          src="https://www.lemontreehotels.com/uploads/images/Food-Safety.jpg"
        />
      </div>
      <div className="col-md-3 chef-cs_sc-1">
        <div className="p-4 text-center">
          <h3>Our Corporate Chef</h3>
          <p>
            Rajeev Janveja, with his exalted experience of nearly four decades
            in food and beverage, works with the hotel teams to curate
            innovative menus and unforgettable dining experiences, so that our
            guests embark on a culinary journey whenever they visit Lemon Tree
            Hotels.
          </p>
          {/* <a href="#">Meet Team</a> */}
        </div>
      </div>
      <div className="col-md-3">
        <img
          className="w-100 "
          src="https://www.lemontreehotels.com/uploads/images/chef-small-1.jpg"
        />
      </div>
    </div>
  </div>
</section>
<section className="recipeofthemonth cs-sc-padding">
  <div className="container">
    <div className="row mb-3">
      <div className="col-md-8">
        <div className="sc-heading new-reso-heading-rj">
          <h2>Chef’s Signature Recipe</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4">
        <div className="recipeofthemonth-box">
          <img
            className="w-100 "
            src="https://www.lemontreehotels.com/uploads/images/recipe-Payasam.jpg"
          />
        </div>
      </div>
      <div className="col-md-8">
        <div className="recipeofthemonth-box new-reso-heading-rj">
          <h3 className="recipeofthemonth-box-content-inner">
            Dum Achari Gosht
          </h3>
          <div className="recipeofthemonth-table">
            <div className="recipeofthemonth-heading mb-3">
              <p>
                "Dum" which literally means slow cooking and maturing the main
                ingredient with aromatic spices, is a very ancient and
                traditional method of cooking, predominantly large joints of
                meat are cooked enclosed with aromatic spices and masala over
                slow heat to retain the juices and also allowing the meat to
                thoroughly absorb the flavor and aroma released from the whole
                spices. Thick-bottomed vessels are called handi's and lagan,
                which are usually cooked on live charcoal or wood fire which
                also gives a smokey flavor in the final dish. Meats or biryanis
                are cooked in this particular method in which the vessel is
                covered with dough casing or covered with a lid which is sealed
                with dough thus not allowing the air to escape. Dum cooking is
                popular in Mughlai And Awadhi cuisine.
              </p>
            </div>
            <a
              href="https://www.lemontreehotels.com/uploads/images/Dum-Achari-Receipe.pdf"
              target="_blank"
            >
              View Recipe
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

          
          </div>
        </div>
      
      </div>
    </div>
  </div>
</section>


<div
  className="modal fade"
  id="res-form-book"
  tabIndex={-1}
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  style={{ display: "none" }}
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Restaurant Form</h5>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close">
         
        </button>
      </div>
      <div className="modal-body">
      <form onSubmit={reservetable}>
        <div className="res-form-book-cs">
          <div className="form-row row">
            <div className="col">
            <input required type="text" className="form-control" placeholder="Name" name="name" value={reserve?.name} onChange={inputHandler} /> 
          
            </div>
            <div className="col">
            <input required type="email" className="form-control" placeholder="Email" name="email" value={reserve?.email} onChange={inputHandler} />              
            </div>
          </div>
          <div className="form-row row">
            <div className="col">
            <input required type="tel" className="form-control" maxLength={10} placeholder="Phone" name="phone" value={reserve?.phone} onChange={inputHandler} />  
            </div>
            <div className="col">
            <DatePicker name="checkin_date" dateFormat="dd-MMM-yyyy" selected={rangeStart} 
                 onChange={selectStartDate} className="form-control datepicker calendar" />
              {/* <input
                id="datepicker-restaurant"
                type="date"
                className="form-control datepicker calendar"
                placeholder="Date"
              /> */}
            </div>
          </div>
          <div className="form-row row">
            <div className="col">
            <input required type="text" className="form-control" placeholder="No. of Guests" name="noofguest" value={reserve?.noofguest} onChange={inputHandler} />  
             
            </div>
          </div>

          <input type="hidden" className="form-control" name="hotel_name" value={reserve?.hotel_name} onChange={inputHandler} />  
          
          <input type="hidden" className="form-control" name="hotel_id" value={reserve?.hotel_id} onChange={inputHandler} />  
          <input type="hidden" className="form-control" name="dine_title" value={reserve?.dine_title} onChange={inputHandler} />  

          <button className="footer-submit-btn">
                   Submit
                  </button>
        </div>
        </form>
      </div>
      <div className="loadingPage" style={{ display: "none" }} />
    </div>
  </div>
</div>


<section id="overviewfaq" className="faq-cs-sc cs-sc-padding location-cs-sc">
  <div className="container">
    <div className="row mb-3">
      <div className="col-md-8">
        <div className="sc-heading">
          <h2> Frequently Asked Questions</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
      {htfaqs.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.title}
          answer={<div dangerouslySetInnerHTML={{__html: item.description}}></div>}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
   
      </div>
    </div>
  </div>
</section>


<FooterPage />

    </>
  )

}

export default RestaurantsPage;

