import '../memdashboard.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';
import { useState, useEffect } from "react";
import axios from "axios";
import * as React from "react";
import { useNavigate } from "react-router-dom";

function DashboardPage() {

  let token = localStorage.getItem('tokenpc');

  var data = JSON.parse(localStorage.getItem("uinfo"))
  
  console.log("profilee data", data[0].membershipId)

  const [uprofile, setuprofile] = useState({
    MembershipId: data[0].membershipId
  })
  const [uprofile1, setuprofile1] = useState([])
  let url = "http://lemontreedemo.netcarrots.in/LTMemberAPI/API/MemberProfileInfoAPI";

  const profiledetails = (e) => {
    const headers = { 'Content-Type': 'application/json', 'Authorization': 'bearer ' + token };
    axios.post(`${url}`, JSON.stringify(uprofile), { headers })
      .then((res) => {
        let result = res.data;

        if (result.errorCode == "0") {
          setuprofile1(result.response);
          sessionStorage.setItem("uprof", JSON.stringify(result.response))
        }
        else {
          alert(result.errorMessage)
        }


      }).catch(err => { console.log(err) });
  }

    // on load function call for loading 
    useEffect(() => {
      profiledetails();
    }, [])

return (
    <>
<HeaderPage />

<div className="maiwrapper">
  {/* member header */}
  {/* member header */}
  <section className="header_space py-5" />
  <section className="member_header py-1">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="profile_member">
            <div className="userName_member">
              <strong>Namaste </strong>
              <h3>     {uprofile1 && uprofile1.length>0 && uprofile1[0].firstName}</h3>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="point_member">
            <div className="point_member_left point_member_main">
              <strong>  {uprofile1 && uprofile1.length>0 && uprofile1[0].pointsBalance}</strong>
              <p>COINS</p>
            </div>
            <div className="point_member_left point_member_main">
              <strong> {uprofile1 && uprofile1.length>0 && uprofile1[0].totalStays}</strong>
              <p>Total Stays</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* member header */}
  <section className="menu_member bg-light">
    <div className="menu_member_inner">
      <nav className="navbar navbar-expand-lg navbar-light pb-0">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a
                className="nav-link"
                href="/dashboard"
              >
                Overview
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/trip"
              >
                My Trips
              </a>
            </li>
            {/*  <li class="nav-item">
			        <a class="nav-link" href="member/promotion">Promotion</a>
			      </li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                href="/redeem"
              >
                Redeem
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/profile"
              >
                Profile
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
  {/* member middle  */}
  <section className="MemberDashboardMiddle-main py-5 bg-light">

    <div className="container">
      <div className="row">
        <div className="col-md-5">
          <div className="member_status bg_color">
            <h4>  {uprofile1 && uprofile1.length>0 && uprofile1[0].tierName}</h4>
            <p>Member ID:   {uprofile1 && uprofile1.length>0 && uprofile1[0].membershipId}</p>
          </div>
        </div>
        <div className="col-md-7">
          <div id="ptier" className="member_status plat">
            <h4>Congratulations!</h4>
            <div className="member_status_right">
              <div className="member_status_right_left member_status_right_right">
                <span>You have achieved the platinum status</span>
                <div className="bar_statusNumber">
                  <div className="progress">
                    <div className="progress-bar" style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="my_benefits py-5">
    <div className="container">
      <div className="row">
        <div className="col-md-5">
          <div className="my_benefits_left_inner">
            <h4>My Benefits</h4>
          </div>
        </div>
        <div className="col-md-7">
          <div className="my_benefits_right_inner">
            <div className="demo">
              <div className="accordion" id="My_Benefits_tab">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        type="button"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                      >
                        <figure>
                          <img
                            className=""
                            src=""
                            data-src="https://www.lemontreehotels.com/assets/front/images/Limitless.png"
                          />
                          Member Exclusive rates{" "}
                        </figure>
                        <i className="fa" />
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#My_Benefits_tab"
                  >
                    <div className="card-body"></div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        type="button"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                      >
                        <figure>
                          <img
                            className=""
                            src=""
                            data-src="https://www.lemontreehotels.com/assets/front/images/Limitless.png"
                          />
                          Redeem Free Room Nights
                        </figure>
                        <i className="fa" />
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#My_Benefits_tab"
                  >
                    <div className="card-body">
                      <p>
                        Redeem free room nights against the accumulated coins in
                        all our participating hotels.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        type="button"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                      >
                        <figure>
                          <img
                            className=""
                            src=""
                            data-src="https://www.lemontreehotels.com/assets/front/images/Limitless.png"
                          />
                          Complimentary Buffet Breakfast
                        </figure>
                        <i className="fa" />
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#My_Benefits_tab"
                  >
                    <div className="card-body">
                      <p>
                        Enjoy the wide-spread buffet breakfast at no additional
                        cost, a perk of being a member.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h2 className="mb-0">
                      <button
                        type="button"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapsefour"
                      >
                        <figure>
                          <img
                            className=""
                            src=""
                            data-src="https://www.lemontreehotels.com/assets/front/images/Limitless.png"
                          />
                          Free Wi-Fi
                        </figure>
                        <i className="fa" />
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapsefour"
                    className="collapse"
                    aria-labelledby="collapsefour"
                    data-parent="#My_Benefits_tab"
                  >
                    <div className="card-body">
                      <p>
                        Connect with the world while staying with us. Our
                        high-speed Wi-Fi will let you stay connected and
                        updated.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="">
                    <h2 className="mb-0">
                      <a
                        href="https://www.lemontreehotels.com/rewards"
                        target="_blank"
                        className="btn btn-link "
                      >
                        <figure>
                          {/* <img src="https://www.lemontreehotels.com/assets/front/images/Limitless.png"> */}
                          Explore all benefits
                        </figure>
                        <i
                          className="fa fa-long-arrow-right"
                          aria-hidden="true"
                        />
                      </a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* member middle  */}
</div>


 <FooterPage />


    </>
  )

}

export default DashboardPage;

