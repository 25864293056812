import '../App.css';
import '../index.css';

import HeaderPage from '../Header';
import FooterPage from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

function UpcomingHotelPage() {
   return (
    <>
      <HeaderPage />
 <div className="banner-beta-one banner h350">
  <img
    className=" w-100"
    src="https://www.lemontreehotels.com/assets/front/images/rewards_banner_1.jpg"
  />
  
</div>

{/* banner end */}



<section className="text-center py-4 coming-soon-hotels-cs welcome-sc">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="readMore-content">
          <h2>Upcoming hotels</h2>
          The renowned hospitality chain is all set to unveil a collection of
          new hotels, promising unforgettable experiences for travelers. Embrace
          the zest of Lemon Tree's signature hospitality as they venture into
          captivating destinations worldwide. From serene hill stations to
          bustling city centers, each Lemon Tree hotel will exude a refreshing
          charm, combining modern comforts with warm, personalized service.
          Whether you're on a business trip or leisure escape, get ready to be
          pampered with their vibrant ambiance and impeccable amenities. With
          Lemon Tree's dedication to guest satisfaction, a memorable stay is on
          the horizon.
        </div>
      </div>
    </div>
  </div>
</section>

<section className="filters-cs cs-sc-padding ">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="https://www.lemontreehotels.com">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Upcoming Hotels
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>


<div className="about-tabs Upcoming-hotels-cs cs-sc-padding upcoming-slider-raj">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="about-tabs-inner">
          <nav>
            <div
              className="nav nav-tabs about-tabs-nav"
              id="nav-tab"
              role="tablist"
            >
              <a
                className="nav-item nav-link active"
                id="nav-new-openings-tab"
                data-toggle="tab"
                href="#nav-new-openings"
                role="tab"
                aria-controls="nav-new-openings"
                aria-selected="true"
              >
                New Openings
              </a>
              <a
                className="nav-item nav-link"
                id="nav-upcominghotels-tab"
                data-toggle="tab"
                href="#nav-upcominghotels"
                role="tab"
                aria-controls="nav-upcominghotels"
                aria-selected="false"
              >
                Upcoming Hotels
              </a>
            </div>
          </nav>
          <div className="tab-content about-tabs-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-new-openings"
              role="tabpanel"
              aria-labelledby="nav-new-openings-tab"
            >
              <h3 className="text-center">
                More destinations, more experiences, more luxury
              </h3>
              <div className="slider-newopening below_slider-cs-sc-main upcoming-slidder-rj">
                <div className="owl-carousel owl-theme slider-upcoming owl-loaded owl-drag">
                  
                  <div className="owl-stage-outer">
                    <div
                      className="owl-stage"
                      style={{
                        transform: "translate3d(-5973px, 0px, 0px)",
                        transition: "all 0.25s ease 0s",
                        width: 13440
                      }}
                    >
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                       <div className="item">
  <div className="otherHotels-item new-item-btn-rj">
    <img src="https://www.lemontreehotels.com/uploads/hotels/14_Dec_2023_04_02_22Facade-Evening.JPG" />
    <div className="otherHotels-cs-sc-item-content">
      <a href="https://www.lemontreehotels.com/lemon-tree-hotel/hisar/hotel-hisar">
        <h4>Lemon Tree Hotel, Hisar</h4>
      </a>
      <div className="otherHotels-item-icon">
        <ul>
          <li>
            <i className="fa fa-train" aria-hidden="true" /> - 1 Km |{" "}
          </li>
          <li>
            <i className="fa fa-plane" aria-hidden="true" /> - 189 Km{" "}
          </li>
        </ul>
      </div>
      <a
        href="javascript:void(0);"
        onclick="return bookHotel('45524','Lemon Tree Hotel, Hisar')"
        className="cs-cta"
      >
        Book Now
      </a>
    </div>
  </div>
</div>

                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/12_Oct_2023_12_00_441.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/haridwar/haridwar-hotels">
                                <h4>Lemon Tree Hotel, Haridwar</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 7 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 45 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('44107','Lemon Tree Hotel, Haridwar')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/06_Nov_2023_03_52_02jams.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/jamshedpur/jamshedpur-hotels">
                                <h4>Lemon Tree Hotel, Jamshedpur</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 6 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 126 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45523','Lemon Tree Hotel, Jamshedpur')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/19_Apr_2024_06_23_20Facade - Day 2 (2).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/nepal/chitwan-resorts">
                                <h4>
                                  Tigerland Safari - A Lemon Tree Resort,
                                  Chitwan, Nepal
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 22 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46400','Tigerland Safari - A Lemon Tree Resort, Chitwan, Nepal')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/11_Apr_2024_04_44_20Facade  (1) (1).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="/lemon-tree-hotel/rishikesh/rishikesh-hotel">
                                <h4>Lemon Tree Hotel, Rishikesh</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 7 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 22 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45724','Lemon Tree Hotel, Rishikesh')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/10_Apr_2024_01_42_13BANQUET HALL GARDEN VIEW (1).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-premier/nepal/kathmandu-hotels">
                                <h4>
                                  Lemon Tree Premier, Buddhanilkantha, Kathmandu
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 12 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45625','Lemon Tree Premier, Buddhanilkantha, Kathmandu')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/10_Apr_2024_10_46_41Kasauli-Facade-Evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/kasauli/hotel-kasauli">
                                <h4>Lemon Tree Hotel, Kasauli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 20 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 40 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46049','Lemon Tree Hotel, Kasauli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/13_Sep_2023_04_03_35Kumbhalgarhf.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/kumbhalgarh/hotel-kumbhalgarh">
                                <h4>Lemon Tree Resort, Kumbhalgarh</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 80 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 100 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45703','Lemon Tree Resort, Kumbhalgarh')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/key-select-udaipur-hotel.webp" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/keys-select-hotel/udaipur/hotels-udaipur">
                                <h4>
                                  Keys Select By Lemon Tree Hotels, Udaipur
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 15 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46051','Keys Select Hotels, Udaipur')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/14_Dec_2023_04_02_22Facade-Evening.JPG" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/hisar/hotel-hisar">
                                <h4>Lemon Tree Hotel, Hisar</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 1 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 189 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45524','Lemon Tree Hotel, Hisar')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/19_Dec_2023_04_07_05Exterior Facade - Pic 1.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-premier/mumbai/hotel-malad-mumbai">
                                <h4>Lemon Tree Premier, Malad, Mumbai</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 2.8 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 12.5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43662','Lemon Tree Premier, Malad, Mumbai')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/12_Dec_2023_07_34_34facde.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/dapoli/hotel-dapoli">
                                <h4>Lemon Tree Hotel, Dapoli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 45 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 139 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45247','Lemon Tree Hotel, Dapoli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/01_Dec_2023_12_55_40Facade - Evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/hubli/hotel-hubli">
                                <h4>Lemon Tree Hotel, Hubli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 5 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43054','Lemon Tree Hotel, Hubli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/28_Jun_2023_12_16_46Untitled-3.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bhopal/hotel-bhopal">
                                <h4>Lemon Tree Hotel, Bhopal</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 6 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 16.5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('42344','Lemon Tree Hotel, Bhopal')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/16_Sep_2023_04_22_41facade-2.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/lemon-tree-suites-hotels">
                                <h4>
                                  Lemon Tree Suites, Whitefield, Bengaluru
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 14 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 39 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('44013','Lemon Tree Suites, Whitefield, Bengaluru')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/22_Apr_2024_04_56_13Skycity.jpeg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/aurika-hotels-resorts/mumbai/aurika-skycity-mumbai-airport">
                                <h4>Aurika, Mumbai International Airport</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 1.8 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43851','Aurika, Mumbai International Airport')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item active"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/21_Sep_2023_05_12_34Facade evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/mcleodganj/hotel-mcleodganj">
                                <h4>Lemon Tree Hotel, McLeodganj</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 90 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 25 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43135','Lemon Tree Hotel, McLeodganj')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item active"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/20_Sep_2023_03_05_00facade-day-front.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/sonmarg/hotel-sonmarg">
                                <h4>Lemon Tree Hotel, Sonmarg</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 104 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 100 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43715','Lemon Tree Hotel, Sonmarg')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item active"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/21_Aug_2023_03_15_07Facade-Evening-Shot-1.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/rajkot/hotel-rajkot">
                                <h4>Lemon Tree Hotel, Rajkot</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 2.3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 2.2 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43818','Lemon Tree Hotel, Rajkot')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/12_Oct_2023_12_00_441.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/haridwar/haridwar-hotels">
                                <h4>Lemon Tree Hotel, Haridwar</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 7 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 45 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('44107','Lemon Tree Hotel, Haridwar')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/06_Nov_2023_03_52_02jams.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/jamshedpur/jamshedpur-hotels">
                                <h4>Lemon Tree Hotel, Jamshedpur</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 6 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 126 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45523','Lemon Tree Hotel, Jamshedpur')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/19_Apr_2024_06_23_20Facade - Day 2 (2).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/nepal/chitwan-resorts">
                                <h4>
                                  Tigerland Safari - A Lemon Tree Resort,
                                  Chitwan, Nepal
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 22 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46400','Tigerland Safari - A Lemon Tree Resort, Chitwan, Nepal')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/11_Apr_2024_04_44_20Facade  (1) (1).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="/lemon-tree-hotel/rishikesh/rishikesh-hotel">
                                <h4>Lemon Tree Hotel, Rishikesh</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 7 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 22 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45724','Lemon Tree Hotel, Rishikesh')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/10_Apr_2024_01_42_13BANQUET HALL GARDEN VIEW (1).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-premier/nepal/kathmandu-hotels">
                                <h4>
                                  Lemon Tree Premier, Buddhanilkantha, Kathmandu
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 12 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45625','Lemon Tree Premier, Buddhanilkantha, Kathmandu')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/10_Apr_2024_10_46_41Kasauli-Facade-Evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/kasauli/hotel-kasauli">
                                <h4>Lemon Tree Hotel, Kasauli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 20 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 40 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46049','Lemon Tree Hotel, Kasauli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/13_Sep_2023_04_03_35Kumbhalgarhf.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/kumbhalgarh/hotel-kumbhalgarh">
                                <h4>Lemon Tree Resort, Kumbhalgarh</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 80 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 100 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45703','Lemon Tree Resort, Kumbhalgarh')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/key-select-udaipur-hotel.webp" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/keys-select-hotel/udaipur/hotels-udaipur">
                                <h4>
                                  Keys Select By Lemon Tree Hotels, Udaipur
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 15 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46051','Keys Select Hotels, Udaipur')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/14_Dec_2023_04_02_22Facade-Evening.JPG" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/hisar/hotel-hisar">
                                <h4>Lemon Tree Hotel, Hisar</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 1 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 189 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45524','Lemon Tree Hotel, Hisar')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/19_Dec_2023_04_07_05Exterior Facade - Pic 1.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-premier/mumbai/hotel-malad-mumbai">
                                <h4>Lemon Tree Premier, Malad, Mumbai</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 2.8 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 12.5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43662','Lemon Tree Premier, Malad, Mumbai')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/12_Dec_2023_07_34_34facde.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/dapoli/hotel-dapoli">
                                <h4>Lemon Tree Hotel, Dapoli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 45 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 139 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45247','Lemon Tree Hotel, Dapoli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/01_Dec_2023_12_55_40Facade - Evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/hubli/hotel-hubli">
                                <h4>Lemon Tree Hotel, Hubli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 5 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43054','Lemon Tree Hotel, Hubli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/28_Jun_2023_12_16_46Untitled-3.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bhopal/hotel-bhopal">
                                <h4>Lemon Tree Hotel, Bhopal</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 6 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 16.5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('42344','Lemon Tree Hotel, Bhopal')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/16_Sep_2023_04_22_41facade-2.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/lemon-tree-suites-hotels">
                                <h4>
                                  Lemon Tree Suites, Whitefield, Bengaluru
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 14 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 39 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('44013','Lemon Tree Suites, Whitefield, Bengaluru')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/22_Apr_2024_04_56_13Skycity.jpeg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/aurika-hotels-resorts/mumbai/aurika-skycity-mumbai-airport">
                                <h4>Aurika, Mumbai International Airport</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 1.8 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43851','Aurika, Mumbai International Airport')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/21_Sep_2023_05_12_34Facade evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/mcleodganj/hotel-mcleodganj">
                                <h4>Lemon Tree Hotel, McLeodganj</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 90 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 25 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43135','Lemon Tree Hotel, McLeodganj')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/20_Sep_2023_03_05_00facade-day-front.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/sonmarg/hotel-sonmarg">
                                <h4>Lemon Tree Hotel, Sonmarg</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 104 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 100 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43715','Lemon Tree Hotel, Sonmarg')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-nav disabled">
                    <button
                      type="button"
                      role="presentation"
                      className="owl-prev"
                    >
                      {" "}
                      <span className="prev-txt">Prev</span>{" "}
                      <div className="owl-btn-line">
                        <i className="fa fa-angle-left" aria-hidden="true" />
                      </div>{" "}
                    </button>
                    <button
                      type="button"
                      role="presentation"
                      className="owl-next"
                    >
                      <div className="owl-btn-line">
                        <i
                          className="fa fa fa-angle-right"
                          aria-hidden="true"
                        />
                      </div>{" "}
                      <span className="next-txt">Next</span>
                    </button>
                  </div>
                  <div className="owl-dots">
                    <button role="button" className="owl-dot">
                      <span />
                    </button>
                    <button role="button" className="owl-dot">
                      <span />
                    </button>
                    <button role="button" className="owl-dot active">
                      <span />
                    </button>
                    <button role="button" className="owl-dot">
                      <span />
                    </button>
                    <button role="button" className="owl-dot">
                      <span />
                    </button>
                    <button role="button" className="owl-dot">
                      <span />
                    </button>
                  </div>
                </div>
                <div className="slider-upcoming-count">8/18</div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-upcominghotels"
              role="tabpanel"
              aria-labelledby="nav-upcominghotels-tab"
            >
              <h3 className="text-center">
                More destinations, more experiences, more luxury
              </h3>
              <div className="slider-newopening below_slider-cs-sc-main upcoming-slidder-rj">
                <div className="owl-carousel owl-theme slider-upcoming owl-loaded owl-drag">
               
                  <div className="owl-stage-outer">
                    <div
                      className="owl-stage"
                      style={{
                        transform: "translate3d(-5973px, 0px, 0px)",
                        transition: "all 0.25s ease 0s",
                        width: 13440
                      }}
                    >
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/21_Aug_2023_03_15_07Facade-Evening-Shot-1.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/rajkot/hotel-rajkot">
                                <h4>Lemon Tree Hotel, Rajkot</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 2.3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 2.2 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43818','Lemon Tree Hotel, Rajkot')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/12_Oct_2023_12_00_441.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/haridwar/haridwar-hotels">
                                <h4>Lemon Tree Hotel, Haridwar</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 7 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 45 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('44107','Lemon Tree Hotel, Haridwar')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/06_Nov_2023_03_52_02jams.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/jamshedpur/jamshedpur-hotels">
                                <h4>Lemon Tree Hotel, Jamshedpur</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 6 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 126 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45523','Lemon Tree Hotel, Jamshedpur')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/19_Apr_2024_06_23_20Facade - Day 2 (2).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/nepal/chitwan-resorts">
                                <h4>
                                  Tigerland Safari - A Lemon Tree Resort,
                                  Chitwan, Nepal
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 22 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46400','Tigerland Safari - A Lemon Tree Resort, Chitwan, Nepal')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/11_Apr_2024_04_44_20Facade  (1) (1).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="/lemon-tree-hotel/rishikesh/rishikesh-hotel">
                                <h4>Lemon Tree Hotel, Rishikesh</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 7 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 22 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45724','Lemon Tree Hotel, Rishikesh')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/10_Apr_2024_01_42_13BANQUET HALL GARDEN VIEW (1).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-premier/nepal/kathmandu-hotels">
                                <h4>
                                  Lemon Tree Premier, Buddhanilkantha, Kathmandu
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 12 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45625','Lemon Tree Premier, Buddhanilkantha, Kathmandu')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/10_Apr_2024_10_46_41Kasauli-Facade-Evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/kasauli/hotel-kasauli">
                                <h4>Lemon Tree Hotel, Kasauli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 20 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 40 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46049','Lemon Tree Hotel, Kasauli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/13_Sep_2023_04_03_35Kumbhalgarhf.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/kumbhalgarh/hotel-kumbhalgarh">
                                <h4>Lemon Tree Resort, Kumbhalgarh</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 80 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 100 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45703','Lemon Tree Resort, Kumbhalgarh')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/key-select-udaipur-hotel.webp" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/keys-select-hotel/udaipur/hotels-udaipur">
                                <h4>
                                  Keys Select By Lemon Tree Hotels, Udaipur
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 15 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46051','Keys Select Hotels, Udaipur')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/14_Dec_2023_04_02_22Facade-Evening.JPG" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/hisar/hotel-hisar">
                                <h4>Lemon Tree Hotel, Hisar</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 1 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 189 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45524','Lemon Tree Hotel, Hisar')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/19_Dec_2023_04_07_05Exterior Facade - Pic 1.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-premier/mumbai/hotel-malad-mumbai">
                                <h4>Lemon Tree Premier, Malad, Mumbai</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 2.8 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 12.5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43662','Lemon Tree Premier, Malad, Mumbai')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/12_Dec_2023_07_34_34facde.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/dapoli/hotel-dapoli">
                                <h4>Lemon Tree Hotel, Dapoli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 45 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 139 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45247','Lemon Tree Hotel, Dapoli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/01_Dec_2023_12_55_40Facade - Evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/hubli/hotel-hubli">
                                <h4>Lemon Tree Hotel, Hubli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 5 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43054','Lemon Tree Hotel, Hubli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/28_Jun_2023_12_16_46Untitled-3.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bhopal/hotel-bhopal">
                                <h4>Lemon Tree Hotel, Bhopal</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 6 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 16.5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('42344','Lemon Tree Hotel, Bhopal')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/16_Sep_2023_04_22_41facade-2.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/lemon-tree-suites-hotels">
                                <h4>
                                  Lemon Tree Suites, Whitefield, Bengaluru
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 14 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 39 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('44013','Lemon Tree Suites, Whitefield, Bengaluru')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/22_Apr_2024_04_56_13Skycity.jpeg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/aurika-hotels-resorts/mumbai/aurika-skycity-mumbai-airport">
                                <h4>Aurika, Mumbai International Airport</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 1.8 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43851','Aurika, Mumbai International Airport')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item active"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/21_Sep_2023_05_12_34Facade evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/mcleodganj/hotel-mcleodganj">
                                <h4>Lemon Tree Hotel, McLeodganj</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 90 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 25 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43135','Lemon Tree Hotel, McLeodganj')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item active"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/20_Sep_2023_03_05_00facade-day-front.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/sonmarg/hotel-sonmarg">
                                <h4>Lemon Tree Hotel, Sonmarg</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 104 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 100 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43715','Lemon Tree Hotel, Sonmarg')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item active"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/21_Aug_2023_03_15_07Facade-Evening-Shot-1.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/rajkot/hotel-rajkot">
                                <h4>Lemon Tree Hotel, Rajkot</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 2.3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 2.2 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43818','Lemon Tree Hotel, Rajkot')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/12_Oct_2023_12_00_441.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/haridwar/haridwar-hotels">
                                <h4>Lemon Tree Hotel, Haridwar</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 7 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 45 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('44107','Lemon Tree Hotel, Haridwar')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/06_Nov_2023_03_52_02jams.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/jamshedpur/jamshedpur-hotels">
                                <h4>Lemon Tree Hotel, Jamshedpur</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 6 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 126 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45523','Lemon Tree Hotel, Jamshedpur')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/19_Apr_2024_06_23_20Facade - Day 2 (2).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/nepal/chitwan-resorts">
                                <h4>
                                  Tigerland Safari - A Lemon Tree Resort,
                                  Chitwan, Nepal
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 22 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46400','Tigerland Safari - A Lemon Tree Resort, Chitwan, Nepal')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/11_Apr_2024_04_44_20Facade  (1) (1).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="/lemon-tree-hotel/rishikesh/rishikesh-hotel">
                                <h4>Lemon Tree Hotel, Rishikesh</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 7 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 22 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45724','Lemon Tree Hotel, Rishikesh')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/10_Apr_2024_01_42_13BANQUET HALL GARDEN VIEW (1).jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-premier/nepal/kathmandu-hotels">
                                <h4>
                                  Lemon Tree Premier, Buddhanilkantha, Kathmandu
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 12 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45625','Lemon Tree Premier, Buddhanilkantha, Kathmandu')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/10_Apr_2024_10_46_41Kasauli-Facade-Evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/kasauli/hotel-kasauli">
                                <h4>Lemon Tree Hotel, Kasauli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 20 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 40 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46049','Lemon Tree Hotel, Kasauli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/13_Sep_2023_04_03_35Kumbhalgarhf.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/kumbhalgarh/hotel-kumbhalgarh">
                                <h4>Lemon Tree Resort, Kumbhalgarh</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 80 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 100 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45703','Lemon Tree Resort, Kumbhalgarh')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/key-select-udaipur-hotel.webp" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/keys-select-hotel/udaipur/hotels-udaipur">
                                <h4>
                                  Keys Select By Lemon Tree Hotels, Udaipur
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 15 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('46051','Keys Select Hotels, Udaipur')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/14_Dec_2023_04_02_22Facade-Evening.JPG" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/hisar/hotel-hisar">
                                <h4>Lemon Tree Hotel, Hisar</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 1 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 189 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45524','Lemon Tree Hotel, Hisar')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/19_Dec_2023_04_07_05Exterior Facade - Pic 1.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-premier/mumbai/hotel-malad-mumbai">
                                <h4>Lemon Tree Premier, Malad, Mumbai</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 2.8 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 12.5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43662','Lemon Tree Premier, Malad, Mumbai')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/assets/front/images/12_Dec_2023_07_34_34facde.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/dapoli/hotel-dapoli">
                                <h4>Lemon Tree Hotel, Dapoli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 45 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 139 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('45247','Lemon Tree Hotel, Dapoli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/01_Dec_2023_12_55_40Facade - Evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/hubli/hotel-hubli">
                                <h4>Lemon Tree Hotel, Hubli</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 5 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43054','Lemon Tree Hotel, Hubli')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/28_Jun_2023_12_16_46Untitled-3.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bhopal/hotel-bhopal">
                                <h4>Lemon Tree Hotel, Bhopal</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 6 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 16.5 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('42344','Lemon Tree Hotel, Bhopal')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/16_Sep_2023_04_22_41facade-2.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/lemon-tree-suites-hotels">
                                <h4>
                                  Lemon Tree Suites, Whitefield, Bengaluru
                                </h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 14 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 39 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('44013','Lemon Tree Suites, Whitefield, Bengaluru')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/22_Apr_2024_04_56_13Skycity.jpeg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/aurika-hotels-resorts/mumbai/aurika-skycity-mumbai-airport">
                                <h4>Aurika, Mumbai International Airport</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 3 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 1.8 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43851','Aurika, Mumbai International Airport')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/21_Sep_2023_05_12_34Facade evening.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/mcleodganj/hotel-mcleodganj">
                                <h4>Lemon Tree Hotel, McLeodganj</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 90 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 25 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43135','Lemon Tree Hotel, McLeodganj')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "363.333px", marginRight: 10 }}
                      >
                        <div className="item">
                          <div className="otherHotels-item new-item-btn-rj">
                            <img src="https://www.lemontreehotels.com/uploads/hotels/20_Sep_2023_03_05_00facade-day-front.jpg" />
                            <div className="otherHotels-cs-sc-item-content">
                              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/sonmarg/hotel-sonmarg">
                                <h4>Lemon Tree Hotel, Sonmarg</h4>
                              </a>
                              <div className="otherHotels-item-icon">
                                <ul>
                                  <li>
                                    <i
                                      className="fa fa-train"
                                      aria-hidden="true"
                                    />{" "}
                                    - 104 Km |{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-plane"
                                      aria-hidden="true"
                                    />{" "}
                                    - 100 Km{" "}
                                  </li>
                                </ul>
                              </div>
                              <a
                                href="javascript:void(0);"
                                onclick="return bookHotel('43715','Lemon Tree Hotel, Sonmarg')"
                                className="cs-cta"
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-nav disabled">
                    <button
                      type="button"
                      role="presentation"
                      className="owl-prev"
                    >
                      {" "}
                      <span className="prev-txt">Prev</span>{" "}
                      <div className="owl-btn-line">
                        <i className="fa fa-angle-left" aria-hidden="true" />
                      </div>{" "}
                    </button>
                    <button
                      type="button"
                      role="presentation"
                      className="owl-next"
                    >
                      <div className="owl-btn-line">
                        <i
                          className="fa fa fa-angle-right"
                          aria-hidden="true"
                        />
                      </div>{" "}
                      <span className="next-txt">Next</span>
                    </button>
                  </div>
                  <div className="owl-dots">
                    <button role="button" className="owl-dot">
                      <span />
                    </button>
                    <button role="button" className="owl-dot">
                      <span />
                    </button>
                    <button role="button" className="owl-dot active">
                      <span />
                    </button>
                    <button role="button" className="owl-dot">
                      <span />
                    </button>
                    <button role="button" className="owl-dot">
                      <span />
                    </button>
                    <button role="button" className="owl-dot">
                      <span />
                    </button>
                  </div>
                </div>
                <div className="slider-upcoming-count">8/18</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<section className="new-blog-cs cs-sc-padding">
  <div className="container">
    <div className="row mb-5">
      <div className="col-md-12">
        <div className="sc-heading">
          <h2>In The News</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        {/* list-blog */}
        <div className="blog-list-cs">
          <span className="blog-date">January 23, 2023</span>
          <h4>
            <a
              href="https://www.hotelierindia.com/operations/lemon-tree-hotels-signs-a-new-hotel-in-kumbhalgarh"
              target="_blank"
            >
              Lemon Tree Hotels signs a new hotel in Kumbhalgarh
            </a>
          </h4>
          <p>
            The new Lemon Tree Resort, Kumbhalgarh, Rajasthan, is expected to
            open by December 2023
          </p>
        </div>
        {/* list-blog */}
        {/* list-blog */}
        <div className="blog-list-cs">
          <span className="blog-date">23 Aug, 2022</span>
          <h4>
            <a
              href="https://www.indiainfoline.com/article/news-top-story/lemon-tree-hotels-signs-new-hotel-in-hubli-karnataka-122082300167_1.html"
              target="_blank"
            >
              Lemon Tree Hotels Signs New Hotel In Hubli, Karnataka
            </a>
          </h4>
          <p>
            Lemon Tree Hotels has unveiled its newest addition: the Lemon Tree
            Hotel in Hubli, Karnataka. Carnation Hotels Private Limited, a
            subsidiary, and management arm of Lemon Tree Hotels Limited will
            operate this hotel, which is planned to open in May 2023.
          </p>
        </div>
        {/* list-blog */}
        {/* list-blog */}
        <div className="blog-list-cs">
          <span className="blog-date">February 09, 2023</span>
          <h4>
            <a
              href="https://www.hospemag.me/cms/2023/2/9/lemon-tree-hotels-signs-a-new-hotel-in-jabalpur"
              target="_blank"
            >
              Lemon Tree Hotels signs a new hotel in Jabalpur
            </a>
          </h4>
          <p>
            The new Lemon Tree Resort, Kumbhalgarh, Rajasthan, is expected to
            open by December 2023
          </p>
        </div>
        {/* list-blog */}
        {/* list-blog */}
        <div className="blog-list-cs">
          <span className="blog-date">06 Mar 2023</span>
          <h4>
            <a
              href="https://www.projectstoday.com/News/Lemon-Tree-Hotels-sign-up-two-new-hotels-in-Dapoli-Maharashtra"
              target="_blank"
            >
              Lemon Tree Hotels sign-up two new hotels in Dapoli, Maharashtra
            </a>
          </h4>
          <p>
            Lemon Tree has signed a license agreement for two properties in
            Dapoli, Maharashtra under the company’s brands 'Lemon Tree Hotel'
            and 'Keys Lite by Lemon Tree Hotel' respectively. The hotels are
            expected to be operational by October, 2023.
          </p>
        </div>
        {/* list-blog */}
        {/* list-blog */}
        <div className="blog-list-cs">
          <span className="blog-date">2022</span>
          <h4>
            <a
              href="https://www.capitalmarket.com/news/hot-pursuit/lemon-tree-hotels-signs-new-hotel-in-tamil-nadu/1365033"
              target="_blank"
            >
              Lemon Tree Hotels signs new hotel in Tamil Nadu
            </a>
          </h4>
          <p>
            This hotel is expected to be operational by December 2023, and shall
            be managed by Carnation Hotels, a wholly-owned subsidiary and the
            management arm of Lemon Tree Hotels.
          </p>
        </div>
        {/* list-blog */}
        {/* list-blog */}
        <div className="blog-list-cs">
          <span className="blog-date">2022</span>
          <h4>
            <a
              href="https://www.livemint.com/companies/news/lemon-tree-hotels-signs-new-hotel-in-rajasthan-11679033991212.html"
              target="_blank"
            >
              Lemon Tree Hotels signs new hotel in Rajasthan
            </a>
          </h4>
          <p>
            Lemon Tree Hotels Limited has signed its latest hotel, a 60-key
            hotel in Rajasthan in Sri Ganganagar. The property is expected to be
            operational by July 2026 and will be managed by its subsidiary,
            Carnation Hotels Private Limited.
          </p>
        </div>
        {/* list-blog */}
      </div>
    </div>
  </div>
</section>







      <FooterPage />


    </>
  )

}

export default UpcomingHotelPage;

