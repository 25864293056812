import * as React from "react";
import SignUpPage from "./signup";


export default function TokenPage(){

  const [tokenname, settokenname] = React.useState('');
  React.useEffect(()=>{
    fetch('http://lemontreedemo.netcarrots.in/LTMemberAPI/API/GenrateToken', {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify( {'UserName':'LemonTree',
      'Password':'Lemon@123'})
    }).then(
      res => res.json())
      
      .then( res => {
        settokenname(res.token);
             console.log("token", tokenname)
             {sessionStorage.setItem('token1', tokenname)}
        // const accessToken = tokenname;
        // console.log("Token Name",accessToken)
        // Save the access token in a React state or state management system
      }
    )
 
    .catch(error => console.error(error));

  },[]);


    return(
<div>



<SignUpPage tokenname={tokenname}/>


    </div>)
}