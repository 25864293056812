import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import '../index.css';  
import { useParams } from 'react-router-dom';

    export default function Restaurants({hresto}){    
      const{brand_slug, city_alias, hotel_slug} = useParams(); 
        const options = {           
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 1,
        
                }
            },
        };
     
      
          return (  
            <div>  
     
     {hresto?.length>0 && (   
          
        <OwlCarousel className="owl-carousel owl-theme owl-loaded owl-drag"
            id="restaurant-cs-main-updated-slider"
          items={1} margin={8} autoplay ={true} loop={true}  nav={true} autoplayTimeout={8000} autoplaySpeed={4000} {...options} >  

<div className="owl-stage-outer">
              <div
                className="owl-stage"
              >
                                 {
   hresto.map((val) => 
                <div className="owl-item">
                  <div className="item">
                    <div className="row">
                      <div className="col-md-6 text-center">
                        <img
                          className="w-100  ed"
                          src={val.dine_image}
                          alt={val.title}
                        />
                      </div>
                      <div className="col-md-6 pl-5 restro-style-crusine-raj">
                        <div className="sc-heading subHeading_cs-sc">
                          <h3 className="">{val.title}</h3>
                          <p className="mb-5">
                           {val.short_description}
                          </p>
                          <ul>
                            <li>
                              <img src="https://www.lemontreehotels.com/uploads/images/restaurant-updated.png" />
                              <b>Cuisine Type:</b> {val.cuisine}
                            </li>
                            <li>
                              <img src="https://www.lemontreehotels.com/uploads/images/menu.png" />
                              <b>Menu:</b> {val.menu_name}
                            </li>
                            <li>
                              <img src="https://www.lemontreehotels.com/uploads/images/clock.png" />
                              <b>Timing:</b> {val.opening_hour}
                            </li>
                          </ul>
                        </div>
                        <div className="restaurant-cs-sc-parter-sc-cs parter-sc-cs">
                          <a
                            href={`/RestaurantsPage/${brand_slug}/${city_alias}/${hotel_slug}`}
                            className="cs-cta cs-cta-explore explore-without-border restaurent-citrus-btn-raj"
                          >
                            Explore
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
   )
  }
              </div>
            </div>
            


      </OwlCarousel>  
        
     )
    }
      </div>  
          )  
          
      }  
        
  
