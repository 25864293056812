import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';

function TNCPage() {
   return (
    <>
      <HeaderPage />
 <section className="filters-cs cs-sc-padding mt-5 pt-4 country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Term & Condition
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
<section className="term-conditon-cs cs-sc-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="heading text-center mb-4">
          <h2>Terms &amp; Conditions</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="hotelpage-midwrapper">
          <div className="right-pannel">
            <h5 className="text_12">Site Usage and Information Agreement</h5>
            <ul>
              <li>
                Please Read These Terms And Conditions Of Use (“terms And
                Conditions”) Carefully Before Using This Site
                <a href="assets/front/upload/term-condition/term-conditon.pdf">
                  
                  Read More
                </a>
              </li>
              <li>
                Website only benefits.
                <a href="assets/front/upload/term-condition/Website_USP.pdf">
                  
                  Read More
                </a>
              </li>
              <li>
                Infinity Rewards Program and term-condition.
                <a href="assets/front/upload/term-condition/Infinity_Rewards_Program_and_TandC.pdf">
                  
                  Read More
                </a>
              </li>
              <li>
                Booking, Cancellation &amp; Refund Policy.
                <a href="assets/front/upload/term-condition/Booking_and_Cancellation_Policy.pdf">
                  
                  Read More
                </a>
              </li>
              <li>
                Guidelines and notifications by Ministry of Tourism for
                classified hotels.
                <a href="assets/front/upload/term-condition/MinistryofTourism.pdf">
                  
                  Read More
                </a>
                <ul>
                  <li>
                    Lemon Tree Hotel,Ahmedabad.
                    <a href="assets/front/upload/term-condition/LTH_Ahmedabad.pdf">
                      
                      Read More
                    </a>
                  </li>
                  <li>
                    Lemon Tree Hotel,Aurangabad.
                    <a href="assets/front/upload/term-condition/LTH_Aurangabad.pdf">
                      
                      Read More
                    </a>
                  </li>
                  <li>
                    Lemon Tree Hotel,Chennai.
                    <a href="assets/front/upload/term-condition/LTH_Chennai.pdf">
                      
                      Read More
                    </a>
                  </li>
                  <li>
                    Lemon Tree Hotel,Hinjewadi Pune.
                    <a href="assets/front/upload/term-condition/LTH_HinjewadiPune.pdf">
                      
                      Read More
                    </a>
                  </li>
                  <li>
                    Lemon Tree Premier,Leisure Valley ,Gurgaon.
                    <a href="assets/front/upload/term-condition/LTP_LeisureValleyGurgaon.pdf">
                      
                      Read More
                    </a>
                  </li>
                  <li>
                    Lemon Tree Premier,The Atrium ,Ahmedabad.
                    <a href="assets/front/upload/term-condition/LTP_TheAtriumAhmedabad.pdf">
                      
                      Read More
                    </a>
                  </li>
                  <li>
                    Red Fox Hotel ,East Delhi.
                    <a href="assets/front/upload/term-condition/RFH_EastDelhi.pdf">
                      
                      Read More
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




      <FooterPage />


    </>
  )

}

export default TNCPage;

