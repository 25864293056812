import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';

function NonAffiliationPage() {
   return (
    <>
      <HeaderPage />
 <section className="filters-cs cs-sc-padding mt-5 pt-4 country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Non-Affiliation
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
<section className="NonAffiliationMain pt-5 pb-4">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <center>
          <h2 className="mb-4">Notice of Non-Affiliation and Disclaimer</h2>
          <p className="mx-auto w-75">
            
            We are not affiliated, associated, authorized, endorsed by, or in
            any way officially connected with the 1) "Lemon and Tree Holidays"
            and/or
            <a href="http://www.lemonandtreeholidays.com/" target="_blank">
              http://www.lemonandtreeholidays.com
            </a>
            " and 2. "Keys Resorts And Hospitality Pvt. Ltd." and/or
            <a href="www.keysresorts.in" target="_blank">
              www.keysresorts.in
            </a>
            ".
            <b>
              The official Lemon Tree Hotels and Keys Hotels website is
              <a href="http://www.lemontreehotels.com/">
                www.lemontreehotels.com
              </a>
            </b>
            . The names Lemon Tree Hotels and Keys Hotels as well as related
            brand / trade names, marks, emblems and images are registered
            trademarks of M/s. Lemon Tree Hotels Limited."
          </p>
        </center>
      </div>
    </div>
  </div>
</section>



      <FooterPage />


    </>
  )

}

export default NonAffiliationPage;

