import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
function DayUseRoomPage() {
   return (
    <>
      <HeaderPage />

  <div className="banner-beta-one banner h350">
  <img className="w-100"
    src="https://www.lemontreehotels.com/assets/front/images/rewards_banner_1.jpg" /> 
</div>


<section className="filters-cs cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Day use room
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>

<div id="WhatsNewSection_cs_sc " className="cs-sc-padding">
  <div className="container">
    <div className="row row justify-content-center">
      <div className="col-12">
        <div className="why_us new-reso-heading-rj ">
          <div className="heading text-center mb-3">
            <h1>Day use room</h1>
            <p className="color_grey f-15">
              Discover a refreshing break at Lemon Tree Hotels with our
              exclusive Day Use Rooms. Seeking a daytime haven of comfort and
              productivity? Our Day Use Rooms offer a serene space for
              relaxation or work. Whether you're a traveler on a layover, a
              professional in need of a quiet workspace, or a couple seeking a
              mini getaway, our thoughtfully designed rooms provide the perfect
              solution. Enjoy full access to hotel amenities, high-speed Wi-Fi,
              and top-notch service. With Lemon Tree Hotels' Day Use Rooms, you
              can recharge, work, or unwind for a few hours without an overnight
              stay. Embrace flexibility and comfort with us.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<section className="cs-sc-padding term-condition">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <strong>The offer inclusions:</strong>
        <ul className="mt-4">
          <li>Rooms starting at Rs. 1,099</li>
          <li>Members only! FLAT 10% ADDITIONAL off on Day Rooms</li>
          <li>Book rooms between 10 AM to 5 PM only for 7 hour</li>
          <li>Free WiFi 24X7</li>
          <li>No hidden charges</li>
        </ul>
      </div>
      <div className="col-md-6">
        <strong>Terms and Conditions:</strong>
        <ul className="mt-4">
          <li>7 hour slot to be booked between 10 AM to 5 PM</li>
          <li>
            *Check-in is allowed only at or after 10 am, and check-out is before
            5 pm on the same day.
          </li>
          <li>
            Every extra hour utilized post 5 pm will be charged at 25% of the
            booking value, per hour, subject to room availability.
          </li>
          <li>
            A 100 percent advance deposit is required at the time of reservation
          </li>
          <li>
            Non-cancellable, non-refundable and non-amendment for entire length
            of stay.
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>


{/* slider */}
<section className="dayUseRooms-main cs-sc-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-8 new-reso-heading-rj">
        <div className="heading">
          <h2>Available at these hotels</h2>
        </div>
      </div>
      <div className="col-md-4">
        <div className="corporate-offer-cs-filter">
          <form
            action="https://www.lemontreehotels.com/day-use-rooms"
            name="searchhotelbycity"
            id="searchhotelbycityId"
            method="post"
          >
            <input
              type="hidden"
              name="_token"
              defaultValue="EktAIrD2bfDhVE7djCkiJ06Oc3P9s79UNeNKKgdg"
            />
              <FontAwesomeIcon icon={faAngleDown} />
          
            <select
              name="DdDayuseCity"
              id="DdDayuseCityId"
              className="form-control"
            >
              <option value={0}>Select Your Location</option>
              <option value={4}>Ahmedabad</option>
              <option value={2}>Alwar</option>
              <option value={5}>Aurangabad</option>
              <option value={22}>Bandhavgarh</option>
              <option selected="selected" value={6}>
                Bengaluru
              </option>
              <option value={54}>Bhiwadi</option>
              <option value={7}>Chandigarh</option>
              <option value={8}>Chennai</option>
              <option value={42}>Coimbatore</option>
              <option value={58}>Dapoli</option>
              <option value={9}>Dehradun</option>
              <option value={1}>Delhi</option>
              <option value={36}>Dwarka</option>
              <option value={11}>Gurugram</option>
              <option value={1059}>Haridwar</option>
              <option value={57}>Hubli</option>
              <option value={38}>Hyderabad</option>
              <option value={24}>Indore</option>
              <option value={12}>Jaipur</option>
              <option value={13}>Kochi</option>
              <option value={14}>Kolkata</option>
              <option value={41}>Kozhikode</option>
              <option value={56}>Lucknow</option>
              <option value={17}>Mumbai</option>
              <option value={31}>Noida</option>
              <option value={18}>Port Blair</option>
              <option value={32}>Pune</option>
              <option value={1057}>Rajkot</option>
              <option value={43}>Rishikesh</option>
              <option value={59}>Sonmarg</option>
              <option value={45}>Thekkady</option>
              <option value={47}>Thiruvananthapuram</option>
              <option value={53}>Tiruchirappalli</option>
              <option value={52}>Vadodara</option>
              <option value={50}>Vijayawada</option>
              <option value={51}>Visakhapatnam</option>
            </select>
          </form>
        </div>
      </div>
    </div>
    <div className="row mt-5 dayuseroom-slider-rj">
      <div className="col-md-12">
        <div className="below_slider-cs-sc-main">
          <div
            className="owl-carousel owl-theme owl-loaded owl-drag"
            id="dayUseRooms-slider"
          >
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: "translate3d(-1493px, 0px, 0px)",
                  transition: "all 0.25s ease 0s",
                  width: 4107
                }}
              >
                <div
                  className="owl-item cloned"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/31_Jul_2023_03_49_44Keys-Select-Hotel,-Whitefield,-Bengaluru---Facade.jpg"
                        className=""
                        alt="Keys Select By Lemon Tree Hotels, Whitefield, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/keys-select-hotel/bengaluru/hotel-whitefield-bengaluru">
                            Keys Select By Lemon Tree Hotels, Whitefield,
                            Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/keys-select-hotel/bengaluru/hotel-whitefield-bengaluru"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('36564','Keys Select By Lemon Tree Hotels, Whitefield, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/29_Jul_2023_01_51_05Lemon-Tree-Hotel,-Whitefield---Facade.jpg"
                        className=""
                        alt="Lemon Tree Hotel, Whitefield, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/whitefield">
                            Lemon Tree Hotel, Whitefield, Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/whitefield"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('60235','Lemon Tree Hotel, Whitefield, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/29_Jul_2023_01_35_07Lemon-Tree-Hotel,-Electronic-City---Facade.jpg"
                        className=""
                        alt="Lemon Tree Hotel, Electronics City, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/electronics-city-bengaluru">
                            Lemon Tree Hotel, Electronics City, Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/electronics-city-bengaluru"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('25480','Lemon Tree Hotel, Electronics City, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/04_Sep_2023_07_12_25facade.jpg"
                        className=""
                        alt="Lemon Tree Premier, Ulsoor Lake, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/lemon-tree-premier/bengaluru/city-center-bengaluru">
                            Lemon Tree Premier, Ulsoor Lake, Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/lemon-tree-premier/bengaluru/city-center-bengaluru"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('28021','Lemon Tree Premier, Ulsoor Lake, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item active"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/31_Jul_2023_03_16_24Keys-Select-Hotel,-Hosur-Road,-Bengaluru---Facade.jpg"
                        className=""
                        alt="Keys Select By Lemon Tree Hotels, Hosur Road, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/keys-select-hotel/bengaluru/hotel-hosur-road-bengaluru">
                            Keys Select By Lemon Tree Hotels, Hosur Road,
                            Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/keys-select-hotel/bengaluru/hotel-hosur-road-bengaluru"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('36684','Keys Select By Lemon Tree Hotels, Hosur Road, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item active"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/31_Jul_2023_03_49_44Keys-Select-Hotel,-Whitefield,-Bengaluru---Facade.jpg"
                        className=""
                        alt="Keys Select By Lemon Tree Hotels, Whitefield, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/keys-select-hotel/bengaluru/hotel-whitefield-bengaluru">
                            Keys Select By Lemon Tree Hotels, Whitefield,
                            Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/keys-select-hotel/bengaluru/hotel-whitefield-bengaluru"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('36564','Keys Select By Lemon Tree Hotels, Whitefield, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item active"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/29_Jul_2023_01_51_05Lemon-Tree-Hotel,-Whitefield---Facade.jpg"
                        className=""
                        alt="Lemon Tree Hotel, Whitefield, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/whitefield">
                            Lemon Tree Hotel, Whitefield, Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/whitefield"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('60235','Lemon Tree Hotel, Whitefield, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/29_Jul_2023_01_35_07Lemon-Tree-Hotel,-Electronic-City---Facade.jpg"
                        className=""
                        alt="Lemon Tree Hotel, Electronics City, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/electronics-city-bengaluru">
                            Lemon Tree Hotel, Electronics City, Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/lemon-tree-hotel/bengaluru/electronics-city-bengaluru"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('25480','Lemon Tree Hotel, Electronics City, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/04_Sep_2023_07_12_25facade.jpg"
                        className=""
                        alt="Lemon Tree Premier, Ulsoor Lake, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/lemon-tree-premier/bengaluru/city-center-bengaluru">
                            Lemon Tree Premier, Ulsoor Lake, Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/lemon-tree-premier/bengaluru/city-center-bengaluru"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('28021','Lemon Tree Premier, Ulsoor Lake, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/31_Jul_2023_03_16_24Keys-Select-Hotel,-Hosur-Road,-Bengaluru---Facade.jpg"
                        className=""
                        alt="Keys Select By Lemon Tree Hotels, Hosur Road, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/keys-select-hotel/bengaluru/hotel-hosur-road-bengaluru">
                            Keys Select By Lemon Tree Hotels, Hosur Road,
                            Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/keys-select-hotel/bengaluru/hotel-hosur-road-bengaluru"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('36684','Keys Select By Lemon Tree Hotels, Hosur Road, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: "363.333px", marginRight: 10 }}
                >
                  <div className="item">
                    <div className="otherHotels-item">
                      <img
                        src="https://www.lemontreehotels.com/uploads/gimages/31_Jul_2023_03_49_44Keys-Select-Hotel,-Whitefield,-Bengaluru---Facade.jpg"
                        className=""
                        alt="Keys Select By Lemon Tree Hotels, Whitefield, Bengaluru"
                      />
                      <div className="otherHotels-cs-sc-item-content">
                        <h4>
                          <a href="https://www.lemontreehotels.com/keys-select-hotel/bengaluru/hotel-whitefield-bengaluru">
                            Keys Select By Lemon Tree Hotels, Whitefield,
                            Bengaluru
                          </a>
                        </h4>
                        <a
                          href="https://www.lemontreehotels.com/keys-select-hotel/bengaluru/hotel-whitefield-bengaluru"
                          className="cs-cta cs-cta-explore"
                        >
                          Explore
                        </a>
                        <a
                          href="javascript:void(0);"
                          onclick="return bookHotel('36564','Keys Select By Lemon Tree Hotels, Whitefield, Bengaluru')"
                          className="cs-cta"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-nav disabled">
              <button type="button" role="presentation" className="owl-prev">
                {" "}
                <span className="prev-txt">Prev</span>{" "}
                <div className="owl-btn-line">
                  <i className="fa fa-angle-left" aria-hidden="true" />
                </div>{" "}
              </button>
              <button type="button" role="presentation" className="owl-next">
                <div className="owl-btn-line">
                  <i className="fa fa fa-angle-right" aria-hidden="true" />
                </div>{" "}
                <span className="next-txt">Next</span>
              </button>
            </div>
            <div className="owl-dots">
              <button role="button" className="owl-dot active">
                <span />
              </button>
              <button role="button" className="owl-dot">
                <span />
              </button>
            </div>
          </div>
          <div className="dayUseRooms-slider-count">2/5</div>
        </div>
      </div>
    </div>
  </div>
</section>

      <FooterPage />


    </>
  )

}

export default DayUseRoomPage;

