import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';


function SpecialOfferPage() {
   return (
    <>
      <HeaderPage />
   <div className='mt-3 pt-1'>
 <section className="filters-cs mt-5 cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="https://www.lemontreehotels.com">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Special Offer
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>

<section className="all-home-offer">
  {/*Our offers*/}
  <div className="container-fluid our_offers_new p-5 bg-light">
    <div className="heading text-center mb-4">
      <h2>offers &amp; promotions</h2>
    </div>
    <div className="row desktop_offer_main">
      <div className="col-lg-6">
        <div className="our_offers_new_inner_right">
          <div className="our_offers_new_inner w-100">
            <div className="our_offers_new_inner_box">
              <figure>
                <img
                  alt="member rate"
                  src="https://www.lemontreehotels.com/assets/front/images/infinity_banner_pre-sign-in-dstp.jpg"
                />
              </figure>
              <div className="our_offers_new_inner_hover signup_offer_cs">
                <div className="offer_new_heading_paragraph">
                  <h4
                    className="spl_desk_Member_heading"
                    style={{ display: "none" }}
                  >
                    Member�s rate
                  </h4>
                  <h4 className="spl_desk_signup_heading">Member's rate</h4>
                </div>
                <div className="btn-grpOffer">
                  <div
                    className="offer-links wow slideInRight2"
                    data-wow-duration=".5s"
                    data-wow-delay="1s"
                    style={{
                      visibility: "visible",
                      animationDuration: "0.5s",
                      animationDelay: "1s",
                      animationName: "slideInRight2"
                    }}
                  >
                    <a
                      href="https://www.lemontreehotels.com/rewards"
                      style={{ color: "black !important" }}
                      onclick="explorestay();"
                      className="spl_desk_signup_btn book-n Sign_up_ds"
                    >
                      Explore
                    </a>
                    <a
                      href="javascript:void(0);"
                      onclick="explorestay();"
                      className="spl_desk_Member_btn book-n Sign_up_ds"
                      style={{ display: "none" }}
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our_offers_new_inner w-100">
            <div className="our_offers_new_inner_box">
              <figure>
                <img
                  alt="member rate"
                  src="https://www.lemontreehotels.com/assets/front/images/happy-hour.jpg"
                />
              </figure>
              <div className="our_offers_new_inner_hover signup_offer_cs">
                <div className="offer_new_heading_paragraph">
                  <h4
                    className="spl_desk_Member_heading"
                    style={{ display: "none" }}
                  >
                    Happy Hour
                  </h4>
                  <h4 className="spl_desk_signup_heading">Happy Hour</h4>
                </div>
                <div className="btn-grpOffer">
                  <div
                    className="offer-links wow slideInRight2"
                    data-wow-duration=".5s"
                    data-wow-delay="1s"
                    style={{
                      visibility: "visible",
                      animationDuration: "0.5s",
                      animationDelay: "1s",
                      animationName: "slideInRight2"
                    }}
                  >
                    <a
                      href="https://www.lemontreehotels.com/happy-hour"
                      style={{ color: "black !important" }}
                      className="spl_desk_signup_btn book-n Sign_up_ds"
                    >
                      Explore
                    </a>
                    <a
                      href="https://www.lemontreehotels.com/happy-hour"
                      className="spl_desk_Member_btn book-n Sign_up_ds"
                      style={{ display: "none" }}
                    >
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="our_offers_new_inner_right">
          <div className="our_offers_new_inner w-100">
            <div className="our_offers_new_inner_box">
              <figure>
                <img
                  alt="Sunday Redefined"
                  src="https://www.lemontreehotels.com/assets/front/images/sunday-redefined.jpg"
                />
              </figure>
              <div className="our_offers_new_inner_hover days_room">
                <div className="offer_new_heading_paragraph">
                  <h4>Sunday Redefined Offer</h4>
                </div>
                <div className="btn-grpOffer">
                  <div
                    className="offer-links wow slideInRight2"
                    data-wow-duration=".5s"
                    data-wow-delay="1s"
                    style={{
                      visibility: "visible",
                      animationDuration: "0.5s",
                      animationDelay: "1s",
                      animationName: "slideInRight2"
                    }}
                  >
                    <a
                      href="https://www.lemontreehotels.com/sunday-redefined"
                      className="book-n dayroom_bn_ds"
                    >
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our_offers_new_inner d-none">
            <div className="our_offers_new_inner_box">
              <figure>
                <img
                  alt="breakfast offer"
                  src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                />
              </figure>
              <div className="our_offers_new_inner_hover">
                <div className="offer_new_heading_paragraph">
                  <h4>Suite Celebration</h4>
                </div>
                <div className="btn-grpOffer">
                  <div
                    className="offer-links wow slideInRight2 animated"
                    data-wow-duration=".5s"
                    data-wow-delay="1s"
                    style={{
                      visibility: "visible",
                      animationDuration: "0.5s",
                      animationDelay: "1s"
                    }}
                  >
                    <a
                      href="https://www.lemontreehotels.com/special-offer"
                      className="book-n"
                    >
                      Book Now
                    </a>
                    <a
                      href="javascript:void(0);"
                      className="explore-n"
                      data-toggle="modal"
                      data-target="#myModal_knownow"
                      onclick="ShowOfferInclusion('2916');"
                    >
                      know more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our_offers_new_inner w-100">
            <div className="our_offers_new_inner_box">
              <figure>
                <img
                  alt="day room"
                  src="/static/images/Day-Cation-616x225.jpg"
                />
              </figure>
              <div className="our_offers_new_inner_hover days_room">
                <div className="offer_new_heading_paragraph">
                  <h4>
                    Day Rooms{" "}
                    <span className="day_rooms_time">( 10am to 5pm )</span>
                  </h4>
                </div>
                <div className="btn-grpOffer">
                  <div
                    className="offer-links wow slideInRight2"
                    data-wow-duration=".5s"
                    data-wow-delay="1s"
                    style={{
                      visibility: "visible",
                      animationDuration: "0.5s",
                      animationDelay: "1s",
                      animationName: "slideInRight2"
                    }}
                  >
                    <a
                      href="https://www.lemontreehotels.com/day-use-rooms"
                      className="book-n dayroom_bn_ds"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
  {/*Our offers*/}
  {/*   <section class="all-home-offer offer-n-pro-raj">
        
         <div class="container-fluid our_offers_new p-5 bg-light">
            <div class="heading text-center mb-4">
               <h2>offers & promotions</h2>
            </div>

            <div id="updated_offer_sc_desktop_">
				  <div class="row desktop_offer_main">
																		<div class="col-lg-6">
                     <div class="our_offers_new_inner_right">
                       <div class="our_offers_new_inner w-100">
                           <div class="our_offers_new_inner_box">
                              <figure><img class="lazyload" src="" data-src="https://www.lemontreehotels.com/uploads/specialoffers/6638a8966b8a8Sign Up.jpg"></figure>
                              <div class="our_offers_new_inner_hover signup_offer_cs">
                                 <div class="offer_new_heading_paragraph">
                                     <h4 class="spl_desk_Member_heading">Sign Up</h4>
										  
                                 </div>
                                 <div class="btn-grpOffer">
                                    <div class="offer-links wow slideInRight2" data-wow-duration=".5s" data-wow-delay="1s" style="visibility: visible; animation-duration: 0.5s; animation-delay: 1s; animation-name: slideInRight2;">
																				<a href="https://www.lemontreehotels.com/member/signup" class="book-n dayroom_bn_ds" >Explore</a>
										   
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
												<div class="col-lg-6">
                     <div class="our_offers_new_inner_right">
                       <div class="our_offers_new_inner w-100">
                           <div class="our_offers_new_inner_box">
                              <figure><img class="lazyload" src="" data-src="https://www.lemontreehotels.com/uploads/specialoffers/10O_Aug_2023_08_08_13sunday-offer-updated.jpg"></figure>
                              <div class="our_offers_new_inner_hover signup_offer_cs">
                                 <div class="offer_new_heading_paragraph">
                                     <h4 class="spl_desk_Member_heading">Sunday Redefined</h4>
										  
                                 </div>
                                 <div class="btn-grpOffer">
                                    <div class="offer-links wow slideInRight2" data-wow-duration=".5s" data-wow-delay="1s" style="visibility: visible; animation-duration: 0.5s; animation-delay: 1s; animation-name: slideInRight2;">
																				<a href="https://www.lemontreehotels.com/sunday-redefined" class="book-n dayroom_bn_ds" >Explore</a>
										   
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
												<div class="col-lg-6">
                     <div class="our_offers_new_inner_right">
                       <div class="our_offers_new_inner w-100">
                           <div class="our_offers_new_inner_box">
                              <figure><img class="lazyload" src="" data-src="https://www.lemontreehotels.com/uploads/specialoffers/19O_Mar_2024_12_46_53hoppr-hour.jpg"></figure>
                              <div class="our_offers_new_inner_hover signup_offer_cs">
                                 <div class="offer_new_heading_paragraph">
                                     <h4 class="spl_desk_Member_heading">Happy Hours</h4>
										  
                                 </div>
                                 <div class="btn-grpOffer">
                                    <div class="offer-links wow slideInRight2" data-wow-duration=".5s" data-wow-delay="1s" style="visibility: visible; animation-duration: 0.5s; animation-delay: 1s; animation-name: slideInRight2;">
																				<a href="https://www.lemontreehotels.com/happy-hour" class="book-n dayroom_bn_ds" >Explore</a>
										   
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
												<div class="col-lg-6">
                     <div class="our_offers_new_inner_right">
                       <div class="our_offers_new_inner w-100">
                           <div class="our_offers_new_inner_box">
                              <figure><img class="lazyload" src="" data-src="https://www.lemontreehotels.com/uploads/specialoffers/667003bedef0f311X312 (2).jpg"></figure>
                              <div class="our_offers_new_inner_hover signup_offer_cs">
                                 <div class="offer_new_heading_paragraph">
                                     <h4 class="spl_desk_Member_heading">Aurika, Mumbai Skycity - Offer</h4>
										  
                                 </div>
                                 <div class="btn-grpOffer">
                                    <div class="offer-links wow slideInRight2" data-wow-duration=".5s" data-wow-delay="1s" style="visibility: visible; animation-duration: 0.5s; animation-delay: 1s; animation-name: slideInRight2;">
																				<a href="javascript:void(0);"   onclick="explorestay();" class="spl_desk_signup_btn book-n Sign_up_ds">Book Now</a> 
                                     
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
												<div class="col-lg-6">
                     <div class="our_offers_new_inner_right">
                       <div class="our_offers_new_inner w-100">
                           <div class="our_offers_new_inner_box">
                              <figure><img class="lazyload" src="" data-src="https://www.lemontreehotels.com/uploads/specialoffers/7O_Mar_2024_05_21_41day use.jpg"></figure>
                              <div class="our_offers_new_inner_hover signup_offer_cs">
                                 <div class="offer_new_heading_paragraph">
                                     <h4 class="spl_desk_Member_heading">Day Use</h4>
										  
                                 </div>
                                 <div class="btn-grpOffer">
                                    <div class="offer-links wow slideInRight2" data-wow-duration=".5s" data-wow-delay="1s" style="visibility: visible; animation-duration: 0.5s; animation-delay: 1s; animation-name: slideInRight2;">
																				<a href="javascript:void(0);"   onclick="explorestay();" class="spl_desk_signup_btn book-n Sign_up_ds">Book Now</a> 
                                     
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
										                          
                  </div>
				  
               
            </div>


            <div id="updated_offer_sc_mob1">
				  
              <div class="row mobile_offer_main1">
                  <div class="owl-carousel owl-theme" id="mobile_offer_main_offer_sc1">
                      												
						
						 <div class="item">
                        <figure><img alt="sunday redefined"   class="lazyload" src="" data-src="https://www.lemontreehotels.com/uploads/specialoffers/6638a8966b8a8Sign Up.jpg"></figure>
                        <div class="mobile_offer_main_inner_item">
                           <h4>Sign Up</h4>
                           <div class="mobile_offer_main_inner_item_btn">
																			<a href="https://www.lemontreehotels.com/member/signup" class="book-n dayroom_bn_ds" >Explore</a>
										 
                           </div>
                        </div>
                     </div>
												
						
						 <div class="item">
                        <figure><img alt="sunday redefined"   class="lazyload" src="" data-src="https://www.lemontreehotels.com/uploads/specialoffers/10O_Aug_2023_08_08_13sunday-offer-updated.jpg"></figure>
                        <div class="mobile_offer_main_inner_item">
                           <h4>Sunday Redefined</h4>
                           <div class="mobile_offer_main_inner_item_btn">
																			<a href="https://www.lemontreehotels.com/sunday-redefined" class="book-n dayroom_bn_ds" >Explore</a>
										 
                           </div>
                        </div>
                     </div>
												
						
						 <div class="item">
                        <figure><img alt="sunday redefined"   class="lazyload" src="" data-src="https://www.lemontreehotels.com/uploads/specialoffers/19O_Mar_2024_12_46_53hoppr-hour.jpg"></figure>
                        <div class="mobile_offer_main_inner_item">
                           <h4>Happy Hours</h4>
                           <div class="mobile_offer_main_inner_item_btn">
																			<a href="https://www.lemontreehotels.com/happy-hour" class="book-n dayroom_bn_ds" >Explore</a>
										 
                           </div>
                        </div>
                     </div>
												
						
						 <div class="item">
                        <figure><img alt="sunday redefined"   class="lazyload" src="" data-src="https://www.lemontreehotels.com/uploads/specialoffers/667003bedef0f311X312 (2).jpg"></figure>
                        <div class="mobile_offer_main_inner_item">
                           <h4>Aurika, Mumbai Skycity - Offer</h4>
                           <div class="mobile_offer_main_inner_item_btn">
																			<a href="javascript:void(0);"   onclick="explorestay();" class="book-n Sign_up_ds_slider">Book Now</a> 
                                   
                           </div>
                        </div>
                     </div>
												
						
						 <div class="item">
                        <figure><img alt="sunday redefined"   class="lazyload" src="" data-src="https://www.lemontreehotels.com/uploads/specialoffers/7O_Mar_2024_05_21_41day use.jpg"></figure>
                        <div class="mobile_offer_main_inner_item">
                           <h4>Day Use</h4>
                           <div class="mobile_offer_main_inner_item_btn">
																			<a href="javascript:void(0);"   onclick="explorestay();" class="book-n Sign_up_ds_slider">Book Now</a> 
                                   
                           </div>
                        </div>
                     </div>
										                           </div>
               </div> 
            </div>
         </div>
         
         
      </section> */}
  {/*Our offers*/}
  {/* partners offer */}
  <div className=" partners-offer" id="offer-page_partners-offers">
    <div className="container-fluid our_offers_new p-5">
      <div className="row">
        <div className="text-center mb-4 col-md-12">
          <h2>Partners Offers</h2>
          <p className="mx-auto w-75">
            Enjoy a wide range of benefits thanks to our partner's exclusive
            offers and services. Join our loyalty program today and discover an
            all-new world of personalised offers and exclusive discounts
            tailored to your needs.
          </p>
        </div>
      </div>
      <div id="updated_offer_sc_desktopOurPartners">
        <div className="row desktop_offer_main">
          <div className="col-lg-6">
            <div className="our_offers_new_inner_right">
              <div className="our_offers_new_inner w-100">
                <div className="our_offers_new_inner_box Skykar_bg">
                  <div className="our_offers_new_inner_hover signup_offer_cs">
                    <div className="offer_new_heading_paragraph">
                      {/* <h4>Skykar</h4> */}
                    </div>
                    <div className="btn-grpOffer">
                      <div
                        className="offer-links wow slideInRight2"
                        data-wow-duration=".5s"
                        data-wow-delay="1s"
                        style={{
                          visibility: "visible",
                          animationDuration: "0.5s",
                          animationDelay: "1s",
                          animationName: "slideInRight2"
                        }}
                      >
                        <a
                          href="https://www.lemontreehotels.com/member/signup"
                          id="Skykar"
                          className="book-n ValentineOfferBN"
                        >
                          Explore
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="our_offers_new_inner our_offers_new_inner_left">
              <div className="our_offers_new_inner_box Healthians-bg">
                <div className="our_offers_new_inner_hover">
                  <div className="offer_new_heading_paragraph offer_new_heading_paragraph_left-big">
                    {/* <h4>Healthians</h4> */}
                  </div>
                  <div className="btn-grpOffer">
                    <div
                      className="offer-links wow slideInRight2"
                      data-wow-duration=".5s"
                      data-wow-delay="1s"
                      style={{
                        visibility: "visible",
                        animationDuration: "0.5s",
                        animationDelay: "1s",
                        animationName: "slideInRight2"
                      }}
                    >
                      <a
                        href="https://www.lemontreehotels.com/member/signup"
                        id="Healthians"
                        className="book-n ValentineOfferBN"
                      >
                        Explore
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* partner offer */}
  {/* corporate offer page  */}
  {/* corporate offer page  */}
</section>

</div>
   

      <FooterPage />


    </>
  )

}

export default SpecialOfferPage;

