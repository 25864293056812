import '../memdashboard.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';
import { useState, useEffect } from "react";
import axios from "axios";
import * as React from "react";
import { useNavigate } from "react-router-dom";

function TripPage() {
 
return (
    <>
<HeaderPage />

<div className="maiwrapper">
  {/* member header */}
  {/* member header */}
  <section className="header_space py-5" />
  <section className="member_header py-1">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="profile_member">
            <div className="userName_member">
              <strong>Namaste </strong>
              <h3>Harsh</h3>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="point_member">
            <div className="point_member_left point_member_main">
              <strong>3204.46</strong>
              <p>COINS</p>
            </div>
            <div className="point_member_right point_member_main">
              <strong>4</strong>
              <p>Total Stays</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* member header */}
  <section className="menu_member bg-light">
    <div className="menu_member_inner">
      <nav className="navbar navbar-expand-lg navbar-light pb-0">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a
                className="nav-link"
                href="/dashboard"
              >
                Overview
              </a>
            </li>
            <li className="nav-item active">
              <a
                className="nav-link"
                href="/trip"
              >
                My Trips
              </a>
            </li>
            {/*  <li class="nav-item">
			        <a class="nav-link" href="member/promotion">Promotion</a>
			      </li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                href="/redeem"
              >
                Redeem
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/profile"
              >
                Profile
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
  {/* member middle  */}
  {/* member middle  */}
  <section className="MemberTripMiddle_main py-5">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="trip_left_nav">
            <ul
              className="nav nav-tabs flex-column tripTab"
              id="tripTab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="completed-tab"
                  data-toggle="tab"
                  href="#completed"
                  role="tab"
                  aria-controls="completed"
                  aria-selected="true"
                >
                  Completed{" "}
                </a>
              </li>
              {/* <li class="nav-item">
					    <a class="nav-link" id="upcoming-tab" data-toggle="tab" href="#upcoming" role="tab" aria-controls="upcoming" aria-selected="false">Upcoming</a>
					  </li>
					  <li class="nav-item">
					    <a class="nav-link" id="cancelled-tab" data-toggle="tab" href="#cancelled" role="tab" aria-controls="cancelled" aria-selected="false">Cancelled</a>
					  </li> */}
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="completed"
                role="tabpanel"
                aria-labelledby="completed-tab"
              >
                <div className="trip_form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control clickable input-md hasDatepicker"
                      id="DtChkInTrip"
                      placeholder="  Check-In"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control clickable input-md hasDatepicker"
                      id="DtChkOutTrip"
                      placeholder="  Check-Out"
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="button"
                      onclick="getfncTripInventorySearch();"
                      className="btn btn-primary"
                    >
                      Search{" "}
                    </button>
                  </div>
                </div>
                <div id="gettripdataId">
                  <table className="table-responsive table table-hover table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">booking date </th>
                        <th scope="col">hotel name </th>
                        <th scope="col">check in </th>
                        <th scope="col">check out</th>
                        <th scope="col">room nights </th>
                        <th scope="col">room amount</th>
                        <th scope="col">points earned</th>
                        <th scope="col">Rewarding type</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* member middle  */}
</div>



 <FooterPage />


    </>
  )

}

export default TripPage;

