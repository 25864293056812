/** @format */

import { useContext, useEffect, useState } from "react";
import "./Homepage.css";
import axios from "axios";
import { Cartcontext } from "../../context/Context";
import Cart from "../cart/Cart";

const Homepage1 = () => {
  const listdata = [{
    name:"pinky",
    id:"1",
    qty:""
  },
  {
    name:"chhoker",
    id:"2",
    qty:""
  }
]

  useEffect(() => {

  }, []);
  const Globalstate = useContext(Cartcontext);
  const dispatch = Globalstate.dispatch;
  console.log(Globalstate);
  return (
<>
<Cart/>
    <div className="home">
     
      {listdata.map((item, index) => {
        item.quantity = 1;
        return (
          <div className="card" key={index}>
            <p>{item.name}</p>
          
            <button onClick={() => dispatch({ type: "ADD", payload: item })}>
              add to cart
            </button>
          </div>
        );
      })}
    </div>
    </>
  );
};

export default Homepage1;
