import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import '../index.css';  

    export default function Testimonial({httestm}){    

        const options = {           
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 3,
        
                }
            },
        };
     
      
          return (  
            <div>  
     
  
     {httestm?.length>0 && (   
        <OwlCarousel className="owl-carousel owl-theme hotelsinProperty_slider owl-loaded owl-drag"
          items={4} margin={8} autoplay ={true} loop={true}  nav={true} autoplayTimeout={8000} autoplaySpeed={4000} {...options} >  

<div className="owl-stage-outer">
              <div
                className="owl-stage"
              >
                
                {
   httestm.map((val) => 
                <div className="owl-item">
                  <div className="item">
                    <div className="test-item">
                      <span>
                      <FontAwesomeIcon icon={faStar} color='#ffc107'/>
                      <FontAwesomeIcon icon={faStar} color='#ffc107'/>
                      <FontAwesomeIcon icon={faStar} color='#ffc107'/>
                      <FontAwesomeIcon icon={faStar} color='#ffc107'/>
                      <FontAwesomeIcon icon={faStar} color='#ffc107'/>
                      </span>
                      <p>
                        {val.description}
                      </p>
                      <div className="testimonials_inner_profile">
                        <a
                          href={val.testimonial_link}
                          target="_blank"
                        >
                          <img
                            src={val.image}
                            alt=""
                          />
                          {/* <img src="https://www.lemontreehotels.com/uploads/images/google_logo.png" alt="google">*/}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
   )
  }
              </div>
            </div>
            


      </OwlCarousel>  
     )
    } 
  
      </div>  
          )  
          
      }  
        
  
