import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';

function MediaPage() {
   return (
    <>
      <HeaderPage />
 <section className="filters-cs cs-sc-padding mt-5 pt-4 country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Media
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
<section className="media-cs cs-sc-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="heading text-center mb-4">
          <h2>Media</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="media-tab">
          <div id="accordion">
            <div className="card">
              <div className="card-header" id="heading2024">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2024"
                    aria-expanded="false"
                    aria-controls="collapse2024"
                  >
                    2024
                  </button>
                </h5>
              </div>
              <div
                id="collapse2024"
                className="collapse"
                aria-labelledby="heading2024"
                data-parent="#accordion"
                style={{}}
              >
                <center>
                  <ul className="nav nav-tabs nynewcenter">
                    <li className="active">
                      <a
                        className="active show"
                        href="#mediatab"
                        data-toggle="tab"
                      >
                        Media Rooms
                      </a>
                    </li>
                    <li className="">
                      <a href="#presstab" data-toggle="tab">
                        Press Release
                      </a>
                    </li>
                  </ul>
                </center>
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/665f0aa71520a24th May 2024 -The Hindu Bangalore.pdf"
                          >
                            24th May 2024 -The Hindu Bangalore - 24 May 2024
                            <span className="media-date">24 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/665f0a5a3f16216th May 2024 - Mint.pdf"
                          >
                            16th May 2024 - Mint - 16 May 2024
                            <span className="media-date">16 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/665f0ad15c3d030th April 2024 Hospitality- Biz India.pdf"
                          >
                            30th April 2024 Hospitality- Biz India - 30 Apr 2024
                            <span className="media-date">30 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/05_Apr_2024_02_02_2822nd-March-2024_The Economic Times.pdf"
                          >
                            The Economic Times - 22 March, 2024 - 22 Mar 2024
                            <span className="media-date">22 Mar 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/04_Apr_2024_05_40_113rd-March-2024_Business India_National.pdf"
                          >
                            Business India National - 3rd March, 2024 - 03 Mar
                            2024<span className="media-date">03 Mar 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_07_04_2829th-February-2024_The Economic Times_Chennai.pdf"
                          >
                            29th February 2024 The Economic Times Chennai - 29
                            Feb 2024
                            <span className="media-date">29 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_07_04_5428th-February-2024_Mint_New Delhi.pdf"
                          >
                            28th February 2024 Mint New Delhi - 28 Feb 2024
                            <span className="media-date">28 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_07_05_2127th-February-2024_Mint_New Delhi.pdf"
                          >
                            27th February 2024 Mint New Delhi - 27 Feb 2024
                            <span className="media-date">27 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_07_05_4419th-February-2024_Business Standard.pdf"
                          >
                            19th February 2024 Business Standard - 19 Feb 2024
                            <span className="media-date">19 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_07_06_0811th-February-2024_The Economic Times.pdf"
                          >
                            11th February 2024 The Economic Times - 11 Feb 2024
                            <span className="media-date">11 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_06_59_4211th-February-2024_The Economic Times.pdf"
                          >
                            11th February 2024 The Economic Times - 11 Feb 2024
                            <span className="media-date">11 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_07_07_247th-February-2024_Mint.pdf"
                          >
                            7th February 2024 Mint - 07 Feb 2024
                            <span className="media-date">07 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_07_07_487th-February-2024_Hindustan Times.pdf"
                          >
                            7th February 2024 Hindustan Times - 07 Feb 2024
                            <span className="media-date">07 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_07_07_027th-February-2024_The Economic Times.pdf"
                          >
                            7th February 2024 The Economic Times - 07 Feb 2024
                            <span className="media-date">07 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_06_55_547th-February-2024_Hindustan Times.pdf"
                          >
                            7th-February-2024 Hindustan Times - 07 Feb 2024
                            <span className="media-date">07 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_06_58_527th-February-2024_The Economic Times.pdf"
                          >
                            7th February 2024 The Economic Times - 07 Feb 2024
                            <span className="media-date">07 Feb 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/06_Mar_2024_06_57_377th-February-2024_Mint.pdf"
                          >
                            7th February 2024 Mint - 07 Feb 2024
                            <span className="media-date">07 Feb 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2023">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2023"
                    aria-expanded="false"
                    aria-controls="collapse2023"
                  >
                    2023
                  </button>
                </h5>
              </div>
              <div
                id="collapse2023"
                className="collapse"
                aria-labelledby="heading2023"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/09_Jan_2024_11_22_55The_Economic_Times_17_December_2023.pdf"
                          >
                            The Economic Times 17 December 2023 - 17 Dec 2023
                            <span className="media-date">17 Dec 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/09_Jan_2024_11_24_17The_Hindu_Business_line_2_December_2023.pdf"
                          >
                            The Hindu Business line 2 December 2023 - 02 Dec
                            2023<span className="media-date">02 Dec 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/02_Nov_2023_06_47_392nd-October-2023_Mint_Mumbai.pdf"
                          >
                            2nd-October-2023_Mint_Mumbai - 02 Nov 2023
                            <span className="media-date">02 Nov 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/02_Nov_2023_06_46_5630th-October-2023_The Pioneer.pdf"
                          >
                            30th-October-2023_The Pioneer - 02 Nov 2023
                            <span className="media-date">02 Nov 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/02_Nov_2023_06_46_2917th-October-2023_The Hindu Business Line.pdf"
                          >
                            17th-October-2023_The Hindu Business Line - 02 Nov
                            2023<span className="media-date">02 Nov 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/02_Nov_2023_06_45_4016th-October-2023_Business Standard.pdf"
                          >
                            16th-October-2023_Business Standard - 02 Nov 2023
                            <span className="media-date">02 Nov 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/02_Nov_2023_06_45_096th-October-2023_The Free Press Journal.pdf"
                          >
                            6th-October-2023_The Free Press Journal - 02 Nov
                            2023<span className="media-date">02 Nov 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/02_Nov_2023_06_44_246th-October-2023_The Economic Times.pdf"
                          >
                            6th-October-2023_The Economic Times - 02 Nov 2023
                            <span className="media-date">02 Nov 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/02_Nov_2023_06_43_485th-October-2023_Business Standard.pdf"
                          >
                            5th-October-2023_Business Standard - 02 Nov 2023
                            <span className="media-date">02 Nov 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/02_Nov_2023_06_43_074th-October-2023_Financial Express.pdf"
                          >
                            4th-October-2023_Financial Express - 02 Nov 2023
                            <span className="media-date">02 Nov 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/04_Oct_2023_06_52_338th-September-2023_The Economic Times.pdf"
                          >
                            8th-September-2023_The Economic Times - 08 Sep 2023
                            <span className="media-date">08 Sep 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/04_Oct_2023_06_52_068th-September-2023_Mint.pdf"
                          >
                            8th-September-2023_Mint - 08 Sep 2023
                            <span className="media-date">08 Sep 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/05_Sep_2023_03_30_0511th-August-2023_The Hindu.pdf"
                          >
                            11th August 2023 The Hindu - 05 Sep 2023
                            <span className="media-date">05 Sep 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/04_Oct_2023_06_51_074th-September-2023_Business Standard.pdf"
                          >
                            4th-September-2023_Business Standard - 05 Sep 2023
                            <span className="media-date">05 Sep 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/05_Sep_2023_03_32_2431st-July-2023_Aviation Times.pdf"
                          >
                            31st-July-2023_Aviation Times - 05 Sep 2023
                            <span className="media-date">05 Sep 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/05_Sep_2023_03_31_0012th-August-2023_The Economic Times.pdf"
                          >
                            12th-August-2023_The Economic Times - 05 Sep 2023
                            <span className="media-date">05 Sep 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/04_Aug_2023_12_03_5226th-July-2023_Business Standard.pdf"
                          >
                            26th-July-2023_Business Standard - 26 Jul 2023
                            <span className="media-date">26 Jul 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/04_Aug_2023_12_02_5425th-July-2023_Business Standard.pdf"
                          >
                            25th-July-2023_Business Standard - 25 Jul 2023
                            <span className="media-date">25 Jul 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/04_Aug_2023_01_15_0916th_July_2023_The_Hindu_Business_Line.pdf"
                          >
                            16th-July-2023_The Hindu Business Line - 16 Jul 2023
                            <span className="media-date">16 Jul 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/04_Aug_2023_12_01_296th-July-2023_Mint.pdf"
                          >
                            6th-July-2023_Mint - 06 Jul 2023
                            <span className="media-date">06 Jul 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_03_3230th-June-2023_Fortune-India_National.pdf"
                          >
                            30th-June-2023_Fortune India_National - 30 Jun 2023
                            <span className="media-date">30 Jun 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_02_1920th-June-2023_Financial-Express.pdf"
                          >
                            20th-June-2023_Financial Express - 20 Jun 2023
                            <span className="media-date">20 Jun 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_01_3716th-June-2023_The-Economic-Times.pdf"
                          >
                            16th-June-2023_The Economic Times - 16 Jun 2023
                            <span className="media-date">16 Jun 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_11_59_4916th-June-2023_The-Economic-Times_New-Delhi_1,-9.pdf"
                          >
                            16th-June-2023_The Economic Times_New Delhi_1, 9 -
                            16 Jun 2023
                            <span className="media-date">16 Jun 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_11_59_085th-June-2023_Mint.pdf"
                          >
                            5th-June-2023_Mint - 05 Jun 2023
                            <span className="media-date">05 Jun 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_11_58_0631st-May-2023_Urban-Melange-_National.pdf"
                          >
                            31st-May-2023_Urban Melange _National - 31 May 2023
                            <span className="media-date">31 May 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_11_57_1128th-February-2023_The-Economic-Times_New-Delhi.pdf"
                          >
                            28th-February-2023_BW Aerocity Live_National - 28
                            Feb 2023
                            <span className="media-date">28 Feb 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_11_56_2928th-February-2023_The-Economic-Times_New-Delhi.pdf"
                          >
                            28th-February-2023_The Economic Times_New Delhi - 28
                            Feb 2023
                            <span className="media-date">28 Feb 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_11_55_3819th-February-2023_Business-Today_National.pdf"
                          >
                            19th-February-2023_Business Today_National - 19 Feb
                            2023<span className="media-date">19 Feb 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_11_54_2131st-January-2023_TRAVEL-LEISURE_National.pdf"
                          >
                            31st-January-2023_TRAVEL + LEISURE_National - 31 Jan
                            2023<span className="media-date">31 Jan 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_11_40_1718th-January-2023_The-Economic-Times_Bangalore.pdf"
                          >
                            18th-January-2023_The Economic Times_Bangalore - 18
                            Jan 2023
                            <span className="media-date">18 Jan 2023</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_11_39_465th-January-2023_Mint_Mumbai.pdf"
                          >
                            5th-January-2023_Mint_Mumbai - 05 Jan 2023
                            <span className="media-date">05 Jan 2023</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2022">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2022"
                    aria-expanded="false"
                    aria-controls="collapse2022"
                  >
                    2022
                  </button>
                </h5>
              </div>
              <div
                id="collapse2022"
                className="collapse"
                aria-labelledby="heading2022"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_50_1131st-December-2022_The-Economic-Times_Bangalore_1.pdf"
                          >
                            The Economic Times_Bangalore_1 - 31 Dec 2022
                            <span className="media-date">31 Dec 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_49_4631st-December-2022_BW-Aerocity-Live_National_74,-75.pdf"
                          >
                            BW Aerocity Live_National_74, 75 - 31 Dec 2022
                            <span className="media-date">31 Dec 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_45_2722nd-December-2022_The-Economic-Times_Bangalore_14.pdf"
                          >
                            The Economic Times_Bangalore_14 - 22 Dec 2022
                            <span className="media-date">22 Dec 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_44_5825th-November-2022_Mint_New-Delhi.pdf"
                          >
                            25th-November-2022_Mint_New Delhi - 25 Nov 2022
                            <span className="media-date">25 Nov 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_44_248th-November-2022_The-Free-Press-Journal_Mumbai.pdf"
                          >
                            8th-November-2022_The Free Press Journal_Mumbai - 08
                            Nov 2022
                            <span className="media-date">08 Nov 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_42_492nd-November-2022_Mint_New-Delhi.pdf"
                          >
                            2nd-November-2022_Mint_New Delhi - 02 Nov 2022
                            <span className="media-date">02 Nov 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_41_5531st-October-2022_Aviation-Times_National_14.pdf"
                          >
                            31st-October-2022_Aviation Times_National_14 - 31
                            Oct 2022
                            <span className="media-date">31 Oct 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_41_1931st-October-2022_FHRAI_National_46.pdf"
                          >
                            31st-October-2022_FHRAI - 31 Oct 2022
                            <span className="media-date">31 Oct 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_40_4421st-October-2022_Mint_Mumbai_9.pdf"
                          >
                            21st-October-2022_Mint_Mumbai_9 - 21 Oct 2022
                            <span className="media-date">21 Oct 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_40_1611th-October-2022_Mint_New-Delhi_8.pdf"
                          >
                            11th-October-2022_Mint_New Delhi_8 - 11 Oct 2022
                            <span className="media-date">11 Oct 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_39_465th-October-2022_Mint_New-Delhi_1.pdf"
                          >
                            5th-October-2022_Mint_New Delhi_1 - 05 Oct 2022
                            <span className="media-date">05 Oct 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_39_193rd-October-2022_The-Economic-Times_Hyderabad_1,-5.pdf"
                          >
                            3rd-October-2022_The Economic Times_Hyderabad_1, 5 -
                            03 Oct 2022
                            <span className="media-date">03 Oct 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_38_3630th-September-2022_Aviation-Times.pdf"
                          >
                            30th-September-2022_Aviation Times - 30 Sep 2022
                            <span className="media-date">30 Sep 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_37_5823rd-September-2022_Mint.pdf"
                          >
                            23rd-September-2022_Mint - 23 Sep 2022
                            <span className="media-date">23 Sep 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_37_2019th-September-2022_Business-Standard.pdf"
                          >
                            19th-September-2022_Business Standard - 19 Sep 2022
                            <span className="media-date">19 Sep 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_36_2131st-August-2022_Hotelier-India.pdf"
                          >
                            31st-August 2022_Hotelier India - 31 Aug 2022
                            <span className="media-date">31 Aug 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_35_3431st-August-2022_Aviation-Times_National_17.pdf"
                          >
                            31st-August-2022_Aviation Times - 31 Aug 2022
                            <span className="media-date">31 Aug 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_34_4930th-August-2022_Business-Standard.pdf"
                          >
                            30th-August-2022_Business Standard - 30 Aug 2022
                            <span className="media-date">30 Aug 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_32_2720th-August-2022_Mint.pdf"
                          >
                            20th-August 2022_Mint - 20 Aug 2022
                            <span className="media-date">20 Aug 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_31_5612th-August-2022_Financial-Express.pdf"
                          >
                            12th-August-2022_Financial Express - 12 Aug 2022
                            <span className="media-date">12 Aug 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_31_1810th-August-2022_The-Economic-Times.pdf"
                          >
                            10th-August-2022_The Economic Times - 10 Aug 2022
                            <span className="media-date">10 Aug 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_30_527th-August-2022_Business-Today.pdf"
                          >
                            7th-August-2022_Business Today - 07 Aug 2022
                            <span className="media-date">07 Aug 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_22_19Aviation-Times-31st-July-2022.pdf"
                          >
                            Aviation Times - 31st July 2022 - 31 Jul 2022
                            <span className="media-date">31 Jul 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_24_19Hotelier-India-31st-July-2022.pdf"
                          >
                            Hotelier India - 31st July 2022 - 31 Jul 2022
                            <span className="media-date">31 Jul 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_20_29Mint-13th-July-2022.pdf"
                          >
                            Mint - 13th July 2022 - 13 Jul 2022
                            <span className="media-date">13 Jul 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_18_21The-Economic-Times-3rd-July-2022.pdf"
                          >
                            The Economic Times - 3rd July 2022 - 03 Jul 2022
                            <span className="media-date">03 Jul 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_13_27Travel-Trade-Journal-30th-June-2022.pdf"
                          >
                            Travel Trade Journal - 30th June 2022 - 30 Jun 2022
                            <span className="media-date">30 Jun 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_16_26HAI-Engage-Magazine-30th-June-2022.pdf"
                          >
                            HAI Engage Magazine - 30th June 2022 - 30 Jun 2022
                            <span className="media-date">30 Jun 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_10_48HRANI-Hospitality-16th-June-2022.pdf"
                          >
                            HRANI Hospitality - 16th June 2022 - 16 Jun 2022
                            <span className="media-date">16 Jun 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_10_09Business-Standard-8th-June-2022.pdf"
                          >
                            Business Standard- 8th June 2022 - 08 Jun 2022
                            <span className="media-date">08 Jun 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_01_02_39EVO-India-31th-May-2022.pdf"
                          >
                            EVO India - 31th May 2022 - 31 May 2022
                            <span className="media-date">31 May 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_03_20Hotelier-India-31th-May-2022.pdf"
                          >
                            Hotelier India - 31th May 2022 - 31 May 2022
                            <span className="media-date">31 May 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_01_48Mint-26th-May-2022.pdf"
                          >
                            Mint - 26th May 2022 - 26 May 2022
                            <span className="media-date">26 May 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_57_03Business-Manager-4th-May-2022.pdf"
                          >
                            Business Manager -4th May 2022 - 04 May 2022
                            <span className="media-date">04 May 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_55_28BW-Hotelier-30-April-2022.pdf"
                          >
                            BW Hotelier - 30 April 2022 - 30 Apr 2022
                            <span className="media-date">30 Apr 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_45_01Hospitalty-Talk-30-April-2022.pdf"
                          >
                            Hospitalty Talk -30 April 2022 - 30 Apr 2022
                            <span className="media-date">30 Apr 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_44_26Hotel-Business-Review-30-April-2022.pdf"
                          >
                            Hotel Business Review - 30 April 2022 - 30 Apr 2022
                            <span className="media-date">30 Apr 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_43_52Mint-26th-April-2022.pdf"
                          >
                            Mint - 26th April 2022 - 26 Apr 2022
                            <span className="media-date">26 Apr 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_43_30The-Times-of-India-25th-April-2022.pdf"
                          >
                            The Times of India - 25th April 2022 - 25 Apr 2022
                            <span className="media-date">25 Apr 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_43_04Mint-4th-April-2022.pdf"
                          >
                            Mint - 4th April 2022 - 04 Apr 2022
                            <span className="media-date">04 Apr 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_42_35Mint,-Thursday,-31st-March-2022.pdf"
                          >
                            Mint, Thursday, 31st March 2022 - 31 Mar 2022
                            <span className="media-date">31 Mar 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_42_04Mint,-Wednesday,-30th-March-2022.pdf"
                          >
                            Mint, Wednesday, 30th March 2022 - 30 Mar 2022
                            <span className="media-date">30 Mar 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_41_27Business-Standard,-Saturday,-26th-March-2022.pdf"
                          >
                            Business Standard, Saturday, 26th March 2022 - 26
                            Mar 2022
                            <span className="media-date">26 Mar 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_40_51Mint,-Wednesday,23rd-March-2022.pdf"
                          >
                            Mint, Wednesday,23rd March 2022 - 23 Mar 2022
                            <span className="media-date">23 Mar 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_34_50Mint,-Wednesday,-16th-March,-2022.pdf"
                          >
                            Mint, Wednesday, 16th March, 2022 - 16 Mar 2022
                            <span className="media-date">16 Mar 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_33_04Business-Standard,-Thursday,-3rd-March-2022.pdf"
                          >
                            Business Standard, Thursday, 3rd March 2022 - 03 Mar
                            2022<span className="media-date">03 Mar 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_32_19Mint,-Tuesday,-1st-March-2022.pdf"
                          >
                            Mint, Tuesday, 1st March 2022 - 01 Mar 2022
                            <span className="media-date">01 Mar 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_31_2328th-February-2022_Hotelier-India__National.pdf"
                          >
                            28th February-2022_Hotelier India__National - 28 Feb
                            2022<span className="media-date">28 Feb 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_30_5621st-February-2022-Business-Standard-Mumbai.pdf"
                          >
                            21st February-2022 Business Standard Mumbai - 21 Feb
                            2022<span className="media-date">21 Feb 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_29_4520th-February-2022-Business-India-National.pdf"
                          >
                            20th-February-2022 Business India National - 20 Feb
                            2022<span className="media-date">20 Feb 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_29_0016th-February-2022-The-Economic-Times-Ahmedabad.pdf"
                          >
                            16th-February-2022 The Economic Times Ahmedabad - 16
                            Feb 2022
                            <span className="media-date">16 Feb 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_28_0610th-February-2022-BizzBuzz-Hyderabad.pdf"
                          >
                            10th-February-2022 BizzBuzz Hyderabad - 10 Feb 2022
                            <span className="media-date">10 Feb 2022</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_27_1031st-January-2022_CondeNastTraveller__National.pdf"
                          >
                            31st January 2022 CondeNastTraveller National - 31
                            Jan 2022
                            <span className="media-date">31 Jan 2022</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2021">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2021"
                    aria-expanded="false"
                    aria-controls="collapse2021"
                  >
                    2021
                  </button>
                </h5>
              </div>
              <div
                id="collapse2021"
                className="collapse"
                aria-labelledby="heading2021"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_57_5631st-December-2021_Hotelier-India__National.pdf"
                          >
                            31st December 2021 Hotelier India National - 31 Dec
                            2021<span className="media-date">31 Dec 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_54_2530th-December-2021_The-Hindu-Business-Line__NewDelhi.pdf"
                          >
                            30th December 2021 The Hindu Business Line NewDelhi
                            - 30 Dec 2021
                            <span className="media-date">30 Dec 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_53_5712th-December-2021_The-Hindu-Business-Line__Hyderabad.pdf"
                          >
                            12th December 2021 The Hindu Business Line Hyderabad
                            - 12 Dec 2021
                            <span className="media-date">12 Dec 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_53_1930th-November-2021-Aviation-Times.pdf"
                          >
                            30th-November-2021 Aviation Times - 30 Nov 2021
                            <span className="media-date">30 Nov 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_52_4530th-November-2021-Travel-Leisure.pdf"
                          >
                            30th-November-2021 Travel+Leisure - 30 Nov 2021
                            <span className="media-date">30 Nov 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_52_0314th-November-2021-The-Economic-Times.pdf"
                          >
                            14th-November-2021 The Economic Times - 14 Nov 2021
                            <span className="media-date">14 Nov 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_51_2531st-October-2021-The-Economic-Times.pdf"
                          >
                            31st October 2021 The Economic Times - 31 Oct 2021
                            <span className="media-date">31 Oct 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_50_4731st-October-2021_-Hotelier-India.pdf"
                          >
                            31st October 2021 Hotelier India - 31 Oct 2021
                            <span className="media-date">31 Oct 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_46_2627th-October-2021_The-New-Indian-Express.pdf"
                          >
                            27th October 2021 The New Indian Express - 27 Oct
                            2021<span className="media-date">27 Oct 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_45_501st-October-2021_-Business-Standard.pdf"
                          >
                            1st October 2021 Business Standard - 01 Oct 2021
                            <span className="media-date">01 Oct 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_05_22_3030th_September_2021_EvoIndia__National.pdf"
                          >
                            30th-September-2021_ Evo India__ National - 30 Sep
                            2021<span className="media-date">30 Sep 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_05_43_10September2021_Hotelier_India__National.pdf"
                          >
                            September-2021_ Hotelier India__ National - 30 Sep
                            2021<span className="media-date">30 Sep 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_39_0330th-September-2021_Hotelier-India.pdf"
                          >
                            30th-September-2021_Hotelier India - 30 Sep 2021
                            <span className="media-date">30 Sep 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_45_0130th-September-2021_-Evo-India__-National-(1).pdf"
                          >
                            30th-September-2021_ Evo India__ National (1) - 30
                            Sep 2021
                            <span className="media-date">30 Sep 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_32_149th-September-2021_-The-Economic-Times__NewDelhi.pdf"
                          >
                            9th-September-2021 The Economic Times New Delhi - 09
                            Sep 2021
                            <span className="media-date">09 Sep 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_30_075th-September-2021-Business-India__-National.pdf"
                          >
                            5th-September-2021 Business India_National - 05 Sep
                            2021<span className="media-date">05 Sep 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_29_1431st-August-2021_Hotelier-India.pdf"
                          >
                            31st August 2021 Hotelier India - 31 Aug 2021
                            <span className="media-date">31 Aug 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_28_1119th-August-2021_Business-Standard.pdf"
                          >
                            19th August 2021 Business Standard - 19 Aug 2021
                            <span className="media-date">19 Aug 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_27_2431st_July_2021_Conde_Nast_Traveller_National.pdf"
                          >
                            31st July 2021 Conde Nast Traveller National - 31
                            Jul 2021
                            <span className="media-date">31 Jul 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_25_1130th_June_2021_HAI_Magazine_National_1.pdf"
                          >
                            30th June 2021 HAI Magazine National (1) - 30 Jun
                            2021<span className="media-date">30 Jun 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_26_1930th-June-2021_BW-Hotelier.pdf"
                          >
                            30th June 2021 BW Hotelier - 30 Jun 2021
                            <span className="media-date">30 Jun 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_23_4430th-June-2021_HAIMagazine__National.pdf"
                          >
                            30th June 2021 HAI Magazine National - 30 Jun 2021
                            <span className="media-date">30 Jun 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_22_3017th_June_2021_The_Free_Press_Journal_Mumbai.pdf"
                          >
                            17th June 2021 The Free Press Journal Mumbai - 17
                            Jun 2021
                            <span className="media-date">17 Jun 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_20_349th_June_2021_The_Free_Press_Journal_Mumbai.pdf"
                          >
                            9th June 2021 The Free Press Journal_Mumbai - 09 Jun
                            2021<span className="media-date">09 Jun 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_19_075th_June_2021_The_Economic_Times_Ahmedabad.pdf"
                          >
                            5th June 2021 The Economic Times Ahmedabad - 05 Jun
                            2021<span className="media-date">05 Jun 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_18_2831st_May_2021_Aviation_Times_National.pdf"
                          >
                            31st May 2021 AviationTimes National - 31 May 2021
                            <span className="media-date">31 May 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_17_3425th-May-2021_TheStatesman__NewDelhi.pdf"
                          >
                            25th May 2021 The States man New Delhi - 25 May 2021
                            <span className="media-date">25 May 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_16_4013th-May-2021_TheHindu__Chennai.pdf"
                          >
                            13th May 2021 The Hindu Chennai - 13 May 2021
                            <span className="media-date">13 May 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_15_495th-May-2021_TheNewIndianExpress__Chennai.pdf"
                          >
                            5th May 2021 The New Indian Express Chennai - 05 May
                            2021<span className="media-date">05 May 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_14_281st-May-2021_TheTelegraph__Kolkata.pdf"
                          >
                            1st May 2021 The Telegraph Kolkata - 01 May 2021
                            <span className="media-date">01 May 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_13_0730th-April-2021_CondeNastTraveller__National.pdf"
                          >
                            30th-April-2021 Conde Nast Traveller National - 30
                            Apr 2021
                            <span className="media-date">30 Apr 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_12_0930th-April-2021_FortuneIndia__National.pdf"
                          >
                            30th-April-2021 Fortune India National - 30 Apr 2021
                            <span className="media-date">30 Apr 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_10_1220th-April-2021_TheEconomicTimes__NewDelhi.pdf"
                          >
                            20th-April-2021 The Economic Times New Delhi - 20
                            Apr 2021
                            <span className="media-date">20 Apr 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_58_0314th-April-2021_BusinessStandard__Mumbai.pdf"
                          >
                            14th-April-2021 Business Standard Mumbai - 14 Apr
                            2021<span className="media-date">14 Apr 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_57_346th-April-2021_TheEconomicTimes__NewDelhi.pdf"
                          >
                            6th-April-2021 The Economic Times New Delhi - 06 Apr
                            2021<span className="media-date">06 Apr 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_56_024th-April-2021_BusinessToday__National.pdf"
                          >
                            4th April 2021 Business Today National - 04 Apr 2021
                            <span className="media-date">04 Apr 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_55_3331st-March-2021_BusinessTraveller__National.pdf"
                          >
                            01st Feb 2021 to 31st Mar 2021 - 31 Mar 2021
                            <span className="media-date">31 Mar 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_54_4931st-January-2021_TravelTradeJournal__National.pdf"
                          >
                            31st January 2021 Travel Trade Journal National - 31
                            Jan 2021
                            <span className="media-date">31 Jan 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_54_4731st-January-2021_TravelTradeJournal__National.pdf"
                          >
                            31st January 2021 Travel Trade Journal National - 31
                            Jan 2021
                            <span className="media-date">31 Jan 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_54_0131st-January-2021_AviationTimes__National.pdf"
                          >
                            31st-January-2021_AviationTimes__National - 31 Jan
                            2021<span className="media-date">31 Jan 2021</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_53_2715th-January-2021_BottIndia__National-(1).pdf"
                          >
                            15th January 2021 BottIndia National - 15 Jan 2021
                            <span className="media-date">15 Jan 2021</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2020">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2020"
                    aria-expanded="false"
                    aria-controls="collapse2020"
                  >
                    2020
                  </button>
                </h5>
              </div>
              <div
                id="collapse2020"
                className="collapse"
                aria-labelledby="heading2020"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_54_5130th-November-2020_AviationTimes__National (1).pdf"
                          >
                            30th-November-2020_Aviation Times__National - 30 Nov
                            2020<span className="media-date">30 Nov 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_53_5730th-September-2020_AviationTimes__National.pdf"
                          >
                            30th-September-2020_Aviation Times__National - 30
                            Sep 2020
                            <span className="media-date">30 Sep 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_53_2730th-September-2020_TravelTradeJournal__National.pdf"
                          >
                            30th September 2020 Travel Trade Journal National -
                            30 Sep 2020
                            <span className="media-date">30 Sep 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_52_5013th-September-2020_TheEconomicTimes__Hyderabad.pdf"
                          >
                            13th-September-2020_The Economic Times__Hyderabad -
                            13 Sep 2020
                            <span className="media-date">13 Sep 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_52_1031st-August-2020_TravelTradeJournal__National.pdf"
                          >
                            31st-August-2020_Travel Trade Journal_National - 31
                            Aug 2020
                            <span className="media-date">31 Aug 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_51_3218th-August-2020_TheEconomicTimes__NewDelhi.pdf"
                          >
                            18th-August-2020_The Economic Times_NewDelhi - 18
                            Aug 2020
                            <span className="media-date">18 Aug 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_50_4414th-August-2020_FinancialExpress__Ahmedabad.pdf"
                          >
                            14th-August-2020_Financial Express_Ahmedabad - 14
                            Aug 2020
                            <span className="media-date">14 Aug 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_47_1313th-August-2020_SwatantraVaartha(Hindi)__Hyderabad.pdf"
                          >
                            13th-August-2020_Swatantra Vaartha(Hindi)_Hyderabad
                            - 13 Aug 2020
                            <span className="media-date">13 Aug 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_46_4113th-August-2020_VeerArjun(Hindi)__NewDelhi.pdf"
                          >
                            13th-August-2020_VeerArjun(Hindi)_NewDelhi - 13 Aug
                            2020<span className="media-date">13 Aug 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_47_4613th-August-2020_RashtriyaSahara(Hindi)__NewDelhi.pdf"
                          >
                            13th-August-2020_Rashtriya Sahara(Hindi)_NewDelhi -
                            13 Aug 2020
                            <span className="media-date">13 Aug 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_48_1813th-August-2020_Hindustan(Hindi)__NewDelhi.pdf"
                          >
                            13th-August-2020_Hindustan(Hindi)_NewDelhi - 13 Aug
                            2020<span className="media-date">13 Aug 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_49_0113th-August-2020_HariBhoomi(Hindi)__NewDelhi.pdf"
                          >
                            13th-August-2020_Hari Bhoomi(Hindi)_NewDelhi - 13
                            Aug 2020
                            <span className="media-date">13 Aug 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_49_5013th-August-2020_FinancialExpress__NewDelhi.pdf"
                          >
                            13th-August-2020_Financial Express_New Delhi - 13
                            Aug 2020
                            <span className="media-date">13 Aug 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_46_1731st-July-2020-The-Economic-Times.pdf"
                          >
                            31st-July-2020 - The Economic Times - 31 Jul 2020
                            <span className="media-date">31 Jul 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_45_3125th-July-2020-The-Hindu-Business-Line.pdf"
                          >
                            25th-July-2020 - The Hindu Business Line - 25 Jul
                            2020<span className="media-date">25 Jul 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_42_3419th-July-2020-Dalal-Street.pdf"
                          >
                            19th-July-2020 - Dalal Street - 19 Jul 2020
                            <span className="media-date">19 Jul 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_41_297th-July-2020-The-Economic-Times.pdf"
                          >
                            7th-July-2020 - The Economic Times - 07 Jul 2020
                            <span className="media-date">07 Jul 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_34_332nd-July-2020-The-Times-of-India.pdf"
                          >
                            2nd-July-2020 - The Times of India - 02 Jul 2020
                            <span className="media-date">02 Jul 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_33_5330th-June-2020_FHRAI.pdf"
                          >
                            30th June 2020 FHRAI - 30 Jun 2020
                            <span className="media-date">30 Jun 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_33_1330th-June-2020_Outlook-Traveller.pdf"
                          >
                            30th June 2020 Outlook Traveller - 30 Jun 2020
                            <span className="media-date">30 Jun 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_32_3530th-June-2020-Hotelier-India.pdf"
                          >
                            30th-June-2020 - Hotelier India - 30 Jun 2020
                            <span className="media-date">30 Jun 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_31_1130th-June-2020-Voyager-s-World.pdf"
                          >
                            30th-June-2020 - Voyager's World - 30 Jun 2020
                            <span className="media-date">30 Jun 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_29_3524th-June-2020_The-Economic-Times.pdf"
                          >
                            24th June 2020 The Economic Times - 24 Jun 2020
                            <span className="media-date">24 Jun 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_28_2711th-June-2020_Bott-India.pdf"
                          >
                            11th June 2020 Bott India - 11 Jun 2020
                            <span className="media-date">11 Jun 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_27_578th-June-2020_Business-Standard.pdf"
                          >
                            8th June 2020 Business Standard - 08 Jun 2020
                            <span className="media-date">08 Jun 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_27_318th-June-2020_WEALTH(The-Economic-Times).pdf"
                          >
                            8th June 2020 WEALTH(The Economic Times) - 08 Jun
                            2020<span className="media-date">08 Jun 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_27_023rd-June-2020_-The-Economic-Times.pdf"
                          >
                            3rd-June-2020_ The Economic Times - 03 Jun 2020
                            <span className="media-date">03 Jun 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_26_31The_Economic_Times_28_May_2020.pdf"
                          >
                            The Economic Times - 28 May 2020 - 28 May 2020
                            <span className="media-date">28 May 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_26_00Lonely-Planet-30th-April-2020.pdf"
                          >
                            {" "}
                            Lonely Planet - 30 Apr 2020
                            <span className="media-date">30 Apr 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_25_09OutlookTraveller-March-Issue.pdf"
                          >
                            OutlookTraveller - March Issue... - 31 Mar 2020
                            <span className="media-date">31 Mar 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_24_24The-Economic-Times-31st-March,-2020.pdf"
                          >
                            The Economic Times - 31st March, 2020.. - 31 Mar
                            2020<span className="media-date">31 Mar 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_23_57The-Hindu-31st-March,-2020.pdf"
                          >
                            The Hindu - 31st March, 2020.. - 31 Mar 2020
                            <span className="media-date">31 Mar 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_22_23The-Hindu-Business-Line-31st-March,-2020.pdf"
                          >
                            The Hindu Business Line - 31st March, 2020... - 31
                            Mar 2020
                            <span className="media-date">31 Mar 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_21_48Financial-Express-31st-March-2020.pdf"
                          >
                            Financial Express - 31st March, 2020... - 31 Mar
                            2020<span className="media-date">31 Mar 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_21_14The-Times-of-India-31st-March,-2020.pdf"
                          >
                            The Times of India - 31st March, 2020... - 31 Mar
                            2020<span className="media-date">31 Mar 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_20_39The-New-Indian-Express-6th-March,-2020.pdf"
                          >
                            The New Indian Express - 6th March, 2020... - 06 Mar
                            2020<span className="media-date">06 Mar 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_19_29Mail-Today-1st-March,-2020.pdf"
                          >
                            Mail Today - 1st March, 2020... - 01 Mar 2020
                            <span className="media-date">01 Mar 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_18_48Business-Traveller__29th-Feb.pdf"
                          >
                            Business Traveller__29th Feb - 29 Feb 2020
                            <span className="media-date">29 Feb 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_14_58Hospitality-Biz-29th-Feb.pdf"
                          >
                            Hospitality Biz - 29th Feb - 29 Feb 2020
                            <span className="media-date">29 Feb 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_12_47Business-Traveller__29th-Feb.pdf"
                          >
                            TodaysTraveller - 29th February - 29 Feb 2020
                            <span className="media-date">29 Feb 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_11_41Business-Standard-28th-February.pdf"
                          >
                            Business Standard - 28th February - 28 Feb 2020
                            <span className="media-date">28 Feb 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_11_03Outlook-Traveller-January-2020.pdf"
                          >
                            Outlook Traveller - January 2020 - 31 Jan 2020
                            <span className="media-date">31 Jan 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_10_24Business-Traveller-January-2020.pdf"
                          >
                            Business Traveller - January 2020 - 31 Jan 2020
                            <span className="media-date">31 Jan 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_09_47BW-Hotelier-2020.pdf"
                          >
                            BW Hotelier 2020 - 31 Jan 2020
                            <span className="media-date">31 Jan 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_07_42Hospitality-Biz-January-2020.pdf"
                          >
                            Hospitality Biz - January 2020 - 31 Jan 2020
                            <span className="media-date">31 Jan 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_05_43Hotelier-India-January-2020.pdf"
                          >
                            Hotelier India - January 2020 - 31 Jan 2020
                            <span className="media-date">31 Jan 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_04_57Mail-Today-19th-January-2020.pdf"
                          >
                            Mail Today - 19th January 2020 - 19 Jan 2020
                            <span className="media-date">19 Jan 2020</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_04_04_22Free-Press-Journal-2nd-January-2020.pdf"
                          >
                            Free Press Journal - 2nd January 2020 - 02 Jan 2020
                            <span className="media-date">02 Jan 2020</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2019">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2019"
                    aria-expanded="false"
                    aria-controls="collapse2019"
                  >
                    2019
                  </button>
                </h5>
              </div>
              <div
                id="collapse2019"
                className="collapse"
                aria-labelledby="heading2019"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_33_24Hotelier-India,-Page-no-14.pdf"
                          >
                            Hotelier India, Page no. 14 - 31 Dec 2019
                            <span className="media-date">31 Dec 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_07_19_54Where-to-Stay-Conde-Nast-Traveller31-DEC-2019.pdf"
                          >
                            Where to Stay - Conde Nast Traveller, Page no. 98 -
                            31 Dec 2019
                            <span className="media-date">31 Dec 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_31_38Business-Standard.pdf"
                          >
                            Business-Standard - 22 Dec 2019
                            <span className="media-date">22 Dec 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_31_13Hotelier-India,-(30-November-issue).pdf"
                          >
                            Hotelier India, (30 November issue) - 30 Nov 2019
                            <span className="media-date">30 Nov 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_30_13Outlook-Business,-(22-11-19).pdf"
                          >
                            Outlook Business, (22.11.19) - 22 Nov 2019
                            <span className="media-date">22 Nov 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_28_21The-Hindu-Business-Line,-(16-11-19).pdf"
                          >
                            The Hindu Business Line, (16.11.19) - 16 Nov 2019
                            <span className="media-date">16 Nov 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_26_52Mail-Today,-(10-11-19).pdf"
                          >
                            Mail Today, (10.11.19) - 10 Nov 2019
                            <span className="media-date">10 Nov 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_27_21The-Telegraph,-(10-11-19).pdf"
                          >
                            The Telegraph, (10.11.19) - 10 Nov 2019
                            <span className="media-date">10 Nov 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_27_50The-Hindu,-(10-11-19).pdf"
                          >
                            The Hindu, (10.11.19) - 10 Nov 2019
                            <span className="media-date">10 Nov 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_25_54Business-Standard,-(4-11-19).pdf"
                          >
                            Business Standard, (4.11.19) - 04 Nov 2019
                            <span className="media-date">04 Nov 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_26_25The-Economic-Times,-(4-11-19).pdf"
                          >
                            The Economic Times, (4.11.19) - 04 Nov 2019
                            <span className="media-date">04 Nov 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_25_20Business-Standard,-(2-11-19).pdf"
                          >
                            Business Standard, (2.11.19) - 02 Nov 2019
                            <span className="media-date">02 Nov 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_24_46The-Statesman-(Evolve).pdf"
                          >
                            The Statesman (Evolve) - 27 Oct 2019
                            <span className="media-date">27 Oct 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_23_53The-Times-of-India.pdf"
                          >
                            The Times of India - 26 Oct 2019
                            <span className="media-date">26 Oct 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_23_17The-Telegraph.pdf"
                          >
                            The Telegraph - 24 Oct 2019
                            <span className="media-date">24 Oct 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_22_38The-Telegraph-T2.pdf"
                          >
                            The Telegraph-T2 - 23 Oct 2019
                            <span className="media-date">23 Oct 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_06_54_24HotelierIndia_September_Issue.pdf"
                          >
                            Hotelier-India-(September-Issue) - 30 Sep 2019
                            <span className="media-date">30 Sep 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_07_03_27HotelierIndia_September_Issue.pdf"
                          >
                            Hotelier India (September Issue) - 30 Sep 2019
                            <span className="media-date">30 Sep 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_17_05The-Financial-Express.pdf"
                          >
                            The Financial Express (16.09.19) - 16 Sep 2019
                            <span className="media-date">16 Sep 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_16_27DNA.pdf"
                          >
                            DNA 12.09.19 - 12 Sep 2019
                            <span className="media-date">12 Sep 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_13_41Travel-Trends-Today.pdf"
                          >
                            Travel Trends Today... - 31 Aug 2019
                            <span className="media-date">31 Aug 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_14_15Todays-Traveller.pdf"
                          >
                            Todays Traveller... - 31 Aug 2019
                            <span className="media-date">31 Aug 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_15_35Hotelier-India.pdf"
                          >
                            Hotelier India... - 31 Aug 2019
                            <span className="media-date">31 Aug 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_11_51Hospitality-Biz,-July-Issue,-2019.pdf"
                          >
                            Hospitality Biz, July Issue, 2019... - 31 Jul 2019
                            <span className="media-date">31 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_12_37Food-and-Hospitality-World,-July-Issue,-2019.pdf"
                          >
                            Food and Hospitality World, July Issue, 2019... - 31
                            Jul 2019
                            <span className="media-date">31 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_19_02Hospitality-Biz,-July-Issue,-2019.pdf"
                          >
                            Hospitality Biz, July Issue, 2019... - 31 Jul 2019
                            <span className="media-date">31 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_07_08_37Hotelier_India_July_Issue2019.pdf"
                          >
                            Hotelier India, July Issue, 2019 - 06 Jul 2019
                            <span className="media-date">06 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_53_29DNA-DNA-Money,-Mumbai-July-02,-2019 (1).pdf"
                          >
                            DNA-DNA Money, Mumbai. July 02, 2019... - 02 Jul
                            2019<span className="media-date">02 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_54_13DNA-DNA-Money,-Mumbai-July-02,-2019.pdf"
                          >
                            DNA - DNA Money, Mumbai. July 02, 2019... - 02 Jul
                            2019<span className="media-date">02 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_08_22Business-Standard,-Mumbai.pdf"
                          >
                            Business Standard, Mumbai, July 02, 2019... - 02 Jul
                            2019<span className="media-date">02 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_09_11Humara-Mahanagar,-Mumbai-July-02,-2019.pdf"
                          >
                            Humara Mahanagar, Mumbai. July 02, 2019... - 02 Jul
                            2019<span className="media-date">02 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_14_11Business-Standard,-Mumbai,-July-02,-2019.pdf"
                          >
                            Business Standard, Mumbai, July 02, 2019... - 02 Jul
                            2019<span className="media-date">02 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_15_00Humara-Mahanagar,-Mumbai-July-02,-2019.pdf"
                          >
                            Humara Mahanagar, Mumbai. July 02, 2019... - 02 Jul
                            2019<span className="media-date">02 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_52_41Travel-and-Hospitality,-Nationly,-July-Issue.pdf"
                          >
                            Travel and Hospitality, Nationly, July Issue... - 01
                            Jul 2019
                            <span className="media-date">01 Jul 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_52_14Navbharat,-Mumbai.pdf"
                          >
                            Navbharat, Mumbai... - 30 Jun 2019
                            <span className="media-date">30 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_48_39The-Hindu,-Mumbai.pdf"
                          >
                            The Hindu, Mumbai.,. - 29 Jun 2019
                            <span className="media-date">29 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_49_15The-Hindu,-Hyderabad.pdf"
                          >
                            The Hindu, Hyderabad... - 29 Jun 2019
                            <span className="media-date">29 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_49_48The-Hindu,-Delhi.pdf"
                          >
                            The Hindu, Delhi... - 29 Jun 2019
                            <span className="media-date">29 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_50_28The-Hindu,-Chennai.pdf"
                          >
                            The Hindu, Chennai... - 29 Jun 2019
                            <span className="media-date">29 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_51_03The-Hindu,-Bangalore.pdf"
                          >
                            The Hindu, Bangalore... - 29 Jun 2019
                            <span className="media-date">29 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_51_37Saamna,-Mumbai.pdf"
                          >
                            Saamna, Mumbai... - 29 Jun 2019
                            <span className="media-date">29 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_18_52Mumbai-Samachar-(Gujarati),-Mumbai.pdf"
                          >
                            Mumbai Samachar (Gujarati), Mumbai... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_19_20Loksatta,-Mumbai.pdf"
                          >
                            Loksatta, Mumbai... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_19_53TOI,-Mumbai.pdf"
                          >
                            TOI, Mumbai... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_25_04TOI,-Kolkata.pdf"
                          >
                            TOI, Kolkata... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_27_05TOI,-Hyderabad.pdf"
                          >
                            TOI, Hyderabad... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_29_18TOI,-Delhi.pdf"
                          >
                            TOI, Delhi... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_44_59TOI,-Chennai.pdf"
                          >
                            TOI, Chennai... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_46_32TOI,-Bangalore.pdf"
                          >
                            TOI, Bangalore... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_47_05Business-Standard,-Mumbai.pdf"
                          >
                            Business Standard, Mumbai... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_47_37Business-Standard,-Delhi.pdf"
                          >
                            Business Standard, Delhi... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_48_07Business-Standard-(Hindi),-Delhi.pdf"
                          >
                            Business Standard (Hindi), Delhi... - 28 Jun 2019
                            <span className="media-date">28 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_18_25The-New-Indian-Express,-Chennai.pdf"
                          >
                            The New Indian Express, Chennai... - 13 Jun 2019
                            <span className="media-date">13 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_17_45Mint,-National.pdf"
                          >
                            Mint, National... - 05 Jun 2019
                            <span className="media-date">05 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_17_11DNA-DNA-Money,-Mumbai.pdf"
                          >
                            DNA-DNA Money, Mumbai... - 03 Jun 2019
                            <span className="media-date">03 Jun 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_14_21Rashtriya-Sahara-26-April-Formers-IIT-student-promises-Rs-20cr-for-r.pdf"
                          >
                            Rashtriya Sahara - 26 April - Formers IIT student
                            promises Rs 20cr for r... - 26 Apr 2019
                            <span className="media-date">26 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_14_55Punjab-Kesari-26-April-Ex-Student-of-IIT-Delhi-donate-Rs-20-crore.pdf"
                          >
                            Punjab Kesari - 26 April - Ex Student of IIT Delhi
                            donate Rs 20 crore... - 26 Apr 2019
                            <span className="media-date">26 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_15_37Navbharat-Times-26-April-IIT-will-get-Rs-20-crore-by-alumnus.pdf"
                          >
                            Navbharat Times - 26 April - IIT will get Rs 20
                            crore by alumnus... - 26 Apr 2019
                            <span className="media-date">26 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_16_18Hindustan-Times-26-April-IIT-D-alumnus-promises-Rs-20cr-for-research.pdf"
                          >
                            Hindustan Times - 26 April - IIT-D alumnus promises
                            Rs 20cr for research... - 26 Apr 2019
                            <span className="media-date">26 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_16_42Dainik-Jagran-26-April-Former-IIT-student-donates-Rs-20-cr.pdf"
                          >
                            Dainik Jagran - 26 April - Former IIT student
                            donates Rs. 20 cr... - 26 Apr 2019
                            <span className="media-date">26 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_13_51Mint-20-April-Hospitality-firms-eye-unbranded-hotel-space-to-expand.pdf"
                          >
                            Mint - 20 April - Hospitality firms eye unbranded
                            hotel space to expand ... - 20 Apr 2019
                            <span className="media-date">20 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_13_19Financial-Express-17-Apr-Goa-hospitality-industry-betting-big-on-MIC.pdf"
                          >
                            Financial Express - 17 Apr - Goa hospitality
                            industry betting big on MIC... - 17 Apr 2019
                            <span className="media-date">17 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_12_39Business-Standard-15-April-Better-Days-Ahead-For-Lemon-tree.pdf"
                          >
                            Business Standard - 15 April - Better Days Ahead For
                            Lemon tree... - 15 Apr 2019
                            <span className="media-date">15 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_12_00HT-Estates-13-April-Lemon-Tree-to-enter-upscale-hotel-segment.pdf"
                          >
                            HT Estates - 13 April - Lemon Tree to enter upscale
                            hotel segment - 13 Apr 2019
                            <span className="media-date">13 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_11_20Economic-Times-(Hindi)-12-April-Pricing-power-of-hotel-companies-inc.pdf"
                          >
                            Economic Times (Hindi) - 12 April - Pricing power of
                            hotel companies inc... - 12 Apr 2019
                            <span className="media-date">12 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_10_47Economic-Times-10-April-Embracing-digitisation-for-global-dominance.pdf"
                          >
                            Economic Times - 10 April - Embracing digitisation
                            for global dominance - 10 Apr 2019
                            <span className="media-date">10 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_10_03Mint-8-April-Lemon-Tree-to-enter-upscale-hotel-segment.pdf"
                          >
                            Mint - 8 April - Lemon Tree to enter upscale hotel
                            segment - 08 Apr 2019
                            <span className="media-date">08 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_09_29Economic-Times-5-Apr-Hotel-Cos-Get-Back-Pricing-Power-as-Demand-Rise.pdf"
                          >
                            Economic Times - 5 Apr - Hotel Cos Get Back Pricing
                            Power as Demand Rise... - 05 Apr 2019
                            <span className="media-date">05 Apr 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_06_57Indian-Hospitality-Sector-may-see-many-more-deals-in-2019-TheEconomicTIm.pdf"
                          >
                            Indian Hospitality Sector may see many more deals in
                            2019-TheEconomicTIm... - 25 Mar 2019
                            <span className="media-date">25 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_06_08Govt-is-giving-importance-to-disabled-people-development-Sanjevani-March.pdf"
                          >
                            Govt is giving importance to disabled people
                            development-Sanjevani-March... - 24 Mar 2019
                            <span className="media-date">24 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_05_09Acquisition-in-line-with-growth-aspirations-FinancialExpress-March19,201.pdf"
                          >
                            Acquisition in line with growth
                            aspirations-FinancialExpress-March19,201... - 19 Mar
                            2019<span className="media-date">19 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_06_10_46Bed,-Breakfast-App-TheEconomicTimesMagazine-March17,2019.pdf"
                          >
                            Bed, Breakfast &amp;
                            App-TheEconomicTimesMagazine-March17,2019 - 17 Mar
                            2019<span className="media-date">17 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_06_36_37Bed_Breaking_App_ETMagazine_March_17_2019.pdf"
                          >
                            Bed, Breaking &amp; App -ETMagazine-March17,2019 -
                            17 Mar 2019
                            <span className="media-date">17 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_55_56Lemon-Tree-to-buy-Key-Hotels-from-Berggruen-The-Economic-Times-March.pdf"
                          >
                            Lemon Tree to buy Key Hotels from Berggruen - The
                            Economic Times - March... - 15 Mar 2019
                            <span className="media-date">15 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_56_33Lemon-Tree-Keys-deal-is-economically-lucrative-Lemon-Tree-Hotels-Chairm.pdf"
                          >
                            Lemon Tree Keys deal is economically lucrative-
                            Lemon Tree Hotels Chairm... - 15 Mar 2019
                            <span className="media-date">15 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_57_12Lemon-Tree-signs-deal-to-buy-berggruen-s-Key-Hotels-DNA-March15,2019.pdf"
                          >
                            Lemon Tree signs deal to buy berggruen's Key Hotels
                            - DNA-March15,2019 - 15 Mar 2019
                            <span className="media-date">15 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_55_16Sensex-Surges-482-Points-Vyaapar-March13,2019.pdf"
                          >
                            Sensex Surges 482 Points-Vyaapar-March13,2019 - 13
                            Mar 2019
                            <span className="media-date">13 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_54_46Most-IPOs-of-FY19-in-the-green-BusinessStandard-March11,2019.pdf"
                          >
                            Most IPOs of FY19 in the
                            green-BusinessStandard-March11,2019 - 11 Mar 2019
                            <span className="media-date">11 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_54_19Lemon-Tree-Maintain-add-with-fair-value-of-Rs-80-per-share-FinancialEx.pdf"
                          >
                            Lemon Tree - Maintain add with fair value of Rs 80
                            per share-FinancialEx... - 07 Mar 2019
                            <span className="media-date">07 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_53_47Lemon-Tree-spreads-its-branches-TravTalk-March01,2019.pdf"
                          >
                            Lemon Tree spreads its branches -
                            TravTalk-March01,2019 - 01 Mar 2019
                            <span className="media-date">01 Mar 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_53_04Aviation-Times-Feb-22-Lemon-Tree-Hotels-launches-its-second-property.pdf"
                          >
                            Aviation Times - Feb 22 - Lemon Tree Hotels launches
                            its second property... - 22 Feb 2019
                            <span className="media-date">22 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_51_46Aviation-Times-21-Feb-Lemon-Tree-Hotels-and-Warburg-Pincus-enters-in.pdf"
                          >
                            Aviation Times - 21 Feb - Lemon Tree Hotels and
                            Warburg Pincus enters in... - 21 Feb 2019
                            <span className="media-date">21 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_52_17This-isn-t-an-appropriate-time-to-check-into-the-Lemon-Tree-Hotels-stock-Mint-Na"
                          >
                            This isn't an appropriate time to check into the
                            Lemon Tree Hotels' stock... - 21 Feb 2019
                            <span className="media-date">21 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_50_37Feb-20-Voyagers-World-Lemon-Tree-joins-hands-with-Warburg-Pincus.pdf"
                          >
                            Feb 20 - Voyagers World - Lemon Tree joins hands
                            with Warburg Pincus - 20 Feb 2019
                            <span className="media-date">20 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_51_15Feb-20-Hotelier-India-Feb-25Lemon-Tree-enters-the-vibrant-co-living-s.pdf"
                          >
                            Feb 20 - Hotelier India- Feb 25Lemon Tree enters the
                            vibrant co-living s... - 20 Feb 2019
                            <span className="media-date">20 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_49_45Travelscapes-Feb-19-Lemon-Tree-Hotels-launches-its-second-property-in-Pune.pdf"
                          >
                            Travelscapes-Feb 19-Lemon Tree Hotels launches its
                            second property in Pune - 19 Feb 2019
                            <span className="media-date">19 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_30_49Lemon-Tree-to-up-inventory-Telangana-Today-Hyderabad-18-Feb-19.pdf"
                          >
                            Lemon Tree to up inventory-Telangana Today-Hyderabad
                            - 18 Feb 2019
                            <span className="media-date">18 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_31_18Lemon-Tree-to-expand-Rashtriya-Sahara-Delhi-18-Feb-19.pdf"
                          >
                            Lemon Tree to expand-Rashtriya Sahara-Delhi... - 18
                            Feb 2019
                            <span className="media-date">18 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_41_45Lemon-Tree-Hotels-to-have-12,000-rooms-in-inventory-by-2021-end-Business-Standar"
                          >
                            Lemon Tree Hotels to have 12,000 rooms in inventory
                            by 2021-end-Business... - 18 Feb 2019
                            <span className="media-date">18 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_43_42Lemon-Tree-Hotels-to-have-12,000-rooms-in-inventory-by-2021-end-Business-Standar"
                          >
                            Lemon Tree Hotels to have 12,000 rooms in inventory
                            by 2021-end-Business... - 18 Feb 2019
                            <span className="media-date">18 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_44_16Lemon-Tree-Hotels-to-have-12,000-rooms-by-2021-end-Hindustan-Times-Mumbai-18-Feb"
                          >
                            Lemon Tree Hotels to have 12,000 rooms by
                            2021-end-Hindustan Times-Mumbai... - 18 Feb 2019
                            <span className="media-date">18 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_45_22Lemon-Tree-Hotels-to-have-12,000-rooms-by-2021-end-Hindustan-Times-Delhi-18-Feb-"
                          >
                            Lemon Tree Hotels to have 12,000 rooms by
                            2021-end-Hindustan Times-Delhi... - 18 Feb 2019
                            <span className="media-date">18 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_46_23Laman-Tree-will-have-12,000-rooms-by-2021-The-Economic-Times-(Hindi)-Delhi-18-Fe"
                          >
                            Lemon Tree will have 12,000 rooms by 2021-The
                            Economic Times (Hindi)-Delhi... - 18 Feb 2019
                            <span className="media-date">18 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_47_01Lemon-Tree-Hotels-to-have-12,000-rooms-by-2021-end-Business-Standard-Delhi-18-Fe"
                          >
                            Lemon Tree Hotels to have 12,000 rooms by
                            2021-end-Business Standard-Delhi... - 18 Feb 2019
                            <span className="media-date">18 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_49_101.pdf"
                          >
                            Lemon Tree Hotels to have 12,000 rooms by
                            2021-end-Business Standard... - 18 Feb 2019
                            <span className="media-date">18 Feb 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_06_40_40Dogs-Pups-Check-in-with-your-furry-travellers-to-Lemon-Tree-Hotel-31st-Jan-2019.pdf"
                          >
                            Dogs &amp; Pups-Check in with your furry travellers
                            to Lemon Tree Hotel... - 31 Jan 2019
                            <span className="media-date">31 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_27_44Lemon-Tree,-Hamstede-to-unveil-co-living-prototype-by-March-DNA-DNA-Money-Mumbai"
                          >
                            Lemon Tree, Hamstede to unveil co-living prototype
                            by March- DNA - DNA... - 04 Jan 2019
                            <span className="media-date">04 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_28_51Lemon-Tree,-Hamstede-to-unveil-co-living-prototype-by-March-DNA-DNA-Money-Delhi-"
                          >
                            Lemon Tree, Hamstede to unveil co-living prototype
                            by March- DNA-DNA... - 04 Jan 2019
                            <span className="media-date">04 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_27_03Warburg-Lemon-Tree-to-prepare-1-lakh-bed-Business-Standard-(Hindi)-Delhi-2-Jan-1"
                          >
                            Warburg-Lemon Tree to prepare 1 lakh bed- Business
                            Standard (Hindi)- Delhi... - 02 Jan 2019
                            <span className="media-date">02 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_01_59Warburg-Pincus-LemonTree-Hotels-targets-100,000-beds-in-co-living-biz-Business-S"
                          >
                            Warburg-Pincus-LemonTree-Hotels-targets-100,000-beds-in-co-living-biz-Business-Standard-Bangalore
                            - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_03_32Warburg-Pincus-Lemon-Tree-Hotels-targets100,000beds-in-co-living-biz-Business-St"
                          >
                            Warburg-Pincus-Lemon-Tree-Hotels-targets100,000beds-in-co-living-biz-Business-Standard-Delhi-1-Jan-19
                            - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_04_54Warburg-Pincus-Lemon-Tree-Hotels-targets100,000-beds-in-co-living-Business-Stand"
                          >
                            Warburg-Pincus-Lemon-Tree-Hotels-targets100,000-beds-in-co-living-Business-Standard-Mumbai
                            - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_06_03Warburg-Pincus-Lemon-Tree-Hotels-targets100,000-beds-in-co-living-biz-Business-S"
                          >
                            Warburg-Pincus-Lemon-Tree-Hotels-targets100,000-beds-in-co-living-biz-Business-Standard-Kolkata
                            - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_07_05Warburg-Pincus-Lemon-Tree-Hotels-targets-100,000-beds-in-co-living-biz-Business-"
                          >
                            Warburg-Pincus-Lemon-Tree-Hotels-targets-100,000-beds-in-co-living-biz-Business-Standard-Hyderabad
                            - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_18_10Warburg-Pincus-Lemon-Tree-Hotels-targets-100,000-beds-in-co-living-biz-Business-"
                          >
                            Warburg-Pincus-Lemon-Tree-Hotels-targets-100,000-beds-in-co-living-biz-Business-Standard-Chennai
                            - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_19_20UNSUNG-HERO-OF-THE-YEAR-WINNER-MANOJ-PR-Hotelier-India-National-1-Jan-19.pdf"
                          >
                            UNSUNG HERO OF THE YEAR-WINNER MANOJ PR- Hotelier
                            India - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_19_46Stay-in-Lemon-Tree-The-Economic-Times-(Hindi)-Delhi-1-Jan-19.pdf"
                          >
                            Stay in Lemon Tree- The Economic Times (Hindi)-
                            Delhi... - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_20_21MARKETING-PERSON-OF-THE-YEAR-WINNER-AKRITI-ARORA-Hotelier-India-National-1-Jan-1"
                          >
                            MARKETING PERSON OF THE YEAR-WINNER AKRITI ARORA-
                            Hotelier India... - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_20_48Lemon-Tree-launches-second-properly-in-Pune-Travel-and-Hospitality-National-1-Ja"
                          >
                            Lemon Tree launches second properly in Pune- Travel
                            and Hospitality - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_26_20F-B-MANAGER-OF-THE-YEAR-WINNER-AMIT-FORDELL-Hotelier-India-National-1-Jan-19.pdf"
                          >
                            F&amp;B MANAGER OF THE YEAR-WINNER AMIT
                            FORDELL-Hotelier India - 01 Jan 2019
                            <span className="media-date">01 Jan 2019</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2018">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2018"
                    aria-expanded="false"
                    aria-controls="collapse2018"
                  >
                    2018
                  </button>
                </h5>
              </div>
              <div
                id="collapse2018"
                className="collapse"
                aria-labelledby="heading2018"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_17_45Warburg-Pincus-Lemon-Tree-Hotels-Dec-31.pdf"
                          >
                            Warburg Pincus-Lemon Tree Hotels targets 100000 beds
                            in co-living biz - 31 Dec 2018
                            <span className="media-date">31 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_16_54Lemon-Tree-spikes-on-JV-deal-The-Hindu-Business-Line-National-29-Dec-18.pdf"
                          >
                            Lemon Tree spikes on JV deal- The Hindu Business
                            Line- National - 29 Dec 2018
                            <span className="media-date">29 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_12_58_07Lemon-Tree,-United-bank-shares-up-in-market-Vaartha-Hyderabad-29-Dec-18.pdf"
                          >
                            Lemon Tree, United bank shares up in market-
                            Vaartha- Hyderabad - 29 Dec 2018
                            <span className="media-date">29 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_34_04Warburg_in_RS_3000_cr_JV_with_Lemon_Tree_to_offer_co_living_spaces_28-Dec-18.pdf"
                          >
                            Warburg in RS3,000-cr J V with Lemon Tree to offer
                            co-living spaces - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_58_42Lemon-Tree-in-PE-pact-The-Telegraph-Kolkata-28-Dec-18.pdf"
                          >
                            Lemon Tree in PE pact- The Telegraph- Kolkata - 28
                            Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_57_55LemonTreeHotelsWarburgPincusformnewventureDNADNAMoneyMumbai-28Dec18.pdf"
                          >
                            Lemon Tree Hotels, Warburg Pincus form new
                            venture-DNA-DNA Money Mumbai - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_56_19LemonTreeHotelsWarburgPincusformnewventure_DNA_Money_Delhi28-Dec-18.pdf"
                          >
                            Lemon Tree Hotels, Warburg Pincus form new venture-
                            DNA-DNA Money-Delhi - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_54_34Lemon_Tree_HotelsRs_3K_crFree_Press_Journal_Mumbai_28-Dec-18.pdf"
                          >
                            Lemon Tree Hotels, Warbug JV lines up Rs 3K-cr
                            investment- The Free Press - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_52_01Lemon-tree-hotels-Warburg-Pincus-JV-Sakshi-Hyderabad-28-Dec-18.pdf"
                          >
                            Lemon tree hotels Warburg Pincus JV- Sakshi-
                            Hyderabad - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_51_06Lemon-Tree-Hotels-Eenadu-Hyderabad-28-Dec-18.pdf"
                          >
                            Lemon Tree Hotels- Eenadu- Hyderabad - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_49_03Lemon_Tree_Forms_JV_Times_Mumbai-28-Dec-18.pdf"
                          >
                            Lemon Tree Forms JV with Warburg Pincus Arm-The
                            Economic Times-Mumbai - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_46_15Lemon_Tree_Forms_JVThe_Economic_Times_Delhi28_Dec_18.pdf"
                          >
                            Lemon Tree Forms JV with Warburg Pincus Arm-The
                            Economic Times-Delhi - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_40_52Lemon_Tree_Forms_JV_with_bangalore28_Dec_18.pdf"
                          >
                            Lemon Tree Forms JV with Warburg Pincus Arm-The
                            Economic Times Bangalore - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_36_57Lemon_Tree_forms_JV_with_Warbug_Pincus_The_Statesman_Kolkata28-Dec-18.pdf"
                          >
                            Lemon Tree forms JV with Warbug Pincus-The
                            Statesman-Kolkata - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_35_47Lemon_Tree_forms_JV_with_Warbug_Pincus_The_Statesman_Delhi_28-Dec-18.pdf"
                          >
                            Lemon Tree forms JV with Warbug Pincus-The
                            Statesman-Delhi - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_27_41Lemon_Tree_Warbug_Mumbai-28_Dec_18.pdf"
                          >
                            Lemon Tree, Warbug Pincus form JV, envisage
                            Rs.3,000-crore investment - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_21_11Lemon_Tree_WarbugDelhi_28_Dec_18.pdf"
                          >
                            Lemon Tree, Warbug Pincus form JV, envisage
                            Rs.3,000-crore investment - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_03_12_58Lemon_Tree_Warbug_Pincus_form_JV_Chennai_28_Dec_18.pdf"
                          >
                            Lemon Tree,Warbug Pincus form JV,envisage
                            Rs.3,000-crore investment - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_49_35Lemon_Tree_Warbug_Pincus_form_JV_Kolkata-28_Dec_18.pdf"
                          >
                            Lemon Tree, Warbug Pincus form JV, envisage INR
                            3,000-crore investment - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_42_23Lemon_Tree_Warbug_Bangalore_28_Dec_18.pdf"
                          >
                            Lemon Tree, Warbug form JV envisage Rs 30-bn
                            investment-Business - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_37_18Lemon_Tree_Warbug_form_JV_envisage_Rs_30_bn_investment_Business_28-Dec-18.pdf"
                          >
                            Lemon Tree, Warbug form JV, envisage Rs.30-bn
                            investment-Business - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_34_04Lemon_Tree_Warbug_Mumbai_28-Dec-18.pdf"
                          >
                            Lemon Tree, Warbug form JV, envisage Rs 30-bn
                            investment- Business - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_30_15Lemon_Tree_Warbug_Business_Standard_Kolkata-28-Dec-18.pdf"
                          >
                            {" "}
                            Lemon Tree, Warbug form JV, envisage Rs 30-bn
                            investment- Business - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_24_55Lemon-Tree_StandardChennai-28-Dec-18.pdf"
                          >
                            {" "}
                            Lemon Tree, Warbug form JV, envisage Rs 30-bn
                            investment - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_24_48Lemon_Tree_Warbug_Standard_Delhi-28-Dec-18.pdf"
                          >
                            Lemon Tree, Warbug form JV, envisage Rs 30-bn
                            investment - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_01_03_13Lemon-tree,-Warbuf-investment-Financial-Express-Bangalore-28-Dec-18.pdf"
                          >
                            Lemon tree, Warbuf Pincus from JV, envisage Rs
                            3,000-crore investment - 28 Dec 2018
                            <span className="media-date">28 Dec 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_52_30Lemon-Tree-Hotels-Valuations-are-rich-and-seem-fully-occupied.pdf"
                          >
                            Lemon Tree Hotels Valuations are rich and seem fully
                            occupied - 22 Nov 2018
                            <span className="media-date">22 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_51_46Lemon-Tree-Premier.pdf"
                          >
                            Lemon Tree Premier - 22 Nov 2018
                            <span className="media-date">22 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_51_12Hospitality-Biz-india-Lemon-Tree-Hotel-signs-first-hotel-in-Dubai.pdf"
                          >
                            Hospitality Biz india - Lemon Tree Hotel signs first
                            hotel in Dubai - 09 Nov 2018
                            <span className="media-date">09 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_50_37Economic-Times-Lemon-Tree-Hotel-first-signs-its-first-property-in-Dubai.pdf"
                          >
                            Economic Times - Lemon Tree Hotel first signs its
                            first property in Dubai - 08 Nov 2018
                            <span className="media-date">08 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_50_02Khaleej-Times-Indian-mid-sector-hotel-brand-in-Dubai-foray.pdf"
                          >
                            Khaleej Times - Indian mid-sector hotel brand in
                            Dubai foray - 07 Nov 2018
                            <span className="media-date">07 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_49_32Dehradun-Times-Red-Fox-Hotel-debuts-in-Dehradun.pdf"
                          >
                            Dehradun Times - Red Fox Hotel debuts in Dehradun -
                            01 Nov 2018
                            <span className="media-date">01 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_49_03Rashtriya-Sahara-Red-Fox-Hotel-debuts-in-Dehradun.pdf"
                          >
                            Rashtriya Sahara - Red Fox Hotel debuts in Dehradun
                            - 01 Nov 2018
                            <span className="media-date">01 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_48_18Veer-Arjun-Red-Fox-Hotel-debuts-in-Dehradun.pdf"
                          >
                            Veer Arjun- Red Fox Hotel debuts in Dehradun - 01
                            Nov 2018
                            <span className="media-date">01 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_47_47Lemon-Tree-Hotel,-Aurangabad-wins-National-Tourism-Award-2016-17-for-the-Best-3-Star-Hotel.pdf"
                          >
                            Lemon Tree Hotel, Aurangabad wins National Tourism
                            Award 2016-17 - 01 Nov 2018
                            <span className="media-date">01 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_47_05Red-Fox-Hotel-debuts-in-Dehradun.pdf"
                          >
                            Red Fox Hotel debuts in Dehradun - 01 Nov 2018
                            <span className="media-date">01 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_46_25HRA-Today-Lemon-Tree-Aurangabad-wins-National-Tourism-Award.pdf"
                          >
                            HRA Today - Lemon Tree Aurangabad wins National
                            Tourism Award - 01 Nov 2018
                            <span className="media-date">01 Nov 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_42_58Secret-Diaries-of-an-Entrepreneur.pdf"
                          >
                            Secret Diaries of an Entrepreneur - 26 Oct 2018
                            <span className="media-date">26 Oct 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_40_12Hotel-chains-set-to-hike-room-rates-by-up-to-20-DNA-DNA-Money,-Delhi,-8-Oct-18.pdf"
                          >
                            Hotel chains set to hike room rates by up to
                            20%-DNA-DNA Money - 08 Oct 2018
                            <span className="media-date">08 Oct 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_38_44LEMON-TREE-HOTEL,-AURANGABAD-WINS-NATIONAL-TOURISM-AWARD-The-Pioneer,-Delhi,-4-Oct-18.pdf"
                          >
                            LEMON TREE HOTEL, AURANGABAD WINS NATIONAL TOURISM
                            AWARD - 04 Oct 2018
                            <span className="media-date">04 Oct 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_38_02Deccan-Chronicles-Goa-to-Kochi-on-cycle.pdf"
                          >
                            Deccan Chronicles - Goa to Kochi on cycle - 05 Sep
                            2018<span className="media-date">05 Sep 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_36_54Patu-Keswani-Hotelier-India,-National,-1-Sep-18.pdf"
                          >
                            Patu Keswani- Hotelier India, National - 01 Sep 2018
                            <span className="media-date">01 Sep 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_32_39Rattan_Keswani_Hotelier_India_National_1_SEP_2018.pdf"
                          >
                            Rattan Keswani- Hotelier India, National - 01 Sep
                            2018<span className="media-date">01 Sep 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_05_05LEMON-TREE-HOTEL,-BANJARA-HILLS-FOURTH-HOTEL-IN-HYDERABAD-Today-s-Traveller-National,-1st-Jun.pdf"
                          >
                            LEMON TREE HOTEL, BANJARA HILLS FOURTH HOTEL IN
                            HYDERABAD-Today's - 01 Jun 2018
                            <span className="media-date">01 Jun 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_04_16Lemon-Tree-Hotels-enters-Baddi-Travel-and-Hospitality-National,-1st-Jun.pdf"
                          >
                            Lemon Tree Hotels enters Baddi-Travel and
                            Hospitality-National - 01 Jun 2018
                            <span className="media-date">01 Jun 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_03_41Lemon-Tree-Hotels-launches-its-fourth-hotel-in-Hyderabad-Aviation-Times-National,-1st-Jun.pdf"
                          >
                            Lemon Tree Hotels launches its fourth hotel in
                            Hyderabad-Aviation Times - 01 Jun 2018
                            <span className="media-date">01 Jun 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_02_18Lemon-Tree-Hotels-Opens-Property-in-Baddi,-HP-The-Economic-Times-Hyderabad,-18th-May.pdf"
                          >
                            Lemon Tree Hotels Opens Property in Baddi, HP-The
                            Economic Times - 18 May 2018
                            <span className="media-date">18 May 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_01_40Lemon-Tree-Hotels-checks-into-Baddi-The-Tribune-Delhi,-17th-May.pdf"
                          >
                            Lemon Tree Hotels checks into Baddi-The
                            Tribune-Delhi, 17th May - 17 May 2018
                            <span className="media-date">17 May 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_00_45Lemon-Tree-chain-opens-49th-hotel-The-New-Indian-Express-Bangalore,-8th-May.pdf"
                          >
                            Lemon Tree chain opens 49th hotel-The New Indian
                            Express-Bangalore - 08 May 2018
                            <span className="media-date">08 May 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_07_00_14Lemon-Tree-Hotels-to-add-1,500-rooms-in-three-years-The-Hindu-Mumbai,-8th-May.pdf"
                          >
                            Lemon Tree Hotels to add 1,500 rooms in three
                            years-The Hindu-Mumbai - 08 May 2018
                            <span className="media-date">08 May 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_59_36Lemon-Tree-Hotels-to-deploy-Rs1,700-cr-for-expansion-The-Times-of-India-Hyderabad,-8th-May.pdf"
                          >
                            Lemon Tree Hotels to deploy Rs1,700 cr for
                            expansion-The Times of. - 08 May 2018
                            <span className="media-date">08 May 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_59_03Lemon-Tree-Hotels-to-expand-Andhra-Prabha-Hyderabad,-8th-May.pdf"
                          >
                            Lemon Tree Hotels to expand-Andhra Prabha-Hyderabad,
                            8th May - 08 May 2018
                            <span className="media-date">08 May 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_58_28Lemon-Tree-to-Invest-Rs-850-Cr-in-3-Years-The-Economic-Times-Delhi,-8th-May.pdf"
                          >
                            Lemon Tree to Invest Rs 850 Cr in 3 Years-The
                            Economic Times-Delhi - 08 May 2018
                            <span className="media-date">08 May 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_57_57Lemon-Tree-Hotels-Scores-a-Hat-Trick-in-Goa-Aviation-Times-National,-1st-May.pdf"
                          >
                            Lemon Tree Hotels Scores a Hat-Trick in Goa-Aviation
                            Times-National - 01 May 2018
                            <span className="media-date">01 May 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_57_55Lemon-Tree-Hotels-Scores-a-Hat-Trick-in-Goa-Aviation-Times-National,-1st-May.pdf"
                          >
                            Lemon Tree Hotels Scores a Hat-Trick in Goa-Aviation
                            Times-National - 01 May 2018
                            <span className="media-date">01 May 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_57_23Lemon-Tree-plans-to-go-asset-light,-monetize-brand-Mint-National,-19th-Apr.pdf"
                          >
                            Lemon Tree plans to go asset-light, monetize
                            brand-Mint-National, 19th Apr - 19 Apr 2018
                            <span className="media-date">19 Apr 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_55_29Lemon-Tree-Hotels-doubles-inventory-Trav-Talk-National,-16th-Apr.pdf"
                          >
                            Lemon Tree Hotels doubles inventory-Trav
                            Talk-National, 16th Apr - 16 Apr 2018
                            <span className="media-date">16 Apr 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_53_45Lemon-Tree-Hotels-soars-30-on-debut-Business-Standard-Bangalore,-10th-Apr.pdf"
                          >
                            Lemon Tree Hotels soars 30% on debut-Business
                            Standard-Bangalore - 10 Apr 2018
                            <span className="media-date">10 Apr 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_52_56Lemon-Tree-Hotels-stock-closes-29-above-issue-price-on-listing-days-Financial-Express-Delhi,-10th-Apr.pdf"
                          >
                            Lemon Tree Hotels stock closes 29% above issue price
                            on listing days - 10 Apr 2018
                            <span className="media-date">10 Apr 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_52_17Lemon-Tree-makes-a-strong-debut,-shares-jump-28-;-Mirae-buys-shares-The-Hindu-Business-Line-National,-10th-Apr.pdf"
                          >
                            Lemon Tree makes a strong debut, shares jump 28%;
                            Mirae buys shares - 10 Apr 2018
                            <span className="media-date">10 Apr 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_51_07Lemon-Tree-opens-strong-The-Telegraph-Kolkata,-10th-Apr.pdf"
                          >
                            Lemon Tree opens strong-The Telegraph-Kolkata, 10th
                            Apr - 10 Apr 2018
                            <span className="media-date">10 Apr 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_50_23Lemon-Tree-Ends-Up-28-on-Debut-The-Economic-Times-Delhi,-10th-Apr.pdf"
                          >
                            Lemon Tree Ends Up 28% on Debut-The Economic
                            Times-Delhi, 10th Apr - 10 Apr 2018
                            <span className="media-date">10 Apr 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_49_49Lemon-Tree-hotels-share-surges-30-percent-on-debut-Business-Standard-(Hindi)-Delhi,-10th-Apr.pdf"
                          >
                            Lemon Tree hotels share surges 30 percent on
                            debut-Business Standard - 10 Apr 2018
                            <span className="media-date">10 Apr 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_49_19Lemon-Tree-Hotels-stock-closes-29-above-issue-price-on-listing-days-Financial-Express,-Kolkata,-10th-Apr.pdf"
                          >
                            Lemon Tree Hotels stock closes 29% above issue price
                            on listing days-Financial - 10 Apr 2018
                            <span className="media-date">10 Apr 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_48_34Lemon-Tree-enters-Hyderabad-market-with-85-room-hotel-DNA-Mumbai,-9th-Apr.pdf"
                          >
                            Lemon Tree enters Hyderabad market with 85-room
                            hotel-DNA-Mumbai, 9th Apr. - 09 Apr 2018
                            <span className="media-date">09 Apr 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_47_23Lemon-Tree-Hotels-IPO-Gets-Fully-Subscribed-The-Economic-Times-Mumbai,-29th-Mar.pdf"
                          >
                            Lemon Tree Hotels IPO Gets Fully Subscribed-The
                            Economic Times-Mumbai - 29 Mar 2018
                            <span className="media-date">29 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_46_29Lemon-Tree-Hotels-IPO-subscribed-1-19-times-Financial-Express-Delhi,-29th-Mar.pdf"
                          >
                            Lemon Tree Hotels IPO subscribed 1.19
                            times-Financial Express-Delhi, 29th Mar - 29 Mar
                            2018<span className="media-date">29 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_45_06Lemen-tree-IPO-to-open-today-Navbharat-Mumbai,-26th-Mar.pdf"
                          >
                            Lemen tree IPO to open today-Navbharat-Mumbai, 26th
                            Mar - 26 Mar 2018
                            <span className="media-date">26 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_43_48Not-economical-to-check-in-to-Lemon-Tree-Hotels-share-sale-Mint-National,-26th-Mar.pdf"
                          >
                            Not economical to check in to Lemon Tree Hotels
                            share sale-Mint-National - 26 Mar 2018
                            <span className="media-date">26 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_44_29Lemon-Tree-Offer-Expensive,-Suitable-for-High-risklnvestors-The-Economic-Times-Bangalore,-26th-Mar.pdf"
                          >
                            Lemon Tree Offer Expensive, Suitable for
                            High-risklnvestors-The Economic - 26 Mar 2018
                            <span className="media-date">26 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_45_49Lemon-Tree-raises-Rs311-cr-ahead-of-IPO-DNA-DNA-Money,-Delhi,-26th-Mar.pdf"
                          >
                            Lemon Tree raises Rs311 cr ahead of IPO-DNA - DNA
                            Money, Delhi, 26th Mar - 26 Mar 2018
                            <span className="media-date">26 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_42_12Lemon-Tree-to-hit-the-IPO-market,-allowing-some-stakeholders-to-exit-The-Free-Press-Journal-Mumbai,-22nd-Mar.pdf"
                          >
                            Lemon Tree to hit the IPO market, allowing some
                            stakeholders to exit-The Free - 22 Mar 2018
                            <span className="media-date">22 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_41_02Lemon-Tree-to-raise-Rs-1000-crore-via-IPO,-open-28-new-hotels-Loksatta-Mumbai,-20th-Mar.pdf"
                          >
                            Lemon Tree to raise Rs 1000 crore via IPO, open 28
                            new hotels-Loksatta - 20 Mar 2018
                            <span className="media-date">20 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_39_56Warburg-to-part-exit-LemonTree-via-IPO-DNA-DNA-Money-Delhi,-20th-Mar.pdf"
                          >
                            Warburg to part-exit LemonTree via IPO-DNA - DNA
                            Money-Delhi - 20 Mar 2018
                            <span className="media-date">20 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_38_55Lemon-Tree-sets-IPO-price-band-at-Rs54-56-Financial-Express-Delhi,-20th-Mar.pdf"
                          >
                            Lemon Tree sets IPO price band at Rs54-56-Financial
                            Express-Delhi - 20 Mar 2018
                            <span className="media-date">20 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_37_47Lemon-Tree-Hotels-to-raise-Rs1,040-cr-via-IPO-Afternoon-Despatch-Courier-Mumbai,-20th-Mar.pdf"
                          >
                            Lemon Tree Hotels to raise Rs1,040 cr via IPO-
                            Afternoon Despatch &amp; Courier - 20 Mar 2018
                            <span className="media-date">20 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_36_51Lemon-Tree-Hotels-plans-to-raise-Rs-1,000-crore-through-public-issue-The-Hindu-Business-Line-National,-20th-Mar.pdf"
                          >
                            Lemon Tree Hotels plans to raise Rs 1,000 crore
                            through public - 20 Mar 2018
                            <span className="media-date">20 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_35_01Lemon-Tree-Hotels-sets-price-band-at-Rs-54-56-Financial-Chronicle-Bangalore,-20th-Mar.pdf"
                          >
                            Lemon Tree Hotels sets price band at Rs
                            54-56-Financial Chronicle-Bangalore - 20 Mar 2018
                            <span className="media-date">20 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_33_01IPOs-break-all-records-in-the-big-March-rush-Mint-Lounge-National,-17th-Mar.pdf"
                          >
                            IPOs break all records in the big March rush-Mint -
                            Lounge-National - 17 Mar 2018
                            <span className="media-date">17 Mar 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_27_24Lemon-Tree-gets-Sebi-nod-for-IPO-Daily-Thanthi-Chennai-Jan-03,2018.pdf"
                          >
                            Lemon Tree gets Sebi nod for IPO-Daily
                            Thanthi-Chennai - 03 Jan 2018
                            <span className="media-date">03 Jan 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_57_46Lemon-Tree-gets-nod-for-IPO-Business-Standard(Hindi)-Delhi-Jan-03,2018.pdf"
                          >
                            Lemon Tree gets nod for IPO-Business Standard(Hindi)
                            - 03 Jan 2018
                            <span className="media-date">03 Jan 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_31_53IPO-Lemon-Tree-gets-SEBI-go-ahead-The-Hindu-Business-Line-National-Jan-03,2018.pdf"
                          >
                            IPO Lemon Tree gets SEBI go-ahead-The Hindu Business
                            Line-National - 03 Jan 2018
                            <span className="media-date">03 Jan 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_29_21Lemon-Tree-gets-approval-for-IPO-Deccan-Herald-Bangalore-Jan-03,2018.pdf"
                          >
                            Lemon Tree gets approval for IPO-Deccan
                            Herald-Bangalore - 03 Jan 2018
                            <span className="media-date">03 Jan 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_06_25_32Lemon_tree_Hotel_interested_to_make_Share_Ask_permission_to_SEBI_Dina_Malar_Chennai_Jan_03_2018.pdf"
                          >
                            Lemon tree Hotel interested to make Share, Ask
                            permission to SEBI-Dina Malar - 03 Jan 2018
                            <span className="media-date">03 Jan 2018</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/24_Jul_2023_05_55_09Disability_awards_Jan_01_2018.pdf"
                          >
                            Disability awards more than ceremony-Civil
                            Society-National - 01 Jan 2018
                            <span className="media-date">01 Jan 2018</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2017">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2017"
                    aria-expanded="false"
                    aria-controls="collapse2017"
                  >
                    2017
                  </button>
                </h5>
              </div>
              <div
                id="collapse2017"
                className="collapse"
                aria-labelledby="heading2017"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_18_30Helen-Keller-awards-for-firms,-differently-abled-achievers-The-Hindu-on-Sunday-Delhi-Dec-03,2017.pdf"
                          >
                            Helen Keller awards for firms, differently-abled
                            achievers - 03 Dec 2017
                            <span className="media-date">03 Dec 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_19_58Hospitality-school-raises-$3-8-mn-from-Lemon-Tree-founder,-others-Business-Standard-Hyderabad-Nov-09,2017.pdf"
                          >
                            Hospitality school raises $3.8 mn from Lemon Tree
                            founder others - 09 Nov 2017
                            <span className="media-date">09 Nov 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_21_03Lemon-Tree-Hotels-look-overseas-for-expansion-The-Hindu,Chennai-Nov-04,2017.pdf"
                          >
                            Lemon Tree Hotels look overseas for expansion-The
                            Hindu,Chennai - 04 Nov 2017
                            <span className="media-date">04 Nov 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_20_30Lemon-Tree-Hotels-look-overseas-for-expansion-The-Hindu,Bangalore-Nov-04,2017.pdf"
                          >
                            Lemon Tree Hotels look overseas for expansion-The
                            Hindu,Bangalore - 04 Nov 2017
                            <span className="media-date">04 Nov 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_22_31Lemon-Tree-Hotels-look-overseas-for-expansion-The-Hindu,Mumbai-Nov-04,2017.pdf"
                          >
                            Lemon Tree Hotels look overseas for expansion-The
                            Hindu,Mumbai - 04 Nov 2017
                            <span className="media-date">04 Nov 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_22_02Lemon-Tree-Hotels-look-overseas-for-expansion-The-Hindu,Hyderabad-Nov-04,2017.pdf"
                          >
                            Lemon Tree Hotels look overseas for expansion-The
                            Hindu,Hyderabad - 04 Nov 2017
                            <span className="media-date">04 Nov 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_21_38Lemon-Tree-Hotels-look-overseas-for-expansion-The-Hindu,Delhi-Nov-04,2017.pdf"
                          >
                            Lemon Tree Hotels look overseas for expansion-The
                            Hindu,Delhi - 04 Nov 2017
                            <span className="media-date">04 Nov 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_22_59Lemon-Tree-Hotels-Food-Hospitality-World,National-Nov-01,2017.pdf"
                          >
                            Lemon Tree Hotels-Food &amp; Hospitality
                            World,National - 01 Nov 2017
                            <span className="media-date">01 Nov 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_23_33LEMON-TREE-HOTELS-EXPANDS-PRESENCE-IN-PATNA-AND-ALWAR-Travel-and-Hospitality,National-Nov-01,2017.pdf"
                          >
                            LEMON TREE HOTELS EXPANDS PRESENCE IN PATNA AND
                            ALWAR-Travel and Hospitality,National - 01 Nov 2017
                            <span className="media-date">01 Nov 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_24_08Lemon-Tree-Hotels-expands-presence-in-Patna-and-Alwar-Trav-Talk-National-Oct-16,2017.pdf"
                          >
                            Lemon Tree Hotels expands presence in Patna and
                            Alwar-Trav Talk-National - 16 Oct 2017
                            <span className="media-date">16 Oct 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_24_38Lemon-Tree-Hotels-files-draft-papers-for-public-issue-The-Hindu-Business-Line,National,Sep-23,2017.pdf"
                          >
                            Lemon Tree Hotels files draft papers for public
                            issue-The Hindu Business Line,National,Sep_23_2017 -
                            23 Sep 2017
                            <span className="media-date">23 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_25_07Lemon-Tree-Hotels-files-IPO-papers-with-Sebi-Daily-Thanthi,Chennai-Sep-23,2017.pdf"
                          >
                            Lemon Tree Hotels files IPO papers with Sebi-Daily
                            Thanthi,Chennai-Sep_23_2017 - 23 Sep 2017
                            <span className="media-date">23 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_25_34Lemon-Tree-Hotels-files-IPO-papers-with-Sebi-Hindustan-Times-Business,Delhi-Sep-22,2017.pdf"
                          >
                            Lemon Tree Hotels files IPO papers with
                            Sebi-Hindustan Times Business,Delhi-Sep_22_2017 - 22
                            Sep 2017
                            <span className="media-date">22 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_26_06Lemon-Tree-Hotels-files-IPO-papers-with-Sebi-Hindustan-Times,Mumbai-Sep-22,2017.pdf"
                          >
                            Lemon Tree Hotels files IPO papers with
                            Sebi-Hindustan Times,Mumbai-Sep_22_2017 - 22 Sep
                            2017<span className="media-date">22 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_26_38Lemon-Tree-Eenadu,Hyderabad-Sep-22,2017.pdf"
                          >
                            Lemon Tree-Eenadu,Hyderabad-Sep_22_2017 - 22 Sep
                            2017<span className="media-date">22 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_27_03News-Brief-Mint,National-Sep-22,2017.pdf"
                          >
                            News Brief-Mint,National-Sep_22_2017 - 22 Sep 2017
                            <span className="media-date">22 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_27_29Lemon-Tree-files-papers-for-IPO-DNA-DNA-Money,Delhi-Sep-22,2017.pdf"
                          >
                            Lemon Tree files papers for IPO-DNA-DNA
                            Money,Delhi-Sep_22_2017 - 22 Sep 2017
                            <span className="media-date">22 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_28_00Lemon-Tree-files-papers-for-IPO-DNA-DNA-Money,Mumbai-Sep-22,2017.pdf"
                          >
                            Lemon Tree files papers for IPO-DNA-DNA
                            Money,Mumbai-Sep_22_2017 - 22 Sep 2017
                            <span className="media-date">22 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_28_30MGB,-Lemon-Tree-join-hands-TravTalk-National-Sep-16,2017.pdf"
                          >
                            MGB, Lemon Tree join
                            hands-TravTalk-National-Sep_16_2017 - 16 Sep 2017
                            <span className="media-date">16 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_29_01PATU-KESWANI-Hotelier-India-National,Sep-01,2017.pdf"
                          >
                            PATU KESWANI-Hotelier India-National,Sep_01_2017 -
                            01 Sep 2017
                            <span className="media-date">01 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_30_16Rattan-Keswani-Hotelier-India-National,Sep-01,2017.pdf"
                          >
                            Rattan Keswani-Hotelier India-National,Sep_01_2017 -
                            01 Sep 2017
                            <span className="media-date">01 Sep 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_31_41For-a-homely-vibe-Metroplus-Bnagalore-Aug-17,-2017.pdf"
                          >
                            For a homely vibe Metroplus Bnagalore Aug_17_2017 -
                            17 Aug 2017
                            <span className="media-date">17 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_34_40For-a-homely-vibe-Metroplus-Chennai-Aug-17,-2017.pdf"
                          >
                            For a homely vibe Metroplus Chennai Aug 17, 2017 -
                            17 Aug 2017
                            <span className="media-date">17 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_35_03For-a-homely-vibe-The-Hindu-Metro-Plus-Hyderabad-Aug-17,-2017.pdf"
                          >
                            For a homely vibe The Hindu Metro Plus Hyderabad
                            Aug_17_2017 - 17 Aug 2017
                            <span className="media-date">17 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_35_10For-a-homely-vibe-The-Hindu-Metro-Plus-Hyderabad-Aug-17,-2017.pdf"
                          >
                            For a homely vibe The Hindu Metro Plus Hyderabad
                            Aug_17_2017 - 17 Aug 2017
                            <span className="media-date">17 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_36_12Lemon-Tree-Hotel-Aviation-Times-National-Aug-09,-2017.pdf"
                          >
                            Lemon Tree Hotel Aviation Times National Aug_09_2017
                            - 09 Aug 2017
                            <span className="media-date">09 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_36_47Lemon-Tree-Hotels-forays-into-extended-stays-with-Sandal-Suites-Express.pdf"
                          >
                            Lemon Tree Hotels forays into extended stays with
                            Sandal Suites Express - 01 Aug 2017
                            <span className="media-date">01 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_37_18Millennials-Can-Build-Rapport-With-The-Older-Generation-The-economic-Tim.pdf"
                          >
                            Millennials Can Build Rapport With The Older
                            Generation The economic Time - 01 Aug 2017
                            <span className="media-date">01 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_40_38RECOGNISING-THE-BEST-Todays-Travller-National-Aug-01,-2017.pdf"
                          >
                            RECOGNISING THE BEST Todays Travller National
                            Aug_01_2017 - 01 Aug 2017
                            <span className="media-date">01 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_44_575-WAYS-Millennials-Can-Build-Rapport-With-The-Older-Generation-The-Econo-(1).pdf"
                          >
                            5 WAYS Millennials Can Build Rapport With The Older
                            Generation The Econo - 01 Aug 2017
                            <span className="media-date">01 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_45_225-WAYS-Millennials-Can-Build-Rapport-With-The-Older-Generation-the-econo.pdf"
                          >
                            5 WAYS Millennials Can Build Rapport With The Older
                            Generation the econo - 01 Aug 2017
                            <span className="media-date">01 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_46_045-WAYS-TO-Millennials-Can-Build-Rapport-With-The-Older-Generation-The-Ec-(2).pdf"
                          >
                            5 WAYS TO Millennials Can Build Rapport With The
                            Older Generation - 01 Aug 2017
                            <span className="media-date">01 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_46_315-WAYS-TO-Millennials-Can-Build-Rapport-With-The-Older-Generation-The-Ec.pdf"
                          >
                            5 WAYS TO Millennials Can Build Rapport With The
                            Older Generation The Ec - 01 Aug 2017
                            <span className="media-date">01 Aug 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_48_03Service-with-a-smile-and-the-best-of-ability-The-Hindu-Business-Line-National,Jun-22,2017.pdf"
                          >
                            Service with a smile and the best of ability-The
                            Hindu Business Line-National_Jun_22_2017 - 22 Jun
                            2017<span className="media-date">22 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_48_40Lemon-Tree-Hotels-operated-Sandal-Suites-opens-doors-TravTalk-National,Jun-16,2017.pdf"
                          >
                            Lemon Tree Hotels operated Sandal Suites opens
                            doors-TravTalk-National_Jun 16_2017 - 16 Jun 2017
                            <span className="media-date">16 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_49_08Lemon-Tree-launches-Sandal-Suites-Mail-Today,Delhi,Jun-11,2017.pdf"
                          >
                            Lemon Tree launches Sandal Suites-Mail
                            Today_Delhi_Jun 11_2017 - 11 Jun 2017
                            <span className="media-date">11 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_49_35Lemon-Tree-Unit-checks-into-serviced-suites-space-The-Economic-Times,Bangalore,Jun-08,2017.pdf"
                          >
                            Lemon Tree Unit checks into serviced suites
                            space-The Economic Times_Bangalore_Jun_08_2017 - 08
                            Jun 2017
                            <span className="media-date">08 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_50_07Lemon-Tree-Unit-checks-into-serviced-suites-space-The-Economic-Times,Delhi,Jun-08,2017.pdf"
                          >
                            Lemon Tree Unit checks into serviced suites
                            space-The Economic Times_Delhi_Jun_08_2017 - 08 Jun
                            2017<span className="media-date">08 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_50_41Lemon-Tree-Unit-checks-into-serviced-suites-space-The-Economic-Times,Mumbai,Jun-08,2017.pdf"
                          >
                            Lemon Tree Unit checks into serviced suites
                            space-The Economic Times_Mumbai_Jun_08_2017 - 08 Jun
                            2017<span className="media-date">08 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_51_14Lemon-Tree-Hotels-check-into-serviced-suites-in-Noida-The-Economic-Times-Kolkata,Jun-08,2017.pdf"
                          >
                            Lemon Tree Hotels check into serviced suites in
                            Noida-The Economic Times-Kolkata_Jun 08_2017 - 08
                            Jun 2017
                            <span className="media-date">08 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_51_46Lemon-Tree-Hotels-checks-into-extended-stays-The-Hindu-Business-Line-National,Jun-08,2017.pdf"
                          >
                            Lemon Tree Hotels checks into extended stays-The
                            Hindu Business Line-National_Jun 08_2017 - 08 Jun
                            2017<span className="media-date">08 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_52_16Lemon-Tree-Hotels-checks-into-serviced-suites-space-in-Noida-The-Economic-Times-Hyderabad,Jun-08,2017.pdf"
                          >
                            Lemon Tree Hotels checks into serviced suites space
                            in Noida-The Economic Times-Hyderabad_Jun 08_2017 -
                            08 Jun 2017
                            <span className="media-date">08 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_53_39Lemon-Tree-Hotels-checks-into-serviced-suites-space-The-Economic-Times-Chennai,Jun-08,2017.pdf"
                          >
                            Lemon Tree Hotels checks into serviced suites
                            space-The Economic Times-Chennai_Jun 08_2017 - 08
                            Jun 2017
                            <span className="media-date">08 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_01_39Lemon-Tree-to-open-up-to-15-properties-DNA-DNA-Money,Mumbai-Jun-05,2017.pdf"
                          >
                            Lemon Tree to open up to 15 properties-DNA-DNA
                            Money_Mumbai-Jun 05_2017 - 05 Jun 2017
                            <span className="media-date">05 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_01_09Lemon-Tree-to-open-up-to-15-properties-DNA-DNA-Money,Delhi-Jun-05,2017.pdf"
                          >
                            Lemon Tree to open up to 15 properties-DNA-DNA
                            Money_Delhi_Jun 05_2017 - 05 Jun 2017
                            <span className="media-date">05 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_00_34Lemon-Tree-plans-15-more-outfits-The-Hindu-Business-Line-National,Jun-05,2017.pdf"
                          >
                            Lemon Tree plans 15 more outfits-The Hindu Business
                            Line-National_Jun 05_2017 - 05 Jun 2017
                            <span className="media-date">05 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_00_07Lemon-Tree-will-open-15-new-hotel-The-Economic-Times(Hindi),Delhi,Jun-05,2017.pdf"
                          >
                            Lemon Tree will open 15 new hotel-The Economic
                            Times(Hindi)_Delhi_Jun 05_2017 - 05 Jun 2017
                            <span className="media-date">05 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_58_53Lemon-Tree-hotel-planning-to-open-15-hotels-by-FY19-Yashobhoomi-Just-Mumbai,-Mumbai-Jun-05,2017.pdf"
                          >
                            Lemon Tree hotel planning to open 15 hotels by
                            FY19-Yashobhoomi-Just Mumbai_Mumbai-Jun 05_2017 - 05
                            Jun 2017
                            <span className="media-date">05 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_58_09Lemon-Tree-hotel-planning-to-open-15-hotels-by-FY19-The-Economic-Times-Mumbai,Jun-05,2017.pdf"
                          >
                            Lemon Tree hotel planning to open 15 hotels by
                            FY19-The Economic Times-Mumbai_Jun 05_2017 - 05 Jun
                            2017<span className="media-date">05 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_57_32Lemon-Tree-hotel-planning-to-open-15-hotels-by-FY19-The-Economic-Times-Delhi,Jun-05,2017.pdf"
                          >
                            Lemon Tree hotel planning to open 15 hotels by
                            FY19-The Economic Times-Delhi_Jun 05_2017 - 05 Jun
                            2017<span className="media-date">05 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_59_34Lemon-Tree-hotel-planning-to-open-15-hotels-Rashtria-Sahara,Delhi-Jun-05,2017.pdf"
                          >
                            Lemon Tree hotel planning to open 15 hotels-Rashtria
                            Sahara_Delhi-Jun 05_2017 - 05 Jun 2017
                            <span className="media-date">05 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_06_04Going-beyond-boundaries-Express-TravelWorld-National,Jun-01,2017.pdf"
                          >
                            Going beyond boundaries-Express
                            TravelWorld-National_Jun 01_2017 - 01 Jun 2017
                            <span className="media-date">01 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_05_19Lemon-Tree-Hotels-Aviation-Times-National,Jun-01,2017.pdf"
                          >
                            Lemon Tree Hotels-Aviation Times-National_Jun
                            01_2017 - 01 Jun 2017
                            <span className="media-date">01 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_04_17Lemon-Tree-Hotel-s-universally-accessible-hotels-Hotelier-India,National-Jun-01,2017.pdf"
                          >
                            Lemon Tree Hotel's universally accessible
                            hotels-Hotelier India_National-Jun 01_2017 - 01 Jun
                            2017<span className="media-date">01 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_03_46Lemon-Tree-Hotels-launches-universally-accessible-hotels-in-Gurugram-Travel-and-Hospitality,National,Jun-01,2017.pdf"
                          >
                            Lemon Tree Hotels launches universally accessible
                            hotels in Gurugram-Travel and Hospitality_National -
                            01 Jun 2017
                            <span className="media-date">01 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_03_21Vive-La-Difference-Outlook-Traveller-National,Jun-01,2017.pdf"
                          >
                            Vive La Difference-Outlook
                            Traveller-National_Jun_01_2017 - 01 Jun 2017
                            <span className="media-date">01 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_02_49Twin-properties-in-Gurugram-Trav-Talk-National,Jun-01,2017.pdf"
                          >
                            Twin properties in Gurugram-Trav
                            Talk-National_Jun_01_2017 - 01 Jun 2017
                            <span className="media-date">01 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_02_16The-business-of-thriving-Hotelier-India-National,Jun-01,2017.pdf"
                          >
                            The business of thriving-Hotelier
                            India-National_Jun_01_2017 - 01 Jun 2017
                            <span className="media-date">01 Jun 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_06_44Beauty-and-the-beast-The-New-Indian-Express,Hyderabad,May-17,2017.pdf"
                          >
                            Beauty and the beast-The New Indian
                            Express_Hyderabad_May 17_2017 - 17 May 2017
                            <span className="media-date">17 May 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_08_15Ada-Sharma-inagurated-Oppo-F3-Sakshi,Hyderabad,-May-14-2017.pdf"
                          >
                            Ada Sharma inagurated Oppo F3-Sakshi_Hyderabad_May
                            14.2017 - 14 May 2017
                            <span className="media-date">14 May 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_08_53Warburg-backed-Lemon-Tree-Hotels-begin-listing-process,-shortlist-i-banks-Mint,Bangalore-May-05,2017.pdf"
                          >
                            Warburg-backed Lemon Tree Hotels begin listing
                            process_shortlist i banks-Mint_Bangalore_May 05_2017
                            - 05 May 2017
                            <span className="media-date">05 May 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_09_33Warburg-backed-Lemon-Tree-Hotels-begin-listing-process,-shortlist-i-banks-Mint,Chennai,-May-05,2017.pdf"
                          >
                            Warburg-backed Lemon Tree Hotels begin listing
                            process_shortlist i banks-Mint_Chennai_May 05_2017 -
                            05 May 2017
                            <span className="media-date">05 May 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_09_58Warburg-backed-Lemon-Tree-Hotels-begin-listing-process,-shortlist-i-banks-Mint,Hyderabad-May-05,2017.pdf"
                          >
                            Warburg-backed Lemon Tree Hotels begin listing
                            process_shortlist i banks-Mint_Hyderabad May 05_2017
                            - 05 May 2017
                            <span className="media-date">05 May 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_10_31Warburg-backed-Lemon-Tree-Hotels-begin-listing-process,-shortlist-i-banks-Mint,Kolkata-May-05,2017.pdf"
                          >
                            Warburg-backed Lemon Tree Hotels begin listing
                            process_shortlist i banks-Mint_Kolkata May 05_2017 -
                            05 May 2017
                            <span className="media-date">05 May 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_11_01Warburg-backed-Lemon-Tree-Hotels-begin-listing-process,-shortlist-i-banks-Mint,Mumbai-May-05,2017.pdf"
                          >
                            Warburg-backed Lemon Tree Hotels begin listing
                            process_shortlist i banks-Mint_Mumbai May 05_2017 -
                            05 May 2017
                            <span className="media-date">05 May 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_12_38More-then-just-a-couple-of-hotels-BW-Hotelier,National,May-01,2017.pdf"
                          >
                            More then just a couple of hotels-BW
                            Hotelier_National_May 01_2017 - 01 May 2017
                            <span className="media-date">01 May 2017</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_15_24Lemon-Tree-Hotels-Limited-People-Matters,-Feb-01,2017.pdf"
                          >
                            Lemon Tree Hotels Limited-People Matters - 01 Feb
                            2017<span className="media-date">01 Feb 2017</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2016">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2016"
                    aria-expanded="false"
                    aria-controls="collapse2016"
                  >
                    2016
                  </button>
                </h5>
              </div>
              <div
                id="collapse2016"
                className="collapse"
                aria-labelledby="heading2016"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_43_33Aviation-Times-Lemon-Tree-Hotel-Bags-the-overall-winner-trophy-Dec-01,2016.pdf"
                          >
                            Aviation Times- Lemon Tree Hotel Bags the overall
                            winner trophy... - 01 Dec 2016
                            <span className="media-date">01 Dec 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_43_09Express-Travel-World-Lemon-Tree-hotels-to-focus-on-enhancing-the-guest-s-digital-and-online-experience-Dec-01,2016.pdf"
                          >
                            Express Travel World- Lemon Tree hotels to focus on
                            enhancing the guest's digital and online
                            experience... - 01 Dec 2016
                            <span className="media-date">01 Dec 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_42_41Food-Hospitality-World-Lemon-Tree-hotels-to-focus-on-enhanching-the-guest-s-digital-and-online-experience-Dec-01,2016.pdf"
                          >
                            Food &amp; Hospitality World- Lemon Tree hotels to
                            focus on enhanching the guest's digital and online
                            experience... - 01 Dec 2016
                            <span className="media-date">01 Dec 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_41_59City-Express-Cyclin-past-the-obstacles-of-life-Hyderabad(LTPHY1),-16th-Nov,2016.pdf"
                          >
                            City Express- Cyclin past the obstacles of life-
                            Hyderabad(LTPHY1)... - 16 Nov 2016
                            <span className="media-date">16 Nov 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_40_37Food-Hospitality-World-Hotels-need-to-plan-comprehensive-data-security-policies-and-measures-16th-Nov,2016.pdf"
                          >
                            Food &amp; Hospitality World- Hotels need to plan
                            comprehensive data security policies and measures...
                            - 16 Nov 2016
                            <span className="media-date">16 Nov 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_40_14Trav-Talk-Lemon-Tree-opens-new-hotels-in-Goa-and-MP-01-Nov,2016.pdf"
                          >
                            Trav Talk-Lemon Tree opens new hotels in Goa and
                            MP... - 01 Nov 2016
                            <span className="media-date">01 Nov 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_38_40DNA-Money-Two-Optus-hotels-come-under-Lemon-Tree-October-14,2016,Delhi.pdf"
                          >
                            DNA Money-Two Optus hotels come under Lemon
                            Tree_Delhi... - 14 Oct 2016
                            <span className="media-date">14 Oct 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_38_04DNA-Money-Two-Optus-hotels-come-under-Lemon-Tree-October-14,2016,Mumbai.pdf"
                          >
                            DNA Money-Two Optus hotels come under Lemon
                            Tree-October_Mumbai... - 14 Oct 2016
                            <span className="media-date">14 Oct 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_37_31Aviation-Times-Red-Fox-Hotel,-Hyderabad-hits-100-occupancy-for-100-days-in-a-row-October-1,2016.pdf"
                          >
                            Red Fox Hotel, Hyderabad hits 100% occupancy for 100
                            days in a row... - 01 Oct 2016
                            <span className="media-date">01 Oct 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_36_46Hotelier-India-News-Brief-October-1,2016.pdf"
                          >
                            Hotelier India-News Brief... - 01 Oct 2016
                            <span className="media-date">01 Oct 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_36_10Hotelier-India-Tarudhan-Valley-October-1,2016.pdf"
                          >
                            Hotelier India-Tarudhan Valley... - 01 Oct 2016
                            <span className="media-date">01 Oct 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_35_23Food-_-Hospitality-World-The-Lemon-Tree-Hotel-Company-September-16,2016.pdf"
                          >
                            The Lemon Tree Hotes... - 16 Sep 2016
                            <span className="media-date">16 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_33_50DNA-Lemon-Tree-closing-in-on-deal-to-buy-Optus-Group-s-hotel-portfolio-September-13,2016-Mumbai.pdf"
                          >
                            Lemon Tree closing in on deal to buy Optus Groups
                            hotel portfolio... - 13 Sep 2016
                            <span className="media-date">13 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_33_08The-Economic-Times-Blackstone,-4-others-look-to-check-into-Lemon-Tree-Sep-2,-2016,Kolkata.pdf"
                          >
                            Blackstone, 4 Others Look to Check into Lemon
                            Tree...(Kolkata) - 02 Sep 2016
                            <span className="media-date">02 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_32_19The-Economic-Times-PE-Funds-Eye-25_-Stake-in-Lemon-Tree-September-1,2016,Delhi.pdf"
                          >
                            PE Funds Eye 25% Stake in Lemon Tree... - 01 Sep
                            2016<span className="media-date">01 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_31_39Hotelier-India-Rattan-Keswani-Chairman,-Carnation-Hotels-Pvt-Ltd-_-Deputy-MD,-The-Lemon-Tree-Hotel-Company-Sep-1,2016.pdf"
                          >
                            RATTAN KESWANI Chairman, Carnation Hotels Pvt Ltd
                            &amp; Deputy MD.The Lemon Tree Hotels - 01 Sep 2016
                            <span className="media-date">01 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_28_35The-Economic-Times-Blackstone,-4-others-look-to-check-into-Lemon-Tree-Sep-1,-2016,Bangalore.pdf"
                          >
                            Blackstone, 4 Others Look to Check into Lemon
                            Tree...(Bangalore) - 01 Sep 2016
                            <span className="media-date">01 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_27_55The-Economic-Times-Blackstone,-4-others-look-to-check-into-Lemon-Tree-Sep-1,-2016,Mumbai.pdf"
                          >
                            Blackstone, 4 Others Look to Check into Lemon
                            Tree... - 01 Sep 2016
                            <span className="media-date">01 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_27_16The-Economic-Times-PE-Funds-Eye-25_-Stake-in-Lemon-Tree-September-1,2016,Hyderabad.pdf"
                          >
                            PE Funds Eye 25% Stake in Lemon Tree... - 01 Sep
                            2016<span className="media-date">01 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_26_14Hotelier-India-Patu-Keswani-CMD,-The-Lemon-Tree-Hotels-Company-Sep-1,2016.pdf"
                          >
                            PATU KESWANI CMD.The Lemon Tree Hotels... - 01 Sep
                            2016<span className="media-date">01 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_25_38Travel-Trends-Today-Lemon-Tree-expands-footprints-in-Gujarat-September-1,2016.pdf"
                          >
                            Lemon Tree expands footprints in Gujarat... - 01 Sep
                            2016<span className="media-date">01 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_25_00The-Economic-Times-PE-Funds-Eye-25_-Stake-in-Lemon-Tree-September-1,2016,Kolkata.pdf"
                          >
                            PE Funds Eye 25% Stake in Lemon Tree... - 01 Sep
                            2016<span className="media-date">01 Sep 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_24_27Travel-_-Hospitality-Lemon-Tree-in-India-s-top-10-best-companies-to-work-for-Aug-1,2016.pdf"
                          >
                            Lemon Tree in India's top 10 best companies to work
                            for... - 01 Aug 2016
                            <span className="media-date">01 Aug 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_23_56Today-s-Traveller-Lemon-Tree-Hotels-Strengthens-present-in-Gujarat-August-1,2016.pdf"
                          >
                            LEMON TREE HOTELS STRENGTHENS I PRESENCE IN GUJARAT
                            - 01 Aug 2016
                            <span className="media-date">01 Aug 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_22_16Hotelier-India-the-Right-Attitude-Aug-1,2016.pdf"
                          >
                            THE RIGHT ATTITUDE... - 01 Aug 2016
                            <span className="media-date">01 Aug 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_21_25Aviation-Times-Lemon-Tree-Hotels-Ranked-Amongst-the-top-10-best-companies-to-work-for-in-India-August-1,2016.pdf"
                          >
                            Lemon Tree Hotels Ranked Amongst The Top 10 Best
                            Companies To Work For In India lia's Best... - 01
                            Aug 2016
                            <span className="media-date">01 Aug 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_18_11BW-Hotelier-Huge-Appetite-for-Growth-July-1,2016.pdf"
                          >
                            HUGE APPETITE FOR GROWTH... - 01 Jul 2016
                            <span className="media-date">01 Jul 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_17_09BW-Hotelier-Vikramjit-Singh-is-President-and-CRO-July-1,2016.pdf"
                          >
                            VIKRAMJIT SINGH IS PRESIDENT AND CHIEF REVENUE
                            OFFICER... - 01 Jul 2016
                            <span className="media-date">01 Jul 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_16_08The-Economic-Times-PF-Funds-Blackstone,Others-to-buy-15_-in-Lemon-Tree-for-1000-cr,-June-7,2016,Hyderabad.pdf"
                          >
                            PE Funds Blackstone, TPG &amp; Others to Buy 15% in
                            Lemon Tree for... - 07 Jun 2016
                            <span className="media-date">07 Jun 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_15_38The-Economic-Times-PF-Funds-Blackstone,Others-to-buy-15_-in-Lemon-Tree-for-1000-cr,-June-7,2016-Kolkata (1).pdf"
                          >
                            PE Funds Blackstone, TPG &amp; Others to Buy 15% in
                            Lemon Tree for Rs1,000 crore... - 07 Jun 2016
                            <span className="media-date">07 Jun 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_07_35The-Economic-Times-PE-Funds-Eye-15_-Stake-in-Lemon-Tree-7-June,2016,Delhi.pdf"
                          >
                            PE Funds Eye 15% Stake in Lemon Tree... - 07 Jun
                            2016<span className="media-date">07 Jun 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_06_30The-Economic-Times-PF-funds-blackstone,-TGP-and-others-7-June,2016,Chennai.pdf"
                          >
                            PF funds blackstone, TGP and others_Chennai... - 07
                            Jun 2016
                            <span className="media-date">07 Jun 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_05_58The-Economic-Times-PE-majors-may-buy-10-15_-in-Lemon-Tree-for-upto-Rs-1,000cr-7-June,2016,Bangalore.pdf"
                          >
                            PE Majors may Buy 10-15% in Lemon Tree for up to Rs
                            1,000 cr... - 07 Jun 2016
                            <span className="media-date">07 Jun 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_05_24The-Economic-Times(Hindi)-Private-equity-funds-to-invest-Rs-1000cr-on-Lemon-Tree-Hotels,June-7,2016.pdf"
                          >
                            Private equity funds to invest Rs 1,000 cr on Lemon
                            Tree Hotels... - 07 Jun 2016
                            <span className="media-date">07 Jun 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_04_55The-Economic-Times-Blackstone,-2-others-look-to-check-into-Lemon-Tree-7-June,2016.pdf"
                          >
                            Blackstone, 2 Others Look to Check into Lemon
                            Tree... - 07 Jun 2016
                            <span className="media-date">07 Jun 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_04_22Food-_-Hospitality-World-Lemon-Tree-Hotels,-June-1,2016.pdf"
                          >
                            Food&amp;Hospitality World-Lemon Tree Hotels... - 01
                            Jun 2016
                            <span className="media-date">01 Jun 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_03_52Aviation-Times-Vikramjit-Singh-Elevated-As-President-and-CRO-of-LTH-June-1,2016.pdf"
                          >
                            Vikramjit Singh Elevated As President And Chief
                            Revenue Officer Of Lemon Tree Hotels... - 01 Jun
                            2016<span className="media-date">01 Jun 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_03_04The-Economic-Times-Lemon-Tree-Elevates-Vikramjit-Singh-as-President-May-11-2016.pdf"
                          >
                            Lemon Tree Elevates Vikramjit Singh as President...
                            - 11 May 2016
                            <span className="media-date">11 May 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_02_31Financial-Chronicle-Vikramjit-Singh-to-be-prez-of-LTH-May-11,2016-Bangalore.pdf"
                          >
                            Vikramjit Singh to be prez of LTH(Bangalore).. - 11
                            May 2016
                            <span className="media-date">11 May 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_00_09Financial-Chronicle-Vikramjit-Singh-to-be-prez-of-LTH-May-11,2016-Chennai.pdf"
                          >
                            Vikramjit Singh to be prez of LTH(Chennai)... - 11
                            May 2016
                            <span className="media-date">11 May 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_58_54Financial-Chronicle-Vikramjit-Singh-to-be-prez-of-LTH-May-11,2016-Delhi.pdf"
                          >
                            Vikramjit Singh to be prez of LTH(Delhi)... - 11 May
                            2016<span className="media-date">11 May 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_57_42Financial-Chronicle-Vikramjit-Singh-to-be-prez-of-LTH-May-11,2016-Mumbai.pdf"
                          >
                            Vikramjit Singh to be prez of LTH(Mumbai)... - 11
                            May 2016
                            <span className="media-date">11 May 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_55_35Aviation-Times-Lemon-Tree-Hotel-May-1,2016.pdf"
                          >
                            Aviation Times... - 01 May 2016
                            <span className="media-date">01 May 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_54_58Growing-Resort-Portfolio-TRAVTALK-National-01-april-2016.pdf"
                          >
                            Growing Resort Portfolio-TRAVTALK-National... - 01
                            Apr 2016
                            <span className="media-date">01 Apr 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_54_32Lemon-Tree-Hotels-Launches-Aviation-times-National-01st-APRIL-2016.pdf"
                          >
                            Lemon Tree Hotels Launches-Aviation
                            times-National... - 01 Apr 2016
                            <span className="media-date">01 Apr 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_53_56Lemon-Tree-Hotels-Launches-Food-Hospitality-World-National-01st-April-2016.pdf"
                          >
                            Lemon Tree Hotels Launches-Food &amp; Hospitality
                            World-National... - 01 Apr 2016
                            <span className="media-date">01 Apr 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_53_25Lemon-Tree-Hotels-launches-Travel-and-Hospitality-National-01st-April-2016.pdf"
                          >
                            Lemon Tree Hotels Launches-Travel and
                            Hospitality-National... - 01 Apr 2016
                            <span className="media-date">01 Apr 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_52_57Lemon-Tree-HotelsLaunches-T3-National-01st-April-2016.pdf"
                          >
                            Lemon Tree Hotels Launches-T3-National... - 01 Apr
                            2016<span className="media-date">01 Apr 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_52_36Naresh-Kumar-general-manager-Hotelier-India-National-01st-April-2016.pdf"
                          >
                            Naresh Kumar general manager-Hotelier
                            India-National... - 01 Apr 2016
                            <span className="media-date">01 Apr 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_52_11On-top-of-the-game-the-pioneer-Delhi-15-March,-2016.pdf"
                          >
                            On top of the game-the pioneer-Delhi... - 15 Mar
                            2016<span className="media-date">15 Mar 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_51_45Lemon-Tree-s-new-resort-in-NCR-Financial-Chronicle-Bangalore-14-March,-2016.pdf"
                          >
                            Lemon Tree's new resort in NCR-Financial
                            Chronicle-Bangalore... - 14 Mar 2016
                            <span className="media-date">14 Mar 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_51_21WATCHTOWER-Mail-Today-Delhi-13-March,-2016.pdf"
                          >
                            WATCHTOWER-Mail Today-Delhi... - 13 Mar 2016
                            <span className="media-date">13 Mar 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_49_13Lemon-Tree-Looks-to-Diversify-The-Economic-Times-Delhi-04th-feb-2016.pdf"
                          >
                            Lemon Tree Looks to Diversify-The Economic
                            Times-Delhi... - 04 Feb 2016
                            <span className="media-date">04 Feb 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_48_47Lemon-Tree-looks-to-The-Economic-Times-Kolkata-04th-Feb-2016.pdf"
                          >
                            Lemon Tree Looks To-The Economic Times-Kolkata... -
                            04 Feb 2016
                            <span className="media-date">04 Feb 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_48_11Lemon-Tree-to-Diversity-The-Economic-Times-Mumbai-04th-feb-2016.pdf"
                          >
                            Lemon Tree To Diversity-The Economic Times-Mumbai...
                            - 04 Feb 2016
                            <span className="media-date">04 Feb 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_47_42LOCAL-HIGH-TEA,-AURANGABAD-The-Statesman-section-II-Delhi-21-jan2016.pdf"
                          >
                            Local High Tea, Aurangabad-The Statesman-section
                            II-Delhi... - 21 Jan 2016
                            <span className="media-date">21 Jan 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_47_17Lemon-Tree-Hotels-plans-to-go-public-in-the-next-18-months-Mint-12-January-2016.pdf"
                          >
                            Lemon Tree Hotels plans to go public in the next 18
                            months-Mint... - 12 Jan 2016
                            <span className="media-date">12 Jan 2016</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_11_46_04Learning-people-management-from-startups-People-Matters-1-January-2016.pdf"
                          >
                            Learning people management from startups- People
                            Matters... - 01 Jan 2016
                            <span className="media-date">01 Jan 2016</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2015">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2015"
                    aria-expanded="false"
                    aria-controls="collapse2015"
                  >
                    2015
                  </button>
                </h5>
              </div>
              <div
                id="collapse2015"
                className="collapse"
                aria-labelledby="heading2015"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_20_36Warmth-and-Hospitality-by-differently-abled-The-Times-of-India-Indore-28-December-2015.pdf"
                          >
                            Warmth and Hospitality by differently abled -The
                            Times of India- Indore... - 28 Dec 2015
                            <span className="media-date">28 Dec 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_20_06Differently-abled-youth-on-a-new-high-at-Lemon-Tree-The-Times-of-India(News-Site)-23-December-2015.pdf"
                          >
                            Differently abled youth on a new high at Lemon
                            Tree-the Times of India(News Site)... - 23 Dec 2015
                            <span className="media-date">23 Dec 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_19_28COVERA_2.PDF"
                          >
                            Coverage of Nawab Mehboob Alam Khan's Biryani Demo
                            Session At Hyderabad- New Indian Express-City
                            Express... - 21 Dec 2015
                            <span className="media-date">21 Dec 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_18_55A-hotelier-is-showing-us-how-to-be-truly-inclusive-while-running-a-profitable-business-alternativ.pdf"
                          >
                            A hotelier is showing us how to be truly inclusive
                            while running a profitable
                            business-alternative.in... - 06 Dec 2015
                            <span className="media-date">06 Dec 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_17_53WORLDD_1.PDF"
                          >
                            World Disability Day: Why limit the scope of the
                            Accessible India campaign, asks activist Javed
                            Abid-DNA India... - 03 Dec 2015
                            <span className="media-date">03 Dec 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_17_10Lemon-Tree-skills-millions,-creates-jobs-Foreign-Investors-on-India(Blog)-23-November-2015.pdf"
                          >
                            Lemon Tree skills millions, creates jobs-Foreign
                            Investors on India(Blog)... - 23 Nov 2015
                            <span className="media-date">23 Nov 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_16_34How-Lemon-Tree-boosts-security-via-face-recognition-Mint-6-November-2015.pdf"
                          >
                            How Lemon Tree boosts security via face
                            recognition-Mint... - 06 Nov 2015
                            <span className="media-date">06 Nov 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_15_47How-Lemon-Tree-Uses-Face-Recognition-To-Bolster-Security-CXO-today-3-November-2015.pdf"
                          >
                            How Lemon Tree Uses Face Recognition To Bolster
                            Security-CXO today... - 03 Nov 2015
                            <span className="media-date">03 Nov 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_14_59ADVISORY-BOARD-Hotelier-India-1-November-2015.pdf"
                          >
                            Advisory Board-Hotelier India... - 01 Nov 2015
                            <span className="media-date">01 Nov 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_14_30But-are-they-really-shaking-up-the-market-Financial-Express(Mumbai,-Kolkata,-Bangalore,-Chennai)-25-October-2015.pdf"
                          >
                            But are they really shaking up the market-Financial
                            Express... - 25 Oct 2015
                            <span className="media-date">25 Oct 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_13_49Lemon-Tree-Hotels-looking-at-buying-resorts-Hospitality-Biz-India-19-October-2015.pdf"
                          >
                            Lemon Tree Hotels looking at buying
                            resorts-Hospitality Biz India... - 19 Oct 2015
                            <span className="media-date">19 Oct 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_13_12Lemon-Tree-Hotels-looking-at-buying-resorts-The-Hindu-Business-Line-19-October-2015.pdf"
                          >
                            Lemon Tree Hotels looking at buying resorts-The
                            Hindu Business Line... - 19 Oct 2015
                            <span className="media-date">19 Oct 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_11_24Lemon-Tree-Hotels-gets-2015-Asian-Human-Capital-Award-The-Economic-Times-5-October-2015.pdf"
                          >
                            Lemon Tree Hotels gets 2015 Asian Human Capital
                            Award-The Economic Times(Business Site)... - 05 Oct
                            2015<span className="media-date">05 Oct 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_11_58Lemon-Tree-Hotels-gets-2015-Asian-Human-Capital-Award-ETRetail.pdf"
                          >
                            Lemon Tree Hotels gets 2015 Asian Human Capital
                            Award... - 05 Oct 2015
                            <span className="media-date">05 Oct 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_10_11Lemon-Tree-Premier-Delhi-Airport-Celebrates-Being-Asian-Aviation-Times-1October-2015.pdf"
                          >
                            Lemon Tree Premier Delhi Airport Celebrates Being
                            Asian-AviationTimes... - 01 Oct 2015
                            <span className="media-date">01 Oct 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_09_19Winners-of-HICAP-2015-Sustainable-Hotel-Awards-Asia-Travel-Tips-17-September-2015.pdf"
                          >
                            Winners of HICAP 2015 Sustainable Hotel Awards-Asia
                            Travel Tips... - 17 Sep 2015
                            <span className="media-date">17 Sep 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_08_11The-Hindu-The-Paws-and-Mews-of-Offices-8th-Sept-2015.pdf"
                          >
                            The Paws and Mews of Offices-The Hindu... - 08 Sep
                            2015<span className="media-date">08 Sep 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_06_23The-Hans-India-Article-6th-September-2015.pdf"
                          >
                            Different and Proud of it-The Hans India... - 06 Sep
                            2015<span className="media-date">06 Sep 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_05_35Coverage-of-Lemon-Tree-Hotels-in-whereabouts-magazine-1-August-2015.pdf"
                          >
                            LTH's Sustainability Initiative-Whereabouts Magazine
                            - 01 Aug 2015
                            <span className="media-date">01 Aug 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_04_24Hospitality-Biz-India-Lemon-Tree-Hotels-ranked-the-30th-Best-Company-to-Work-for-in-India-Hospitality-Biz-July-18-2015.pdf"
                          >
                            Lemon Tree Hotels ranked the 30th Best Company to
                            Work for in India-Hospitality Biz... - 18 Jul 2015
                            <span className="media-date">18 Jul 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_03_44Lesser-known-people-undertaking-charity-work-Restoring-your-faith-in-humanity-India-Today-June-24-2015.pdf"
                          >
                            Lesser known people undertaking charity work-
                            Restoring your faith in humanity- India Today... -
                            24 Jun 2015
                            <span className="media-date">24 Jun 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_59_19Press-release-Lemon-Tree-Hotels-TripAdvisor-Certificate-of-Excellence-2015.pdf"
                          >
                            18 Lemon Tree Hotels win TripAdvisor Certificate of
                            Excellence 2015... - 11 Jun 2015
                            <span className="media-date">11 Jun 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_58_1218-Lemon-Tree-Hotels-across-India-win-TripAdvisor-Certificate-of-Excellence-2015-Travel-Daily-Media-11th-June-2015.pdf"
                          >
                            18 Lemon Tree Hotels across India win TripAdvisor
                            Certificate of Excellence 2015-Travel Daily Media...
                            - 11 Jun 2015
                            <span className="media-date">11 Jun 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_57_46Foxy-smart-hotel-in-Delhi-Aerocity-Outlook-Traveller-June-1-2015.pdf"
                          >
                            Foxy smart hotel in Delhi Aerocity- Outlook
                            Traveller... - 01 Jun 2015
                            <span className="media-date">01 Jun 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_57_13Lemon-Tree-Hotels-draws-up-plan-to-grow-roots-overseas-Financial-Chronicle-May-18,2005.pdf"
                          >
                            Lemon Tree Hotels draws up plan to grow roots
                            overseas- Financial Chronicle... - 18 May 2015
                            <span className="media-date">18 May 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_56_16Carnation-Hotels-to-enter-Tiruchirappalli-(Trichy)-by-2016-The-Economic-Times-May-14,2015.pdf"
                          >
                            Carnation Hotels to enter Tiruchirappalli (Trichy)
                            by 2016- The Economic Times... - 14 May 2015
                            <span className="media-date">14 May 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_55_28Carnation-Hotels-to-open-70-rooms-Red-Fox-Hotels-in-Tiruchirapalli-BW-Hotelier-May-13,2015.pdf"
                          >
                            Carnation Hotels to open 70-rooms Red Fox Hotels in
                            Tiruchirapalli- BW Hotelier... - 13 May 2015
                            <span className="media-date">13 May 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_54_57Lemon-Tree-Hotels-aanounces-first-managed-Red-Fox-in-Trichy-Travel-Trends-Today-May-12,2015.pdf"
                          >
                            Lemon Tree Hotels announces first managed Red Fox in
                            Trichy- Travel Trends Today... - 12 May 2015
                            <span className="media-date">12 May 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_54_15Carnation-Hotels-to-enter-Tiruchirappalli-(Trichy)-May-7,-2015.pdf"
                          >
                            Carnation Hotels to enter Tiruchirappalli
                            (Trichy)... - 07 May 2015
                            <span className="media-date">07 May 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_53_47The-Geek-who-snuggles-up-to-Byte-Bangalore-Mirror-April-4,2015.pdf"
                          >
                            The Geek who snuggles up to Byte- Bangalore
                            Mirror... - 04 Apr 2015
                            <span className="media-date">04 Apr 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_53_11Lemon-Tree-Hotels-draws-up-plan-to-grow-roots-overseas-Financial-Chronicle-May-18,2005.pdf"
                          >
                            Back to the Drawing Board- Hotelier India... - 01
                            Apr 2015
                            <span className="media-date">01 Apr 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_52_37Carnation-Hotels-to-enter-Tiruchirappalli-(Trichy)-May-7,-2015.pdf"
                          >
                            Carnation Hotels Weaving Hotels With A Difference...
                            - 01 Apr 2015
                            <span className="media-date">01 Apr 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_52_14Lemon-Tree-Premier,-Jaipur-Aviation-Times-April-issue-Page-46-48.pdf"
                          >
                            Lemon Tree Premier, Jaipur- Aviation Times... - 01
                            Apr 2015
                            <span className="media-date">01 Apr 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_50_09The-expectations-of-our-key-stakeholders-impact-the-sector-Travelscapes-Pg-No-16-April-2015.pdf"
                          >
                            The expectations of our key stakeholders... impact
                            the sector- Travelscapes... - 01 Apr 2015
                            <span className="media-date">01 Apr 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_49_35Lemon-Tree-Hotels,-Where-strays-get-executive-level-jobs-Gingertail-25th-March-2015.pdf"
                          >
                            Lemon Tree Hotels, Where strays get executive-level
                            jobs... - 25 Mar 2015
                            <span className="media-date">25 Mar 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_47_11Taste-Check-Wins-Hearts-Incredible-Chef-March-2015.pdf"
                          >
                            Taste...Check...Wins Hearts- Incredible Chef... - 01
                            Mar 2015
                            <span className="media-date">01 Mar 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_46_44Lemon-Tree-Plans-Hotel-in-Mumbai-Airport-Dist-The-Economic-Times-Page-no-5-February-20-2015.pdf"
                          >
                            Lemon Tree Plans Hotel in Mumbai Airport Dist.- The
                            Economic Times... - 24 Feb 2015
                            <span className="media-date">24 Feb 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_45_44Launch-of-Slounge-Bar-at-Lemon-Tree-Hotel,-Shimona,-Chennai-The-Times-of-India-February-20-2015 (2).pdf"
                          >
                            Sameea looked gorgeous at launch of Slounge bar at
                            Lemon Tree Hotel in Chennai... - 20 Feb 2015
                            <span className="media-date">20 Feb 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_41_47Launch-of-Slounge-Bar-at-Lemon-Tree-Hotel,-Shimona,-Chennai-The-Times-of-India-February-20-2015 (1).pdf"
                          >
                            Launch of Slounge Bar at Lemon Tree Hotel, Shimona,
                            Chennai- The Times of India... - 20 Feb 2015
                            <span className="media-date">20 Feb 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_41_14Republic-of-Noodles,-Lemon-Tree-Hotel,-Aurangabad-The-Statesman-Page-no-3-February-19-2015.pdf"
                          >
                            Republic of Noodles, Lemon Tree Hotel, Aurangabad-
                            The Statesman... - 19 Feb 2015
                            <span className="media-date">19 Feb 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_40_36ICT-Led-Social-Innovation-by-a-Corporate-Responsible-Business-NASSCOM-February-12-2015.pdf"
                          >
                            ICT Led Social Innovation by a Corporate-
                            Responsible Business - NASSCOM... - 12 Feb 2015
                            <span className="media-date">12 Feb 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_39_4048-room-Lemon-Tree-Hotel-to-open-in-Vadodara-in-April-2015-Tourism-Breaking-News-February-5-2015.pdf"
                          >
                            48 room Lemon Tree to open in Vadodara in April
                            2015- Tourism Breakng News... - 05 Feb 2015
                            <span className="media-date">05 Feb 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_38_57Lemon-Tree-Hotels-enters-Vadodara-Hindu-Business-Line-February-3-2015.pdf"
                          >
                            Lemon Tree Hotels enters Vadodara- Hindu Business
                            Line... - 03 Feb 2015
                            <span className="media-date">03 Feb 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_38_03Lemon-Tree-Hotels-enters-Vadodara-Hospitality-Biz-India-February-3-2015.pdf"
                          >
                            Lemon Tree Hotels enters Vadodara- Hospitality Biz
                            India... - 03 Feb 2015
                            <span className="media-date">03 Feb 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_34_21Lemon-Tree-Hotel-to-come-up-with-mega-hotel-project-in-Mumbai-The-Financial-Express-January-2-2015.pdf"
                          >
                            Lemon Tree Hotels to come up with mega hotel project
                            in India's financial capital- 4 Hoteliers... - 02
                            Feb 2015
                            <span className="media-date">02 Feb 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_33_51Lemon-Tree-Hotels-Enters-Vadodara-Travelscapes-Page-no-26-February-2015.pdf"
                          >
                            Lemon Tree Hotels enters Vadodara- Travelscapes... -
                            01 Feb 2015
                            <span className="media-date">01 Feb 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_33_21Companies-warming-upto-differrently-abled-Business-Standard-January-10,-2015.pdf"
                          >
                            Companies warmig upto differently abled- Business
                            Standard... - 10 Jan 2015
                            <span className="media-date">10 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_31_16Lemon-Tree-Hotel-to-come-up-with-mega-hotel-project-in-Mumbai-The-Financial-Express-January-2-2015.pdf"
                          >
                            Lemon Tree to come up with mega hotel project in
                            Mumbai- The Financial Express... - 02 Jan 2015
                            <span className="media-date">02 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_52_44Adding-Light-to-Someone-s-Life-FHRAI-Pg-No-64-January-2015.pdf"
                          >
                            Adding Light to Someone's Life- FHRAI... - 01 Jan
                            2015<span className="media-date">01 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_30_52Lemon-Tree-Hotels-Begins-Mega-Sale-For-2015-Today-s-Traveller-Page-No-12-Vol-12;-December-2014.pdf"
                          >
                            Lemon Tree Hotels begins mega sale for 2015- Today's
                            Traveler... - 01 Jan 2015
                            <span className="media-date">01 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_30_27Lemon-Tree-Hotels-launch-their-third-property-in-Hyderabad-Hotel-Connect-Pg-no-7-January-2015.pdf"
                          >
                            Lemon Tree Hotels launch their third property in
                            Hyderabad- Hotel Connect... - 01 Jan 2015
                            <span className="media-date">01 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_29_40Lemon-Tree-Hotels-ventures-in-Hyderabad-The-Financial-Express-Pg-No-7-January-2015.pdf"
                          >
                            Lemon Tree Hotels ventures in Hyderabad- The
                            Financial Express... - 01 Jan 2015
                            <span className="media-date">01 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_29_07Lemon-Tree-intends-to-tap-leisue-and-pilgrimage-destinations-Wonderlust-Pg-No-28-January-2015.pdf"
                          >
                            Lemon Tree intends to tap leisure and pilgrimage
                            destinations- Wonderlust... - 01 Jan 2015
                            <span className="media-date">01 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_28_44Lemon-Tree-scores-a-hat-trick-in-Hyderabad-The-Global-Analyst-Pg-No-54-January-2015.pdf"
                          >
                            Lemon Tree scores a 'hat-trick' in Hyderabad- The
                            Global Analyst... - 01 Jan 2015
                            <span className="media-date">01 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_28_24Occupancies-Will-Be-On-Track-By-2018-19-BW-Hotelier;-Vol-01-Pg-No-50-51-Issue-January-Febraury-2015.pdf"
                          >
                            OCCUPANCIES WILL BE ON TRACK 2018- 19 - BW
                            Hotelier... - 01 Jan 2015
                            <span className="media-date">01 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_53_59The-Portfolio-of-LTH-is-bound-to-increase-Wonderlust-Pg-no-18-January-2015.pdf"
                          >
                            THE PORTFOLIO OF LTH IS BOUND TO INCREASE: KESWANI-
                            Wonderlust... - 01 Jan 2015
                            <span className="media-date">01 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_53_36Vision-2015-Future-Insights-Hospitality-Biz-Pg-No-19-26-January-2015.pdf"
                          >
                            Vision 2015 Future Insights- Hospitality Biz... - 01
                            Jan 2015
                            <span className="media-date">01 Jan 2015</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_12_53_093rd-Lemon-Tree-hotel-opens-in-Hyderabad-with-190-keys-FHRAI-Pg-No-24-January-2015.pdf"
                          >
                            3rd Lemon Tree hotel opens in Hyderabad with 190
                            keys- FHRAI... - 01 Jan 2015
                            <span className="media-date">01 Jan 2015</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2014">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2014"
                    aria-expanded="false"
                    aria-controls="collapse2014"
                  >
                    2014
                  </button>
                </h5>
              </div>
              <div
                id="collapse2014"
                className="collapse"
                aria-labelledby="heading2014"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_13_40Startup-Advice-from-Startup-Mavens,-Put-the-Right-Culture-in-Place-Economic-Times.pdf"
                          >
                            Startup Advice from Startup Mavens, Put the Right
                            Culture in Place - Economic Times - 28 Dec 2014
                            <span className="media-date">28 Dec 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_12_55Press-Release-Red-Fox-Hotel-Delhi-Airport-December-2014.pdf"
                          >
                            Red Fox Hotel, Delhi Airport celebrates a
                            refreshingly different Christmas - 24 Dec 2014
                            <span className="media-date">24 Dec 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_12_25Press-Release-LTH-Gachibowli-launch-Hyderabad-December-2014.pdf"
                          >
                            Lemon Tree Hotels launch their third property in
                            Hyderabad. - 18 Dec 2014
                            <span className="media-date">18 Dec 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_11_54Press-Release-Lemon-Tree-Hotels-Mega-Sale-2015-December-2014.pdf"
                          >
                            Lemon Tree Hotels announce Indian hospitality
                            industry?s first ever Flash Sale - 01 Dec 2014
                            <span className="media-date">01 Dec 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_10_30TAPPING-INDIA-s-BUSINESS-POTENTIAL-Hospitality-Talk-Pg-No-34-December-2014.pdf"
                          >
                            TAPPING INDIA'S BUSINESS POTENTIAL - Hospitality
                            Talk - 01 Dec 2014
                            <span className="media-date">01 Dec 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_09_47Introducing-Advanced-Security-at-Lemon-Tree-Hotels-Aviation-Times;-Page-No-50-December-2014.pdf"
                          >
                            Introducing Advanced Security at Lemon Tree Hotels -
                            Aviation Times - 01 Dec 2014
                            <span className="media-date">01 Dec 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_09_18Lemon-Tree-Hotel-Company-Deploy-NEC-Security-System-At-Delhi-Aerocity-Hotels-Hospitality-Biz-Pg-No-13-December-2014.pdf"
                          >
                            Lemon Tree Hotel Company Deploy NEC security system
                            at Delhi Aerocity hotels - Hospitality Biz - 01 Dec
                            2014<span className="media-date">01 Dec 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_08_28Lemon-Tree-Hotel-to-invest-600-crores-by-Next-Fiscal.pdf"
                          >
                            Lemon Tree Hotel to invest 600 crores by Next
                            Fiscal, to Add 900 Rooms- The Economic Times,
                            Bangalore Edition - 01 Dec 2014
                            <span className="media-date">01 Dec 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_07_51REWARDS-IN-HOSPITALITY-INDUSTRY,-A-temptation-to-guests-Hospitality-Talk.pdf"
                          >
                            REWARDS IN HOSPITALITY INDUSTRY, A temptation to
                            guests- Hospitality Talk - 01 Dec 2014
                            <span className="media-date">01 Dec 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_04_49Lemon-Tree-Hotel-Deploy-s-NEC-India-s-face-recognition-solution-Business-Standard-November-20,-2014.pdf"
                          >
                            Lemon Tree Hotel Deploy's NEC India's face
                            recognition solution- Business Standard - 20 Nov
                            2014<span className="media-date">20 Nov 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_03_49Lemon-Tree-hotels-deploys-Face-Recognition-Solution-for-advanced-security-informationweek-in-November-20-2014.pdf"
                          >
                            Lemon Tree hotels deploys Face Recognition Solution
                            for advanced security- Information Week - 20 Nov
                            2014<span className="media-date">20 Nov 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_05_52Lemon-Tree-deploys-NEC-India-s-face-recognition-solution-The-Economic-Times-November-20-2014.pdf"
                          >
                            Lemon Tree Hotel Deploy's NEC India's face
                            recognition solution- The Economic Times - 20 Nov
                            2014<span className="media-date">20 Nov 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_07_18Lemon-Tree-born-on-a-dream,-built-on-demand-Hotel-News-Now-Nov-20-2014.pdf"
                          >
                            Lemon Tree born on a dream, built on demand - Hotel
                            News Now - 20 Nov 2014
                            <span className="media-date">20 Nov 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_02_28Lemon-Tree-Premier-a-stylish-upscale-business-hotel-and-how!-Travel-Scapes-Pg-34-35-November-12-2014.pdf"
                          >
                            Lemon Tree Premier a stylish upscale business hotel
                            and how! - Travel Scapes... Read More &gt;&gt; - 12
                            Nov 2014
                            <span className="media-date">12 Nov 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_01_39Assotech-realty-Lemon-Tree-Hotels-Group-Alliance-To-manage-And-Operate-Sandal-Suites-Serviced-Residences-Aviation-Times-Pg-21-November-12-2014.pdf"
                          >
                            Assotech realty &amp; Lemon Tree Hotels Group
                            Alliance To Manage And Operate Sandal Suites
                            Serviced Residences-Aviation Times - 12 Nov 2014
                            <span className="media-date">12 Nov 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_00_01Cornell-university-Honours-Lemon-Tree-Hotels-With-The-Prestigious-Hospitality-Exemplary-Practice-Award-Aviation-Times-Pg-20-November-12-2014.pdf"
                          >
                            Cornell University Honours Lemon Tree Hotels With
                            The Prestigious Hospitality Exemplary Practice Award
                            - Aviation Times - 12 Nov 2014
                            <span className="media-date">12 Nov 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_59_14Cornell-University-honours-Lemon-Tree-Hotels-Todays-Traveller-November-11-2014.pdf"
                          >
                            Cornell University honours Lemon Tree Hotels -
                            Today's Traveller - 11 Nov 2014
                            <span className="media-date">11 Nov 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_58_34A-HomeGrown-Hotel-Chain-Small-Enterprise-Page-No-58-61-November-2014_Vol-II.pdf"
                          >
                            A HomeGrown Hotel chain- Small Enterprise - 01 Nov
                            2014<span className="media-date">01 Nov 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_57_34Press-Release-LTH-Cornell-Exemplary-Practice-Award-October-2014.pdf"
                          >
                            Cornell University honors Lemon Tree Hotels with the
                            prestigious Hospitality Exemplary Practice Award -
                            20 Oct 2014
                            <span className="media-date">20 Oct 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_56_33Lemon-Tree-Hotels-branches-into-serviced-apartments-domain-TTGAsia-com.pdf"
                          >
                            Lemon Tree Hotels branches into serviced apartments
                            domain - TTGAsia - 10 Oct 2014
                            <span className="media-date">10 Oct 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_55_54Lemon-Tree-Hotels-to-run-Assotech-s-serviced-suites-Hindu-Business-Line-Pg-2-October-10-2014.pdf"
                          >
                            Lemon Tree Hotels to run Assotech's serviced suites-
                            Hindu Business Line - 10 Oct 2014
                            <span className="media-date">10 Oct 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_52_35Assotech-Lemon-Tree-tie-up-National-Duniya-Pg-13-October-10-2014.pdf"
                          >
                            Assotech- Lemon Tree tie-up- National Duniya - 10
                            Oct 2014
                            <span className="media-date">10 Oct 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_51_50Assotech-Realty-Lemon-tree-hotels-will-invest-900-crores-Deshbandhu-Pg-14-October-10-2014.pdf"
                          >
                            Assotech Realty &amp; Lemon tree hotels will invest
                            900 crores- Deshbandhu- Pg 14 October 10 2014 - 10
                            Oct 2014
                            <span className="media-date">10 Oct 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_50_54Assotech-to-invest-upto-Rs-1,000-crore-on-serviced-residences-The-Economic-Times-October-9,2015 (1).pdf"
                          >
                            Assotech to invest upto Rs. 1,000 crore on serviced
                            residences- The Economic Times - 09 Oct 2014
                            <span className="media-date">09 Oct 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_50_04Lemon-Tree-Hotels-signs-management-operational-contract-for-Sandal-Suites-Service-Residences-of-Assotech-Realty-Hospitality-Biz-India-October-9-,2014.pdf"
                          >
                            Lemon Tree Hotels signs management &amp; operational
                            contract for Sandal Suites Residences of Assotech
                            Realty - Hospitality Biz India (online) - 09 Oct
                            2014<span className="media-date">09 Oct 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_30_35Carnation-Hotels-announce-their-inaugural-foray-in-Coimbatore-for-Lemon-Tree-India-Infoline-News-September-9-2014.pdf"
                          >
                            Carnation Hotels announce their inaugural foray in
                            Coimbatore for Lemon Tree- India Infoline News - 09
                            Sep 2014
                            <span className="media-date">09 Sep 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_27_01Lemon-Tree-group-to-enter-Coimbatore-The-Hindu-September-9,-2014.pdf"
                          >
                            Lemon Tree group to enter Coimbatore- The Hindu - 09
                            Sep 2014
                            <span className="media-date">09 Sep 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_25_43Carnation-Hotels-announce-their-inaugural-foray-in-Coimbatore-for-Lemon-Tree-India-Infoline-News-September-9-2014.pdf"
                          >
                            Carnation Hotels announce their inaugural foray in
                            Coimbatore for Lemon Tree - 08 Sep 2014
                            <span className="media-date">08 Sep 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_22_31Lemon-Tree-introduces-Mobile-Website-AFaqs-1-Sep-2014.pdf"
                          >
                            Lemon Tree introduces mobile website - afaqs - 01
                            Sep 2014
                            <span className="media-date">01 Sep 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_21_43TOP-50-Power-List,-Leaders-of-the-Pack-Hotelier-India-Pg-78-and-88-September-2014.pdf"
                          >
                            Top 50 Power list, Leaders of the Pack - 01 Sep 2014
                            <span className="media-date">01 Sep 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_21_07A-place-to-find-solace-Terrascape-Pg90-Sep.pdf"
                          >
                            A place to find solace - Terrascape - 01 Sep 2014
                            <span className="media-date">01 Sep 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_20_29St-Stephen-s-College-and-Lemon-Tree-Hotels-awarded-Equal-Opportunity-Awards-2014-India-EduNews-29Aug-2014.pdf"
                          >
                            St. Stephen's College and Lemon Tree Hotels awarded
                            'Equal Opportunity Awards 2014'-EduNews - 29 Aug
                            2014<span className="media-date">29 Aug 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_19_23Under-the-lemon-tree-Khaleej-Times-Pg-16-18,-29-Aug-2014.pdf"
                          >
                            Under the lemon tree- Khaleej Times - 29 Aug 2014
                            <span className="media-date">29 Aug 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_17_45Lemon-Tree?s-revamped-August-29-2014.pdf"
                          >
                            Lemon Tree's revamped mobile website aims at
                            coverting visitors into customer - India Hospitality
                            Review - 29 Aug 2014
                            <span className="media-date">29 Aug 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_16_49Press-Release-Lemon-Tree-Hotel-Aurangabad-Freedom-Rally-August-2014.pdf"
                          >
                            Lemon Tree Hotel, Aurangabad organizes a Freedom
                            Rally for a fresh &amp; clean city - 15 Aug 2014
                            <span className="media-date">15 Aug 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_13_34Movers-and-Shakers-Hotelier-India-Pg14-Aug2014.pdf"
                          >
                            MOVERS &amp; SHAKERS- Nikhil Sharma- Food and
                            Hospitality World - 01 Aug 2014
                            <span className="media-date">01 Aug 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_15_17Nikhil-Sharma-appointed-as-COO-Food-and-Hospitality-World-Pg20,1Aug.pdf"
                          >
                            Nikhil Sharma appointed as COO - Hotelier India - 01
                            Aug 2014
                            <span className="media-date">01 Aug 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_12_56Hospitality-Industry-seeks-uptick-in-demand-from-Cos-and-Biz-Travellers-The-Economic-Times-July-14,-2014.pdf"
                          >
                            Hospitality Industry Seeks Uptick in demand from Cos
                            ans Biz Travellers - 14 Jul 2014
                            <span className="media-date">14 Jul 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_12_05Lemon-Tree-Hotels-introduce-the-Indian-hospitality-industry-s-first-ever-100-Money-Back-Offer-Hotelscapes-Pg52-July-2014.pdf"
                          >
                            Lemon Tree Hotels introduce the Indian hospitality
                            industry's first ever 100% Money Back Offer-
                            Hotelscapes - 01 Jul 2014
                            <span className="media-date">01 Jul 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_11_11LEMON-TREE-HOTELS-INTRODUCES-100-MONEY-BACK-OFFER-Wonderlust-July-2014.pdf"
                          >
                            LEMON TREE HOTELS INTRODUCES 100% MONEY BACK OFFER-
                            Wonderlust - 01 Jul 2014
                            <span className="media-date">01 Jul 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_10_21Lemon-Tree-introduce-money-back-offer-Travel-and-Trade-Journal-Pg-6-July-2014.pdf"
                          >
                            Lemon Tree introduce money back offer- Travel &amp;
                            Trade journal - 01 Jul 2014
                            <span className="media-date">01 Jul 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_09_05Bonjour-Pan-Asia!-Hotel-Connect-Pg-50-July-2014.pdf"
                          >
                            Bonjour Pan-Asia!-Hotel Connect - 01 Jul 2014
                            <span className="media-date">01 Jul 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_08_02Hotels-woo-customers-in-niche-segment-Hindu-Business-Line-17-June-2014.pdf"
                          >
                            Hotels woo customers in niche segment- Hindu
                            Business Line - 17 Jun 2014
                            <span className="media-date">17 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_02_49New-Venture-HT-City-Pg-3-June-13-2014.pdf"
                          >
                            New Venture- HT City - 13 Jun 2014
                            <span className="media-date">13 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_07_10Lemon-Tree-Hotel-launched-in-Doon-Himachal-Times-Pg-3-June-13-2014.pdf"
                          >
                            Lemon Tree Hotel launched in Doon - Himachal Times -
                            13 Jun 2014
                            <span className="media-date">13 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_06_26Lemon-Tree-Hotel-opens-branch-The-Tribune-Pg-5-June-13-2014.pdf"
                          >
                            Lemon Tree Hotel opens branch- The Tribune - 13 Jun
                            2014<span className="media-date">13 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_05_20Lemon-Tree-inaugurates-hotel-in-Doon-The-Pioneer-Pg-2-June-13-2014.pdf"
                          >
                            Lemon Tree inaugurates hotel in Doon- The Pioneer -
                            13 Jun 2014
                            <span className="media-date">13 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_04_25Lemon-Tree-Opens-a-branch-in-Dehradun-Rashtriya-Sahara-Pg-13-June-13-2014.pdf"
                          >
                            Lemon Tree Opens a branch in Dehradun- Rashtriya
                            Sahara - 13 Jun 2014
                            <span className="media-date">13 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_03_25Lemon-Tree-to-invest-Rs-4,500-cr-The-Deccan-Chronicle-Pg-13-June-4-2014.pdf"
                          >
                            Lemon Tree to invest Rs 4,500 cr - The Deccan
                            Chronicle - 13 Jun 2014
                            <span className="media-date">13 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_01_57Now,-Dehradun-has-a-Lemon-Tree-Hotel-Mid-Day-Pg-5-June-13-2014.pdf"
                          >
                            Now, Dehradun has a Lemon Tree Hotel- Mid-Day - 13
                            Jun 2014
                            <span className="media-date">13 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_00_45Lemon-Tree-hotels-eye-customer-loyalty-Mail-Today-5-June-2014.pdf"
                          >
                            Lemon Tree hotels eye customer loyalty-Mail Today -
                            05 Jun 2014
                            <span className="media-date">05 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_05_00_04No-plan-to-enter-luxury-space-Lemon-Tree-Hotels-Hindu-Business-Line-Pg-2-June-4-2014.pdf"
                          >
                            No plan to enter luxury space: Lemon Tree Hotels-
                            Hindu Business Line - 04 Jun 2014
                            <span className="media-date">04 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_58_37Lemon-Tree-To-Invest-Rs-4,500-CR-Asian-Age-Pg-16-June-4-2014.pdf"
                          >
                            Lemon Tree To Invest Rs 4,500 CR- The Asian Age - 04
                            Jun 2014
                            <span className="media-date">04 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_57_56Lemon-Tree-Hotel-to-Invest-Rs-4,500-crore-Over-four-Years-The-Economic-Times-Pg-5-June-4-2014.pdf"
                          >
                            Lemon Tree Hotel to Invest Rs. 4,500 crore Over four
                            Years- The Economic Times - 04 Jun 2014
                            <span className="media-date">04 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_56_2949-rooms-hotel-opens-Amar-Ujala-Pg-4-June-14-2014.pdf"
                          >
                            49 rooms hotel opens- Amar Ujala - 04 Jun 2014
                            <span className="media-date">04 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_55_46Final-Press-Release-_30-5-14_.pdf"
                          >
                            100% Money Back Offer - 03 Jun 2014
                            <span className="media-date">03 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_36_09Social-Responsibility-FHRAI-Pg-26-28-June-2014.pdf"
                          >
                            Social Responsibility - FHRAI - 01 Jun 2014
                            <span className="media-date">01 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_35_04Press-release-Lemon-Tree,-Dehradun-June-2014.pdf"
                          >
                            Lemon Tree Hotels launch their first property in
                            Uttarakhand - 01 Jun 2014
                            <span className="media-date">01 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_36_46Republic-of-Noodles-comes-to-Delhi-FHRAI-Pg-68-June-2014.pdf"
                          >
                            Republic of Noodles comes to Delhi-FHRAI - 01 Jun
                            2014<span className="media-date">01 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_37_50Lemon-Tree-debuts-in-a-new-avatar-Hotelier-India-June-2014.pdf"
                          >
                            Lemon Tree debuts in a new avatar- Hotelier India -
                            01 Jun 2014
                            <span className="media-date">01 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_54_40Award-Winning-Restaurant-Republic-Of-Noodles-Comes-to-Delhi-Aviation-Times-Pg-28-June-2014.pdf"
                          >
                            Award Winning Restaurant 'Republic Of Noodles' Comes
                            to Delhi-Aviation Times - 01 Jun 2014
                            <span className="media-date">01 Jun 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_33_58Mid-segment-hotels-poised-to-give-stiff-competition-to-5-stars-DNA-Pg-3-May-24-2014.pdf"
                          >
                            Mid-segment hotels poised to give stiff competition
                            to 5 stars -DNA India - 24 May 2014
                            <span className="media-date">24 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_33_04New-hotel-inaugurated-The-New-Indian-Express-Pg-5-May-24-2014.pdf"
                          >
                            New hotel inaugurated - The New Indian Express - 24
                            May 2014
                            <span className="media-date">24 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_32_09Lemon-Tree-Hotels-Whitefield-opens-in-Bengaluru-Travel-Biz-Monitor-May-24-2014.pdf"
                          >
                            Lemon Tree Hotels Whitefield opens in Bengaluru-
                            Travel Biz Monitor - 24 May 2014
                            <span className="media-date">24 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_31_20Lemon-Tree-pumps-in-Rs-1,000-cr-to-ramp-up-room-capacity-Hindu-Business-Line-May-13-2014.pdf"
                          >
                            Lemon Tree pumps in Rs 1,000 cr to ramp up room
                            capacity- Hindu Business line - 13 May 2014
                            <span className="media-date">13 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_30_17Republic-Of-Noodles,-Aerocity-First-Look-Times-City-May-12-2014.pdf"
                          >
                            Republic Of Noodles, Aerocity: First Look - Times
                            City - 12 May 2014
                            <span className="media-date">12 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_29_22Lemon-Tree-to-triple-inventory-in-3-years-Financial-Chronicle-Pg-6-May-8-2014.pdf"
                          >
                            Lemon Tree to triple inventory in 3 years -
                            Financial Chronicle - 08 May 2014
                            <span className="media-date">08 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_28_45Press-Release-LTH-Whitefield-Bengaluru-May-2014.pdf"
                          >
                            Lemon Tree Hotel debuts in a new avatar in
                            Whitefield, Bengaluru - 07 May 2014
                            <span className="media-date">07 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_24_18Lemon-Tree-Hotels-debuts-in-a-new-avatar-in-Whitefield,-Bengaluru-Hotelscapes-Pg-58-May-2014.pdf"
                          >
                            Lemon Tree Hotels debuts in a new avatar in
                            Whitefield, Bengaluru - Hotelscapes - 01 May 2014
                            <span className="media-date">01 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_25_07Branching-out-Hotelier-India-Pg-26-28-May-2014.pdf"
                          >
                            Branching out- Hotelier india - 01 May 2014
                            <span className="media-date">01 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_25_44Republic-of-Noodles-A-Voyage-To-The-Essence-Of-South-East-Asia-Aviation-Times-May-2014.pdf"
                          >
                            Republic of Noodles- A Voyage To The Essence Of
                            South East Asia- Aviation Times - 01 May 2014
                            <span className="media-date">01 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_27_46Value-for-money-at-Lemon-Tree-Hotels-Go-Now-Pg-140-May-2014.pdf"
                          >
                            Value for money at Lemon Tree Hotels - Go Now - 01
                            May 2014
                            <span className="media-date">01 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_26_53Republic-of-Noodles-A-perfect-symphony-of-South-East-Asia-Wonderlust-Pg-54-May-2014.pdf"
                          >
                            Republic of Noodles: A perfect symphony of South
                            East Asia - Wonderlust - 01 May 2014
                            <span className="media-date">01 May 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_23_26Press-Release-LTP-RON-launch-Delhi-Airport-April-2014.pdf"
                          >
                            Award winning restaurant ?Republic of Noodles? comes
                            to Delhi - 16 Apr 2014
                            <span className="media-date">16 Apr 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_22_42Coverage-of-Lemon-Tree-survey-conducted-by-Ixigo.pdf"
                          >
                            What Women Want' From Hotels? Ixigo-LemonTree Survey
                            Reveals All - 06 Mar 2014
                            <span className="media-date">06 Mar 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_21_48Coverage-of-LTH-in-Lonely-Planet.pdf"
                          >
                            Lonely Planet - 01 Mar 2014
                            <span className="media-date">01 Mar 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_19_59Coverage-of-LTP-RFH-in-Go-Now.pdf"
                          >
                            Republic of Noodles is among eateries at Lemon Tree
                            Premier - 01 Mar 2014
                            <span className="media-date">01 Mar 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_10_03Coverage-of-National-Tourism-Award.pdf"
                          >
                            Winners of National Tourism Award 2012-13 - 01 Mar
                            2014<span className="media-date">01 Mar 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_09_04Coverage-of-RON-Jaipur-in-Simply-Jaipur-March-2014.pdf"
                          >
                            Hotel Lemon Tree Premier announces the launch of
                            Republic of Noodles - 01 Mar 2014
                            <span className="media-date">01 Mar 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_06_44Coverage-of-an-announcement-of-Lemon-Tree-Hotel-in-Dehradun_1.pdf"
                          >
                            Coverage of an announcement of Lemon Tree Hotel in
                            Dehradun_1 - 25 Feb 2014
                            <span className="media-date">25 Feb 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_05_30Coverage-of-an-announcement-of-Lemon-Tree-Hotel-in-Dehradun.pdf"
                          >
                            Coverage of an announcement of Lemon Tree Hotel in
                            Dehradun - 21 Feb 2014
                            <span className="media-date">21 Feb 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_04_05Coverage-of-an-announcement-of-Lemon-Tree-Hotel-in-Dehradun.pdf"
                          >
                            Coverage of an announcement of Lemon Tree Hotel in
                            Dehradun_2 - 20 Feb 2014
                            <span className="media-date">20 Feb 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_01_42Coverage-Lemon-Tree-Premier-and-Red-Fox-Hotel,-Delhi-Airport.pdf"
                          >
                            Coverage Lemon Tree Premier and Red Fox Hotel, Delhi
                            Airport - 01 Feb 2014
                            <span className="media-date">01 Feb 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_56_33Consolidated-coverage-of-Rattan-Keswani-appointment (1).pdf"
                          >
                            Lemon Tree Hotel has appointed Rattan Keswani - 01
                            Feb 2014
                            <span className="media-date">01 Feb 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_59_43Coverage-Lemon-Tree-Premier-and-Red-Fox-Hotel,-Delhi-Airport_2.pdf"
                          >
                            Coverage Lemon Tree Premier and Red Fox Hotel, Delhi
                            Airport_2 - 01 Feb 2014
                            <span className="media-date">01 Feb 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_00_48Coverage-Lemon-Tree-Premier-and-Red-Fox-Hotel,-Delhi-Airport_1.pdf"
                          >
                            Coverage Lemon Tree Premier and Red Fox Hotel, Delhi
                            Airport_1 - 01 Feb 2014
                            <span className="media-date">01 Feb 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_02_36Coverage-Lemon-Tree-Premier-and-Red-Fox-Hotel,-Delhi-Airport-in-Aviation-Times-February-2014.pdf"
                          >
                            Coverage Lemon Tree Premier and Red Fox Hotel, Delhi
                            Airport in Aviation Times- February 2014 - 01 Feb
                            2014<span className="media-date">01 Feb 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_55_07Coverage-Lemon-Tree-Premier-and-Red-Fox-Hotel,-Delhi-Airport-1.pdf"
                          >
                            Coverage Lemon Tree Premier and Red Fox Hotel, Delhi
                            Airport 1 - 31 Jan 2014
                            <span className="media-date">31 Jan 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_54_05Coverage-of-Lemon-Tree-Hotels-in-The-Indian-Express.pdf"
                          >
                            Coverage of Lemon Tree Hotels in The Indian Express
                            - 27 Jan 2014
                            <span className="media-date">27 Jan 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_53_13Coverage-of-Republic-of-Noodles-in-The-Sunday-Standard.pdf"
                          >
                            Coverage of Republic of Noodles in The Sunday
                            Standard - 26 Jan 2014
                            <span className="media-date">26 Jan 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_52_10Coverage-of-National-Tourism-Award-2012-2013.pdf"
                          >
                            Coverage of National Tourism Award 2012- 2013 - 20
                            Jan 2014
                            <span className="media-date">20 Jan 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_51_15Press-Release-LTP-RON-Jaipur-Chef-Table-February-2014.pdf"
                          >
                            Jaipur gets its first authentic pan-Asian
                            restaurant; Republic of Noodles opens at Lemon Tree
                            Premier - 20 Jan 2014
                            <span className="media-date">20 Jan 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_50_03Coverage-of-Lemon-Tree-Hotels-in-Business-India (1).pdf"
                          >
                            Coverage of Lemon Tree Hotels in Business India - 19
                            Jan 2014
                            <span className="media-date">19 Jan 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_49_12Coverage-of-Lemon-Tree-Hotels-in-Businessworld.pdf"
                          >
                            Coverage of Lemon Tree Hotels in Businessworld - 19
                            Jan 2014
                            <span className="media-date">19 Jan 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_48_10Coverage-Lemon-Tree-Premier-and-Red-Fox-Hotel,-Delhi-Airport.pdf"
                          >
                            New Delhi Aerocity welcomes more hotel openings - 13
                            Jan 2014
                            <span className="media-date">13 Jan 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_46_54Coverage-of-Lemon-Tree-Hotels-in-Business-India.pdf"
                          >
                            Busines India - 06 Jan 2014
                            <span className="media-date">06 Jan 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_39_30Coverage-of-Lemon-Tree-Premier (1).pdf"
                          >
                            Coverage of Lemon Tree Premier - 01 Jan 2014
                            <span className="media-date">01 Jan 2014</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_46_04Coverage-of-Lemon-Tree-Premier (1).pdf"
                          >
                            Coverage of Lemon Tree Premier - 01 Jan 2014
                            <span className="media-date">01 Jan 2014</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2013">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2013"
                    aria-expanded="false"
                    aria-controls="collapse2013"
                  >
                    2013
                  </button>
                </h5>
              </div>
              <div
                id="collapse2013"
                className="collapse"
                aria-labelledby="heading2013"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_10_55_26Coverage-of-Lemon-Tree-Premier,-Jaipur.pdf"
                          >
                            Tulip turns into Lemon?for good - 04 Sep 2013
                            <span className="media-date">04 Sep 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_10_54_53HOTELIER-INDIA-VOLUME-5-ISSUE-9-SEPTEMBER-2013-TOP-100-MOST-INFLUENT.pdf"
                          >
                            Top 100 Most Influential People in the Hospitality
                            Industry - 01 Sep 2013
                            <span className="media-date">01 Sep 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_10_53_39Hindu-Business-Line.pdf"
                          >
                            Lemon Tree buys The Clarion, Bengaluru - 11 Jul 2013
                            <span className="media-date">11 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_10_52_35Economictimes-com.pdf"
                          >
                            Fleur Hotels acquires Clarion Hotel, Bengaluru for
                            Rs 64 crore - 11 Jul 2013
                            <span className="media-date">11 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_13_59Hospemag-com.pdf"
                          >
                            Fleur Hotels acquires Clarion Hotel, Bengaluru for
                            Rs 64 crore - 10 Jul 2013
                            <span className="media-date">10 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_10_51_11Reuters-in.pdf"
                          >
                            Asian Hotels (West) sells its 50.49 pct stake in
                            Clarion Bengaluru to Lemon Tree - 10 Jul 2013
                            <span className="media-date">10 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_10_50_02Lookbangalore-com.pdf"
                          >
                            Lemon Tree Hotels acquires the Clarion Hotel in
                            Whitefield, Bengaluru - 10 Jul 2013
                            <span className="media-date">10 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_20_25Indiahospitalityreview-com.pdf"
                          >
                            The Clarion Hotel, Bengaluru to be rebranded as
                            Lemon Tree Hotel - 10 Jul 2013
                            <span className="media-date">10 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_19_26Lookbangalore-com (1).pdf"
                          >
                            Lemon Tree Hotels acquire the Clarion Hotel in
                            Whitefield, Bengaluru - 10 Jul 2013
                            <span className="media-date">10 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_18_33Infranews-in.pdf"
                          >
                            Fleur Hotels acquires Clarion Hotel, Bengaluru for
                            Rs 64 crore - 10 Jul 2013
                            <span className="media-date">10 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_17_27Hospitalitybizindia-com.pdf"
                          >
                            Fleur Hotels acquires Clarion Hotel, Bengaluru for
                            Rs 64 crore - 10 Jul 2013
                            <span className="media-date">10 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_16_36Financial-Chronicle.pdf"
                          >
                            Fleur Hotels buys Clarion Hotel - 10 Jul 2013
                            <span className="media-date">10 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_15_04Zimbio-com.pdf"
                          >
                            Fleur Hotels acquires Clarion Hotel, Bengaluru for
                            Rs 64 crore - 10 Jul 2013
                            <span className="media-date">10 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_12_57Mint-com.pdf"
                          >
                            Fleur Hotels acquires Clarion Hotel, Bengaluru for
                            Rs 64 crore - 09 Jul 2013
                            <span className="media-date">09 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_08_21Economictimes-com (1).pdf"
                          >
                            Fleur Hotels acquires Clarion Hotel, Bengaluru for
                            Rs 64 crore - 09 Jul 2013
                            <span className="media-date">09 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_07_31VCCircle-com.pdf"
                          >
                            Asian Hotels (West) sells its 50.49% stake in
                            Clarion Bengaluru to Lemon Tree - 09 Jul 2013
                            <span className="media-date">09 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_06_45LT-Pune.pdf"
                          >
                            Ideally located business hotel - 01 Jul 2013
                            <span className="media-date">01 Jul 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_06_03Indiahospitalityreview-com (1).pdf"
                          >
                            Maahesh S Aiyer Appointed VP- Operations Lemon Tree
                            Premier - 17 Jun 2013
                            <span className="media-date">17 Jun 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_05_11Financial-World.pdf"
                          >
                            A Family With Elders - 23 Apr 2013
                            <span className="media-date">23 Apr 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_03_59Coverage-of-Lemon-Tree-Premier,-Jaipur (1).pdf"
                          >
                            Fresh Appeal - 01 Apr 2013
                            <span className="media-date">01 Apr 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_03_2336-Hours-in-Bangalore.pdf"
                          >
                            36 Hours in Bangalore, India - 28 Mar 2013
                            <span className="media-date">28 Mar 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_07_02_48Coverage-of-Red-Fox-Hotel.pdf"
                          >
                            Home Like Comfort at Red Fox Hotel, Jaipur - 01 Mar
                            2013<span className="media-date">01 Mar 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_58_28The-Indian-Hotel-Group-Where-GMs.pdf"
                          >
                            The Indian Hotel Group Where GMs ?Speak? Sign
                            Language - 28 Feb 2013
                            <span className="media-date">28 Feb 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_56_47Lemon-Tree-Hotels-in-Business-Standard.pdf"
                          >
                            New hoteliers bet big on the budget traveler - 22
                            Feb 2013
                            <span className="media-date">22 Feb 2013</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_06_53_50Lemon-Tree-Hotels-wins-National-Award-Presented-by-the-Ministry-of-Social-Justice-and-Empowerment,-Government-of-India-(2).pdf"
                          >
                            Lemon Tree Hotels wins National Award - 07 Feb 2013
                            <span className="media-date">07 Feb 2013</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2012">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2012"
                    aria-expanded="false"
                    aria-controls="collapse2012"
                  >
                    2012
                  </button>
                </h5>
              </div>
              <div
                id="collapse2012"
                className="collapse"
                aria-labelledby="heading2012"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_12_16_53Lemon-Tree-Hotels-conferred-NCPEDP-Shell-Helen-Keller-Award-for-2012.pdf"
                          >
                            Lemon Tree Hotels conferred NCPEDP-Shell Helen
                            Keller Award for 2012 - 04 Dec 2012
                            <span className="media-date">04 Dec 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_12_16_21Shell-Helen-Keller-award-Lemon-Tree-Hotels-in-The-Hindu.pdf"
                          >
                            Shell Helen Keller Awards for seven men and women -
                            03 Dec 2012
                            <span className="media-date">03 Dec 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_12_15_53NCPEDP-Shell-Helen-Keller-Award-Brochure-2012.pdf"
                          >
                            NCPEDP-Shell-Helen-Keller-Award-Brochure-2012 - 02
                            Dec 2012
                            <span className="media-date">02 Dec 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_12_15_13Times-Crest-(The-Times-of-India).pdf"
                          >
                            Can and able - 01 Dec 2012
                            <span className="media-date">01 Dec 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_12_04_09Lemon-Tree-Hotels-wins-National-Award-president-by-the-ministry-of-social-justice-and-empowerment,-Government-of-India.pdf"
                          >
                            Lemon Tree Hotels wins National Award Presented by
                            the Ministry of Social Justice and Empowerment,
                            Government of India - 27 Nov 2012
                            <span className="media-date">27 Nov 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_57_591.pdf"
                          >
                            Lemon Tree Vembanad Lake Resort, Muhamma-West of
                            Kumarakom, Kerala wins CNBC AWAAZ Travel Awards 2012
                            - 22 Nov 2012
                            <span className="media-date">22 Nov 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_50_022.pdf"
                          >
                            Lemon Tree Vembanad Lake Resort, Muhamma-West of
                            Kumarakom, Kerala wins CNBC AWAAZ Travel Awards 2012
                            - 22 Nov 2012
                            <span className="media-date">22 Nov 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_48_413.pdf"
                          >
                            Lemon Tree Vembanad Lake Resort, Muhamma-West of
                            Kumarakom, Kerala wins CNBC AWAAZ Travel Awards 2012
                            - 22 Nov 2012
                            <span className="media-date">22 Nov 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_44_274.pdf"
                          >
                            Lemon Tree Vembanad Lake Resort, Muhamma-West of
                            Kumarakom, Kerala wins CNBC AWAAZ Travel Awards 2012
                            - 22 Nov 2012
                            <span className="media-date">22 Nov 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_42_36Lemon-Tree-Hotels-wins-National-Award-Presented-by-the-Ministry-of-Social-Justice-and-Empowerment,-Government-of-India-Indianshowbiz.pdf"
                          >
                            Lemon Tree Wins National Award - 20 Nov 2012
                            <span className="media-date">20 Nov 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_38_58Lemon-Tree-Hotels-Go-Now,-November,-2012.pdf"
                          >
                            A Dining Getaway at Lemon Tree Hotel, Goa, the
                            Republic of Noodles - 09 Nov 2012
                            <span className="media-date">09 Nov 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_37_42Lemon-Tree-Hotels-celebrates-its-10th-anniversary;-announces-the-10-2-offer.pdf"
                          >
                            Lemon Tree Hotels celebrates its 10th anniversary;
                            announces the 10+2 offer - 08 Nov 2012
                            <span className="media-date">08 Nov 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_36_436.pdf"
                          >
                            Lemon with a new punch - 14 May 2012
                            <span className="media-date">14 May 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_35_297.pdf"
                          >
                            Lemon Tree's $800m quest: 2000 rooms and counting -
                            04 May 2012
                            <span className="media-date">04 May 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_31_309.pdf"
                          >
                            Dutch PF manager APG invests Rs 650 crore in Lemon
                            Tree Hotels; forms JV to build 35 hotels - 02 May
                            2012<span className="media-date">02 May 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_30_158.pdf"
                          >
                            Lemon Tree Hotels Announces Strategic Partnership
                            With APG - 01 May 2012
                            <span className="media-date">01 May 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_29_2410.pdf"
                          >
                            Quick fixers and demand drivers - 21 Mar 2012
                            <span className="media-date">21 Mar 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_28_0911.pdf"
                          >
                            Lemon Tree Enters Upscale Market Segment With
                            Premier - 15 Mar 2012
                            <span className="media-date">15 Mar 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_27_0712.pdf"
                          >
                            Lemon Tree: Rapid Growth - 08 Mar 2012
                            <span className="media-date">08 Mar 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_26_0414.pdf"
                          >
                            Lemon Tree Hotels honoured with ?Best Employer?
                            National Award - 01 Mar 2012
                            <span className="media-date">01 Mar 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_24_1713.pdf"
                          >
                            Lemon Tree out to prove a point with business recast
                            - 01 Feb 2012
                            <span className="media-date">01 Feb 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_19_2017.pdf"
                          >
                            Lemon Tree Launched ?Premier?? - 05 Jan 2012
                            <span className="media-date">05 Jan 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_16_4016 (1).pdf"
                          >
                            Homegrown hotel cos take on MNC biggies, launch new
                            brands to cater - 01 Jan 2012
                            <span className="media-date">01 Jan 2012</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/26_Jul_2023_11_18_1315.pdf"
                          >
                            Lemon Tree Hotels Unveils Its Premium Brand ?Lemon
                            Tree Premier? - 01 Jan 2012
                            <span className="media-date">01 Jan 2012</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2011">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2011"
                    aria-expanded="false"
                    aria-controls="collapse2011"
                  >
                    2011
                  </button>
                </h5>
              </div>
              <div
                id="collapse2011"
                className="collapse"
                aria-labelledby="heading2011"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_49_59Lemon Tree Hotels bags the coveted Times Travel Academy Award.pdf"
                          >
                            Lemon Tree Hotels bags the coveted Times Travel
                            Academy Award - 21 Dec 2011
                            <span className="media-date">21 Dec 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_50_29UNDER THE LEMON TREE.pdf"
                          >
                            UNDER THE LEMON TREE - 17 Dec 2011
                            <span className="media-date">17 Dec 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_51_05The mind behind the brand.pdf"
                          >
                            The mind behind the brand - 15 Dec 2011
                            <span className="media-date">15 Dec 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_51_39Can And Able.pdf"
                          >
                            Can And Able - 02 Dec 2011
                            <span className="media-date">02 Dec 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_52_27In Conversation.pdf"
                          >
                            In Conversation - 15 Nov 2011
                            <span className="media-date">15 Nov 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_53_38Lemon Tree Hotels awarded with NCPEDP MphasiS Universal Design Award.pdf"
                          >
                            Lemon Tree Hotels awarded with NCPEDP MphasiS
                            Universal Design Award - 21 Sep 2011
                            <span className="media-date">21 Sep 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_54_08Distinguished accomplishment.pdf"
                          >
                            Distinguished accomplishment - 21 Sep 2011
                            <span className="media-date">21 Sep 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_54_11Distinguished accomplishment.pdf"
                          >
                            Distinguished accomplishment - 21 Sep 2011
                            <span className="media-date">21 Sep 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_55_03Republic of good taste.pdf"
                          >
                            Republic of good taste - 10 Sep 2011
                            <span className="media-date">10 Sep 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_55_43Lemon Tree Hotels Launches Its 14th Property.pdf"
                          >
                            Lemon Tree Hotels Launches Its 14th Property - 05
                            Jul 2011
                            <span className="media-date">05 Jul 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_56_25Patu Keswani, entrepreneur by chance.pdf"
                          >
                            Patu Keswani, entrepreneur by chance - 02 Jun 2011
                            <span className="media-date">02 Jun 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_57_3611.pdf"
                          >
                            {" "}
                            A fresh approach - 31 Mar 2011
                            <span className="media-date">31 Mar 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_58_17Living it up in style and bliss at Goa.pdf"
                          >
                            Living it up in style and bliss at Goa - 05 Mar 2011
                            <span className="media-date">05 Mar 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_58_51He gave up the suits.pdf"
                          >
                            He gave up the suits - 16 Feb 2011
                            <span className="media-date">16 Feb 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_59_54Besides focusing on consolidation, we are excited about our initiative in providing employment to people with disabilities.pdf"
                          >
                            Besides focusing on consolidation, we are excited
                            about our initiative in providing employment to
                            people with disabilities - 01 Feb 2011
                            <span className="media-date">01 Feb 2011</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_04_00_37Lemon Tree Hotels Announces its 13th Hotel in India.pdf"
                          >
                            Lemon Tree Hotels Announces its 13th Hotel in India
                            - 12 Jan 2011
                            <span className="media-date">12 Jan 2011</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2010">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2010"
                    aria-expanded="false"
                    aria-controls="collapse2010"
                  >
                    2010
                  </button>
                </h5>
              </div>
              <div
                id="collapse2010"
                className="collapse"
                aria-labelledby="heading2010"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_39_39Republic of Noodles.pdf"
                          >
                            Republic of Noodles - 13 Dec 2010
                            <span className="media-date">13 Dec 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_41_072.pdf"
                          >
                            {" "}
                            The challange of our times: Taking stock - 09 Dec
                            2010<span className="media-date">09 Dec 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_41_52JUST CHILL.pdf"
                          >
                            JUST CHILL - 09 Dec 2010
                            <span className="media-date">09 Dec 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_42_34Lemon Tree Hotels bestowed with NCPEDP?Shell Helen Keller Award.pdf"
                          >
                            Lemon Tree Hotels bestowed with NCPEDP?Shell Helen
                            Keller Award - 09 Dec 2010
                            <span className="media-date">09 Dec 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_43_14Lemon Tree Vembanad Lake Resort.pdf"
                          >
                            Lemon Tree Vembanad Lake Resort - 09 Dec 2010
                            <span className="media-date">09 Dec 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_43_45Agra Convention Programme.pdf"
                          >
                            Agra Convention Programme - 09 Dec 2010
                            <span className="media-date">09 Dec 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_44_30No rush for bookings yet but Delhi hotels to raise rates during Games.pdf"
                          >
                            No rush for bookings yet but Delhi hotels to raise
                            rates during Games - 09 Dec 2010
                            <span className="media-date">09 Dec 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_45_188.pdf"
                          >
                            {" "}
                            Pressing the Green Button, Hotel Business Review,
                            July ?August?10 - 08 Dec 2010
                            <span className="media-date">08 Dec 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_45_57Holidaying abroad may be a smarter option this New Year.pdf"
                          >
                            Holidaying abroad may be a smarter option this New
                            Year - 26 Nov 2010
                            <span className="media-date">26 Nov 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_46_27Lemon Tree targets affordable housing.pdf"
                          >
                            Lemon Tree targets affordable housing - 04 Nov 2010
                            <span className="media-date">04 Nov 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_46_59Just check in for a tangy experience.pdf"
                          >
                            Just check in for a tangy experience - 07 Apr 2010
                            <span className="media-date">07 Apr 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_47_42Lemon Tree aims to be 2nd largest hotel company.pdf"
                          >
                            Lemon Tree aims to be 2nd largest hotel company - 20
                            Mar 2010
                            <span className="media-date">20 Mar 2010</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_48_23Have a romantic weekend in a portugese mansion.pdf"
                          >
                            Have a romantic weekend in a portugese mansion - 16
                            Mar 2010
                            <span className="media-date">16 Mar 2010</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2009">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2009"
                    aria-expanded="false"
                    aria-controls="collapse2009"
                  >
                    2009
                  </button>
                </h5>
              </div>
              <div
                id="collapse2009"
                className="collapse"
                aria-labelledby="heading2009"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_26_56The Growth Of The Lemon Tree.pdf"
                          >
                            The Growth Of The Lemon Tree - 25 Dec 2009
                            <span className="media-date">25 Dec 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_28_13At Citrus Sink Into Slounge & Enjoy Spring Chicken.pdf"
                          >
                            At Citrus Sink Into Slounge &amp; Enjoy Spring
                            Chicken - 28 Nov 2009
                            <span className="media-date">28 Nov 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_28_56Forbes India.pdf"
                          >
                            Forbes India - 20 Nov 2009
                            <span className="media-date">20 Nov 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_49_09Ignorance of ability brings disability.pdf"
                          >
                            Ignorance of ability brings disability - 07 Nov 2009
                            <span className="media-date">07 Nov 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_31_34Maverick on the Move.pdf"
                          >
                            Maverick on the Move - 04 Oct 2009
                            <span className="media-date">04 Oct 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_29_30Slowdown proves not so bad for mid-market hotels.pdf"
                          >
                            Slowdown proves not so bad for mid-market hotels -
                            01 Oct 2009
                            <span className="media-date">01 Oct 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_30_11The mid-market monopoly.pdf"
                          >
                            The mid-market monopoly - 01 Oct 2009
                            <span className="media-date">01 Oct 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_30_48Ten Indian hotels amongst world's favourite list.pdf"
                          >
                            Ten Indian hotels amongst world's favourite list -
                            01 Oct 2009
                            <span className="media-date">01 Oct 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_32_52Who moved into my cheese.pdf"
                          >
                            Who moved into my cheese? - 12 Sep 2009
                            <span className="media-date">12 Sep 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_33_33Refreshingly different.pdf"
                          >
                            Refreshingly different - 01 Sep 2009
                            <span className="media-date">01 Sep 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_34_04Aurangabad Circuit.pdf"
                          >
                            Aurangabad Circuit - 01 Sep 2009
                            <span className="media-date">01 Sep 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_34_36New players in mid-market hotel segment.pdf"
                          >
                            New players in mid-market hotel segment - 24 Aug
                            2009<span className="media-date">24 Aug 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_35_14Indian hoteliers exchange swimming pools for price.pdf"
                          >
                            Indian hoteliers exchange swimming pools for price -
                            24 Aug 2009
                            <span className="media-date">24 Aug 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_35_55Go back!.pdf"
                          >
                            Go back! - 17 Aug 2009
                            <span className="media-date">17 Aug 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_36_29Lemon Mania.pdf"
                          >
                            Lemon Mania - 13 Aug 2009
                            <span className="media-date">13 Aug 2009</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_37_31Top 3 places to holiday in GOA.pdf"
                          >
                            Top 3 places to holiday in GOA - 09 Aug 2009
                            <span className="media-date">09 Aug 2009</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2008">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2008"
                    aria-expanded="false"
                    aria-controls="collapse2008"
                  >
                    2008
                  </button>
                </h5>
              </div>
              <div
                id="collapse2008"
                className="collapse"
                aria-labelledby="heading2008"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_19_35Lemon Tree adds 3 more properties.pdf"
                          >
                            Lemon Tree adds 3 more properties - 27 Dec 2008
                            <span className="media-date">27 Dec 2008</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_20_21Lemon Tree Hotels launches three hotels.pdf"
                          >
                            Lemon Tree Hotels launches three hotels - 27 Dec
                            2008<span className="media-date">27 Dec 2008</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_20_59Hospitality Sector_ Present tense future uncertain.pdf"
                          >
                            Hospitality Sector: Present tense future uncertain -
                            26 Dec 2008
                            <span className="media-date">26 Dec 2008</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_21_38Lemon Tree invests Rs 90 crore in three hotel projects.pdf"
                          >
                            Lemon Tree invests Rs 90 crore in three hotel
                            projects - 24 Dec 2008
                            <span className="media-date">24 Dec 2008</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_22_21Lemon Tree to roll out first ?Red Fox? in Jaipur in Oct,2009.pdf"
                          >
                            Lemon Tree to roll out first ?Red Fox? in Jaipur in
                            Oct,2009 - 21 Dec 2008
                            <span className="media-date">21 Dec 2008</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_22_56Lemon Tree launches three new hotels.pdf"
                          >
                            Lemon Tree launches three new hotels - 20 Dec 2008
                            <span className="media-date">20 Dec 2008</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_24_47Goa?s New Year eve bash still on.pdf"
                          >
                            Goa?s New Year eve bash still on - 17 Dec 2008
                            <span className="media-date">17 Dec 2008</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_25_17Finmin rejects tourism pitch for subsidies to budget hotels.pdf"
                          >
                            Finmin rejects tourism pitch for subsidies to budget
                            hotels - 17 Dec 2008
                            <span className="media-date">17 Dec 2008</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_23_27Scenic Goa.pdf"
                          >
                            Scenic Goa - 14 Dec 2008
                            <span className="media-date">14 Dec 2008</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_24_03Must Try.pdf"
                          >
                            Must Try - 01 Dec 2008
                            <span className="media-date">01 Dec 2008</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2007">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2007"
                    aria-expanded="false"
                    aria-controls="collapse2007"
                  >
                    2007
                  </button>
                </h5>
              </div>
              <div
                id="collapse2007"
                className="collapse"
                aria-labelledby="heading2007"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_56_28Seaside Idyl.pdf"
                          >
                            Seaside Idyl - 01 Dec 2007
                            <span className="media-date">01 Dec 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_57_12Right Place, Right Time.pdf"
                          >
                            {" "}
                            Right Place, Right Time - 28 Nov 2007
                            <span className="media-date">28 Nov 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_57_52GOA CALLING.pdf"
                          >
                            {" "}
                            GOA CALLING - 17 Nov 2007
                            <span className="media-date">17 Nov 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_58_29Technology corridors lure hotel chains to IT hotspots.pdf"
                          >
                            Technology corridors lure hotel chains to IT
                            hotspots - 15 Nov 2007
                            <span className="media-date">15 Nov 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_59_05Hospitality takes on a different challenge.pdf"
                          >
                            Hospitality takes on a different challenge - 14 Nov
                            2007<span className="media-date">14 Nov 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_02_00_07Lemon Tree plans to hive off assets into separate branch.pdf"
                          >
                            Lemon Tree plans to hive off assets into separate
                            branch - 05 Nov 2007
                            <span className="media-date">05 Nov 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_10_52Dog House.pdf"
                          >
                            Dog House - 05 Nov 2007
                            <span className="media-date">05 Nov 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_11_27India's Lemon Tree Hotels signs deal with SynXis.pdf"
                          >
                            India's Lemon Tree Hotels signs deal with SynXis -
                            02 Nov 2007
                            <span className="media-date">02 Nov 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_12_43Lemon Tree acquires two hotels on lease.pdf"
                          >
                            Lemon Tree acquires two hotels on lease - 01 Nov
                            2007<span className="media-date">01 Nov 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_13_26PATU KESWANI deconstructed.pdf"
                          >
                            {" "}
                            PATU KESWANI deconstructed - 01 Nov 2007
                            <span className="media-date">01 Nov 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_12_05India?s freshest hotel group smells success with Synxis.pdf"
                          >
                            India?s freshest hotel group smells success with
                            Synxis - 31 Oct 2007
                            <span className="media-date">31 Oct 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_14_03Lemon Tree acquires Hotel Planet.pdf"
                          >
                            Lemon Tree acquires Hotel Planet - 22 Oct 2007
                            <span className="media-date">22 Oct 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_14_54Lemon Tree acquires two hotels on lease1.pdf"
                          >
                            Lemon Tree acquires two hotels on lease - 16 Oct
                            2007<span className="media-date">16 Oct 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_16_53ESOP_Paving hospitality growth.pdf"
                          >
                            ESOP: Paving hospitality growth - 16 Oct 2007
                            <span className="media-date">16 Oct 2007</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_03_17_47Lemon Tree Hotels expands into Aurangabad and Indore.pdf"
                          >
                            Lemon Tree Hotels expands into Aurangabad and Indore
                            - 15 Oct 2007
                            <span className="media-date">15 Oct 2007</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2006">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2006"
                    aria-expanded="false"
                    aria-controls="collapse2006"
                  >
                    2006
                  </button>
                </h5>
              </div>
              <div
                id="collapse2006"
                className="collapse"
                aria-labelledby="heading2006"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_36_46Budget Hotels - What really is ?BUDGET? in India.pdf"
                          >
                            Budget Hotels - What really is ?BUDGET? in India? -
                            16 Oct 2006
                            <span className="media-date">16 Oct 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_37_33Lemon Tree Hotels on major expansion spree.pdf"
                          >
                            Lemon Tree Hotels on major expansion spree - 01 Sep
                            2006<span className="media-date">01 Sep 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_38_23Lemon Tree spreads its branches.pdf"
                          >
                            Lemon Tree spreads its branches - 16 Aug 2006
                            <span className="media-date">16 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_39_12Lemon Tree will set up 3 hotels in city.pdf"
                          >
                            Lemon Tree will set up 3 hotels in city - 13 Aug
                            2006<span className="media-date">13 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_40_28Lemon Tree hotel in Chennai by 2007-end.pdf"
                          >
                            Lemon Tree hotel in Chennai by 2007-end - 05 Aug
                            2006<span className="media-date">05 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_41_07Warburg Pincus invests in India.pdf"
                          >
                            Warburg Pincus invests in India - 05 Aug 2006
                            <span className="media-date">05 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_41_47No room for budget blues.pdf"
                          >
                            No room for budget blues - 04 Aug 2006
                            <span className="media-date">04 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_42_27Warburg Pincus infuses Rs 210 cr into Lemon Tree.pdf"
                          >
                            Warburg Pincus infuses Rs 210 cr into Lemon Tree -
                            03 Aug 2006
                            <span className="media-date">03 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_43_21Budget blues not to bother hotels anymore.pdf"
                          >
                            Budget blues not to bother hotels anymore - 03 Aug
                            2006<span className="media-date">03 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_44_04Lemon Tree Hotels bets big on Pune.pdf"
                          >
                            Lemon Tree Hotels bets big on Pune - 03 Aug 2006
                            <span className="media-date">03 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_44_47Warburg invests in Lemon Tree.pdf"
                          >
                            Warburg invests in Lemon Tree - 02 Aug 2006
                            <span className="media-date">02 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_45_30Warburg buys 27% stake in Lemon Tree for Rs 280 cr.pdf"
                          >
                            Warburg buys 27% stake in Lemon Tree for Rs 280 cr -
                            01 Aug 2006
                            <span className="media-date">01 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_51_16Lemon Tree Hotels12.pdf"
                          >
                            Lemon Tree Hotels - 01 Aug 2006
                            <span className="media-date">01 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_50_31Warburg pays Rs 280 crore for Lemon Tree stake.pdf"
                          >
                            Warburg pays Rs 280 crore for Lemon Tree stake - 01
                            Aug 2006
                            <span className="media-date">01 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_49_17Lemon Tree Hotels1.pdf"
                          >
                            {" "}
                            Lemon Tree Hotels - 01 Aug 2006
                            <span className="media-date">01 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_48_24Lemon Tree plans Rs 500-cr expansion.pdf"
                          >
                            Lemon Tree plans Rs 500-cr expansion - 01 Aug 2006
                            <span className="media-date">01 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_47_49Warburg to pump Rs 280 cr. in Lemon Tree.pdf"
                          >
                            Warburg to pump Rs 280 cr. in Lemon Tree - 01 Aug
                            2006<span className="media-date">01 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_47_06Warburg picks up 27% in Lemon Tree1.pdf"
                          >
                            {" "}
                            Warburg picks up 27% in Lemon Tree - 01 Aug 2006
                            <span className="media-date">01 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_46_07Warburg picks up 27% in Lemon Tree.pdf"
                          >
                            Warburg picks up 27% in Lemon Tree - 01 Aug 2006
                            <span className="media-date">01 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_39_53Lemon Tree Hotels.pdf"
                          >
                            Lemon Tree Hotels - 01 Aug 2006
                            <span className="media-date">01 Aug 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_51_54Lemon Tree Hotels attracts investment of Rs. 280 crores from Warburg Pincus.pdf"
                          >
                            Lemon Tree Hotels attracts investment of Rs. 280
                            crores from Warburg Pincus - 31 Jul 2006
                            <span className="media-date">31 Jul 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_52_31For Warburg Pincus India still favourite hunting ground.pdf"
                          >
                            For Warburg Pincus India still favourite hunting
                            ground - 31 Jul 2006
                            <span className="media-date">31 Jul 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_53_27Lemon Tree Hotels Enters Low Budget Segment.pdf"
                          >
                            Lemon Tree Hotels Enters Low Budget Segment - 31 Jul
                            2006<span className="media-date">31 Jul 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_54_07Temptations of the mid-market.pdf"
                          >
                            Temptations of the mid-market - 19 Jul 2006
                            <span className="media-date">19 Jul 2006</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_54_41Walking Inn.pdf"
                          >
                            Walking Inn - 05 May 2006
                            <span className="media-date">05 May 2006</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2005">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2005"
                    aria-expanded="false"
                    aria-controls="collapse2005"
                  >
                    2005
                  </button>
                </h5>
              </div>
              <div
                id="collapse2005"
                className="collapse"
                aria-labelledby="heading2005"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_28_10Where the ROOMS Have Gone.pdf"
                          >
                            Where the ROOMS Have Gone - 07 Dec 2005
                            <span className="media-date">07 Dec 2005</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_29_05IT IS ABOUT CREATING GOOD EXPERIENCES.pdf"
                          >
                            IT IS ABOUT CREATING GOOD EXPERIENCES - 05 Nov 2005
                            <span className="media-date">05 Nov 2005</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_29_55Hotel encourages ponytails.pdf"
                          >
                            Hotel encourages ponytails - 22 Sep 2005
                            <span className="media-date">22 Sep 2005</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_31_00Pony-ed Up!.pdf"
                          >
                            Pony-ed Up! - 07 Sep 2005
                            <span className="media-date">07 Sep 2005</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_34_10Who Needs A Concierge.pdf"
                          >
                            Who Needs A Concierge? - 11 Jul 2005
                            <span className="media-date">11 Jul 2005</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_34_51Room for more.pdf"
                          >
                            Room for more - 28 May 2005
                            <span className="media-date">28 May 2005</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading2004">
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapse2004"
                    aria-expanded="false"
                    aria-controls="collapse2004"
                  >
                    2004
                  </button>
                </h5>
              </div>
              <div
                id="collapse2004"
                className="collapse"
                aria-labelledby="heading2004"
                data-parent="#accordion"
                style={{}}
              >
                <div className="tab-content ">
                  <div className="tab-pane active" id="mediatab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_20_22Lemon Tree Sowing Seeds of Expansion.pdf"
                          >
                            {" "}
                            Lemon Tree Sowing Seeds of Expansion - 23 Sep 2004
                            <span className="media-date">23 Sep 2004</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_21_172.pdf"
                          >
                            Lemon Tree to grow in other cities - 17 Sep 2004
                            <span className="media-date">17 Sep 2004</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_22_30Two-Third Of Five-Star Service At One-Third Price.pdf"
                          >
                            Two-Third Of Five-Star Service At One-Third Price -
                            30 Aug 2004
                            <span className="media-date">30 Aug 2004</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_23_21LEMON TREE HOTELS - New Budget Hotel Chain Launched.pdf"
                          >
                            {" "}
                            LEMON TREE HOTELS - New Budget Hotel Chain Launched
                            - 01 Jun 2004
                            <span className="media-date">01 Jun 2004</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_24_39Boom Checks In.pdf"
                          >
                            Boom Checks In - 25 Apr 2004
                            <span className="media-date">25 Apr 2004</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_25_26A Twist, Alright.pdf"
                          >
                            {" "}
                            A Twist, Alright - 11 Apr 2004
                            <span className="media-date">11 Apr 2004</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/uploads/pressmedia/25_Jul_2023_01_26_23Budget Boardings.pdf"
                          >
                            Budget Boardings - 17 Mar 2004
                            <span className="media-date">17 Mar 2004</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="presstab">
                    <div className="card-body">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Sigining_of_Keys_Select_by_Lemon_Tree_Hotels_Bareilly.pdf"
                          >
                            Press Release- Sigining of Keys Select by Lemon Tree
                            Hotels_Bareilly
                            <span className="media-date">10th May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/20-Years-of-Lemon-Tree-Hotels-Operations.pdf"
                          >
                            20 Years of Lemon Tree Hotels' Operations
                            <span className="media-date">31st May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Press_Release_Signing_of_Keys_Lite_by_Lemon_Tree_Hotels_Somnath.pdf"
                          >
                            Press Release- Signing of Keys Lite by Lemon Tree
                            Hotels_Somnath
                            <span className="media-date">17 May 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Lemon-Tree-Premier-Budhanilkantha-Kathmandu-Nepal.pdf"
                          >
                            Launch Release - Lemon Tree Premier, Budhanilkantha,
                            Kathmandu, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.lemontreehotels.com/assets/front/upload/Launch-Release-Tigerland-Safari-A-Lemon-Tree-Resort-Chitwan-Nepal.pdf"
                          >
                            Launch Release - Tigerland Safari - A Lemon Tree
                            Resort, Chitwan, Nepal
                            <span className="media-date">17 Apr 2024</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




      <FooterPage />


    </>
  )

}

export default MediaPage;

