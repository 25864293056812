
import * as React from "react";
import HeaderPage2 from '../Header2';
import ShowMoreText from "react-show-more-text";
import FooterPage from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import OfferSlider from "../Sliders/Offerslider";
import RoomSuites from '../Sliders/roomssuites';
import ReviewHotelCity from '../Sliders/ReviewHotelCity';
import Testimonial from '../Sliders/testimonial';
import Restaurants from '../Sliders/Restaurants';
import DatePicker from "react-datepicker";
import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import $ from 'jquery'; 
import axiosBaseURL from "../member/httpCommon";
import useCounter from "../useCounter";
import useCounterChild from "../useCounterChild";
import useCountroom from "../useCountroom.js";
import useCalender from "../useCalender.js";
import AccordionItem from '../AccordionComponent/Accordion.js';

function HotelDetailPage() {
  const[adult,setadult,increase, decrease]=useCounter();
  const[ children,setchildren,Cincrease, Cdecrease]=useCounterChild();
  const[ countroom]=useCountroom();
  const[ rangeStart,setRangeStart,selectStartDate,rangeEnd,setRangeEnd,selectEndDate, today1 ]=useCalender();

  const [activeIndex, setActiveIndex] = useState(null);
  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // script for switch disable
 $(function () {
  $("#mySwitch1").click(function () {
      if ($(this).is(":checked")) {
        $("#txtPassportNumber1").attr("disabled", "disabled");

          // $("#txtPassportNumber").focus();
      } else {
        $("#txtPassportNumber1").removeAttr("disabled");
      }
  });
});
// script for switch disable end

  const{brand_slug} = useParams(); 
  const{city_alias} = useParams();
  const{hotel_slug} = useParams();

  const[htlreview, sethtlreview] = useState({
      brand_slug:brand_slug,
      city_slug:city_alias,
      hotel_slug:hotel_slug
})
const[banner, setbanner] = useState([]);
const[overvhtl, setovervhtl] = useState([]);

const[htfaq, sethtfaq] = useState([]);
const[htcity, sethtcity] = useState([]);
const[httestm, sethttestm] = useState([]);


const[todoculture, settodoculture] = useState([]);
const[hltcare, sethltcare] = useState([]);
const[lndmrk, setlndmrk] = useState([]);
const[shpg, setshpg] = useState([]);

const[rsoot, setrsoot] = useState([]);
const[hresto, sethresto] = useState([]);

const[hotame, sethotame] = useState([]);
const[hotameall, sethotameall] = useState([]);
const[heventt, setheventt] = useState([]);
const showhotelreview = () =>{
  axiosBaseURL.post('/hotel-overview', htlreview).then(res => {
    setbanner(res.data.hotel_banner_slider);
    setovervhtl(res.data.hotels);
    setrsoot(res.data.rooms_n_suites);
    sethresto(res.data.hotel_restaurants);
    sethtfaq(res.data.hotel_faqs);
    sethtcity(res.data.near_by_hotel_data);
    sethttestm(res.data.hotel_testimonials);
    sethotame(res.data.hotel_amenities);
    sethotameall(res.data.hotel_amenitiesall);
    settodoculture(res.data.things_to_dos_culture_category);
    sethltcare(res.data.things_to_dos_healthcare_category);
    setlndmrk(res.data.things_to_dos_landmark_category);
    setshpg(res.data.things_to_dos_shopping_category);
    setheventt(res.data.hotel_event);
    

}).catch(err => {
console.log(err)
})
}

console.log("test11", overvhtl)

  const [counter, setCounter] = useState(1);  
    const handleAddDiv = () => {
      setCounter(counter + 1);
    };
  
  const removeNode = (idx) => document.getElementById(`id-${idx}`).remove();


  $(".rooms-childs-input1").click(function(){
    $(".add-rooms-block1").toggle();
  });
  

  const today = new Date();
  const month = today.getMonth()+1;
  const year = today.getFullYear();
  const date1 = today. getDate();
  
  
  const currentDate = month + "/" + date1 + "/" + year;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  
const navigate =  useNavigate()

// on load function call for loading 
useEffect(() => {
  showhotelreview();
  window.scrollTo(0, 0);
},[])


  return (
    
    <>

 <HeaderPage2 overvhtl={overvhtl}/> 

<section className="filters-cs cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href={hotel_slug}>
               
                Destination
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/hotels"> Hotels </a>
            </li>
            <li className="breadcrumb-item">
              <a href="https://www.lemontreehotels.com/lemon-tree-hotel/agra/hotel-agra">
               
                {brand_slug}, {city_alias}
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Overview
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
<section className="hero pt-3 pb-5 overviewheading">
  <div className="container">
    
    <div className="row">
      <div className="col-md-12 hero-left pt-5 mt-3">
      {
      overvhtl && overvhtl.length && overvhtl.map((val) => 
        <div className="hero-leftInner">
          <div className="hero-uppar-heading">
        
            <div className="upper-main-heading">
              <img
                src={val.brand_logo}
                className=""
                alt="Lemon Tree Hotels"
              />
              <div className="review_cs_main new-city-hotel-head-rj">
                <h1>{val.hotel_name}</h1>
                <em>
                  <a
                    href="https://maps.app.goo.gl/Q2eAETssqfg5EeXX7"
                    target="_blank"
                  >
                    {val.hotel_address}
                  </a>
                </em>
              </div>
            </div>
       
            <div className="review-cs-main-link">
              <a href="#Testimonials_cs">
                <span className="treview" data-original-title="" title="">
                  <span
                    className="review_cs_inner_count"
                    data-original-title=""
                    title=""
                  >
                    <img
                      className=""
                      src="https://www.lemontreehotels.com/uploads/images/Tripadvisor-Logo.png"
                    />&nbsp;
                    
                    <img id='triprating'
                      src={val.tripadvisor_rating_image} 
                      className=""
                    />&nbsp;{val.tripavisor_review_count} reviews
                  </span>
                </span>
                <span className="treview" data-original-title="" title="">
                  <span
                    className="review_cs_inner_count"
                    data-original-title=""
                    title=""
                  >
                    <img
                      className="google-review "
                      src="https://www.lemontreehotels.com/uploads/images/google-review-no-star.png"
                    />&nbsp;
                    
                    <img
                      src={val.google_rating_image} 
                      id="googlerating"
                      className=""
                    />&nbsp;
                    {val.google_review_count} reviews
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
          )
        }
      </div>
      <div className="col-md-12">
      {
               banner && banner.length && banner.map((val) => 
        <div className="hero-right">
          <div className="hero-left">
            
            <img
              src={val.banner_image_1}
              alt={val.banner_image_title_1}
            />
          </div>
          <div className="hero-right-inner-main">
            <div className="hero-right-inner">
              <img
                src={val.banner_image_2}
                alt={val.banner_image_title_2}
              />
              <img
                src={val.banner_image_3}
                alt={val.banner_image_title_3}
              />
            </div>
            <div className="hero-right-inner hero-right-inner-last">
              <img
                src={val.banner_image_4}
                alt={val.banner_image_title_4}
              />
              <img
                src={val.banner_image_5}
                alt={val.banner_image_title_5}
              />
            </div>
          </div>
          <a
             onClick={() => navigate("/GalleryPage/")}
            id="GalleryURL">
           <FontAwesomeIcon icon={faArrowsAlt} /> Show all
            photos
          </a>
        </div>
          )
        }
      </div>
    </div>
  </div>
</section>


<section className="below_slider-cs-sc pb-5">
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <div className="readMore-content new-hotel-contentt">
          <div className="article">
          {
      overvhtl && overvhtl.length && overvhtl.map((val) => 
          <ShowMoreText
        /* Default options */
        lines={8}
        more="Read More"
        less="Read Less"
      >
            <div className="rdmr-contentn">
              <p
                style={{
                  color: "rgb(34, 34, 34)",
                  fontFamily: "Arial, Verdana, sans-serif",
                  fontSize: 12
                }}
              >
                   <div dangerouslySetInnerHTML={{__html: val.short_desc}}></div>
            
              </p>
              <p
                style={{
                  color: "rgb(34, 34, 34)",
                  fontFamily: "Arial, Verdana, sans-serif",
                  fontSize: 12
                }}
              >
                  <div dangerouslySetInnerHTML={{__html: val.home_description}}></div>
       
              </p>
            </div>
    
            <div className=" rdmr-contentn">
          
              <p
                style={{
                  color: "rgb(34, 34, 34)",
                  fontFamily: "Arial, Verdana, sans-serif",
                  fontSize: 12
                }}
              >
                While looking to book hotels in Agra, don’t forget to add Lemon
                Tree Hotel, Agra to your bucket list.
              </p>
            </div>
            </ShowMoreText>
      )
    }
          </div>
       
        </div>
        <div className="below_slider-cs-sc-list new-sjr-rj">
          <ul>
          {
            
            hotame && hotame.length && hotame.map((val) => 
            <li>
              <img
                src={val.amenities_image}
                alt={val.title}
              />
              {val.description}
            </li>
      )
    }
          
            <li>
              <a href="#Amenities-main">View All</a>
            </li>
          </ul>
        </div>
      </div> 
      <div className="col-md-4">
      <div className='cs-booking-form-belowSlider'>
        {console.log("hotel name", overvhtl)}
 
<input type='text' className='form-control' disabled placeholder={overvhtl && overvhtl.length>0 && overvhtl[0].hotel_name}  />

<div className="cs_day_room mt-3">
<div className="over_night cs_swt_btn_inner">
  <p>
    <img src="https://www.lemontreehotels.com/assets/front/images/os.png" />
    Overnight Stay 
  </p>
</div>

<div className="switch_new me-3 ms-2">
  <div className="form-check form-switch">
    <input
      className="form-check-input"
      type="checkbox"
      id="mySwitch1"
      name="checkbox1"
      defaultValue="no"
    />
    <label className="form-check-label" htmlFor="mySwitch1" />
  </div>
</div>

<div className='day_room1'>
  <p>
    <img src="https://www.lemontreehotels.com/assets/front/images/dr.png" />
    Day Rooms
  </p>
  <span className="suggestion-btn">
    <img
      src="https://www.lemontreehotels.com/assets/front/images/informative.png"
      className="img-fulid "
    />
    <p>10 am to 5 pm</p>
  </span>
  </div>
</div>


<div className='datepicker-outer'>
<DatePicker
        selectsStart
        selected={rangeStart}
         minDate={today1}
        startDate={rangeStart}
         endDate={rangeEnd}
          className="form-control"
          // onChange={(date) => setStartDate(date)}
        onChange={selectStartDate} 
        shouldCloseOnSelect={true}      
   />
       <div className="input-group-addon">
        <img src="/static/images/cal-icon-blue.png" alt="" className="calender-icon" />
      </div>
</div>

<div className='datepicker-outer'>
<DatePicker
        selectsEnd
        selected={rangeEnd}
        // startDate={rangeStart}
        endDate={rangeEnd}
        id="txtPassportNumber1"
        className="form-control"
        onChange={selectEndDate}
        shouldCloseOnSelect={true}
        minDate={rangeStart}
         />

    
       <div className="input-group-addon">
        <img src="/static/images/cal-icon-blue.png" alt="" className="calender-icon" />
      </div>
</div>

<div className='form-group position-relative1'>
<input type="text" class="rooms-childs-input1" id="rooms_childs_showT" value={`Room(s) ${countroom} - Adult(s) ${adult} - Children ${children}`}>
</input>
<div className="showmoreT add-rooms-block1 mt-2">
      {Array.from(Array(counter)).map((item, idx) => (
        <div key={idx} id={`id-${idx}`} className="aab">

        <div className="row px-1  mt-0 mb-1" id="more_fieldsT">
          <div className="col-12 room-guestsT">
            <fieldset>
              <div id="append_hereT">
                <div
                  className="row add-rooms-div add-thisT"
                  style={{
                    padding: "20px 15px 0px 0px",
                    borderBottom: "1px solid #989898"
                  }}
                >
                  <div className="Add-rooms-2">
                    <p className="rooms-listT"> Room 1</p>
                    <span className="stripT d-none">
                     
                      <span className="font16 blackText">
                       
                        <span className="latoBlack adultT">1</span> Adults,
                        <span className="latoBlack childT"> 0</span> Children
                      </span>
                      <a className="font12 appendLeft10 edit_roomT">Edit </a>
                      <a className="font12 remove_roomT" id="removeT" >
                        Remove
                      </a>
                      
                    </span>
                  </div>
                  <div className="row col-12 pr-0 stripT2">
                    <div className="col-12 col-md-6 ">
                      <div className="form-group plus-min-style">
                        <div className="rounded-0">
                          <p>Adult(s):</p>
                          <button
                            type="button"
                            className="sub-adult"
                            id="sub_adultT">
                            -
                          </button>
                          <input
                            type="number"
                            className="adult-inputT"
                            id="adultsT"
                            defaultValue={1}
                            min={1}
                            max={3}
                            readOnly=""
                          />
                          <button type="button" className="add" id="add_adultT">
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group plus-min-style">
                        <div className="rounded-0 ">
                          <p>Child (0-6 years):</p>
                          <button type="button" className="sub" id="sub_childT">
                            -
                          </button>
                          <input
                            type="number"
                            className="child-inputT"
                            id="childsT"
                            defaultValue={0}
                            min={1}
                            max={3}
                            readOnly=""
                          />
                          <button type="button" className="add" id="add_childT">
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 padd-no" style={{ padding: "0px 5px" }}>
                  <div className="add-rooms-style">
                   
                    {/* <a className="add-another-room" id="add_new_roomT">
                     
                      + Add Another Room
                    </a> */}
                    <button className="add-another-room btn" onClick={handleAddDiv}>+ Add Another Room</button>
                    <button className="add-another-room btn" onClick={() => removeNode(idx)}>Remove</button>
                    
                    <a className="appy-rooms-add"> Apply</a>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      
 ))}
 </div>
</div>



{/* <button class="btn d-block mt-2 bg_ylw btn-light">Book Now</button> */}
<button className="strip-book-now-btn" onClick={() => window.open(`https://be.synxis.com/?adult=${adult}&arrive=${rangeStart}&chain=7710&child=${children}&currency=INR&depart=${rangeEnd}&hotel=${$("#getBeCityHotel").val()}&level=hotel&locale=en-US&rooms=${countroom}&shell=SBE&start=availresults&template=SBE&theme=initialTheme2`)}>
    Book Now
    </button>
      </div>
      </div>
    </div>
  </div>
</section>


<section className="ourOffers-cs-sc cs-sc-padding ">
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <div className="sc-heading new-reso-heading-rj">
          <h2> Hotel Offers</h2>
        </div>
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-md-12">
        <div className="below_slider-cs-sc-main">
        <OfferSlider adult={adult} children={children} countroom={countroom} rangeStart={rangeStart} rangeEnd={rangeEnd}/>
       
        </div>
      </div>
    </div>
  </div>
</section>

<section className="rooms-cs-main-updated cs-sc-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <div className="rooms-cs-main-updated-left sc-heading subHeading_cs-sc new-reso-heading-rj">
          <h2>Rooms &amp; Suites</h2>
        </div>
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-md-12">
        <div className="below_slider-cs-sc-main">
          <RoomSuites rsoot={rsoot}/>
        </div>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-md-12">
        <center>
        <button className="hotel-new-booknow-btn" onClick={() => window.open(`https://be.synxis.com/?adult=${adult}&arrive=${rangeStart}&chain=7710&child=${children}&currency=INR&depart=${rangeEnd}&hotel=${$("#getBeCityHotel").val()}&level=hotel&locale=en-US&rooms=${countroom}&shell=SBE&start=availresults&template=SBE&theme=initialTheme2`)}>
    Book Now
    </button>
        </center>
      </div>
    </div>
  </div>
</section>


<section id="overrestaurant" className="restaurant-cs-sc cs-sc-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <div className="rooms-cs-main-updated-left sc-heading subHeading_cs-sc new-reso-heading-rj">
          <h2>Restaurants</h2>
        </div>
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-md-12">
        <div className="below_slider-cs-sc-main">
     <Restaurants hresto={hresto}/>
        </div>
      </div>
    </div>
  </div>
</section>


<section
  className="amenities-cs-sc amenities-cs-sc-home cs-sc-padding"
  id="Amenities-main"
>
  <div className="container">
    <div className="row">
      <div className="col-md-6 mb-0 new-reso-heading-rj">
        <center className="sc-heading subHeading_cs-sc">
          <h2>Amenities</h2>
          <p />
          <div>            
            Lemon Tree Hotels caters to a spectrum of needs with tailored
            services. Enjoy seamless WiFi, relish delightful dining at our
            restaurants, and find instant assistance at our service desk. We
            assure an exceptional stay, whether your purpose is business or
            leisure. Our steadfast dedication to comfort and quality promises an
            unforgettable and pleasurable experience.
          </div>
          <p />
          {/* <a
            href="https://www.lemontreehotels.com/lemon-tree-hotel/agra/hotel-agra/facilities"
            id="lnkAmeninities"
            className="cs-cta cs-cta-explore explore-without-border"
          >
            Explore
          </a> */}
        </center>
      </div>
      <div className="col-md-6">
    
        <div className="row">
        {
      hotameall && hotameall.length && hotameall.map((val) => 
          <div className="col-md-4">
            <img
              src={val.amenities_image}
              alt={val.title}
            />
            <h4>{val.description}</h4>
          </div>

             )
            }
        </div>
  
      </div>
    </div>
  </div>
</section>


<section id="overviewmeet" className="meeting-events_cs-sc cs-sc-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <div className="sc-heading new-reso-heading-rj">
          <h2>Meeting &amp; Events</h2>
        </div>
      </div>
    </div>
    {
      overvhtl && overvhtl.length && overvhtl.map((val) => 
    <div className="row mt-3">
 
      <div className="col-md-6">
        <img
          className="w-100  ed"
          src={val.hotel_image}
          alt=""
        />
      </div>
 
      <div className="col-md-6">
        <div className="sc-heading subHeading_cs-sc">
          
        <p>
         {val.meeting_overview_content}
         </p>
          <table className="table event-table-cs table-bordered">
            <thead>
              
              <tr>
                <th scope="col" style={{ width: "10%" }}>
                  S.No
                </th>
                <th scope="col" style={{ width: "40%" }}>
                  Venues
                </th>
                <th scope="col" style={{ width: "40%" }}>
                  Pax
                </th>
              </tr>
            </thead>
            <tbody>
            {
              heventt && heventt.length && heventt.map((item, index) =>           
              <tr>
                <th scope="row">{index+1}</th>
                <td>{item.title}</td>
                <td>{item.no_of_pax}</td>
              </tr>
              
      )
    }
            
              
            </tbody>
          </table>
        </div>
        <div className="restaurant-cs-sc-parter-sc-cs parter-sc-cs">
          <a
            href="https://www.lemontreehotels.com/lemon-tree-hotel/agra/hotel-agra/meeting-event"
            id="eventslug"
            className="cs-cta cs-cta-explore explore-without-border"
          >
            Explore
          </a>
        </div>
      </div>
    </div>
      )
    }
  </div>
</section>


{/* rewards */}
<section className="rewards-cs-sc cs-sc-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <div className="rewards-cs-sc-inner">
          <div className="benefits_list_cs benefits-cs-raj">
            <div className="sc-heading subHeading_cs-sc">
              <h2 className="mb-4 new-heading-rj">
                Explore A World of Infinity Rewards
              </h2>
              <p className="text-white mt-5 mb-4">
                Infinity Rewards by Lemon Tree allows you to earn and burn
                reward coins easier than ever before.
              </p>
            </div>
            <div className="benefits_list_cs_listing mt-5">
              <div className="benefits_list_cs_listing_item">
                <figure>
                  <img
                    className=""
                    src="https://www.lemontreehotels.com/assets/front/Ka_assest/images/freeRoomNights.png"
                  />
                </figure>
                <div className="benefits_list_cs_listing_item_content">
                  <h4> Free room nights </h4>
                </div>
              </div>
              <div className="benefits_list_cs_listing_item">
                <figure>
                  <img
                    className=""
                    src="https://www.lemontreehotels.com/assets/front/Ka_assest/images/Breakfast.png"
                  />
                </figure>
                <div className="benefits_list_cs_listing_item_content">
                  <h4>Complimentary breakfast </h4>
                </div>
              </div>
              <div className="benefits_list_cs_listing_item">
                <figure>
                  <img
                    className=""
                    src="https://www.lemontreehotels.com/assets/front/Ka_assest/images/Special-Offers.png"
                  />
                </figure>
                <div className="benefits_list_cs_listing_item_content">
                  <h4>Special offers </h4>
                </div>
              </div>
              <div className="benefits_list_cs_listing_item">
                <figure>
                  <img
                    className=""
                    src="https://www.lemontreehotels.com/assets/front/Ka_assest/images/WiFi-updated.png"
                  />
                </figure>
                <div className="benefits_list_cs_listing_item_content">
                  <h4>Free wifi </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="rewardsBtns">
            <a
              href="https://www.lemontreehotels.com/rewards"
              className=" cs-cta-explore cs-cta OurRewardsProgramExplore new-reward-explore-btn-rj"
            >
              Explore
            </a>
            <a
              href="https://www.lemontreehotels.com/member/signup"
              className="cs-cta reward_join_btn OurRewardsProgramjoinUs"
            >
              Join us
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="location-cs-sc cs-sc-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-8 mb-4 new-reso-heading-rj">
        <center className="sc-heading">
          <h2>Things To Do In {city_alias}</h2>
          <p>
            
            <a href="">Read More</a>
          </p>
        </center>
      </div>
    </div>
    <div className="row">
    <div className="col-md-6">
        <div id="accordion">
          <div className="card">
            <div className="card-header" id="heading0">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapse0"
                  aria-expanded="false"
                  aria-controls="collapse0"
                >
                  
                  {todoculture && todoculture.length>0 && todoculture[0].thingstodo_category_id}
                </button>
              </h5>
            </div>
            <div
              id="collapse0"
              className="collapse show"
              aria-labelledby="heading0"
              data-parent="#accordion"
            >
              <div className="card-body">
            { 
           todoculture && todoculture.length && todoculture.map ((val) => 
                <ul>
                  <li>
                    {val.title} <span>{val.distance_in} km</span>
                  </li>
                
                </ul>
            )
          }
              </div>
            </div>
          </div>

        

          <div className="card">
            <div className="card-header" id="heading1">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapse1"
                  aria-expanded="false"
                  aria-controls="collapse1"
                >
           {hltcare && hltcare.length>0 && hltcare[0].thingstodo_category_id}
                </button>
              </h5>
            </div>
            <div
              id="collapse1"
              className="collapse"
              aria-labelledby="heading1"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                { 
             hltcare && hltcare.length && hltcare.map((val) => 
                  <li>
                       {val.title} <span>{val.distance_in} km</span>
                  </li>
          
)}
                
                </ul>
              </div>
            </div>
          </div>


        
          <div className="card">
            <div className="card-header" id="heading2">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapse2"
                  aria-expanded="false"
                  aria-controls="collapse2"
                >
                 {lndmrk && lndmrk.length>0 && lndmrk[0].thingstodo_category_id}
                </button>
              </h5>
            </div>
            <div
              id="collapse2"
              className="collapse"
              aria-labelledby="heading2"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                { 
            lndmrk && lndmrk.length && lndmrk.map((val) => 
                  <li>
                  {val.title} <span>{val.distance_in} km</span>
                  </li>
           )
          }
                </ul>
              </div>
            </div>
          </div>
 

           
          <div className="card">
            <div className="card-header" id="heading3">
              <h5 className="mb-0">
                <button
                  type="button"
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapse3"
                  aria-expanded="false"
                  aria-controls="collapse3"
                >
             {shpg && shpg.length>0 && shpg[0].thingstodo_category_id}
                </button>
              </h5>
            </div>
            <div
              id="collapse3"
              className="collapse"
              aria-labelledby="heading3"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul>
                { 
            shpg && shpg.length && shpg.map((val) =>
                  <li>
                  {val.title} <span>{val.distance_in} km</span>
                  </li>
                      )
                    }
            </ul>
              </div>
            </div>
          </div>
        
              
        </div>
      </div>
      <div className="col-md-6">
        <div className="location-map-cs">
        {
      overvhtl && overvhtl.length && overvhtl.map((val) => 
          <a href={val.embed_map} target="_blank">
            <img src={val.google_map_image} />
          </a>
      )
    }
        </div>
      </div>
    </div>
  </div>
</section>


<section id="Testimonials_cs" className="testimonials-cs-sc cs-sc-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-8 mb-3 new-reso-heading-rj">
        <center className="sc-heading">
          <h2>Testimonials</h2>
        </center>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="below_slider-cs-sc-main">
       <Testimonial httestm={httestm}/>    
        </div>
      </div>
    </div>
  </div>
</section>


<section id="HotelsInState" className="otherHotels-cs-sc cs-sc-padding">
  <div className="container">
    <div className="row ">
      <div className="col-md-8">
        <div className="sc-heading new-reso-heading-rj">
          <h2>Hotels In {city_alias}</h2>
        </div>
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-md-12">
        <div className="below_slider-cs-sc-main">
         <ReviewHotelCity htcity={htcity} adult={adult} children={children} countroom={countroom} rangeStart={rangeStart} rangeEnd={rangeEnd}/>
        </div>
      </div>
    </div>
  </div>
</section>


<section id="overviewfaq" className="faq-cs-sc cs-sc-padding location-cs-sc">
  <div className="container">
    <div className="row mb-3">
      <div className="col-md-8">
        <div className="sc-heading new-reso-heading-rj">
          <h2> Frequently Asked Questions</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
      {htfaq.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.title}
          answer={<div dangerouslySetInnerHTML={{__html: item.description}}></div>}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
         

      </div>
    </div>
  </div>
</section>
<a
  id="back-to-top"
  href="#"
  className="back-to-top"
 
  style={{ display: "inline" }}
>
<FontAwesomeIcon icon={faAngleDoubleUp} />
</a>


      <FooterPage />

    </>
  )

}

export default HotelDetailPage;

