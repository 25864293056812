import '../memdashboard.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';
import { useState, useEffect } from "react";
import axios from "axios";
import * as React from "react";
import { useNavigate } from "react-router-dom";

function RedeemPage() {
 
return (
    <>
<HeaderPage />

<div className="maiwrapper">
  <section className="header_space py-5" />
  <section className="member_header py-1">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="profile_member">
            <div className="userName_member">
              <strong>Namaste </strong>
              <h3>Harsh</h3>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="point_member">
            <div className="point_member_left point_member_main">
              <strong>3204.46</strong>
              <p>COINS</p>
            </div>
            <div className="point_member_right point_member_main">
              <strong>4</strong>
              <p>Total Stays</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* member header */}
  <section className="menu_member bg-light">
    <div className="menu_member_inner">
      <nav className="navbar navbar-expand-lg navbar-light pb-0">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a
                className="nav-link"
                href="/dashboard"
              >
                Overview
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/trip"
              >
                My Trips
              </a>
            </li>
            {/*  <li class="nav-item">
			        <a class="nav-link" href="member/promotion">Promotion</a>
			      </li> */}
            <li className="nav-item active">
              <a
                className="nav-link"
                href="/redeem"
              >
                Redeem
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/profile"
              >
                Profile
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
  {/* member middle  */}
  <section className="citynamevaib_main py-5 redeem_cs_main">
    <div className="container" id="containers">
      <center className="mb-5">
        <h4 className='text-uppercase'>Select destinations</h4>
      </center>
      <div className="city-search">
        <form
          action="/redeem"
          method="post"
          encType="multipart/form-data"
          style={{ display: "flex" }}
        >
          <input
            type="hidden"
            name="_token"
            defaultValue="5xY5ImsGkWWYKCkpvEOKkFHsJnq9JvJYwEaPXFON"
          />
          <input
            name="txtcountyRedeem"
            type="text"
            id="txtcountyRedeem"
            className="search_in showHidden ui-autocomplete-input"
            placeholder="Search"
            autoComplete="off"
          />
          <input
            type="submit"
            name="btnsearch"
            defaultValue="Search"
            id="btnsearch"
            className="submit_in"
          />
        </form>
        <div className="view-all-city"></div>
      </div>
    </div>
    <div className="citynamevaib_main_hotels_inner_main ">
      <div className="container">
        <div className="row">
          <div className="citynamevaib_main_hotels_inner">
            <div className="row pt-md-5 mb-4 justify-content-center hotels-row position-relative">
              <div className="col-md-11">
                <div
                  id="accordion"
                  className="citynamevaib_main_hotels_inner_main_inner"
                >
                  <div className="card my-4">
                    <div className="card-header pb-0 pl-3 pt-3">
                      <div className="row">
                        <div className="col-lg-3 pr-lg-0 pl-3">
                          <input
                            type="hidden"
                            name="RptCityHoteldata$ctl01$HidHotelUrl"
                            id="RptCityHoteldata_ctl01_HidHotelUrl"
                            defaultValue={58955}
                          />
                          <input
                            type="hidden"
                            name="RptCityHoteldata$ctl01$HidHotelsid"
                            id="RptCityHoteldata_ctl01_HidHotelsid"
                            defaultValue={86}
                          />
                          <figure className="position-relative mb-0">
                            <a
                              data-lightbox="hotel58955"
                              className="gallery-icon"
                              href="https://www.lemontreehotels.com/lemon-tree-hotel/agra/hotel-agra"
                            >
                              <img
                                className="img-fluid "
                                src="https://www.lemontreehotels.com/uploads/gimages/29_Jul_2023_07_38_12Facade (17).JPG"
                                style={{}}
                                alt="Lemon Tree Hotel, Agra"
                              />
                            </a>
                          </figure>
                        </div>
                        <div className="col-sm-8 col-lg-7 p-lg-0 pt-2 pl-lg-3 border-bottom">
                          <h5 className="mb-0">
                            <a
                              className="link-underline"
                              onclick="GoCityPage304('https://www.lemontreehotels.com/lemon-tree-hotel/agra/hotel-agra')"
                              href="javascript:void(0);"
                            >
                              Lemon Tree Hotel, Agra
                            </a>
                          </h5>
                          <p className=" d-block mb-2">
                            <a
                              className="link-underline google_iframe"
                              href="https://maps.app.goo.gl/Q2eAETssqfg5EeXX7"
                              target="_blank"
                            >
                              NH 2, near St. Conrad’s Inter College, Bypass Road
                              Khandari, Agra, Uttar Pradesh - 282002, India,
                              Agra, Uttar Pradesh - 282002
                            </a>
                          </p>
                          <p className="m-0 pt-2 pb-3">
                            {" "}
                            ...
                            <a
                              onclick="GoCityPage304('https://www.lemontreehotels.com/lemon-tree-hotel/agra/hotel-agra')"
                              href="javascript:void(0);"
                              className="explorehotelcta"
                            >
                              Know More{" "}
                            </a>{" "}
                          </p>
                          <p className="pt-2 m-0 distance">
                            Distance &nbsp;
                            <img
                              src="https://www.lemontreehotels.com/assets/front/images/plane.png"
                              alt="Airport Distance"
                              className="facility_toolTip tooltip2 "
                              data-tip={4}
                            />
                            14 km &nbsp;&nbsp;
                            <img
                              src="https://www.lemontreehotels.com/assets/front/images/train.png"
                              alt="Railway Distance"
                              className="facility_toolTip tooltip2 "
                              data-tip={18}
                            />
                            6.4 km
                          </p>
                        </div>
                        <div className="col-sm-4 col-lg-2 text-center text-lg-right py-3 pr-4 border-bottom hide-logo grey-logo"></div>
                      </div>
                    </div>
                    <div className="py-3 px-2 pt-lg-4 text-center text-lg-left">
                      <div className="row px-2">
                        {/* owl-carousel owl-theme owl-facility */}
                        <div className="col-lg-3 pr-lg-0 pl-lg-3 pb-3 pb-lg-0">
                          <div className="owl-carousel owl-theme facility_owl owl-loaded owl-drag">
                            <div className="owl-stage-outer">
                              <div className="owl-stage" />
                            </div>
                            <div className="owl-nav disabled">
                              <button
                                type="button"
                                role="presentation"
                                className="owl-prev"
                              >
                                <span aria-label="Previous">‹</span>
                              </button>
                              <button
                                type="button"
                                role="presentation"
                                className="owl-next"
                              >
                                <span aria-label="Next">›</span>
                              </button>
                            </div>
                            <div className="owl-dots disabled" />
                          </div>
                        </div>
                        <div className="col-lg-9 text-end px-2 text-lg-right">
                          <a
                            className="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk"
                            target="_blank"
                            href="https://www.lemontreehotels.com/lemon-tree-hotel/agra/hotel-agra"
                          >
                            Explore
                          </a>
                          {/*<a class="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk" data-target="#signup-popuprj" href="#">Enquire Now</a> */}
                          <a
                            href="javascript:void(0);"
                            data-id="Lemon Tree Hotel, Agra"
                            data-toggle="modal"
                            data-target="#book_nw_btn"
                            className="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk redeemnowcls"
                          >
                            Redeem Now
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      id="collapseOne58955"
                      className="collapse"
                      data-parent="#accordion"
                    ></div>
                  </div>
                  <div className="card my-4">
                    <div className="card-header pb-0 pl-3 pt-3">
                      <div className="row">
                        <div className="col-lg-3 pr-lg-0 pl-3">
                          <input
                            type="hidden"
                            name="RptCityHoteldata$ctl01$HidHotelUrl"
                            id="RptCityHoteldata_ctl01_HidHotelUrl"
                            defaultValue={58955}
                          />
                          <input
                            type="hidden"
                            name="RptCityHoteldata$ctl01$HidHotelsid"
                            id="RptCityHoteldata_ctl01_HidHotelsid"
                            defaultValue={86}
                          />
                          <figure className="position-relative mb-0">
                            <a
                              data-lightbox="hotel58955"
                              className="gallery-icon"
                              href="https://www.lemontreehotels.com/keys-select-hotel/ahmedabad/gandhi-ashram-ahmedabad"
                            >
                              <img
                                className="img-fluid "
                                src="https://www.lemontreehotels.com/uploads/gimages/31_Jul_2023_03_15_04Keys-Select-by-Lemon-Tree-Hotels,-Gandhi-Ashram,-Ahmedabad---Facade-day-shot.jpg"
                                style={{}}
                                alt="Keys Select by Lemon Tree Hotels, Gandhi Ashram, Ahmedabad"
                              />
                            </a>
                          </figure>
                        </div>
                        <div className="col-sm-8 col-lg-7 p-lg-0 pt-2 pl-lg-3 border-bottom">
                          <h5 className="mb-0">
                            <a
                              className="link-underline"
                              onclick="GoCityPage304('https://www.lemontreehotels.com/keys-select-hotel/ahmedabad/gandhi-ashram-ahmedabad')"
                              href="javascript:void(0);"
                            >
                              Keys Select by Lemon Tree Hotels, Gandhi Ashram,
                              Ahmedabad
                            </a>
                          </h5>
                          <p className=" d-block mb-2">
                            <a
                              className="link-underline google_iframe"
                              href="https://maps.app.goo.gl/cY6NUUVwqJupAEmv7"
                              target="_blank"
                            >
                              Between Gandhi Ashram and RTO Circle Subhash
                              Bridge, Ahmedabad 380027, Ahmedabad, Gujarat -
                              380027
                            </a>
                          </p>
                          <p className="m-0 pt-2 pb-3">
                            {" "}
                            ...
                            <a
                              onclick="GoCityPage304('https://www.lemontreehotels.com/keys-select-hotel/ahmedabad/gandhi-ashram-ahmedabad')"
                              href="javascript:void(0);"
                              className="explorehotelcta"
                            >
                              Know More{" "}
                            </a>{" "}
                          </p>
                          <p className="pt-2 m-0 distance">
                            Distance &nbsp;
                            <img
                              src="https://www.lemontreehotels.com/assets/front/images/plane.png"
                              alt="Airport Distance"
                              className="facility_toolTip tooltip2 "
                              data-tip={4}
                            />
                            6.7 km &nbsp;&nbsp;
                            <img
                              src="https://www.lemontreehotels.com/assets/front/images/train.png"
                              alt="Railway Distance"
                              className="facility_toolTip tooltip2 "
                              data-tip={18}
                            />
                            7.1 km
                          </p>
                        </div>
                        <div className="col-sm-4 col-lg-2 text-center text-lg-right py-3 pr-4 border-bottom hide-logo grey-logo"></div>
                      </div>
                    </div>
                    <div className="py-3 px-2 pt-lg-4 text-center text-lg-left">
                      <div className="row px-2">
                        {/* owl-carousel owl-theme owl-facility */}
                        <div className="col-lg-3 pr-lg-0 pl-lg-3 pb-3 pb-lg-0">
                          <div className="owl-carousel owl-theme facility_owl owl-loaded owl-drag">
                            <div className="owl-stage-outer">
                              <div className="owl-stage" />
                            </div>
                            <div className="owl-nav disabled">
                              <button
                                type="button"
                                role="presentation"
                                className="owl-prev"
                              >
                                <span aria-label="Previous">‹</span>
                              </button>
                              <button
                                type="button"
                                role="presentation"
                                className="owl-next"
                              >
                                <span aria-label="Next">›</span>
                              </button>
                            </div>
                            <div className="owl-dots disabled" />
                          </div>
                        </div>
                        <div className="col-lg-9 text-end px-2 text-lg-right">
                          <a
                            className="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk"
                            target="_blank"
                            href="https://www.lemontreehotels.com/keys-select-hotel/ahmedabad/gandhi-ashram-ahmedabad"
                          >
                            Explore
                          </a>
                          {/*<a class="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk" data-target="#signup-popuprj" href="#">Enquire Now</a> */}
                          <a
                            href="javascript:void(0);"
                            data-id="Keys Select by Lemon Tree Hotels, Gandhi Ashram, Ahmedabad"
                            data-toggle="modal"
                            data-target="#book_nw_btn"
                            className="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk redeemnowcls"
                          >
                            Redeem Now
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      id="collapseOne58955"
                      className="collapse"
                      data-parent="#accordion"
                    ></div>
                  </div>
                  <div className="card my-4">
                    <div className="card-header pb-0 pl-3 pt-3">
                      <div className="row">
                        <div className="col-lg-3 pr-lg-0 pl-3">
                          <input
                            type="hidden"
                            name="RptCityHoteldata$ctl01$HidHotelUrl"
                            id="RptCityHoteldata_ctl01_HidHotelUrl"
                            defaultValue={58955}
                          />
                          <input
                            type="hidden"
                            name="RptCityHoteldata$ctl01$HidHotelsid"
                            id="RptCityHoteldata_ctl01_HidHotelsid"
                            defaultValue={86}
                          />
                          <figure className="position-relative mb-0">
                            <a
                              data-lightbox="hotel58955"
                              className="gallery-icon"
                              href="https://www.lemontreehotels.com/lemon-tree-hotel/ahmedabad/hotel-ahmedabad"
                            >
                              <img
                                className="img-fluid "
                                src="https://www.lemontreehotels.com/uploads/gimages/29_Jul_2023_01_26_04Lemon-Tree-Hotel,-Ahmedabad---Facade.jpg"
                                style={{}}
                                alt="Lemon Tree Hotel, Ahmedabad"
                              />
                            </a>
                          </figure>
                        </div>
                        <div className="col-sm-8 col-lg-7 p-lg-0 pt-2 pl-lg-3 border-bottom">
                          <h5 className="mb-0">
                            <a
                              className="link-underline"
                              onclick="GoCityPage304('https://www.lemontreehotels.com/lemon-tree-hotel/ahmedabad/hotel-ahmedabad')"
                              href="javascript:void(0);"
                            >
                              Lemon Tree Hotel, Ahmedabad
                            </a>
                          </h5>
                          <p className=" d-block mb-2">
                            <a
                              className="link-underline google_iframe"
                              href="https://goo.gl/maps/muLohpiQumtkfYJi9"
                              target="_blank"
                            >
                              434/1, Mithakali Six Cross Roads, Navrangpura,
                              Ahmedabad, Gujarat - 380006, India, Ahmedabad,
                              Gujarat - 380006
                            </a>
                          </p>
                          <p className="m-0 pt-2 pb-3">
                            {" "}
                            ...
                            <a
                              onclick="GoCityPage304('https://www.lemontreehotels.com/lemon-tree-hotel/ahmedabad/hotel-ahmedabad')"
                              href="javascript:void(0);"
                              className="explorehotelcta"
                            >
                              Know More{" "}
                            </a>{" "}
                          </p>
                          <p className="pt-2 m-0 distance">
                            Distance &nbsp;
                            <img
                              src="https://www.lemontreehotels.com/assets/front/images/plane.png"
                              alt="Airport Distance"
                              className="facility_toolTip tooltip2 "
                              data-tip={4}
                            />
                            12 km &nbsp;&nbsp;
                            <img
                              src="https://www.lemontreehotels.com/assets/front/images/train.png"
                              alt="Railway Distance"
                              className="facility_toolTip tooltip2 "
                              data-tip={18}
                            />
                            6 km
                          </p>
                        </div>
                        <div className="col-sm-4 col-lg-2 text-center text-lg-right py-3 pr-4 border-bottom hide-logo grey-logo"></div>
                      </div>
                    </div>
                    <div className="py-3 px-2 pt-lg-4 text-center text-lg-left">
                      <div className="row px-2">
                        {/* owl-carousel owl-theme owl-facility */}
                        <div className="col-lg-3 pr-lg-0 pl-lg-3 pb-3 pb-lg-0">
                          <div className="owl-carousel owl-theme facility_owl owl-loaded owl-drag">
                            <div className="owl-stage-outer">
                              <div className="owl-stage" />
                            </div>
                            <div className="owl-nav disabled">
                              <button
                                type="button"
                                role="presentation"
                                className="owl-prev"
                              >
                                <span aria-label="Previous">‹</span>
                              </button>
                              <button
                                type="button"
                                role="presentation"
                                className="owl-next"
                              >
                                <span aria-label="Next">›</span>
                              </button>
                            </div>
                            <div className="owl-dots disabled" />
                          </div>
                        </div>
                        <div className="col-lg-9 text-end px-2 text-lg-right">
                          <a
                            className="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk"
                            target="_blank"
                            href="https://www.lemontreehotels.com/lemon-tree-hotel/ahmedabad/hotel-ahmedabad"
                          >
                            Explore
                          </a>
                          {/*<a class="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk" data-target="#signup-popuprj" href="#">Enquire Now</a> */}
                          <a
                            href="javascript:void(0);"
                            data-id="Lemon Tree Hotel, Ahmedabad"
                            data-toggle="modal"
                            data-target="#book_nw_btn"
                            className="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk redeemnowcls"
                          >
                            Redeem Now
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      id="collapseOne58955"
                      className="collapse"
                      data-parent="#accordion"
                    ></div>
                  </div>
                  <div className="card my-4">
                    <div className="card-header pb-0 pl-3 pt-3">
                      <div className="row">
                        <div className="col-lg-3 pr-lg-0 pl-3">
                          <input
                            type="hidden"
                            name="RptCityHoteldata$ctl01$HidHotelUrl"
                            id="RptCityHoteldata_ctl01_HidHotelUrl"
                            defaultValue={58955}
                          />
                          <input
                            type="hidden"
                            name="RptCityHoteldata$ctl01$HidHotelsid"
                            id="RptCityHoteldata_ctl01_HidHotelsid"
                            defaultValue={86}
                          />
                          <figure className="position-relative mb-0">
                            <a
                              data-lightbox="hotel58955"
                              className="gallery-icon"
                              href="https://www.lemontreehotels.com/lemon-tree-premier/ahmedabad/the-atrium-ahmedabad"
                            >
                              <img
                                className="img-fluid "
                                src="https://www.lemontreehotels.com/uploads/gimages/29_Jul_2023_02_03_55Lemon-Tree-Premier_-The-Atrium,-Ahmedabad---Facade.jpg"
                                style={{}}
                                alt="Lemon Tree Premier, The Atrium, Ahmedabad"
                              />
                            </a>
                          </figure>
                        </div>
                        <div className="col-sm-8 col-lg-7 p-lg-0 pt-2 pl-lg-3 border-bottom">
                          <h5 className="mb-0">
                            <a
                              className="link-underline"
                              onclick="GoCityPage304('https://www.lemontreehotels.com/lemon-tree-premier/ahmedabad/the-atrium-ahmedabad')"
                              href="javascript:void(0);"
                            >
                              Lemon Tree Premier, The Atrium, Ahmedabad
                            </a>
                          </h5>
                          <p className=" d-block mb-2">
                            <a
                              className="link-underline google_iframe"
                              href="https://goo.gl/maps/aY8R5uwWjQ2botmt9"
                              target="_blank"
                            >
                              Off Nehru Bridge, Sabarmati River Front,
                              Ahmedabad, 380001, Gujarat, Ahemdabad, Gujarat -
                              380001
                            </a>
                          </p>
                          <p className="m-0 pt-2 pb-3">
                            {" "}
                            ...
                            <a
                              onclick="GoCityPage304('https://www.lemontreehotels.com/lemon-tree-premier/ahmedabad/the-atrium-ahmedabad')"
                              href="javascript:void(0);"
                              className="explorehotelcta"
                            >
                              Know More{" "}
                            </a>{" "}
                          </p>
                          <p className="pt-2 m-0 distance">
                            Distance &nbsp;
                            <img
                              src="https://www.lemontreehotels.com/assets/front/images/plane.png"
                              alt="Airport Distance"
                              className="facility_toolTip tooltip2 "
                              data-tip={4}
                            />
                            9 km &nbsp;&nbsp;
                            <img
                              src="https://www.lemontreehotels.com/assets/front/images/train.png"
                              alt="Railway Distance"
                              className="facility_toolTip tooltip2 "
                              data-tip={18}
                            />
                            3 km
                          </p>
                        </div>
                        <div className="col-sm-4 col-lg-2 text-center text-lg-right py-3 pr-4 border-bottom hide-logo grey-logo"></div>
                      </div>
                    </div>
                    <div className="py-3 px-2 pt-lg-4 text-center text-lg-left">
                      <div className="row px-2">
                        {/* owl-carousel owl-theme owl-facility */}
                        <div className="col-lg-3 pr-lg-0 pl-lg-3 pb-3 pb-lg-0">
                          <div className="owl-carousel owl-theme facility_owl owl-loaded owl-drag">
                            <div className="owl-stage-outer">
                              <div className="owl-stage" />
                            </div>
                            <div className="owl-nav disabled">
                              <button
                                type="button"
                                role="presentation"
                                className="owl-prev"
                              >
                                <span aria-label="Previous">‹</span>
                              </button>
                              <button
                                type="button"
                                role="presentation"
                                className="owl-next"
                              >
                                <span aria-label="Next">›</span>
                              </button>
                            </div>
                            <div className="owl-dots disabled" />
                          </div>
                        </div>
                        <div className="col-lg-9 text-end px-2 text-lg-right">
                          <a
                            className="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk"
                            target="_blank"
                            href="https://www.lemontreehotels.com/lemon-tree-premier/ahmedabad/the-atrium-ahmedabad"
                          >
                            Explore
                          </a>
                          {/*<a class="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk" data-target="#signup-popuprj" href="#">Enquire Now</a> */}
                          <a
                            href="javascript:void(0);"
                            data-id="Lemon Tree Premier, The Atrium, Ahmedabad"
                            data-toggle="modal"
                            data-target="#book_nw_btn"
                            className="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk redeemnowcls"
                          >
                            Redeem Now
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      id="collapseOne58955"
                      className="collapse"
                      data-parent="#accordion"
                    ></div>
                  </div>
                  <div className="card my-4">
                    <div className="card-header pb-0 pl-3 pt-3">
                      <div className="row">
                        <div className="col-lg-3 pr-lg-0 pl-3">
                          <input
                            type="hidden"
                            name="RptCityHoteldata$ctl01$HidHotelUrl"
                            id="RptCityHoteldata_ctl01_HidHotelUrl"
                            defaultValue={58955}
                          />
                          <input
                            type="hidden"
                            name="RptCityHoteldata$ctl01$HidHotelsid"
                            id="RptCityHoteldata_ctl01_HidHotelsid"
                            defaultValue={86}
                          />
                          <figure className="position-relative mb-0">
                            <a
                              data-lightbox="hotel58955"
                              className="gallery-icon"
                              href="https://www.lemontreehotels.com/lemon-tree-hotel/aligarh/hotel-aligarh"
                            >
                              <img
                                className="img-fluid "
                                src="https://www.lemontreehotels.com/uploads/gimages/29_Jul_2023_01_27_13Lemon-Tree-Hotel,-Aligarh-Facade.jpg"
                                style={{}}
                                alt="Lemon Tree Hotel, Aligarh"
                              />
                            </a>
                          </figure>
                        </div>
                        <div className="col-sm-8 col-lg-7 p-lg-0 pt-2 pl-lg-3 border-bottom">
                          <h5 className="mb-0">
                            <a
                              className="link-underline"
                              onclick="GoCityPage304('https://www.lemontreehotels.com/lemon-tree-hotel/aligarh/hotel-aligarh')"
                              href="javascript:void(0);"
                            >
                              Lemon Tree Hotel, Aligarh
                            </a>
                          </h5>
                          <p className=" d-block mb-2">
                            <a
                              className="link-underline google_iframe"
                              href="https://www.google.com/maps/place/Lemon+Tree+Hotel,+Aligarh/@27.902546,78.088745,13z/data=!4m9!3m8!1s0x3974a50066650bed:0xfb61bc046d6c69d3!5m2!4m1!1i2!8m2!3d27.9025464!4d78.0887449!16s%2Fg%2F11qqkv4g87?hl=en&entry=ttu"
                              target="_blank"
                            >
                              Plot No: B/11, Marris Road, Opp. Pragati Vihar
                              Colony, Aligarh Uttar Pradesh - 202001, India,
                              Aligarh, Uttar Pradesh - 202001
                            </a>
                          </p>
                          <p className="m-0 pt-2 pb-3">
                            {" "}
                            ...
                            <a
                              onclick="GoCityPage304('https://www.lemontreehotels.com/lemon-tree-hotel/aligarh/hotel-aligarh')"
                              href="javascript:void(0);"
                              className="explorehotelcta"
                            >
                              Know More{" "}
                            </a>{" "}
                          </p>
                          <p className="pt-2 m-0 distance">
                            Distance &nbsp;
                            <img
                              src="https://www.lemontreehotels.com/assets/front/images/plane.png"
                              alt="Airport Distance"
                              className="facility_toolTip tooltip2 "
                              data-tip={4}
                            />
                            140 km &nbsp;&nbsp;
                            <img
                              src="https://www.lemontreehotels.com/assets/front/images/train.png"
                              alt="Railway Distance"
                              className="facility_toolTip tooltip2 "
                              data-tip={18}
                            />
                            2 km
                          </p>
                        </div>
                        <div className="col-sm-4 col-lg-2 text-center text-lg-right py-3 pr-4 border-bottom hide-logo grey-logo"></div>
                      </div>
                    </div>
                    <div className="py-3 px-2 pt-lg-4 text-center text-lg-left">
                      <div className="row px-2">
                        {/* owl-carousel owl-theme owl-facility */}
                        <div className="col-lg-3 pr-lg-0 pl-lg-3 pb-3 pb-lg-0">
                          <div className="owl-carousel owl-theme facility_owl owl-loaded owl-drag">
                            <div className="owl-stage-outer">
                              <div className="owl-stage" />
                            </div>
                            <div className="owl-nav disabled">
                              <button
                                type="button"
                                role="presentation"
                                className="owl-prev"
                              >
                                <span aria-label="Previous">‹</span>
                              </button>
                              <button
                                type="button"
                                role="presentation"
                                className="owl-next"
                              >
                                <span aria-label="Next">›</span>
                              </button>
                            </div>
                            <div className="owl-dots disabled" />
                          </div>
                        </div>
                        <div className="col-lg-9 text-end px-2 text-lg-right">
                          <a
                            className="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk"
                            target="_blank"
                            href="https://www.lemontreehotels.com/lemon-tree-hotel/aligarh/hotel-aligarh"
                          >
                            Explore
                          </a>
                          {/*<a class="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk" data-target="#signup-popuprj" href="#">Enquire Now</a> */}
                          <a
                            href="javascript:void(0);"
                            data-id="Lemon Tree Hotel, Aligarh"
                            data-toggle="modal"
                            data-target="#book_nw_btn"
                            className="btn btn-sec mr-2 mr-lg-3 p-1 px-3 mt-2 mt-lg-0 card-link ldesk redeemnowcls"
                          >
                            Redeem Now
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      id="collapseOne58955"
                      className="collapse"
                      data-parent="#accordion"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n   .hide {display: none!important;}\n   .citynamevaib_main .citynamevaib_inner li a.active{display: inline;}\n"
    }}
  />
  {/* Modal */}
  <div
    className="modal fade"
    id="book_nw_btn"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            Enquire Now
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="trip_form">
            <div className="row mb-3">
              <div className="form-group col-12">
                <input
                  type="hidden"
                  name="txtDName22"
                  id="txtDName22"
                  className="form-control"
                  defaultValue="Harsh Yadav"
                  placeholder="Name"
                />
                <input
                  type="hidden"
                  id="txtDEmail2"
                  defaultValue="harsh.yadav243@gmail.com"
                />
                <input
                  type="hidden"
                  id="txtDPhone2"
                  defaultValue={9971426378}
                />
                <input type="hidden" id="getredeemhotelname" defaultValue="" />
                <input
                  type="hidden"
                  id="redeem_membershipId"
                  defaultValue="G2808604"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="form-group col-6">
                <label>Check In</label>
                <input
                  type="text"
                  className="form-control clickable input-md hasDatepicker"
                  id="DtChkIn22"
                  defaultValue=""
                  placeholder="Check-In"
                />
              </div>
              <div className="form-group col-6">
                <label>Check Out</label>
                <input
                  type="text"
                  className="form-control clickable input-md hasDatepicker"
                  id="DtChkOut22"
                  defaultValue=""
                  placeholder="Check-Out"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="form-group col-6">
                <label>Rooms</label>
                <select id="redeemrooms" className="form-control">
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </select>
              </div>
              <div className="form-group col-6">
                <label>Adults </label>
                <select id="redeemAdults" className="form-control">
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
            </div>
            <div className="row mb-3" style={{ display: "none" }}>
              <div className="form-group col-12">
                <input
                  type="text"
                  id="redeempointId"
                  className="form-control"
                  placeholder="Redeem Point"
                />
              </div>
            </div>
            <a
              href="javascript:void(0)"
              className="cs-cta reserve-cs-cta"
              onclick="return ValidateRedeem234578()"
            >
              Submit
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* member middle  */}
</div>



 <FooterPage />


    </>
  )

}

export default RedeemPage;

