import {useNavigate} from "react-router-dom";
import $ from 'jquery'; 
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import '../index.css';  
import {useParams} from "react-router-dom";
import {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import axios from "axios";

    export default function OfferSlider({adult,children, countroom, rangeStart, rangeEnd}){

      const navigate = useNavigate()

      const options = {           
          responsive: {
              0: {
                  items: 1,
              },
              400: {
                  items: 1,
              },
              600: {
                  items: 2,
              },
              700: {
                  items: 3,
              },
              1000: {
                  items: 3,
      
              }
          },
      };
   

      const{brand_slug, city_alias, hotel_slug}  = useParams(); 
      const[htloffer, sethtloffer] = useState({   
        brand_slug:brand_slug,
        city_slug:city_alias,
        hotel_slug:hotel_slug
  })

  const[htofr, sethtofr] = useState([]);
  const[meta1, setmeta1] = useState([]);
const showhotelcontact = () =>{
  axios.post('https://www.sdsfoundation.com/lth/demo/hotel-offers', htloffer).then(res => {
      sethtofr(res.data.hotel_offers);
      setmeta1(res.data.hotel_meta);
}).catch(err => {
console.log(err)
})
}

// on load function call for loading 
useEffect(() => {
  showhotelcontact();
},[])
 
      
          return (  
            <div>  
      <Helmet>
        <title>{meta1 && meta1.length>0 &&  meta1[0].title}</title>
        <meta name="description" content={meta1 && meta1.length>0 &&  meta1[0].description} />
    </Helmet>
       <div className='container-fluid' >  
       {htofr?.length>0 && (   
        <OwlCarousel  
          className="owl-carousel owl-theme owl-loaded owl-drag" items={4} margin={8} autoplay ={true} loop={true}  nav={true} autoplayTimeout={8000} autoplaySpeed={4000} {...options} navText={[
            'Prev<span></span>',
            'Next<span></span>'
          ]} >  

          
{
 htofr.map((item) => 
<div className="item cs4">
  <div className="ourOffers-cs-sc-slider-item">
    <img
      src={item.image}
      alt="Sign Up"
    />
    <div className="ourOffers-cs-sc-slider-item-heading">
      <div className="ourOffers-cs-sc-slider-item-heading-left">
        <h4 className="px-0">{item.title}</h4>
        <p>
          {item.short_description}
        </p>
      </div>
      <div className="ourOffers-cs-sc-slider-item-hover">
        <a
          href={item.landing_page_link}
          target="_blank"
          className="cs-cta cs-cta-explore explore-without-border offer-read-more-btn-raj"
        >
          Read More About Offer
        </a>
        <button className="hotel-new-booknow-btn" onClick={() => window.open(`https://be.synxis.com/?adult=${adult}&arrive=${rangeStart}&chain=7710&child=${children}&currency=INR&depart=${rangeEnd}&hotel=${$("#getBeCityHotel").val()}&level=hotel&locale=en-US&rooms=${countroom}&shell=SBE&start=availresults&template=SBE&theme=initialTheme2`)}>
    Book Now
    </button>
      </div>
    </div>
  </div>
</div>
      )
    }

      </OwlCarousel>  
       )
      }
      </div>  
  
      </div>  
          )  
          
      }  
        
  
