import HeaderPage2 from "../Header2";
import FooterPage from '../Footer';
import axiosBaseURL from "../member/httpCommon";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

function FacilitiesPage() {
 
  const{brand_slug} = useParams(); 
  const{city_alias} = useParams();
  const{hotel_slug} = useParams();

  const[htlcontact, sethtlcontact] = useState({   
      brand_slug:brand_slug,
      city_slug:city_alias,
      hotel_slug:hotel_slug
})


const[htsrvc, sethtsrvc] = useState([]);
const[htfacilities, sethtfacilities] = useState([]);
const[htrmnities, sethtrmnities] = useState([]);
const[meta1, setmeta1] = useState([]);

const showalldetail = () =>{
  axiosBaseURL.post('/hotel-facilities', htlcontact).then(res => {
    sethtfacilities(res.data.hotel_services_facilities);
    sethtsrvc(res.data.hotel_facilities);
    sethtrmnities(res.data.room_amenities);
    setmeta1(res.data.hotel_meta);

}).catch(err => {
console.log(err)
})
}

// on load function call for loading 
useEffect(() => {
  showalldetail();
},[])



  return (
    <>
         <Helmet>
        <title>{meta1 && meta1.length>0 &&  meta1[0].title}</title>
        <meta name="description" content={meta1 && meta1.length>0 &&  meta1[0].description} />
    </Helmet>
    
<HeaderPage2 hoteldt1={[]}/>
<section className="filters-cs cs-sc-padding country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href={hotel_slug}>
               
                Destination
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/hotels"> Hotels </a>
            </li>
            <li className="breadcrumb-item">
            <Link to={`../${brand_slug}/${city_alias}/${hotel_slug}`}> {brand_slug}, {city_alias}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Facilities
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>




<section className="amenities-cs-sc-page-main amenities-cs-sc cs-sc-padding heading-bg-sc-cs facilities-main-pg ">
  <div className="container">
    <div className="row">
      <div className="col-md-12 new-reso-heading-rj service-and-facilities-rj">
        <center className="sc-heading">
          <h2>Services &amp; Facilities</h2>
          {
      htfacilities.map((val) => 
          <div>
            
            <div dangerouslySetInnerHTML={{__html: val.description}}></div>
          </div>
      )
    }
          <p />
        </center>
      </div>
    </div>
  </div>
</section>

<section className="cs-sc-padding facilities-main-pg">
  <div className="container">
    <div className="row ">
      <div className="col-md-12">
        <div className="facilities-col new-reso-heading-rj">
          <center className="sc-heading">
            <h2>Hotel Facilities</h2>
          </center>
          <ul className="mt-3 new-reso-heading-rj-ul">
          {
      htsrvc.map((val) => 
            <li>
              <img
                src={val.amenities_image}
                alt={val.title}
              />
              <h4>{val.description}</h4>
            </li>
      )
    }
        
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>


<section className="cs-sc-padding facilities-main-pg mb-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="facilities-col new-reso-heading-rj">
          <center className="sc-heading">
            <h2>Room Facilities</h2>
          </center>
          <ul className="new-reso-heading-rj-ul">
          {
      htrmnities.map((val) => 
            <li>
              <img
                src={val.amenities_image}
                alt={val.title}
              />
              <h4>{val.description}</h4>
            </li>             
      )
    }
           
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>




<FooterPage />

    </>
  )

}

export default FacilitiesPage;

