
import './App.css';
import HomePage from './Home';
import HotelListPage from './hotels';
import CityHotelPage from './CityHotels';
import HotelDetailPage from './HotelDetailPages/HotelDetail';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import HofferPage from './HotelDetailPages/Offers';
import RoomsPage from './HotelDetailPages/Rooms';
import RestaurantsPage from './HotelDetailPages/Restaurants';
import EventPage from './HotelDetailPages/Events';
import FacilitiesPage from './HotelDetailPages/Facilities';
import GalleryPage from './HotelDetailPages/Gallery';
import ContactUsPage from './HotelDetailPages/ContactUs';

import HeaderPage from './Header';
import SpecialOfferPage from './otherPages/special-offer';
import RewardPage from './otherPages/rewards';
import OurBrandsPage from './otherPages/ourbrands';
import SustainabilityPage from './otherPages/sustainability';
import AwardsPage from './FooterPages/awards';
import AboutUsPage from './FooterPages/aboutUs';
import ContactUsMPage from './FooterPages/ContactUs';
import PrivacyPolicyPage from './FooterPages/PrivacyPolicy';
import DayUseRoomPage from './FooterPages/DayUseRooms';
import NonAffiliationPage from './FooterPages/NonAffiliation';
import TNCPage from './FooterPages/term-condition';
import MediaPage from './FooterPages/media';
import SiteMapPage from './FooterPages/sitemap';
import TravekGPage from './FooterPages/travel-guide';
import HeaderPage2 from './Header2';
import UpcomingHotelPage from './otherPages/upcominghotels';
import SignUpPage from './member/signup';
import SignInPage from './member/signin';
import ThankYouPage from './otherPages/thankyou';
import TokenPage from './member/TokenPage';
import DashboardPage from './member/dashboard';
import TripPage from './member/trip';
import RedeemPage from './member/redeem';
import Cart from './pages/cart/Cart';
import Homepage1 from './pages/homepage1/Homepage1';
import { Context } from './context/Context';
import ProfilePage from './member/profile';

function App() {

  return (
 
<BrowserRouter> 
<Context>
    <Routes>
    <Route path="/" element={<HomePage/>}/>
    <Route path="/Homepage1" element={<Homepage1/>}/>
    <Route path="/" element={<Cart/>}/>
    <Route path="/tokenpage" element={<TokenPage/>}/>
    <Route path="/HomePage" element={<HomePage/>}/>
    <Route path="/HeaderPage/" element={<HeaderPage/>}/>   
    <Route path="/HeaderPage2/:city_slug" element={<HeaderPage2/>}/>  
    <Route path="/hotels" element={<HotelListPage/>}/>   
    <Route path="/:city_slug" element={<CityHotelPage/>}/>   
    <Route path="/:brand_slug/:city_alias/:hotel_slug" element={<HotelDetailPage/>}/>  
    <Route path="/OfferPage/:brand_slug/:city_alias/:hotel_slug" element={<HofferPage/>}/>  
    <Route path="/RoomsPage/:brand_slug/:city_alias/:hotel_slug" element={<RoomsPage/>}/>  
    <Route path="/RestaurantsPage/:brand_slug/:city_alias/:hotel_slug" element={<RestaurantsPage/>}/> 
    <Route path="/EventPage/:brand_slug/:city_alias/:hotel_slug" element={<EventPage/>}/> 
    <Route path="/FacilitiesPage/:brand_slug/:city_alias/:hotel_slug" element={<FacilitiesPage/>}/>
    <Route path="/GalleryPage/:brand_slug/:city_alias/:hotel_slug" element={<GalleryPage/>}/>
    <Route path="/ContactUsPage/:brand_slug/:city_alias/:hotel_slug" element={<ContactUsPage/>}/>
    <Route path="/SpecialOffer" element={<SpecialOfferPage/>}/>
    <Route path="/rewards" element={<RewardPage/>}/>
    <Route path="/our-brands" element={<OurBrandsPage/>}/>
    <Route path="/sustainability" element={<Cart/>}/>
    <Route path="/awards" element={<AwardsPage/>}/>
    <Route path="/AboutUs" element={<AboutUsPage/>}/>
    <Route path="/ContactUs/" element={<ContactUsMPage/>}/>
    <Route path="/PrivacyPolicy" element={<PrivacyPolicyPage/>}/>
    <Route path="/DayUseRoom" element={<DayUseRoomPage/>}/>
    <Route path="/NonAffiliation" element={<NonAffiliationPage/>}/>
    <Route path="/term-condition" element={<TNCPage/>}/>
    <Route path="/media" element={<MediaPage/>}/>
    <Route path="/sitemap" element={<SiteMapPage/>}/>
    <Route path="/travel-guide" element={<TravekGPage/>}/>
    <Route path="/upcominghotels" element={<UpcomingHotelPage/>}/>
    <Route path="/signup" element={<SignUpPage/>}/>
    <Route path="/signin" element={<SignInPage/>}/>   
    <Route path="/dashboard" element={<DashboardPage/>}/>
    <Route path="/trip" element={<TripPage/>}/>
    <Route path="/redeem" element={<RedeemPage/>}/>
    {/* <Route path="/BookingPage/:synxis_id" element={<BookingPage/>}/>  */}
    <Route path="/thankyou" element={<ThankYouPage/>}/>
    <Route path="/profile" element={<ProfilePage/>}/>
    </Routes>
    </Context>
</BrowserRouter>


  
  );
  
}

export default App;
