import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import '../index.css';  

    export default function RoomSuites({rsoot}){    

        const options = {           
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 3,
        
                }
            },
        };
     
      
          return (  
            <div>  
     
  
       <div className='container-fluid'>   
       {rsoot?.length>0 && (           
        <OwlCarousel id='rooms-cs-main-updated-slider'
          className="owl-carousel owl-theme owl-loaded owl-drag" items={4} margin={8} autoplay ={true} loop={true}  nav={true} autoplayTimeout={8000} autoplaySpeed={4000} {...options}>  

<div className="owl-stage-outer">
              <div
                className="owl-stage"
              >
                  {
   rsoot.map((val) => 
                <div
                  className="owl-item">
                  <div className="item">
                    <img
                      src={val.image}
                      alt={val.title}
                    />
                    <div className="price-cs">
                      ₹ 0<span> From/Per Night</span>
                    </div>
                    <div className="below_slider-cs-sc-card">
                      <div className="below_slider-cs-sc-card-left">
                        <h4>{val.title}</h4>
                        <span>{val.area} sq.mtr.</span>
                      </div>
                      <ul className="list--inline-rooms ">
                        <li className="small color-gunmetal">
                          <img
                            src="https://www.lemontreehotels.com/uploads/images/25_49_2023_01_49_02coffee-machine.png"
                            alt="Tea Coffee Maker"
                          />
                        </li>
                        <li className="small color-gunmetal">
                          <img
                            src="https://www.lemontreehotels.com/uploads/images/19_13_2023_08_13_32lcd.png"
                            alt="LCD TV"
                          />
                        </li>
                        <li className="small color-gunmetal">
                          <img
                            src="https://www.lemontreehotels.com/uploads/images/19_14_2023_08_14_02house-safe.png"
                            alt="Electronic Safe"
                          />
                        </li>
                        <li className="small color-gunmetal">
                          <img
                            src="https://www.lemontreehotels.com/uploads/images/19_12_2023_08_12_22wifi.png"
                            alt="Wi-Fi"
                          />
                        </li>
                        <li className="small color-gunmetal">
                          <img
                            src="https://www.lemontreehotels.com/uploads/images/05_47_2023_01_47_57call.png"
                            alt="Phone"
                          />
                        </li>
                        <li className="small color-gunmetal">
                          <img
                            src="https://www.lemontreehotels.com/uploads/images/09_20_2024_07_20_42Working Desk.png"
                            alt="Working Desk"
                          />
                        </li>
                        
                      </ul>
                      {/* <a href="#" className="cs-cta">
                        Book Now
                      </a> */}
                    </div>
                  </div>
                </div>
   )
  }
              </div>
            </div>
            


      </OwlCarousel>  
       )
      }
      </div>  
  
      </div>  
          )  
          
      }  
        
  
