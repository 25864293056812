import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';
import { useState, useEffect } from "react";
import axios from "axios";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import SignInPage from './signin';


function SignUpPage() {
  const navigate = useNavigate();
  const [tokenname, settokenname] = React.useState('');
  React.useEffect(()=>{
    fetch('http://lemontreedemo.netcarrots.in/LTMemberAPI/API/GenrateToken', {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify( {'UserName':'LemonTree',
      'Password':'Lemon@123'})
    }).then(
      res => res.json())
      
      .then( res => {
        settokenname(res.token);
             console.log("token", tokenname)
             {sessionStorage.setItem('token1', tokenname)}
        // const accessToken = tokenname;
        // console.log("Token Name",accessToken)
        // Save the access token in a React state or state management system
      }
    )
 
    .catch(error => console.error(error));
  },[]);



  const token1p = sessionStorage.getItem('token1')

  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState();


  const[reguser,setreguser]=useState({    
      FirstName: "",
      LastName: "",
      MobileNo: "",
      EmailId: "",
      City: "",
      Country: "",
      PrivacyPolicyAcceptance: "Y",
       DateofBirth:(date),
       WeddingAnniversary:"",
      OTP: ""          
})

let url="http://lemontreedemo.netcarrots.in/LTMemberAPI/API/SignUpAPI";
//inputHandler
const inputHandler=(e)=>{
  setreguser({...reguser,[e.target.name]:e.target.value})
}

console.log("signup get result", tokenname)
const RegisterMe=(e)=>{
  e.preventDefault();
  const headers = { 'Content-Type': 'application/json', 'Authorization':'bearer '+ tokenname };
  axios.post(`${url}`,JSON.stringify(reguser), {headers})
  .then(()=>{    
      alert("Registered Successfully")
      navigate("/SignInPage")
      setreguser(null);
      
      console.log("this is", e.target[1].value)
      e.target.reset();
  }).catch(err=>{console.log(err)});
}

return (
    <>
<HeaderPage />

<section className="MemberSignUp_hero_sc">
  <div className="container">
    <div className="row">
      <div className="col-md-4 benefits_list_cs p-4">
        <div>
          <h3 className="mb-4">
            <span>Join</span> Infinity Rewards
          </h3>
          <div className="benefits_list_cs_listing">
            <div className="benefits_list_cs_listing_item">
              <figure>
                <img
                  src="https://www.lemontreehotels.com/uploads/images/freeRoomNights.png"
                  className=""
                />
              </figure>
              <div className="benefits_list_cs_listing_item_content">
                <h4> Free room nights </h4>
              </div>
            </div>
            <div className="benefits_list_cs_listing_item">
              <figure>
                <img
                  src="https://www.lemontreehotels.com/uploads/images/Breakfast_new.png"
                  className=""
                />
              </figure>
              <div className="benefits_list_cs_listing_item_content">
                <h4>Complimentary breakfast </h4>
              </div>
            </div>
            <div className="benefits_list_cs_listing_item">
              <figure>
                <img src="https://www.lemontreehotels.com/uploads/images/Special-Offers.png " />
              </figure>
              <div className="benefits_list_cs_listing_item_content">
                <h4>Special offers </h4>
              </div>
            </div>
            <div className="benefits_list_cs_listing_item">
              <figure>
                <img src="https://www.lemontreehotels.com/uploads/images/WiFi-updated.png" />
              </figure>
              <div className="benefits_list_cs_listing_item_content">
                <h4>Free wifi </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="cs_form_join_main">
          <div className="cs_form registerpage">
            <h2 className="form-title">Create a new account?</h2>
            <form onSubmit={RegisterMe}>


<div className="row g-3 my-2">
  <div className="col-12 col-lg-6">
  <input required type="text" placeholder="First Name *" className="form-control" name="FirstName" value={reguser?.FirstName} onChange={inputHandler} />

  </div>
  <div className="col-12 col-lg-6">
  <input required type="text" placeholder="Last Name *" className="form-control" name="LastName" value={reguser?.LastName} onChange={inputHandler} />
  </div>
</div>

<div className="row g-3 my-2">
  <div className="col-12 col-lg-6">
  <input required type="text" placeholder="Email *" className="form-control" name="EmailId" value={reguser?.EmailId} onChange={inputHandler} />
  </div>

  <div className="col-12 col-lg-6">
  <input required type="tel" placeholder="Enter phone number *" maxLength={10} className="form-control" name="MobileNo" value={reguser?.MobileNo} onChange={inputHandler} />

  </div>
</div>

<div className="row g-3 my-2">
  <div className="col-12 col-lg-6">
  <input required type="text" placeholder="Enter Country" className="form-control search_in" name="Country" value={reguser?.Country} onChange={inputHandler} />

  {/* <select onChange={inputHandler} id="inputState" class="form-select" name="Country" value={reguser?.Country}>
      <option selected>Select Country *</option>
      <option>India</option>
      <option>USA</option>
    </select>
    */}
  </div>
  <div className="col-12 col-lg-6">
  <input required type="text" placeholder="Enter City" className="form-control search_in" name="City" value={reguser?.City} onChange={inputHandler} />

  </div>
</div>
<div className="row g-3 my-2">
  <div className="col-12 col-lg-6">
  <DatePicker name="DateofBirth" value={reguser?.DateofBirth} className='form-control' placeholderText={'Birthday Date'} 
        selectsStart
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        startDate={startDate}
      />
        
   
  </div>
  <div className="col-12 col-lg-6">
  {/* <DatePicker className='form-control' placeholderText={'Anniversary Date'} 
        selectsStart
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        startDate={startDate}
      /> */}
        

  </div>
</div>

<div className="row g-3 my-2">
<div className="col-12 col-lg-6">
  <div className="form-check">
    <input className="form-check-input" type="checkbox" value="Y"  />
    <label className="form-check-label" htmlFor="gridCheck">
    I agree to the <a href="/privacy-policy">Privacy Policy</a>
    </label>
    <p>By joining, you are agreeing to the Infinity Rewards <a href="/term-condition">Terms and Condition</a>.</p>
  </div>
  </div>
</div>


<div className="row g-3 my-2">
  <div className="col-12 col-lg-6">
  <button type="submit" class="btn btn-primary submit_in">Join</button>
  </div>
  <div className="col-12 col-lg-6 text-end">
  <p className="p-text">
  <strong>Not a member?</strong>
  <a href="/signin">Sign In</a>
</p>

  </div>
</div>
</form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


      <FooterPage />


    </>
  )

}

export default SignUpPage;

