import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faVolumeControlPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function ContactUsMPage() {
   return (
    <>
      <HeaderPage />
 <section className="filters-cs cs-sc-padding mt-5 pt-4 country-page-cs px-0">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="https://www.lemontreehotels.com">Home</a>
            </li>
                <li className="breadcrumb-item active" aria-current="page">
              Contact Us
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>


<section className="contact-main-cs-sc cs-sc-padding bg-light">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="contact-us-inner-main contact-us-lt-rj">
          <ul>
            <li>
              <h4>
              <FontAwesomeIcon icon={faMapMarkerAlt} color="#c3cfd6" />
                          Address
              </h4>
              <span>
                <a
                  href="https://www.google.com/maps?ll=27.21121,77.997202&z=13&t=m&hl=en&gl=IN&mapclient=embed&cid=1767151043225105889"
                  target="_blank">
                 Asset No. 6, Aerocity Hospitality District, IGI Airport New Delhi, 110037, India
                </a>
              </span>
            </li>
            <li>
              <h4>
              <FontAwesomeIcon icon={faVolumeControlPhone} color="#c3cfd6" spin />
              
                Phone
              </h4>
              <span>For Room Reservations: <a href="tel:+919911701701">+91 9911701701</a></span>
                        <span>Corporate Office: <a href="tel:01146050101">011 46050101</a></span>
                        <span>Fax: <a href="fax:01146050101">+91 11 46050110</a></span>
                  
            </li>
            <li>
              <h4>
              <FontAwesomeIcon icon={faEnvelope} color="#c3cfd6" /> Email
              </h4>
              <span>
                <a href="mailto:reservations@lemontreehotels.com">
                reservations@lemontreehotels.com
                </a>
            
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

      <FooterPage />


    </>
  )

}

export default ContactUsMPage;

