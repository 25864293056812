import '../App.css';
import '../index.css';
import HeaderPage from '../Header';
import FooterPage from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

function OurBrandsPage() {
   return (
    <>
      <HeaderPage />
 <div className="banner-beta-one banner h350">
  <img
    className=" w-100"
    src="https://www.lemontreehotels.com/assets/front/images/rewards_banner_1.jpg"
  />
  
</div>

{/* banner end */}



<div id="WhatsNewSection_cs_sc " className="pt-5">
  <div className="container">
    <div className="row row justify-content-center">
      <div className="col-12">
        <div className="why_us">
          <div className="heading text-center">
            <h4>Our Brands</h4>
            <p>
              Step into a realm of unrivaled comfort, style, and impeccable
              service that will leave you spellbound. With a diverse range of
              hotels tailored to your every need, from luxurious indulgence to
              economical charm, we invite you to embark on a journey where every
              moment is an exquisite experience. Discover our warm welcomes,
              thoughtfully designed rooms, tantalizing cuisines, and our
              unwavering commitment to sustainability and community. Be
              captivated. Be inspired. Be a part of the extraordinary Lemon Tree
              family. Welcome to a world where hospitality knows no bounds.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section className="main-our-brands py-5" id="cs-main-our-brands">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="ourBrands-nav">
          <ul>
            <li>
              <a href="#upScale">Upscale</a>
            </li>
            <li>
              <a href="#upperMidscale">Upper Midscale</a>
            </li>
            <li>
              <a href="#midscale">Midscale</a>
            </li>
            <li>
              <a href="#economy">Economy</a>
            </li>
          </ul>
        </div>
        <div className="ourBrands_main">
          <div className="itemOurbrand_main cs-sc-padding" id="upScale">
            <h2>Upscale</h2>
            <p className="w-50 mx-auto text-center mb-5">
              Luxury hotel brand in India that offers stylishly elegant yet
              comfortably informal accommodation and exceptional guest
              experiences.
            </p>
            <div className="row mb-3">
              <div className="col-md-12 new-reso-heading-rj">
                <h3>Aurika Hotels &amp; Resorts</h3>
                <p>
                  Aurika Hotels &amp; Resorts is the upscale brand of Lemon Tree
                  Hotels. We curate extraordinary experiences in a stunning
                  ambience, whenever you stay with us
                  <a href="/aurika-hotels-resorts">Explore</a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 p-0 pl-2">
                <img
                  src="https://www.lemontreehotels.com/assets/front/images/hero-img.jpg"
                  className=" w-100 p-2"
                />
              </div>
              <div className="col-md-6 p-0 pr-2">
                <div className="right-ourbrand">
                  <img
                    src="https://www.lemontreehotels.com/assets/front/kimages/511767306-aurika-hotels-and-resorts-logo-black.jpg"
                    className="p-2 "
                  />
                  <img
                    src="https://www.lemontreehotels.com/assets/front/kimages/Aurikahero1.jpg"
                    className="p-2 "
                  />
                  <img
                    src="https://www.lemontreehotels.com/assets/front/kimages/Aurikahero2.jpg"
                    className="p-2 "
                  />
                  <img
                    src="https://www.lemontreehotels.com/assets/front/kimages/Aurikahero3.jpg"
                    className="p-2 "
                  />
                </div>
              </div>
            </div>
            <a href="#cs-main-our-brands" className="up-top">
                  <FontAwesomeIcon icon={faArrowUp} /> back to top
            </a>
          </div>
          <div
            className="itemOurbrand_main cs-sc-padding new-reso-heading-rj"
            id="upperMidscale"
          >
            <h2>Upper Midscale</h2>
            <p className="w-50 mx-auto text-center mb-5">
              Elevated upper midscale hotels offering stylish features,
              excellent service, customized experiences, spa, dining, and
              impeccable attention.
            </p>
            <div className="brand_row">
              <div className="row mb-3">
                <div className="col-md-12">
                  <h3>Lemon Tree Premier</h3>
                  <p>
                    Lemon Tree Premier Hotel This chain of upper midscale
                    business and leisure hotels elevates the Lemon Tree
                    experience while retaining the same freshness
                    <a href="/lemon-tree-premier">Explore</a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 p-0 pl-2">
                  <img
                    src="https://www.lemontreehotels.com/assets/front/kimages/citysearchgallery.jpg"
                    className="w-100 p-2 "
                  />
                </div>
                <div className="col-md-6 p-0 pr-2">
                  <div className="right-ourbrand">
                    <img
                      src="https://www.lemontreehotels.com/assets/front/kimages/511767338-lemon-tree-premier-logo-black.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/kimages/citysearchgallery3.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/kimages/citysearchgallery2.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/kimages/citysearchgallery4.jpg"
                      className="p-2 "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="brand_row brand_row_last">
              <div className="row mb-3">
                <div className="col-md-12">
                  <h3>Keys Prima By Lemon Tree Hotels</h3>
                  <p>
                    Keys Prima Our premium brand welcomes you with its stylish
                    features and excellent service. Experience exclusive
                    hospitality with our customised services that make your time
                    with us unforgettable. We assure you of our impeccable
                    attention and innovative service. Relax and indulge at our
                    spa, salon, swimming pool and savour an array of cuisines at
                    our dining restaurants.
                    <a href="/keys-prima-hotel">Explore</a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 p-0 pl-2">
                  <img
                    src="https://www.lemontreehotels.com/assets/front/kimages/KPH.jpg"
                    className="w-100 p-2 "
                  />
                </div>
                <div className="col-md-6 p-0 pr-2">
                  <div className="right-ourbrand">
                    <img
                      src="https://www.lemontreehotels.com/assets/front/kimages/511767322-keys-prima-logo-black.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/kimages/KPH4.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/kimages/KPH2.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/kimages/KPH5.jpg"
                      className="p-2 "
                    />
                  </div>
                </div>
              </div>
            </div>
            <a href="#cs-main-our-brands" className="up-top">
                  <FontAwesomeIcon icon={faArrowUp} /> back to top
            </a>
          </div>
          <div
            className="itemOurbrand_main cs-sc-padding new-reso-heading-rj"
            id="midscale"
          >
            <h2>Midscale</h2>
            <p className="w-50 mx-auto text-center mb-5">
              Vibrant midscale hotels that provide uplifting experiences, warm
              hospitality, efficient service, impeccable hygiene, delicious
              food, and restful nights.
            </p>
            <div className="brand_row">
              <div className="row mb-3">
                <div className="col-md-12">
                  <h3>Lemon Tree Hotel</h3>
                  <p>
                    Lemon Tree Hotel are midscale business and leisure hotels
                    that uplift your spirits at the end of a long day. Like the
                    fruit they are named after
                    <a href="/lemon-tree-hotel">Explore</a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 p-0 pl-2">
                  <img
                    src="https://www.lemontreehotels.com/assets/front/images/LTH.jpg"
                    className="w-100 p-2 "
                  />
                </div>
                <div className="col-md-6 p-0 pr-2">
                  <div className="right-ourbrand">
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/511767332-lemon-tree-hotels-logo-black.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/LTH2.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/LTH3.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/LTH4.jpg"
                      className="p-2 "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="brand_row brand_row_last ">
              <div className="row mb-3">
                <div className="col-md-12">
                  <h3>Keys Select By Lemon Tree Hotels</h3>
                  <p>
                    Keys Select Our popular brand invites you to enjoy the
                    comfort and convenience at these hotels, whether you are on
                    business or leisure. A warm welcome, efficient service,
                    impeccable hygiene, delectable food and a good night’s sleep
                    will keep you coming back to stay with us.
                    <a href="/keys-select-hotel">Explore</a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 p-0 pl-2">
                  <img
                    src="https://www.lemontreehotels.com/assets/front/images/KSH.jpg"
                    className="w-100 p-2 "
                  />
                </div>
                <div className="col-md-6 p-0 pr-2">
                  <div className="right-ourbrand">
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/511767328-keys-select-logo-black.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/KSH2.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/KSH4.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/KSH5.jpg"
                      className="p-2 "
                    />
                  </div>
                </div>
              </div>
            </div>
            <a href="#cs-main-our-brands" className="up-top">
                  <FontAwesomeIcon icon={faArrowUp} /> back to top
            </a>
          </div>
          <div
            className="itemOurbrand_main cs-sc-padding new-reso-heading-rj"
            id="economy"
          >
            <h2>Economy</h2>
            <p className="w-50 mx-auto text-center mb-5">
              Uniquely designed economy hotels offering affordable rates, clean
              rooms, convenient amenities, and a focus on safety and security
              for budget-conscious travelers.
            </p>
            <div className="brand_row">
              <div className="row mb-3">
                <div className="col-md-12">
                  <h3>Red Fox by Lemon Tree Hotels</h3>
                  <p>
                    Red Fox by Lemon Tree Hotels Red Fox welcome you with its
                    bold interiors as well as crisp and clean rooms. These
                    economy hotels delight you with their unbeatable value and
                    reliable safety standards. Here friendly smiles and a lively
                    environment go hand in hand with professional service. The
                    business facilities at Red Fox include hi-speed WiFi, Cyber
                    Kiosk, Clever Fox Café, an efficient meeting room, a
                    well-equipped gym and laundry service. Hotels that are young
                    at heart, Red Fox lets you be yourself whether you are
                    working on your laptop or winning a game of carom in the
                    lounge. Add to this, Clever Fox Café’s scrumptious food and
                    the hotels' ‘close to home’ environment - these hotels
                    become the first choice for price conscious travelers.
                    <a href="/red-fox-hotel">Explore</a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 p-0 pl-2">
                  <img
                    src="https://www.lemontreehotels.com/assets/front/images/RFH.jpg"
                    className="w-100 p-2 "
                  />
                </div>
                <div className="col-md-6 p-0 pr-2">
                  <div className="right-ourbrand">
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/511767301-red-fox-by-lemon-tree-hotels-colour-main.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/RFH2.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/RFH1.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/RFH3.jpg"
                      className="p-2 "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="brand_row brand_row_last ">
              <div className="row mb-3">
                <div className="col-md-12">
                  <h3>Keys Lite By Lemon Tree Hotels</h3>
                  <p>
                    Keys Lite This budget brand is targeted at smart consumers
                    who look for affordable and stress free accommodation when
                    they travel. These hotels offer hygienic rooms, multiple
                    amenities, a selection of delicious food, safety and
                    security for the price conscious traveler.
                    <a href="/keys-lite-hotel">Explore</a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 p-0 pl-2">
                  <img
                    src="https://www.lemontreehotels.com/assets/front/images/KLH.jpg"
                    className="w-100 p-2 "
                  />
                </div>
                <div className="col-md-6 p-0 pr-2">
                  <div className="right-ourbrand">
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/511767311-keys-lite-logo-black.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/KLH4.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/KLH2.jpg"
                      className="p-2 "
                    />
                    <img
                      src="https://www.lemontreehotels.com/assets/front/images/KLH3.jpg"
                      className="p-2 "
                    />
                  </div>
                </div>
              </div>
            </div>
            <a href="#cs-main-our-brands" className="up-top">
                  <FontAwesomeIcon icon={faArrowUp} /> back to top
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>





      <FooterPage />


    </>
  )

}

export default OurBrandsPage;

