import './App.css';
import { useLocation } from "react-router-dom";
import FooterPage from './Footer';
import ShowMoreText from "react-show-more-text";
import HeaderPage from './Header';
import {useParams} from "react-router-dom";
import { useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPlane, faTrain } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import useCounter from "./useCounter";
import useCounterChild from "./useCounterChild";
import useCountroom from "./useCountroom.js";
import useCalender from "./useCalender.js";
import $ from 'jquery';
import AccordionItem from './AccordionComponent/Accordion.js';
import axiosBaseURL from "./member/httpCommon";


function CityHotelPage() {

  const[adult]=useCounter();
  const[children]=useCounterChild();
  const[countroom]=useCountroom();
  const[rangeStart,rangeEnd]=useCalender();

  const [activeIndex, setActiveIndex] = useState(null);
  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const location = useLocation();
  const [url, setUrl] = useState(null);

  const navigate = useNavigate()
  const{city_slug} = useParams('city_slug');
  
  const options = {           
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};

const[htdisplay, sethtdisplay] = useState({
    url_slug:city_slug
})

const[cityList, setcityList] = useState([]);

const[faqsList, setfaqsList] = useState([]);

const[stateCity, setstateCity] = useState([]);
const[nearby, setnearby] = useState([]);

const showhoteldetail = () =>{
  axiosBaseURL.post('/hotellistbycity', htdisplay).then(res => {
        console.log("testabc", res.data);
        setcityList(res.data.city_by_hotels);
        setfaqsList(res.data.city_faqs);
        setstateCity(res.data.city_data);
        setnearby(res.data.near_by_hotel_data);     

    }).catch(err => {
        console.log(err)
    })
}

// on load function call for loading 
useEffect(() => {
  setUrl(location.pathname);
  showhoteldetail()
  window.scrollTo(0, 0);
},[location])

  return (
    <>
      <HeaderPage />

<div className="hero-cs-main city-hero-cs pt-0">
  {
   stateCity.map((val) => 
 
   <>
       <img
       src={val.city_banner_image}
       id="ImgTopBanner"
       alt="image" />
       <h1 className="location_name">Welcome to {val.city_name}</h1></>
 
)}
</div>

<section className="filters-cs cs-sc-padding pt-3">
  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <nav className="breadcrumb-cs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="https://www.lemontreehotels.com">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="https://www.lemontreehotels.com/hotels">Destination</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Hotels
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-md-8">
        <div className="row">
          <div className="col-lg-6 label-txt checkbox_cs-design">
            <span className="px-md-3 px-2 py-2 d-inline-block">
              Availability:
            </span>
            <div className="custom-control custom-radio custom-control-inline">
              <div className="px">
                <table id="RdFillter" className="filterAvail" border={0}>
                  <tbody>
                    <tr>
                      
                      <td>
                        <input
                          id="RdFillter_0"
                          type="radio"
                          name="RdFillter"
                          defaultValue="All"
                          defaultChecked="checked"
                          onclick="window.location.reload();"
                        />
                        <label htmlFor="RdFillter_0">All Hotels</label>
                      </td>
                      <td>
                                           
                      <Link to={"/DayUseRoom"} className='d-flex'>   <input
                          id="RdFillter_1"
                          type="radio"
                          name="RdFillter"
                          defaultValue="DayUse"
                          onclick="window.location.reload();"
                        />
                        <label htmlFor="RdFillter_1">Day Use</label></Link>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <select
              name="DdlServices"
              onchange="javascript:setTimeout('__doPostBack(\'DdlServices\',\'\')', 0)"
              id="DdlServices"
              className="city-select py-2 px-3"
            >
              <option selected="selected" value={0}>
                By Services
              </option>
              <option value={2}>Fitness Center</option>
              <option value={4}>Rooftop Jacuzzi</option>
              <option value={5}>Spa</option>
             
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section className="hotels-block voffset3">
  <article className="container">
  <div className="row mb-3">
  <div className="col-md-12">
    <div className="rooms-cs-main-updated-left sc-heading subHeading_cs-sc">
      
      {
stateCity.map((val) =>  
 <>  
 <h2>Hotels In {val.city_name}</h2>
   </>
)}     
    </div>
  </div>
</div>

    <div className="row">
      <div className="col-lg-12">
     
      <div className='container-fluid p-0' >  
           
      {cityList?.length>0 && (          
        <OwlCarousel  
          className="owl-carousel owl-theme aurika-top-hotel-slider" margin={8} autoplay ={true} loop={false}  nav={true} autoplayTimeout={8000} autoplaySpeed={4000} {...options} navText={[
            'Prev<span></span>',
            'Next<span></span>'
          ]} > 
             {
   cityList.map((val) =>  
           <div className='item'><img className="img" src= {val.hotel_image}/>
           <div className="hotel-bottom-info">
                                <h3>
                                <a target='_blank' onClick={() => navigate("/"+val.city_name)} >
                                       {val.hotel_name}
                                       </a>
                                  
                                </h3>
                            </div>
                            <div className="hotel-in-row-contentInner">
                            <a target='_blank' href={val.embed_map}>
                            <span className='address1'>
                            <FontAwesomeIcon className='mr-2' icon={faMapMarkerAlt} fontSize={12}/>{val.hotel_address}</span>
                              </a>

                              <p className="pt-2 m-0 distance">
  <strong>Distance: </strong>
  <FontAwesomeIcon className='pl-2' icon={faPlane} transform={{ rotate: -45 }} fontSize={12}/>
  {val.distance_from_airport} km

  <FontAwesomeIcon className='pl-2' icon={faTrain} fontSize={12}/>
   {val.distance_from_railway_station} km
</p>
<span className="treview" data-original-title="" title="">
  <span
    className="tripadver"
    title=""
  >
  
    <img
      src="https://www.lemontreehotels.com/assets/front/images/Tripadvisor-Logo.png"
      alt="Lemon Tree Hotels Trip advisor rating"
    /> 
    <div className="starRating">
      <img
        src={val.tripadvisor_rating_image}
        alt=""
      /> 
    </div>
  </span>
  <div className="reviews_count_cs">
 | {val.tripavisor_review_count} reviews
  </div>
</span>


</div>
                            <div className="btns-block">
                               {sessionStorage.setItem('hotelsynxis_id', val.synxis_id)}
                            <Link to={`../${val.brand_slug}/${val.city_alias}/${val.hotel_slug}`} className={"hotel-new-explore-btn underline nav-link" + (url === `../${val.brand_slug}/${val.city_alias}/${val.hotel_slug}` ?" active" : "")}>Explore</Link>
                                    
                              
                           
<button className="hotel-new-booknow-btn" onClick={() => window.open(`https://be.synxis.com/?adult=${adult}&arrive=${rangeStart}&chain=7710&child=${children}&currency=INR&depart=${rangeEnd}&hotel=${$("#getBeCityHotel").val()}&level=hotel&locale=en-US&rooms=${countroom}&shell=SBE&start=availresults&template=SBE&theme=initialTheme2`)}>
    Book Now
    </button>

                                {/* <a className="hotel-new-booknow-btn" href="#">Book Now</a> */}
                            </div></div>  
   )}

      </OwlCarousel>  
      )
    }
      </div>
                      
      </div>
    </div>
  </article>
</section>

<section className="hotels-block voffset3">
  <article className="container">
  <div className="row mb-3">
  <div className="col-md-12">
    <div className="rooms-cs-main-updated-left sc-heading subHeading_cs-sc">
      
      {
   stateCity.map((val) => 
 
   <>
   {/* <img
       src={window.location.origin + '/20230624122649625AGRA.jpg'} */}
      
    
 <h2>Hotels Near {val.city_name}</h2>
   </>
)}

      
    </div>
  </div>
</div>

    <div className="row">
      <div className="col-lg-12">
      <div className='container-fluid p-0' >    
        {nearby?.length>0 && (   
        <OwlCarousel  
          className="owl-carousel owl-theme aurika-top-hotel-slider"  margin={8} autoplay ={true} loop={false}  nav={true} autoplayTimeout={8000} autoplaySpeed={4000} {...options} navText={[
            'Prev<span></span>',
            'Next<span></span>'
          ]} > 
             {
   nearby.map((val) =>  {
    return (
      <>
    
           <div className='item'>
          <img className="img" src= {val.hotel_image}/>
           <div className="hotel-bottom-info">
                                <h3>
                                <a target='_blank' onClick={() => navigate("/"+val.city_name)} >
                                       {val.hotel_name}
                                       </a>
                                  
                                </h3>
                            </div>
                            <div className="hotel-in-row-contentInner">
                            <a target='_blank' href={val.embed_map}>
                            <span className='address1'>
                            <FontAwesomeIcon className='mr-2' icon={faMapMarkerAlt} fontSize={12}/>{val.hotel_address}</span>
                              </a>

                              <p className="pt-2 m-0 distance">
  <strong>Distance: </strong>
  <FontAwesomeIcon className='pl-2' icon={faPlane} transform={{ rotate: -45 }} fontSize={12}/>
  {val.distance_from_airport} km

  <FontAwesomeIcon className='pl-2' icon={faTrain} fontSize={12}/>
   {val.distance_from_railway_station} km
</p>
<span className="treview" data-original-title="" title="">
  <span
    className="tripadver"
    title=""
  >
  
    <img
      src="https://www.lemontreehotels.com/assets/front/images/Tripadvisor-Logo.png"
      alt="Lemon Tree Hotels Trip advisor rating"
    /> 
    <div className="starRating">
    <img
        src={val.tripadvisor_rating_image} alt="" />        
    </div>
  </span>
  <div className="reviews_count_cs">
 | {val.tripavisor_review_count} reviews.
  </div>
</span>


</div>

                            <div className="btns-block">
                            
                               <Link className="hotel-new-explore-btn" to={`../${val.brand_slug}/${val.city_alias}/${val.hotel_slug}`}>Explore</Link>           
                              
                          
<button className="hotel-new-booknow-btn" onClick={() => window.open(`https://be.synxis.com/?adult=${adult}&arrive=${rangeStart}&chain=7710&child=${children}&currency=INR&depart=${rangeEnd}&hotel=${$("#getBeCityHotel").val()}&level=hotel&locale=en-US&rooms=${countroom}&shell=SBE&start=availresults&template=SBE&theme=initialTheme2`)}>
    Book Now
    </button>
                                {/* <a className="hotel-new-booknow-btn" href="#">Book Now</a> */}
                            </div></div>  
                            </>
              );
            })}
      </OwlCarousel>  
      )}
      </div>
                      
      </div>
    </div>
  </article>
</section>


<section className="city-map cs-sc-padding">
  <div className="container">
    <div className="row mb-3">
      <div className="col-md-12">
        <div className="rooms-cs-main-updated-left sc-heading subHeading_cs-sc">
          <h2>Map</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
      {
   stateCity.map((val) => 

   <div dangerouslySetInnerHTML={{__html: val.city_map}}></div>

)}
      </div>
    </div>
  </div>
</section>
<section className="text-center py-4 coming-soon-hotels-cs welcome-sc">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
      {
   stateCity.map((val) => 
    <ShowMoreText
   /* Default options */
   lines={3}
   more="Read More"
   less="Read Less"
 >
        <div className="readMore-content">
          <p>
          <div dangerouslySetInnerHTML={{__html: val.shortdescription}}></div>
           
          </p>
          
       
      
          <span>
            
            <p>
              
            <div dangerouslySetInnerHTML={{__html: val.longdescription}}></div>
            </p>
            <p />
          </span>
   
        </div>
        </ShowMoreText>
        )}
      </div>
      
    </div>
  </div>
</section>


<section id="seccityfaq" className="faq-cs-sc cs-sc-padding location-cs-sc">
  <div className="container">
    <div className="row mb-3">
      <div className="col-md-12">
        <div className="sc-heading">
          <h2> Frequently Asked Questions</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">

        <div id="faq">
     
        {faqsList.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.title}
          answer={<div dangerouslySetInnerHTML={{__html: item.description}}></div>}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
         

       
        </div>
      </div>
    </div>
  </div>
</section>

      <FooterPage />

    </>
  )

}

export default CityHotelPage;

