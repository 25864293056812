import '../App.css';
import '../index.css';
import * as React from "react";
import HeaderPage from '../Header';
import FooterPage from '../Footer';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function SignInPage() {
  const [value, setValue] = useState()
  const [tokenname, settokenname] = React.useState('');
  React.useEffect(()=>{
    fetch('http://lemontreedemo.netcarrots.in/LTMemberAPI/API/GenrateToken', {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify( {'UserName':'LemonTree',
      'Password':'Lemon@123'})
    }).then(
      res => res.json())
      
      .then( res => {
        settokenname(res.token);
             console.log("token pc", tokenname)
            //  localStorage.setItem('tokenpc', tokenname);
            //  {sessionStorage.setItem('token1', tokenname)}
        // const accessToken = tokenname;
        // console.log("Token Name",accessToken)
        // Save the access token in a React state or state management system
      }
    )
 
    .catch(error => console.error(error));
  },[]);

  console.log("signup get result login", tokenname)
  const navigate = useNavigate();

  const [logindetail, setLogindetail] = useState({
    MobileNo: '',
    EmailId: '',
    OTP: '',

  });

  const [showButton, setShowButton] = useState(true);


  const Getopt = (e) => {
    e.preventDefault();
    setShowButton(!showButton);  
console.log("tkname", tokenname)
    if (logindetail.MobileNo!= "" && logindetail.MobileNo.match(/^[0-9]{10}$/)) {
      alert("Please enter 10 Digit Mobile No.")
    }
 
    const headers = { 'Content-Type': 'application/json', 'Authorization':'bearer '+ tokenname };
    axios.post(`${'http://lemontreedemo.netcarrots.in/LTMemberAPI/API/SignInAPI'}`,JSON.stringify(logindetail), {headers})
    .then((res) => {
         
      // setLogindetail(res.data)
      let result= res.data
      console.log("otp sent", result); 
      let statusofuser = false;
      localStorage.setItem('tokenpc', tokenname);
      if (result.errorCode=="1") {
        //statusofuser = true;
        setShowButton(!showButton);
      }
      else {
        setShowButton(showButton);  
        alert('User Does not Exist!')
      }

      // if (statusofuser == true) {
      //   alert(res.data.ErrorMessage)
      //   setShowButton(!showButton);
      // }
      // else {
      //   setShowButton(showButton);  
      //   alert('User Does not Exist!')
      // }


    }).catch((err) => console.log(err))
 
  };

  const editDetail = (e) => {
    setLogindetail({ ...logindetail, [e.target.name]: e.target.value })
  }

  const userSubmit = (e) => {
    e.preventDefault();

    if (!logindetail.OTP) {
      alert("Please enter OTP")
    }
    else{
      alert(JSON.stringify(logindetail))
    const headers = { 'Content-Type': 'application/json', 'Authorization':'bearer '+ tokenname };
    axios.post(`${'http://lemontreedemo.netcarrots.in/LTMemberAPI/API/SignInAPI'}`,JSON.stringify(logindetail), {headers})
    
    .then((res) => {
       let result = res.data;    
    
      console.log("final submit", result)

      if (result.errorCode == "0") {
        localStorage.setItem("uinfo", JSON.stringify(result.response))   
        console.log('user exist');
        navigate("/dashboard")
      }
      else {
        setShowButton(!showButton);  
        alert('User Does not Exist!')
      }


    }).catch((err) => console.log(err.ErrorMessage))
  }
  }


  return (
    <>
      <HeaderPage />
     
      <section className="MemberSignUp_hero_sc">
        <div className="container">
          <div className='row justify-content-center registerpage'>
            <div className='col-md-6 MemberSignIn_inner_form_cs bg-white'>
              <h2 className="form-title">Sign-in</h2>
              <nav>
                <div className="nav nav-tabs bg-white mb-3" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    Email
                  </button>
                  <button
                    className="nav-link"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    Phone
                  </button>

                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="row">

                    <div className="col-12">
                      <input type="text" name='EmailId' value={logindetail?.EmailId} className="form-control" placeholder="Email" aria-label="Email" />
                    </div>
                  </div>

                </div>
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                      <form className="login100-form validate-form">           
           
           {showButton && <div
             className="wrap-input100 validate-input"
             data-validate="Enter username">
               {/* <PhoneInput placeholder='Enter Mobile No.'
                    country={'in'} className="form-control"
                    name="MobileNo"
                    value={logindetail.MobileNo}
                    onChange={editDetail}
                    
                  />  */}
             <input type="text" name="MobileNo" value={logindetail?.MobileNo} maxLength="10" onChange={editDetail} className="form-control mt-2" placeholder="Enter Mobile Number" />
          
           </div>
           }
           {!showButton && <div
             className="wrap-input100 validate-input"
             data-validate="Enter password">
             <input type="password" name="OTP" value={logindetail?.OTP} onChange={editDetail} className="form-control" placeholder="OTP" />
             
           </div>
           }

           <div className="container-login100-form-btn">

             {showButton && <button onClick={Getopt} className="btn btn-secondary m-2">Get OTP</button>}

           
             {!showButton &&
               <button type='' onClick={userSubmit} className="btn btn-light m-2">Final Submit</button>
             }
           </div>

         </form>
                  
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-contact"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab">

                </div>
              </div>
              <div className="row g-3 my-2">
                <div className="col-12 col-lg-6">
                  <button type="submit" className="btn btn-primary submit_in">Sign-In</button>
                </div>
                <div className="col-12 col-lg-6 text-end">
                  <p className="p-text">
                    <strong>Already a member?</strong>
                    <a href="/signup">Join</a>
                  </p>

                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <div className="wrap-login100">
        
          </div>

      <FooterPage />


    </>
  )

}

export default SignInPage;

